import "whatwg-fetch";
import { useReducer, useMemo } from "react";

export function useReducerWithEnhancedDispatch(args: {
  reducer: React.Reducer<any, any>;
  initialAppState: any;
}): [any, React.Dispatch<any>] {
  const [state, dispatch] = useReducer(args.reducer, args.initialAppState);
  const dispatchEnhanced = useMemo(
    () => (action: any) => {
      if (!action.payload || !action.payload.request) {
        dispatch(action);
        return;
      }
      const beginType =
        typeof action.types === "string" ? action.types : action.types[0];
      const successType =
        typeof action.types === "string"
          ? `${action.types}_SUCCESS`
          : action.types[1];
      const failType =
        typeof action.types === "string"
          ? `${action.types}_FAILURE`
          : action.types[2];
      const startAction = { types: beginType, payload: action.payload };
      dispatch(startAction);

      let url = action.payload.request.url;
      // tslint:disable-next-line:no-console
      const headers: { [key: string]: string } = {
        Accept: "application/json",
        "X-APP-SESSIONID": state.session_id,
      };
      const params: RequestInit = {
        credentials: "omit",
        headers: headers as any,
        method: "",
      };
      if (
        action.payload.request.method &&
        action.payload.request.method.match(/get/i)
      ) {
        headers["Content-Type"] = "application/json; charset=utf-8";
        params.method = "get";
        if (action.payload.request.data) {
          url =
            url +
            "?" +
            Object.keys(action.payload.request.data)
              .map(
                k =>
                  encodeURI(k) +
                  "=" +
                  encodeURI(action.payload.request.data[k]),
              )
              .join("&");
        }
      } else if (
        action.payload.request.method === "POST" ||
        action.payload.request.method === "PUT"
      ) {
        headers["Content-Type"] = "application/json; charset=utf-8";
        params.method = action.payload.request.method;
        params.body = JSON.stringify(action.payload.request.data);
      } else {
        const formData = new FormData();
        params.method = action.payload.request.method;
        Object.keys(action.payload.request.data).forEach(name => {
          formData.append(name, action.payload.request.data[name]);
        });
        params.body = formData;
      }
      fetch(url, params)
        .then(response => {
          if (response.ok) {
            response.json().then(result => {
              const successAction = {
                payload: {
                  data: result,
                  params: { ...params, url, ...action.payload.request.data },
                },
                types: successType,
              };
              dispatch(successAction);
              return;
            });
          } else if (response.status === 403) {
            const logoutAction = { types: "LOGOUT_SUCCESS" };
            dispatch(logoutAction);
            return;
          } else if (response.status === 503) {
            const action503 = { types: "ACTION_503" };
            dispatch(action503);
            return;
          } else {
            if (response.status === 400) {
              response.json().then(json => {
                const failAction = {
                  payload: {
                    error: {
                      message: json.message,
                      field: json.field,
                      status: response.status,
                    },
                    params: { ...params, url, ...action.payload.request.data },
                  },
                  types: failType,
                };
                dispatch(failAction);
              });
              return;
            } else {
              const failAction = {
                payload: {
                  error: {
                    message: response.statusText,
                    status: response.status,
                  },
                  params: { ...params, url, ...action.payload.request.data },
                },
                types: failType,
              };
              dispatch(failAction);
              return;
            }
          }
        })
        .catch(() => {
          const failAction = {
            payload: {
              error: { statusText: "fetch error" },
              params: { ...params, url },
            },
            types: failType,
          };
          dispatch(failAction);
        });
    },
    [state.session_id],
  );
  return [state, dispatchEnhanced];
}
