import React from "react";

import styles from "./HintCard.module.scss";

interface IHintCardProps {
  title: string;
  width?: string;
  children: React.ReactNode;
}

export const HintCard: React.FC<IHintCardProps> = ({
  title,
  width = "100%",
  children,
}) => {
  return (
    <div className={styles.hintCard} style={{ width }}>
      <p className={styles.title}>{title}</p>
      <p className={styles.text}>{children}</p>
    </div>
  );
};

export default HintCard;
