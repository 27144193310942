import React, { useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import clsx from "clsx";

export interface IVideoJSProps {
  className?: string;
  sourceUrl: string;
  options?: videojs.PlayerOptions;
  playing?: boolean;
  showVideo: boolean;
  onReady?: (player: videojs.Player) => void;
}

const defaultOptions: (
  sourceUrl: string,
) => videojs.PlayerOptions = sourceUrl => ({
  autoplay: false,
  controls: true,
  responsive: true,
  fill: true,
  preload: "none",
  sources: [
    {
      src: sourceUrl + ".m3u8",
      type: "application/x-mpegURL",
    },
  ],
  poster: sourceUrl + ".jpg",
});

export const VideoJS: React.FC<IVideoJSProps> = ({
  className = "",
  sourceUrl,
  options,
  playing,
  showVideo,
  onReady,
}) => {
  const videoRef = useRef(null);
  const playerRef = useRef<videojs.Player | null>(null);

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = (playerRef.current = videojs(
        videoElement,
        options ?? defaultOptions(sourceUrl),
        () => {
          onReady && onReady(player);
        },
      ));
    }
  }, [options, videoRef, sourceUrl, onReady]);

  React.useEffect(() => {
    if (playerRef.current) {
      playerRef.current.src({
        src: sourceUrl + ".m3u8",
        type: "application/x-mpegURL",
      });
      playerRef.current.poster(sourceUrl + ".jpg");
    }
  }, [sourceUrl]);

  React.useEffect(() => {
    if (playerRef.current) {
      playing && showVideo
        ? playerRef.current.play()
        : playerRef.current.pause();
    }
  }, [playerRef, playing, showVideo]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className={clsx("video-js vjs-big-play-centered", className)}
      />
    </div>
  );
};

export default VideoJS;
