import React from "react";

import AssessmentPage1_1Icon from "../assets/svg/assessment_glide_1_1.svg";
import AssessmentPage1_2Icon from "../assets/svg/assessment_glide_1_2.svg";
import AssessmentPage2_1Icon from "../assets/svg/assessment_glide_2_1.svg";
import AssessmentPage2_2Icon from "../assets/svg/assessment_glide_2_2.svg";
import AssessmentPage3_1Icon from "../assets/svg/assessment_glide_3_1.svg";
import AssessmentPage3_2Icon from "../assets/svg/assessment_glide_3_2.svg";
import AssessmentPage4_1Icon from "../assets/svg/assessment_glide_4_1.svg";
import AssessmentPage4_2Icon from "../assets/svg/assessment_glide_4_2.svg";
import AssessmentPage5_1Icon from "../assets/svg/assessment_glide_5_1.svg";
import AssessmentPage5_2Icon from "../assets/svg/assessment_glide_5_2.svg";
import AssessmentPage6_1Icon from "../assets/svg/assessment_glide_6_1.svg";
import AssessmentPage6_2Icon from "../assets/svg/assessment_glide_6_2.svg";
import AssessmentPage7_1Icon from "../assets/svg/assessment_glide_7_1.svg";
import AssessmentPage7_2Icon from "../assets/svg/assessment_glide_7_2.svg";

const score1 = `この力の考え方や
この力が必要な理由が
よくわからない。`;
const score2 = `この力を意識して
行動したことはないが、
大切な考え方だと思う。`;
const score3 = `人から言われて、
この力を意識して
行動しようと取り組んだ
ことがある。`;
const score4 = `この力を意識して
行動したことで、
自分や周囲に具体的な
変化を起こした
ことがある。`;
const score5 = `この力を強く意識して、
自ら行動したことが
何度もある。`;
const score6 = `この力を強く意識して
自ら行動したことで、
自分や周囲に具体的な
変化を起こしたことが
何度もある。`;
const score7 = `常にこの力を
発揮して行動し、
自分なりの反省や改善を
日常的に行っている。`;
const score8 = `常にこの力を発揮して
未来に向けて行動でき、
他者がこの力を発揮する
サポートも行っている。`;

export interface IAssessmentContent {
  id: number;
  titleForStudentGlide: string;
  titleForTeacherGlide: string;
  questionTitle: string;
  subQuestionTitle?: string;
  personA?: string;
  personB?: string;
  personASP?: string;
  personBSP?: string;
  questionContentForStudent: string;
  questionContentForTeacher: string;
  iconGlide1?: string;
  iconGlide2?: string;
  score1: string;
  score2: string;
  score3: string;
  score4: string;
  score5: string;
  score6: string;
  score7: string;
  score8: string;
  placeholder?: string;
}

interface IScoreLabels {
  label1: string;
  label2: string;
  label3: string;
  label4: string;
  label5: string;
  label6: string;
  label7: string;
  label8: string;
}

export const PAGE_1: IAssessmentContent = {
  id: 1,
  titleForStudentGlide: "7つの素養①",
  titleForTeacherGlide: "7つの素養①",
  questionTitle: "自分で考えて動く力",
  subQuestionTitle: "- 主体性 -",
  questionContentForStudent: `身の回りに何か良くないことが起きた時に、誰かのせいにせず、自分でどうするかを考えようとする姿勢。
  この力は、「自分の考えや行動によって、身の回りに変化を起こした経験」を繰り返すことで身につけることができます。`,
  questionContentForTeacher: `身の回りに何か良くないことが起きた時に、誰かのせいにせず、自分でどうするかを考えようとする姿勢。
  この力は、「自分の考えや行動によって、身の回りに変化を起こした経験」を繰り返すことで身につけることができます。`,
  personA: `うちの会社って
  こういうところが
  ダメなんだよ。`,
  personB: `どうしようかな。
  こうすればみんな喜ぶ
  かも。やってみよう。`,
  personASP: `うちの会社ってこういうところが
  ダメなんだよ。`,
  personBSP: `どうしようかな。こうすれば
  みんな喜ぶかも。やってみよう。`,
  iconGlide1: AssessmentPage1_1Icon,
  iconGlide2: AssessmentPage1_2Icon,
  score1,
  score2,
  score3,
  score4,
  score5,
  score6,
  score7,
  score8,
  placeholder:
    "例：合唱コンクールで、みんながやる気を出してくれるように練習前に話し合いの時間をとった。／家族が家事を始める前に、自分から申し出て家事をやってみる。",
};

export const PAGE_2: IAssessmentContent = {
  id: 2,
  titleForStudentGlide: "7つの素養②",
  titleForTeacherGlide: "7つの素養②",
  questionTitle: "推進し実現する力",
  subQuestionTitle: "- 実現思考 -",
  questionContentForStudent: `やりたいことや問題に対して、「どうすれば実現できるか」と順序立てて考えようとする姿勢。
  この力は、「捉え方や考え方次第で、できないことができるようになった経験」を繰り返すことで身につけることができます。`,
  questionContentForTeacher: `やりたいことや問題に対して、「どうすれば実現できるか」と順序立てて考えようとする姿勢。
  この力は、「捉え方や考え方次第で、できないことができるようになった経験」を繰り返すことで身につけることができます。`,
  personA: `誰もやったこと
  ないんだから
  できるわけないよ…`,
  personB: `順序立てて考えれば、
  方法がみつかった！
  できるかもしれない！`,
  personASP: `誰もやったことないんだから
  できるわけないよ…`,
  personBSP: `順序立てて考えれば、方法が
  みつかった！できるかもしれない！`,
  iconGlide1: AssessmentPage2_1Icon,
  iconGlide2: AssessmentPage2_2Icon,
  score1,
  score2,
  score3,
  score4,
  score5,
  score6,
  score7,
  score8,
  placeholder:
    "例：国語のテストの点を上げたくて、苦手分野の分析や漢字の練習ノルマを計画して実行したことがある。その結果点数が上がった。／ギターのプロになるために、オーディションの合格基準を研究して練習してみる。",
};

export const PAGE_3: IAssessmentContent = {
  id: 3,
  titleForStudentGlide: "7つの素養③",
  titleForTeacherGlide: "7つの素養③",
  questionTitle: "相手の立場で考える力",
  subQuestionTitle: "- 他者視点 -",
  questionContentForStudent: `相手の想いや考えを想像した上で考えようとする姿勢。この力は、「相手の立場に立って考え行動し、問題を解決したり、
  相手の気持ちに共感したりする経験」を繰り返すことで身につけることができます。`,
  questionContentForTeacher: `相手の想いや考えを想像した上で考えようとする姿勢。この力は、「相手の立場に立って考え行動し、問題を解決したり、
  相手の気持ちに共感したりする経験」を繰り返すことで身につけることができます。`,
  personA: `なんでわかってくれ
  ないの？人の意見なんか
  聞かなくて良いや。`,
  personB: `仲間の視点に立つと、
  人と新しいものをつく
  れる！楽しめる！`,
  personASP: `なんでわかってくれないの？
  人の意見なんか聞かなくて良いや。`,
  personBSP: `仲間の視点に立つと、人と
  新しいものをつくれる！楽しめる！`,
  iconGlide1: AssessmentPage3_1Icon,
  iconGlide2: AssessmentPage3_2Icon,
  score1,
  score2,
  score3,
  score4,
  score5,
  score6,
  score7,
  score8,
  placeholder:
    "例：委員会の仕事で、先生に「図書室を初めて使う人のために利用方法のポスターを作ろう」と言われて作ったことがある。図書室を初めて使う人の視点に立って考えて作った。／運動が好きな人も苦手な人も、全員が楽しめる体育祭にするにはどうしたら良いのかを考えてみる。",
};

export const PAGE_4: IAssessmentContent = {
  id: 4,
  titleForStudentGlide: "7つの素養④",
  titleForTeacherGlide: "7つの素養④",
  questionTitle: "社会的な視点で考える力",
  subQuestionTitle: "- 社会的有用感 -",
  questionContentForStudent: `周りや社会のために自分には何ができるかを考え、誰かの役に立とうとする姿勢。この力は、「自分の行動によって身の回りを
  より良くすることができるという感覚をもとに行動を起こし、変化を生み出した経験」を繰り返すことで身につけることができます。`,
  questionContentForTeacher: `周りや社会のために自分には何ができるかを考え、誰かの役に立とうとする姿勢。この力は、「自分の行動によって身の回りを
  より良くすることができるという感覚をもとに行動を起こし、変化を生み出した経験」を繰り返すことで身につけることができます。`,
  personA: `働くイメージが
  湧かない。
  働きたくないな。`,
  personB: `自分なら、
  誰かの役に立てる！
  もっと良くしよう！`,
  personASP: `働くイメージが湧かない。
  働きたくないな。`,
  personBSP: `自分なら、誰かの役に立てる！
  もっと良くしよう！`,
  iconGlide1: AssessmentPage4_1Icon,
  iconGlide2: AssessmentPage4_2Icon,
  score1,
  score2,
  score3,
  score4,
  score5,
  score6,
  score7,
  score8,
  placeholder:
    "例：部活で後輩が辛そうにしていたので、声をかけて相談に乗ってあげたことがある。「アドバイスをもらえて元気が出た」と言ってくれた。／クラス行事に貢献するために、情報の授業で習ったPCスキルを活かしてプレゼン資料を作ってみる。",
};

export const PAGE_5: IAssessmentContent = {
  id: 5,
  titleForStudentGlide: "7つの素養⑤",
  titleForTeacherGlide: "7つの素養⑤",
  questionTitle: "自分で成長機会を創る力",
  subQuestionTitle: "- 成長志向 -",
  questionContentForStudent: `知らないこと・予想外のことに対して前向きに取り組み、自分の意志で成長しようとする姿勢。この力は、意図的に新しいことに取り
  組み、できなかったことができるようになったなど「自分の成長を実感した経験」を繰り返すことで身につけることができます。`,
  questionContentForTeacher: `知らないこと・予想外のことに対して前向きに取り組み、自分の意志で成長しようとする姿勢。この力は、意図的に新しいことに取り
  組み、できなかったことができるようになったなど「自分の成長を実感した経験」を繰り返すことで身につけることができます。`,
  personA: `どうせ自分なんて…
  このくらいで
  いいや。`,
  personB: `自分の道はこれだ！
  やる気が湧いてくる、
  成長したい！`,
  personASP: `どうせ自分なんて…
  このくらいでいいや。`,
  personBSP: `自分の道はこれだ！やる気が
  湧いてくる、成長したい！`,
  iconGlide1: AssessmentPage5_1Icon,
  iconGlide2: AssessmentPage5_2Icon,
  score1,
  score2,
  score3,
  score4,
  score5,
  score6,
  score7,
  score8,
  placeholder:
    "例：英語を話せるようになりたいなら〇〇の本を読んだ方がいい、と言われて読んでみたら、長文読解に苦手がなくなった。／これからは料理の腕をもっと磨くために、人に教えることを意識したレシピノートを作ってインターネットで公開してみる",
};

export const PAGE_6: IAssessmentContent = {
  id: 6,
  titleForStudentGlide: "7つの素養⑥",
  titleForTeacherGlide: "7つの素養⑥",
  questionTitle: "自分で視野を広げる力",
  subQuestionTitle: "- 視野拡大志向 -",
  questionContentForStudent: `知らない分野や世界に関心を持ち、積極的に自分の世界観を広げようとする姿勢。この力は、「自分の世界は自分の意志で広げられると理解し
  世界で起きている課題や事象に関心を持ち、多くの選択肢の中から自分の行動を選択する経験」を繰り返すことで身につけることができます。`,
  questionContentForTeacher: `知らない分野や世界に関心を持ち、積極的に自分の世界観を広げようとする姿勢。この力は、「自分の世界は自分の意志で広げられると理解し
  世界で起きている課題や事象に関心を持ち、多くの選択肢の中から自分の行動を選択する経験」を繰り返すことで身につけることができます。`,
  personA: `めんどうくさいし、
  知らないことは知らな
  くっていいや。`,
  personB: `もっと知りたい。
  どうやったら知れるか
  方法を考えよう！`,
  personASP: `めんどうくさいし、知らない
  ことは知らなくっていいや。`,
  personBSP: `もっと知りたい。どうやったら
  知れるか方法を考えよう！`,
  iconGlide1: AssessmentPage6_1Icon,
  iconGlide2: AssessmentPage6_2Icon,
  score1,
  score2,
  score3,
  score4,
  score5,
  score6,
  score7,
  score8,
  placeholder:
    "例：自分の行きたい大学や学部が決まらないので、あえて興味がない本を読んできたり先生になぜ先生になったのかを聞いてみたりしたら、視野が広がって行きたい学部を決めることができた。／夏休みにフランス語の雑誌を読んでみて、フランス語とフランスの文化について学んでみる。",
};
export const PAGE_7: IAssessmentContent = {
  id: 7,
  titleForStudentGlide: "7つの素養⑦",
  titleForTeacherGlide: "7つの素養⑦",
  questionTitle: "自分で目的を設定する力",
  subQuestionTitle: "- 中長期的ビジョン構想力 -",
  questionContentForStudent: `どんな未来を実現したいかを考え、日々の行動に自分なりの目的を決めようとする姿勢。
  この力は、「自分で目的を設定してそれを実現する経験」を繰り返すことで身につけることができます。`,
  questionContentForTeacher: `どんな未来を実現したいかを考え、日々の行動に自分なりの目的を決めようとする姿勢。
  この力は、「自分で目的を設定してそれを実現する経験」を繰り返すことで身につけることができます。`,
  personA: `別に将来やりたいこと
  なんてないし
  興味もないよ。`,
  personB: `こんなことがやりたい！
  いつまでに、何をすれば
  良いか決めよう。`,
  personASP: `別に将来やりたいこと
  なんてないし興味もないよ。`,
  personBSP: `こんなことがやりたい！いつまでに、
  何をすれば良いか決めよう。`,
  iconGlide1: AssessmentPage7_1Icon,
  iconGlide2: AssessmentPage7_2Icon,
  score1,
  score2,
  score3,
  score4,
  score5,
  score6,
  score7,
  score8,
  placeholder:
    "例：部活で嫌いだったトレーニングがあったが、どこの筋肉をつけるためにやっているのか、その筋肉をつけるとどんな動きができるようになるかを考えて取り組んだ。／今度の模試について、結果をちゃんと分析して苦手分野を把握したり、克服のための勉強計画を立ててみたりする。",
};

export const NEXT_ACTIONS_PAGE: IAssessmentContent = {
  id: 8,
  titleForStudentGlide: "7つの素養 まとめ",
  titleForTeacherGlide: "7つの素養 まとめ",
  questionTitle: "現状を把握し、未来について考えてみましょう。",
  questionContentForStudent: `自分の経験が増えるたびに、いつでも、何回でも記録しましょう。`,
  questionContentForTeacher: `自分の経験が増えるたびに、いつでも、何回でも記録しましょう。`,
  score1: "",
  score2: "",
  score3: "",
  score4: "",
  score5: "",
  score6: "",
  score7: "",
  score8: "",
};

export const ASSESSMENT_CONTENTS: IAssessmentContent[] = [
  PAGE_1,
  PAGE_2,
  PAGE_3,
  PAGE_4,
  PAGE_5,
  PAGE_6,
  PAGE_7,
];

export const SCORE_LABELS: IScoreLabels = {
  label1: "よくわからない",
  label2: "わかるけどできている自信はない",
  label3: "言われたからやってみた",
  label4: "言われてやって変化を実感した",
  label5: "自分からやってみた",
  label6: "自分でやって変化を実感した",
  label7: "日常の習慣になっている",
  label8: "周囲のサポートもしている",
};

export const SCORE_LABELS_JSX = {
  label1: <>よくわからない</>,
  label2: (
    <>
      わかるけどできて
      <br />
      いる自信はない
    </>
  ),
  label3: (
    <>
      言われたから
      <br />
      やってみた
    </>
  ),
  label4: (
    <>
      言われてやって
      <br />
      変化を実感した
    </>
  ),
  label5: (
    <>
      自分から
      <br />
      やってみた
    </>
  ),
  label6: (
    <>
      自分でやって
      <br />
      変化を実感した
    </>
  ),
  label7: (
    <>
      日常の習慣に
      <br />
      なっている
    </>
  ),
  label8: (
    <>
      周囲のサポート
      <br />
      もしている
    </>
  ),
};

export const SCORE_LABELS_JSX_SP = {
  label1: (
    <>
      よく
      <br />
      わからない
    </>
  ),
  label2: (
    <>
      わかるけど
      <br />
      できている
      <br />
      自信はない
    </>
  ),
  label3: (
    <>
      言われたから
      <br />
      やってみた
    </>
  ),
  label4: (
    <>
      言われて
      <br />
      やって変化を
      <br />
      実感した
    </>
  ),
  label5: (
    <>
      自分から
      <br />
      やってみた
    </>
  ),
  label6: (
    <>
      自分で
      <br />
      やって変化を
      <br />
      実感した
    </>
  ),
  label7: (
    <>
      日常の習慣に
      <br />
      なっている
    </>
  ),
  label8: (
    <>
      周囲の
      <br />
      サポート
      <br />
      もしている
    </>
  ),
};

export const LABELS: { [key: number]: string } = {
  1: "自分で考えて動く力",
  2: "推進し実現する力",
  3: "相手の立場で考える力",
  4: "社会的な視点で考える力",
  5: "自分で成長機会を創る力",
  6: "自分で視野を広げる力",
  7: "自分で目的を設定する力",
};

export const X_LABELS: string[] = [
  "自分で\n考えて\n動く力",
  "推進し\n実現する力",
  "相手の\n立場で\n考える力",
  "社会的な\n視点で\n考える力",
  "自分で\n成長機会を\n創る力",
  "自分で\n視野を\n広げる力",
  "自分で\n目的を\n設定する力",
];

export const X_LABELS_JSX = [
  <>
    自分で考えて
    <br />
    動く力
  </>,
  <>
    推進し
    <br />
    実現する力
  </>,
  <>
    相手の立場で
    <br />
    考える力
  </>,
  <>
    社会的な視点で
    <br />
    考える力
  </>,
  <>
    自分で成長
    <br />
    機会を創る力
  </>,
  <>
    自分で視野を
    <br />
    広げる力
  </>,
  <>
    自分で目的を
    <br />
    設定する力
  </>,
];

export const X_LABELS_JSX_SP = [
  <>主体性</>,
  <>
    実現
    <br />
    思考
  </>,
  <>
    他者
    <br />
    視点
  </>,
  <>
    社会的
    <br />
    有用感
  </>,
  <>
    成長
    <br />
    志向
  </>,
  <>
    視野
    <br />
    拡大志向
  </>,
  <>
    中長期的
    <br />
    ビジョン
    <br />
    構想力
  </>,
];

export const CSV_HEADERS = [
  "学年",
  "クラス",
  "実施日",
  "実施回",
  "ログインID",
  "学籍番号",
  "生徒名",
  "生徒・①自分で考えて動く力・経験",
  "生徒・①自分で考えて動く力・コメント",
  "生徒・②推進し実現する力・経験",
  "生徒・②推進し実現する力・コメント",
  "生徒・③相手の立場で考える力・経験",
  "生徒・③相手の立場で考える力・コメント",
  "生徒・④社会的な視点で考える力・経験",
  "生徒・④社会的な視点で考える力・コメント",
  "生徒・⑤自分で成長機会を創る力・経験",
  "生徒・⑤自分で成長機会を創る力・コメント",
  "生徒・⑥自分で視野を広げる力・経験",
  "生徒・⑥自分で視野を広げる力・コメント",
  "生徒・⑦自分で目的を設定する力・経験",
  "生徒・⑦自分で目的を設定する力・コメント",
  "生徒・まとめ・7つの素養を振り返っての「自分の強み」",
  "生徒・まとめ・将来の夢、もしくは将来どんな人間になりたいか",
  "生徒・まとめ・将来の目標実現に向けての課題や必要な経験",
  "先生・メモ",
];

export const REASON_LABEL: {
  upTexts: { [key: number]: string };
  downText: string;
  defaultText: string;
} = {
  upTexts: {
    1: "前回記録した時点から今日までに、この力について何か気づいたこと、考えたことがなかったか思い出して書いてみましょう。",
    2: "前回記録した時点から今日までに、この力がなぜ大切だと思ったのか、考えたことやきっかけになった経験があれば書いてみましょう。",
    3: "前回記録した時点から今日までに、この力を意識して取り組んだことを書いてみましょう。",
    4: "前回記録した時点から今日までに、この力を意識して取り組んだことや、取り組みによって自分や周囲にどんな変化を起こすことができたかを書いてみましょう。",
    5: "前回記録した時点から今日までに、この力を意識して自分から取り組んだ経験について、いくつかのエピソードを書いてみましょう。",
    6: "前回記録した時点から今日までに、この力を意識して自分から取り組んだ経験について、いくつかのエピソードを書いてみましょう。また取り組みによって自分や周囲にどんな変化を起こすことができたかを書いてみましょう。",
    7: "前回記録した時点から今日までに、この力を意識して自分から取り組んだ経験を書いてみましょう。またその取り組みにおいて「もっとこうすればよかった」「次はこうしたい」と思ったことや考えたことを書いてみましょう。",
    8: "前回記録した時点から今日までに、自分ではない他者がこの力を意識して行動する上でどんなサポート・支援を行うことができたのかを書いてみましょう。",
  },
  downText:
    "前回記録から自己評価を下げた理由について、考えたことや学んだこと、きっかけになった経験があれば書いてみましょう。",
  defaultText:
    "この段階を選んだ理由や、過去の経験を書いてみましょう。\nもしも「気づいたこと」や「今後やりたいこと」などもあれば、それも書きましょう。",
};
