import React from "react";
import { useMemo, useCallback } from "react";

import {
  IActiveLearningAnswers,
  IActiveLearningIssue,
} from "../../../../state";
import { Context, updateActiveLearningAnswers } from "../../../../studentStore";
import Header from "../../../../organisms/ActiveLearning/Header";
import EndLesson from "../../../../organisms/ActiveLearning/EndLesson";
import Issue1 from "../../../../organisms/student/ActiveLearningLessons/Lesson6/Issue1";
import Issue2 from "../../../../organisms/student/ActiveLearningLessons/Lesson6/Issue2";
import styles from "../ActiveLearningLesson.module.scss";
import useWaitApiCall from "../../../../hooks/useWaitApiCall";
import SaveAnswerButton from "../../../../atoms/ActiveLearning/SaveAnswerButton";

export const Lesson6 = () => {
  const { state, dispatch } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const [answers, setAnswers] = React.useState<IActiveLearningAnswers[]>([]);

  React.useEffect(() => {
    setAnswers([...state.active_learning_answers]);
  }, [state.active_learning_answers]);

  const currentActiveLearning = useMemo(() => {
    return state.active_learning_data_by_lesson;
  }, [state.active_learning_data_by_lesson]);

  const findIssue = useCallback(
    (no: number): IActiveLearningIssue | undefined =>
      currentActiveLearning.issues?.find(issue => issue.no === no),
    [currentActiveLearning.issues],
  );

  const saveAnswers = () => {
    const params = answers;
    const { material_code, no } = state.active_learning_data_by_lesson;
    if (calling) return;
    return dispatch(
      peep(
        updateActiveLearningAnswers(material_code, no, JSON.stringify(params)),
      ),
    );
  };

  return (
    <>
      <div className={styles.wrapper} id="accordion">
        <Header
          no={currentActiveLearning.no}
          label={"実現までの道筋を描く"}
          title={currentActiveLearning.title}
          read={
            "今まで考えてきた「問い」、「課題」、解決のアイデアを共有して様々な考えに触れよう。	\n発表して感じたことと発表に対してもらった意見を参考に、どんな部分が良かったのか、どうすればもっと良くなるのかを考えよう。"
          }
        />
        <div className={styles.container}>
          <div className={styles.section}>
            <Issue1
              issue={findIssue(1)}
              answers={answers}
              setAnswers={setAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <Issue2
              issue={findIssue(2)}
              answers={answers}
              setAnswers={setAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <EndLesson
              text={
                "次のLessonでは、解決のアイデアの実現のために計画をする。次回までに、アイデアを実現するためにはどのようなステップが必要なのか、イメージを膨らませておこう。"
              }
              saveAnswers={saveAnswers}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Lesson6;
