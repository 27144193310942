import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { IAnswer } from "../../../../../constants/active_learning";
import Card from "../../../../../atoms/ActiveLearning/Card";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import styles from "../../ActiveLearningIssue.module.scss";
import { ReactComponent as IconLive } from "../../../../../assets/images/ActiveLearning/icon_live.svg";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface IAnswerToQ1 extends IAnswer {
  value: string;
}

export const Issue1 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 2;
  const question = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);

  const [answer, setAnswer] = useState<IAnswerToQ1>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question?.order_num || NaN,
    question_id: question?.id || NaN,
    value: "",
  });

  useEffect(() => {
    const targetAnswer = state.active_learning_answers.find(
      answer =>
        answer.issue_no === currentIssueNo &&
        answer.question_no === question?.order_num,
    );
    const answerFormatOfI1Q1: IAnswerToQ1 = {
      id: targetAnswer?.id || 0,
      issue_no: currentIssueNo,
      question_no: 1,
      question_id: targetAnswer?.question_id || 0,
      value: targetAnswer?.contents?.value || "",
    };
    setAnswer({ ...answerFormatOfI1Q1 });
  }, [state.active_learning_answers, question?.order_num]);

  useEffect(() => {
    const answers = props.answers.filter(
      answer =>
        !(
          answer.issue_no === currentIssueNo &&
          answer.question_no === question?.order_num
        ),
    );
    props.setAnswers([
      ...answers,
      {
        id: answer.id || null,
        issue_no: answer.issue_no,
        question_no: question?.order_num || NaN,
        question_id: question?.id || NaN,
        contents: {
          value: answer.value,
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, question?.order_num, question?.id]);

  const changeAnswer = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setAnswer({
      id: answer.id,
      question_id: question?.id || NaN,
      question_no: question?.order_num || NaN,
      issue_no: currentIssueNo,
      value,
    });
  };

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          自分が「やりたい」「考えたい」「解決したい」「こうしたい」という想いが、「問い」を立てる材料になる。
          <br />
          選んだテーマの内容とヒントを参考に、自分がこれから考えていきたいと思うことを思いつく限り入力しよう。
          <br />
          <br />
          「自分だったらこうしたい」を引き出すヒント
          <br />
          『誰に対して何をしたい？』『どんな場所/場面で？』『どんなふうに？』
          <br />
          <br />
          例：テーマに対して自分は自然保護の観点で考えていきたいと思った。/自分は特に小学生以下の子どもたちに対して考えていきたいと思った。
        </p>
      </div>
      {question && (
        <div className={styles.themEntry}>
          <div className={styles.textAreaWrapper}>
            <TextArea value={answer.value} handleChange={changeAnswer} />
          </div>
        </div>
      )}
      <Card label={"TRY"} icon={<IconLive />} title={"グループワーク"}>
        ①同じテーマ同士でグループをつくろう。
        <br />
        ②選んだテーマに対して「こうしたい」と思ったこととその理由をを発表し合おう。
        <br />
        ③このテーマに対して他にどんな事ができそうかを考えてみよう。
      </Card>
    </div>
  );
};
export default Issue1;
