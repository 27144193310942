import React, { Dispatch, SetStateAction, useMemo } from "react";
import clsx from "clsx";

import {
  Context,
  activeLearningStoryBookPath,
} from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningTheme,
  IActiveLearningAnswers,
} from "../../../../../state";
import {
  IAnswerToI2Q1,
  IAnswerToI2Q2,
} from "../../../../../constants/active_learning";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import ThemeCard from "../../../../../organisms/ActiveLearning/ThemeCard";
import ThemeModal from "../../../../../organisms/ActiveLearning/ThemeModal";
import styles from "../../ActiveLearningIssue.module.scss";
import { ReactComponent as IconLive } from "../../../../../assets/images/ActiveLearning/icon_live.svg";
import notFoundImg from "../../../../../assets/images/ActiveLearning/not_found_theme.jpg";

interface IActiveLearningIssue2Props {
  issue?: IActiveLearningIssue;
  themes: IActiveLearningTheme[];
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
  fieldCategories: { id: number; name: string }[];
  fieldCounts: { [fieldId: number]: number };
  actionCounts: { [actionId: number]: number };
}

export const Issue2 = (props: IActiveLearningIssue2Props) => {
  const { state } = React.useContext(Context);
  const currentIssueNo = 2;
  const question1 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);

  const question2 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 2);
  }, [props.issue?.questions]);

  const [answerQ1, setAnswerQ1] = React.useState<IAnswerToI2Q1>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question1?.order_num || NaN,
    question_id: question1?.id || NaN,
    theme_id: NaN,
  });
  const [answerQ2, setAnswerQ2] = React.useState<IAnswerToI2Q2>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question2?.order_num || NaN,
    question_id: question2?.id || NaN,
    reason: "",
  });
  const [themeModal, setThemeModal] = React.useState<{
    isShow: boolean;
    id: number;
  }>({
    isShow: false,
    id: 0,
  });

  React.useEffect(() => {
    const answerI2Q1 = state.active_learning_answers.find(
      answer =>
        answer.issue_no === 2 && answer.question_no === question1?.order_num,
    );
    const answerFormatOfI2Q1: IAnswerToI2Q1 = {
      id: answerI2Q1?.id || 0,
      issue_no: currentIssueNo,
      question_no: 1,
      question_id: answerI2Q1?.question_id || 0,
      theme_id: answerI2Q1?.contents?.theme_id || 0,
    };
    setAnswerQ1({ ...answerFormatOfI2Q1 });
    const answerI2Q2 = state.active_learning_answers.find(
      answer =>
        answer.issue_no === 2 && answer.question_no === question2?.order_num,
    );
    const answerFormatOfI2Q2: IAnswerToI2Q2 = {
      id: answerI2Q2?.id || 0,
      issue_no: currentIssueNo,
      question_no: 2,
      question_id: answerI2Q2?.question_id || 0,
      reason: answerI2Q2?.contents?.reason || "",
    };
    setAnswerQ2({ ...answerFormatOfI2Q2 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.active_learning_answers]);

  React.useEffect(() => {
    // この設問の回答は１件までしか存在しない想定のため、常に上書き
    const answers = props.answers.filter(
      answer =>
        !(
          answer.issue_no === currentIssueNo &&
          answer.question_no === question1?.order_num
        ),
    );
    props.setAnswers([
      ...answers,
      {
        id: answerQ1.id || null,
        issue_no: answerQ1.issue_no,
        question_no: question1?.order_num || NaN,
        question_id: question1?.id || NaN,
        contents: {
          theme_id: answerQ1.theme_id,
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerQ1]);

  React.useEffect(() => {
    // この設問の回答は１件までしか存在しない想定のため、常に上書き
    const answers = props.answers.filter(
      answer =>
        !(
          answer.issue_no === currentIssueNo &&
          answer.question_no === question2?.order_num
        ),
    );
    props.setAnswers([
      ...answers,
      {
        id: answerQ2.id || null,
        issue_no: answerQ2.issue_no,
        question_no: question2?.order_num || NaN,
        question_id: question2?.id || NaN,
        contents: {
          reason: answerQ2.reason,
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerQ2]);

  const selectTheme = (selectedThemeId: number) => {
    setAnswerQ1({
      id: answerQ1.id,
      question_id: question1?.id || NaN,
      question_no: question1?.order_num || NaN,
      issue_no: currentIssueNo,
      theme_id: selectedThemeId,
    });
  };

  const changeReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setAnswerQ2({
      id: answerQ2.id,
      question_id: question2?.id || NaN,
      question_no: question2?.order_num || NaN,
      issue_no: currentIssueNo,
      reason: value,
    });
  };

  const fieldRankings = useMemo(() => {
    const rankingsArray = Object.entries(props.fieldCounts).map(
      ([fieldId, count]) => ({
        fieldId: parseInt(fieldId),
        count,
      }),
    );
    rankingsArray.sort((a, b) => b.count - a.count);
    return rankingsArray;
  }, [props.fieldCounts]);

  const findCategoryNameById = React.useCallback(
    (fieldId: number): string => {
      const category = props.fieldCategories.find(
        category => category.id === fieldId,
      );
      return category ? category.name : "未定義の分野";
    },
    [props.fieldCategories],
  );

  const sortedThemes = useMemo(() => {
    const themeScores = props.themes.map(theme => {
      const fieldScore = theme.field_categories.reduce((acc, fieldCategory) => {
        return acc + (props.fieldCounts[fieldCategory.id] || 0);
      }, 0);
      const actionScore = theme.action_categories.reduce(
        (acc, actionCategory) => {
          return acc + (props.actionCounts[actionCategory.id] || 0);
        },
        0,
      );
      const totalScore = fieldScore + actionScore;
      return { theme, score: totalScore };
    });
    themeScores.sort((a, b) => b.score - a.score);
    return themeScores.map(ts => ts.theme);
  }, [props.themes, props.fieldCounts, props.actionCounts]);

  return (
    <div key={props.issue?.no} className={styles.issue}>
      {themeModal.isShow && (
        <ThemeModal
          videoDomain={process.env.REACT_APP_STUDENT_DLP_URL}
          activeLearningThemes={state.active_learning_themes}
          activeLearningStoryBookPath={activeLearningStoryBookPath}
          handleClose={() => setThemeModal({ ...themeModal, isShow: false })}
          handleAction={() => selectTheme(themeModal.id)}
          themeId={themeModal.id}
        />
      )}
      {question1 && (
        <div className={styles.question}>
          <div className={styles.header}>
            <span className={styles.no}>
              WORK
              {props.issue?.no && props.issue?.no < 10
                ? `0${props.issue?.no}`
                : props.issue?.no}
            </span>
            <p className={styles.title}>{props.issue?.title}</p>
            <p className={styles.read}>
              POINTには、あなたが選んだ分野タグ/特性タグに沿って、おすすめ順にテーマが表示されている。
              <br />
              各テーマの詳細には、テーマに関連した取り組みを行う企業の事例と動画が掲載されている。
              <br />
              おすすめ順と各テーマの詳細を参考にして、自分が興味のあるテーマを選び、なぜそのテーマを選んだのかを入力しよう。
              <br />
              ※動画の再生については先生の指示に従ってください。
              <br />
              <br />
              操作：「詳細を見る」をクリックすると次回視聴する動画の情報が見られます。「取り組む」を押すとテーマを選択できます。
              <br />
            </p>
          </div>
          <div className={styles.card}>
            <div className={styles.wrapper}>
              <div className={styles.block}>
                <p className={styles.label}>
                  <IconLive />
                  POINT
                </p>
                <p className={styles.title}>
                  あなたが選んだ
                  <br className={styles.isPc} />
                  分野タグTOP3
                </p>
              </div>
              <div className={styles.block}>
                <ul className={styles.rankList}>
                  {fieldRankings.slice(0, 3).map((ranking, index) => (
                    <li key={ranking.fieldId} className={styles.rank}>
                      <span className={styles.no}>{index + 1}位</span>
                      <span className={styles.theme}>
                        {findCategoryNameById(ranking.fieldId)}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={clsx(styles.themeCardList, styles.isPc)}>
            {sortedThemes.map((theme, index) => {
              const i = index + 1;
              const no = i < 10 ? `0${i}` : i;
              return (
                <ThemeCard
                  key={theme.id}
                  no={String(no)}
                  title={theme.title}
                  desc={theme.desc}
                  imageUrl={
                    theme.enageed_theme_name
                      ? `https://admin.gear.enageed.net${theme.image_url}`
                      : notFoundImg
                  }
                  selected={answerQ1.theme_id === theme.id}
                  width={"calc((100% - 16px * 2) / 3)"}
                  handleDetail={() =>
                    setThemeModal({ isShow: true, id: theme.id })
                  }
                  handleAction={() => selectTheme(theme.id)}
                />
              );
            })}
          </div>
          <div className={clsx(styles.themeCardList, styles.isSp)}>
            {sortedThemes.map((theme, index) => {
              const i = index + 1;
              const no = i < 10 ? `0${i}` : i;
              return (
                <ThemeCard
                  key={theme.id}
                  no={String(no)}
                  title={theme.title}
                  desc={theme.desc}
                  imageUrl={
                    theme.enageed_theme_name
                      ? `https://admin.gear.enageed.net${theme.image_url}`
                      : notFoundImg
                  }
                  selected={answerQ1.theme_id === theme.id}
                  width={"100%"}
                  handleDetail={() =>
                    setThemeModal({ isShow: true, id: theme.id })
                  }
                  handleAction={() => selectTheme(theme.id)}
                />
              );
            })}
          </div>
        </div>
      )}
      {question2 && (
        <div className={styles.question}>
          <div className={styles.themEntry}>
            <p className={styles.title}>テーマを選んだ理由を入力しよう。</p>
            <p className={styles.read}>
              自分はどんなことに興味があるのか、なぜそのテーマを選んだのかを入力してみよう。
            </p>
            <div className={styles.textAreaWrapper}>
              <TextArea value={answerQ2.reason} handleChange={changeReason} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Issue2;
