import React from "react";

import { ReactComponent as ArrowIcon } from "../../../assets/svg/caret_down.svg";
import { ITeachingMaterial } from "../../../state";
import Icons from "../../../templates/student/Home/icons";

import styles from "./TeachingMaterial.module.scss";

export interface TeachingMaterialProps {
  teaching_material: ITeachingMaterial;
  directTo: (teaching_material_id: number, lesson_id: number) => void;
}

const renderIconByIndex = (index: number) => {
  const Icon = Icons[index];
  return <Icon />;
};

const TeachingMaterial = ({
  teaching_material,
  directTo,
}: TeachingMaterialProps) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.cover}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)), url("${teaching_material.image_url}")`,
        }}
        key={teaching_material.id}
        data-bs-toggle="collapse"
        data-bs-target={`#lesson${teaching_material.id.toString()}`}
        role="button"
        aria-expanded="false"
        aria-controls={`#lesson${teaching_material.id.toString()}`}
      >
        <div className={styles.header}>
          <div className={styles.name}>{teaching_material.name}</div>
          <div className={styles.title}>{teaching_material.title}</div>
        </div>
      </div>
      {teaching_material.lessons && (
        <div
          className={`collapse ${styles.collapseContent}`}
          id={`lesson${teaching_material.id.toString()}`}
          data-bs-parent="#accordion"
        >
          {teaching_material.lessons.map(l => {
            return (
              <div
                key={l.id}
                className={`${styles.index}`}
                onClick={() => directTo(teaching_material.id, l.id)}
              >
                {typeof l.skill_no === "number" && (
                  <div className={styles.skillNo}>
                    {renderIconByIndex(l.skill_no)}
                  </div>
                )}
                <div className={styles.lessonName}>{l.name}</div>
                <div className={styles.lessonTitle}>{l.title}</div>
                <ArrowIcon />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TeachingMaterial;
