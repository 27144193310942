import React from "react";

import Button from "../../../atoms/ActiveLearning/Button";
import { ReactComponent as IconArrowIncrement } from "../../../assets/images/ActiveLearning/icon_arrow_increment.svg";
import { ReactComponent as IconArrowDecrement } from "../../../assets/images/ActiveLearning/icon_arrow_decrement.svg";

import styles from "./ExchangeStepAnswerButton.module.scss";

interface IExchangeStepAnswerButtonProps {
  index?: number;
  length?: number;
  incrementStepAnswer?: () => void;
  decrementStepAnswer?: () => void;
}

export const ExchangeStepAnswerButton: React.FC<IExchangeStepAnswerButtonProps> = ({
  index,
  length,
  incrementStepAnswer,
  decrementStepAnswer,
}) => {
  return (
    <div className={styles.wrapper}>
      <Button
        variant={"secondary"}
        width={"48px"}
        height={"33px"}
        handleClick={incrementStepAnswer}
        disabled={index === 0}
      >
        <IconArrowIncrement />
      </Button>
      <Button
        variant={"secondary"}
        width={"48px"}
        height={"33px"}
        handleClick={decrementStepAnswer}
        disabled={index === length}
      >
        <IconArrowDecrement />
      </Button>
    </div>
  );
};

export default ExchangeStepAnswerButton;
