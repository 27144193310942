import React from "react";

import Modal from "../../atoms/Modal";

import styles from "./ModalDeleteConfirm.module.scss";

export type Props = {
  title: string;
  onClose: () => void;
  onConfirm: () => void;
};

const ModalDeleteConfirm: React.FC<Props> = ({ title, onClose, onConfirm }) => {
  return (
    <Modal onClose={onClose} className={styles.customModal}>
      <p className={styles.customModal__title}>{title}</p>
      <div className={styles.customModal__actions}>
        <button className={styles.btnConfirm} onClick={onConfirm}>
          はい
        </button>
        <button className={styles.btnCancel} onClick={onClose}>
          いいえ
        </button>
      </div>
    </Modal>
  );
};

export default ModalDeleteConfirm;
