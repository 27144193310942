import React from "react";

import { Context } from "../../../studentStore";

import styles from "./Header.module.scss";

const Header: React.FC = () => {
  const { state } = React.useContext(Context);

  return state.class_name && state.student ? (
    <div className={styles.wrapper}>
      {`${state.class_name} ${state.student.last_name} ${state.student.first_name}`}
    </div>
  ) : (
    <> </>
  );
};

export default Header;
