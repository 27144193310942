import clsx from "clsx";
import React from "react";

import style from "./RatingStar.module.scss";

type Props = {
  indexTitle?: string;
  title: JSX.Element;
  rating: number;
  onHandleRating: (rating: number) => void;
};

const RatingStar: React.FC<Props> = ({
  title,
  rating,
  indexTitle,
  onHandleRating,
}) => {
  const [hover, setHover] = React.useState<number>(0);

  return (
    <div className={clsx(style.wrapper, "row")}>
      <div className="col-5">
        <div className={clsx(style.title, "d-flex align-items-center h-100")}>
          {indexTitle && (
            <span>
              {indexTitle}.
              <br />
              &nbsp;
            </span>
          )}
          <span>{title}</span>
        </div>
      </div>
      <div className="col-7 d-flex align-items-center flex-column">
        <div className={style.ratingStar}>
          {[...Array(4)].map((_, index) => {
            index += 1;
            return (
              <button
                type="button"
                key={index}
                className={index <= (hover || rating) ? style.on : style.off}
                onClick={() => onHandleRating(index)}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(rating)}
              >
                <span className={style.star}>&#9733;</span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RatingStar;
