import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { ReactComponent as GroupIcon } from "../../assets/svg/discussion.svg";
import { ReactComponent as AnswersOtherIcon } from "../../assets/svg/answers_other.svg";
import { ReactComponent as SaveIcon } from "../../assets/svg/answerer.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/edit.svg";
import { groupPath } from "../../studentStore";
import { ILesson } from "../../state";

import styles from "./MaterialSidebar.module.scss";

export interface MaterialSidebarProps {
  hasIssue: boolean;
  isChangeAnswer: boolean;
  isEditMode: boolean;
  lesson: ILesson;
  currentPage: number;
  onSubmit: () => void;
  onResetAnswer: () => void;
  onSaveToStorage: () => void;
  onStartEditing: () => void;
  onShowModalAnswers: () => void;
  onShowModalEvaluate: () => void;
}

export const MaterialSidebar: React.FC<MaterialSidebarProps> = ({
  hasIssue,
  isChangeAnswer,
  isEditMode,
  lesson,
  currentPage,
  onSubmit,
  onResetAnswer,
  onSaveToStorage,
  onStartEditing,
  onShowModalAnswers,
  onShowModalEvaluate,
}) => {
  const showEvaluateButton = !!lesson.student_lesson_retrospective;

  return isEditMode ? (
    <div key={"edit"} className={clsx(styles.wrapper, styles.edit)}>
      <button
        onClick={onSubmit}
        className={clsx(styles.actionBtn, styles.primary)}
        disabled={!hasIssue || !isChangeAnswer}
      >
        <SaveIcon className={styles.icon} />
        <span className={styles.text}>
          ワーク
          <br />
          回答送信
        </span>
      </button>
      {showEvaluateButton && (
        <button className={clsx(styles.actionBtn, styles.hidden)}>
          <span>{/* ダミー */}</span>
        </button>
      )}
      <button
        onClick={onResetAnswer}
        className={clsx(styles.actionBtn, !isChangeAnswer && styles.hidden)}
      >
        <span>
          下書きを
          <br />
          削除して
          <br />
          終了
        </span>
      </button>
      {isChangeAnswer ? (
        <button onClick={onSaveToStorage} className={clsx(styles.actionBtn)}>
          <span>
            下書きを
            <br />
            保存して
            <br />
            終了
          </span>
        </button>
      ) : (
        <button onClick={onSaveToStorage} className={clsx(styles.actionBtn)}>
          <span>
            編集を
            <br />
            終了
          </span>
        </button>
      )}
    </div>
  ) : (
    <div key={"normal"} className={styles.wrapper}>
      <button
        onClick={onStartEditing}
        className={clsx(
          styles.actionBtn,
          isChangeAnswer ? styles.alert : styles.primary,
        )}
        disabled={!hasIssue}
      >
        <EditIcon className={styles.icon} />
        {isChangeAnswer ? (
          <span>
            下書きの
            <br />
            編集を再開
          </span>
        ) : (
          <span>
            ワーク
            <br />
            回答入力
          </span>
        )}
      </button>
      <button
        onClick={onShowModalAnswers}
        className={styles.actionBtn}
        disabled={!hasIssue}
      >
        <AnswersOtherIcon className={styles.icon} />
        <span className={styles.text}>
          みんなの
          <br />
          回答
        </span>
      </button>
      <div
        className={clsx(styles.linkBtnWrapper, !hasIssue && styles.disabled)}
      >
        <Link
          aria-current="page"
          to={{
            pathname: groupPath,
            state: {
              materialId: lesson.teaching_material_id,
              lessonId: lesson.id,
              page: currentPage,
            },
          }}
          className={styles.actionBtn}
        >
          <GroupIcon className={styles.icon} />
          <span className={styles.text}>
            グループに
            <br />
            参加
          </span>
        </Link>
      </div>
      {showEvaluateButton && (
        <button onClick={onShowModalEvaluate} className={styles.actionBtn}>
          <div className={styles.icon}>★</div>
          <span className={styles.text}>
            アンケート
            <br />
            編集
          </span>
        </button>
      )}
    </div>
  );
};

export default MaterialSidebar;
