import React from "react";
import clsx from "clsx";

import Portal from "../../Portal";

import styles from "./Modal.module.scss";

interface IModalProps {
  className?: string;
  isTransparentBackground?: boolean;
  children: React.ReactNode;
  handleClose: () => void;
}

const Modal: React.FC<IModalProps> = ({
  className,
  isTransparentBackground,
  children,
  handleClose,
}) => {
  return (
    <Portal>
      <div className={styles.modal}>
        <div
          className={clsx(
            styles.overlay,
            isTransparentBackground && styles.isTransparentBackground,
          )}
          onClick={handleClose}
        />
        <div className={clsx(styles.contents, className)}>{children}</div>
      </div>
    </Portal>
  );
};

export default Modal;
