import React from "react";

import Radio from "../../../atoms/RadioButton";
import { radioMap } from "../../../constants/oedc_survey";

import styles from "./OecdQuestionSection.module.scss";

export type OecdQuestionSectionProps = {
  label: string;
  block: {
    section: number;
    type: any;
    questions: {
      title: string;
      labels: string[];
    }[];
  };
  sectionNo: number;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const OecdQuestionSection = ({
  block,
  sectionNo,
  label,
  handleInputChange,
}: OecdQuestionSectionProps) => {
  return (
    <div>
      <p className={styles.sectionNo}>{block.section}</p>
      <div className={styles.questionWrap}>
        {block.questions.map((question, question_no) => {
          return (
            <div
              key={`question_${sectionNo}_${question_no}`}
              className={styles.questionBlock}
            >
              <p className={styles.questionTitle}>{question.title}</p>
              {block.type === "satisfaction" ? (
                <>
                  <ul className={styles.answerLabelGroup}>
                    {question.labels.map((label, i) => (
                      <li
                        key={`${label}${block.section}_${i}`}
                        className={styles.answerLabel}
                      >
                        {label}
                      </li>
                    ))}
                  </ul>
                  <div className={styles.groupRadio}>
                    {new Array(radioMap[block.type]).fill("a").map((_, i) => (
                      <Radio
                        id={`${label}${block.section}_${question_no + 1}_${i}`}
                        label={(i + 1).toString()}
                        value={i + 1}
                        name={`${label}${block.section}_${question_no + 1}`}
                        key={`${label}${block.section}_${question_no + 1}_${i}`}
                        onChange={handleInputChange}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div className={styles.answerLabelGroup}>
                  {new Array(radioMap[block.type]).fill("a").map((_, i) => (
                    <div
                      key={`${label}${block.section}_${question_no + 1}_${i}`}
                    >
                      <p className={styles.answerLabel}>{question.labels[i]}</p>
                      <div>
                        <Radio
                          id={`${label}${block.section}_${
                            question_no + 1
                          }_${i}`}
                          label={(i + 1).toString()}
                          value={i + 1}
                          name={`${label}${block.section}_${question_no + 1}`}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OecdQuestionSection;
