import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";
import clsx from "clsx";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { IAnswer, HintList } from "../../../../../constants/active_learning";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import SmallCard from "../../../../../atoms/ActiveLearning/SmallCard";
import styles from "../../ActiveLearningIssue.module.scss";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface IAnswerToQ1 extends IAnswer {
  value: string;
}

export const Issue1 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 1;
  const question = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);

  const [answer, setAnswer] = useState<IAnswerToQ1>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question?.order_num || NaN,
    question_id: question?.id || NaN,
    value: "",
  });

  useEffect(() => {
    const targetAnswer = state.active_learning_answers.find(
      answer =>
        answer.issue_no === currentIssueNo &&
        answer.question_no === question?.order_num,
    );
    const answerFormatOfI1Q1: IAnswerToQ1 = {
      id: targetAnswer?.id || 0,
      issue_no: currentIssueNo,
      question_no: 1,
      question_id: targetAnswer?.question_id || 0,
      value: targetAnswer?.contents?.value || "",
    };
    setAnswer({ ...answerFormatOfI1Q1 });
  }, [state.active_learning_answers, question?.order_num]);

  useEffect(() => {
    const answers = props.answers.filter(
      answer =>
        !(
          answer.issue_no === currentIssueNo &&
          answer.question_no === question?.order_num
        ),
    );
    props.setAnswers([
      ...answers,
      {
        id: answer.id || null,
        issue_no: answer.issue_no,
        question_no: question?.order_num || NaN,
        question_id: question?.id || NaN,
        contents: {
          value: answer.value,
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, question?.order_num, question?.id]);

  const changeAnswer = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setAnswer({
      id: answer.id,
      question_id: question?.id || NaN,
      question_no: question?.order_num || NaN,
      issue_no: currentIssueNo,
      value,
    });
  };

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          Lesson02
          WORK03で考えた「問い」を具体的にして、もっと自分が取り組みたくなる要素を追加しよう。
          <br />
          ヒントを参考に様々な視点から「問い」の案を考えて、思いつく限り入力してみよう。
        </p>
      </div>
      {question && (
        <div className={styles.question}>
          <div className={styles.hint}>
            <p className={styles.title}>「問い」を具体的にするためのヒント</p>
            <p>例：「地元の若者離れを解消するには？」</p>
            <div className={clsx(styles.cardList, styles.isPc)}>
              {HintList.lesson3.issue1.hint1.map((hint, index) => {
                return (
                  <SmallCard
                    key={index}
                    icon={hint.icon}
                    label={hint.label}
                    title={hint.title}
                    text={hint.text}
                    width={"calc(50% - 16px / 2)"}
                  />
                );
              })}
            </div>
          </div>
          <div className={clsx(styles.cardList, styles.isSp)}>
            {HintList.lesson3.issue1.hint1.map((hint, index) => {
              return (
                <SmallCard
                  key={index}
                  icon={hint.icon}
                  label={hint.label}
                  title={hint.title}
                  text={hint.text}
                  width={"100%"}
                />
              );
            })}
          </div>
          <TextArea value={answer.value} handleChange={changeAnswer} />
        </div>
      )}
    </div>
  );
};
export default Issue1;
