import React from "react";

import { parseQueryParams } from "../../../libs/Util";
import { IActiveLearningMaterial } from "../../../state";
import Button from "../../../atoms/ActiveLearning/Button";
import Header from "../../../organisms/student/Header";
import ActiveLearningMaterial from "../../../organisms/ActiveLearning/ActiveLearningMaterial";

import styles from "./ActiveLearningMaterialList.module.scss";

export interface IActiveLearningMaterialsListProps {
  activeLearningMaterials: IActiveLearningMaterial[];
  directTo: (active_learning_material_code: string, lesson_no: number) => void;
  availableItemCodes: string[];
}

export const ActiveLearningMaterialsList = (
  props: IActiveLearningMaterialsListProps,
) => {
  const material_code = parseQueryParams()["code"];
  const [isMaterial, setIsMaterial] = React.useState(material_code);

  return (
    <>
      <div className={styles.wrapper} id="accordion">
        <Header />
        <div className={styles.buttonWrapper}>
          {props.availableItemCodes.includes("ECS001") ? (
            <Button
              variant={isMaterial === "ECS001" ? "primary" : "secondaryBgWhite"}
              width={"240px"}
              height={"40px"}
              fontSize={"14px"}
              handleClick={() => setIsMaterial("ECS001")}
            >
              1周目
            </Button>
          ) : (
            ""
          )}
          {props.availableItemCodes.includes("ECS002") ? (
            <Button
              variant={isMaterial === "ECS002" ? "primary" : "secondaryBgWhite"}
              width={"240px"}
              height={"40px"}
              fontSize={"14px"}
              handleClick={() => setIsMaterial("ECS002")}
            >
              2周目
            </Button>
          ) : (
            ""
          )}
        </div>
        {props.activeLearningMaterials.length > 0 &&
          props.activeLearningMaterials.map(
            activeLearningMaterial =>
              activeLearningMaterial.code === isMaterial && (
                <div className={styles.round} key={activeLearningMaterial.code}>
                  <ActiveLearningMaterial
                    key={activeLearningMaterial.code}
                    active_learning_material={activeLearningMaterial}
                    directTo={props.directTo}
                  />
                </div>
              ),
          )}
      </div>
    </>
  );
};
export default ActiveLearningMaterialsList;
