import React from "react";
import clsx from "clsx";

import {
  IActiveLearningAnswers,
  IActiveLearningQuestion,
} from "../../../state";
import { IAnswer } from "../../../constants/active_learning";
import Selector from "../../../atoms/ActiveLearning/Selector";

import AuthenticitySelector from "./AuthenticitySelector";
import ResearchedText from "./ResearchedText";
import styles from "./AssessmentTable.module.scss";

interface IAuthenticityAnswer extends IAnswer {
  parentUid: string;
  parentLabel: string;
  authenticity: number;
}
interface IResearchedTextAnswer extends IAnswer {
  parentUid: string;
  value: string;
}

type Questions = {
  question1?: IActiveLearningQuestion;
  question2?: IActiveLearningQuestion;
  question3?: IActiveLearningQuestion;
  question4?: IActiveLearningQuestion;
  question5?: IActiveLearningQuestion;
};

type AnswersTo = {
  answersToQ1?: IAuthenticityAnswer[];
  answersToQ2?: IAuthenticityAnswer[];
  answersToQ3?: IAuthenticityAnswer[];
  answersToQ4?: IAuthenticityAnswer[];
  answersToQ5?: IResearchedTextAnswer[];
};

type SetAnswersTo = {
  setAnswersToQ1?: React.Dispatch<React.SetStateAction<IAuthenticityAnswer[]>>;
  setAnswersToQ2?: React.Dispatch<React.SetStateAction<IAuthenticityAnswer[]>>;
  setAnswersToQ3?: React.Dispatch<React.SetStateAction<IAuthenticityAnswer[]>>;
  setAnswersToQ4?: React.Dispatch<React.SetStateAction<IAuthenticityAnswer[]>>;
  setAnswersToQ5?: React.Dispatch<
    React.SetStateAction<IResearchedTextAnswer[]>
  >;
};

type AssessmentTableProps = {
  isTeacher?: boolean;
  activeLearningAnswers?: IActiveLearningAnswers[];
  currentIssueNo?: number;
  selectedUid?: string;
  commonAnswers?: {
    label: any;
    value: any;
  }[];
  setSelectedUid?: (value: React.SetStateAction<string>) => void;
  questions?: Questions;
  answersTo?: AnswersTo;
  setAnswersTo?: SetAnswersTo;
};

const AssessmentTable: React.FC<AssessmentTableProps> = ({
  isTeacher,
  activeLearningAnswers,
  currentIssueNo,
  selectedUid,
  commonAnswers,
  setSelectedUid,
  questions,
  answersTo,
  setAnswersTo,
}) => {
  return (
    <>
      <Selector
        maxWidth={"750px"}
        selectedValue={selectedUid}
        options={commonAnswers}
        handleChange={e => setSelectedUid && setSelectedUid(e.target.value)}
      />
      <div
        className={clsx(styles.assessmentTable, isTeacher && styles.isTeacher)}
      >
        <div className={styles.tableWrapper}>
          <table>
            <tbody>
              <tr>
                <th className={styles.assessment} rowSpan={4}>
                  評価ポイント
                </th>
                <td className={styles.point}>
                  有効性：問題の大部分が解決されるアイデアか。
                </td>
                <AuthenticitySelector
                  activeLearningAnswers={activeLearningAnswers}
                  question={questions?.question1}
                  answers={answersTo?.answersToQ1}
                  setAnswers={setAnswersTo?.setAnswersToQ1}
                  selectedUid={selectedUid}
                  currentIssueNo={currentIssueNo}
                />
              </tr>
              <tr>
                <td className={styles.point}>
                  新規性：既に世の中にあるものと比較して
                  <br />
                  自分ならではの要素が含まれたアイデアか。
                </td>
                <AuthenticitySelector
                  activeLearningAnswers={activeLearningAnswers}
                  question={questions?.question2}
                  answers={answersTo?.answersToQ2}
                  setAnswers={setAnswersTo?.setAnswersToQ2}
                  selectedUid={selectedUid}
                  currentIssueNo={currentIssueNo}
                />
              </tr>
              <tr>
                <td className={styles.point}>
                  実現性：実現までの道のりをイメージできるアイデアか。
                </td>
                <AuthenticitySelector
                  activeLearningAnswers={activeLearningAnswers}
                  question={questions?.question3}
                  answers={answersTo?.answersToQ3}
                  setAnswers={setAnswersTo?.setAnswersToQ3}
                  selectedUid={selectedUid}
                  currentIssueNo={currentIssueNo}
                />
              </tr>
              <tr>
                <td className={styles.point}>
                  思えそう：自分がやってみたいと思えるアイデアか。
                </td>
                <AuthenticitySelector
                  activeLearningAnswers={activeLearningAnswers}
                  question={questions?.question4}
                  answers={answersTo?.answersToQ4}
                  setAnswers={setAnswersTo?.setAnswersToQ4}
                  selectedUid={selectedUid}
                  currentIssueNo={currentIssueNo}
                />
              </tr>
              <tr>
                <th className={styles.research}>調べたこと</th>
                <td className={styles.textarea} colSpan={4}>
                  <ResearchedText
                    activeLearningAnswers={activeLearningAnswers}
                    question={questions?.question5}
                    answers={answersTo?.answersToQ5}
                    setAnswers={setAnswersTo?.setAnswersToQ5}
                    selectedUid={selectedUid}
                    currentIssueNo={currentIssueNo}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default AssessmentTable;
