import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { Context } from "../../../../../studentStore";
import { IAnswer } from "../../../../../constants/active_learning";
import Card from "../../../../../atoms/ActiveLearning/Card";
import MultipleTextArea from "../../../../../organisms/ActiveLearning/MultipleTextArea";
import styles from "../../ActiveLearningIssue.module.scss";
import { ReactComponent as IconLive } from "../../../../../assets/images/ActiveLearning/icon_live.svg";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface ICurrentAnswer extends IAnswer {
  good: string;
  motto: string;
}

export const Issue2 = (props: IActiveLearningIssue1Props) => {
  const { state } = React.useContext(Context);
  const currentIssueNo = 2;
  const question1 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);
  const question2 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 2);
  }, [props.issue?.questions]);
  const question3 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 3);
  }, [props.issue?.questions]);

  const [answerToQ1, setAnswerToQ1] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question1?.order_num || NaN,
    question_id: question1?.id || NaN,
    good: "",
    motto: "",
  });
  const [answerToQ2, setAnswerToQ2] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question2?.order_num || NaN,
    question_id: question2?.id || NaN,
    good: "",
    motto: "",
  });
  const [answerToQ3, setAnswerToQ3] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question3?.order_num || NaN,
    question_id: question3?.id || NaN,
    good: "",
    motto: "",
  });

  useEffect(() => {
    const excludedAnswer = props.answers.filter(
      answer => answer.issue_no !== currentIssueNo,
    );
    const currentAnswers: IActiveLearningAnswers[] = [];
    [answerToQ1, answerToQ2, answerToQ3].forEach(answer => {
      currentAnswers.push({
        id: answer.id,
        issue_no: answer.issue_no,
        question_no: answer.question_no,
        question_id: answer.question_id,
        contents: {
          good: answer.good,
          motto: answer.motto,
        },
      });
    });
    props.setAnswers([...excludedAnswer, ...currentAnswers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerToQ1, answerToQ2, answerToQ3]);

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          今まで考えてきた内容を相手に伝わりやすいようにまとめて発表しよう。
          <br />
          発表した結果ともらった意見を参考に、自分の発表内容の良かった点/改善点を整理しよう。
        </p>
      </div>
      <Card label={"TRY"} icon={<IconLive />} title={"グループワーク"}>
        ①グループをつくろう。
        <br />
        ②今まで考えてきたことをまとめて発表しよう。
        <br />
        ③発表を聞くときは、「問い」/「課題」/解決のアイデアのよかった点を書きながら発表を聞こう。
        <br />
        <br />
        <span className={styles.bold}>
          発表を聞くときによかった点を探すヒント
        </span>
        <br />
        <span className={styles.bold}>1.「問い」に関して</span>
        <br />
        自分ならではの「問い」か。
        <br />
        具体性のある「問い」か。
        <br />
        答えが簡単に見つからない「問い」か。
        <br />
        <span className={styles.bold}>2.「課題」に関して</span>
        <br />
        問題、原因、理想状況が整理された「課題」か。
        <br />
        <span className={styles.bold}>3.解決のアイデアに関して</span>
        <br />
        有効性：問題の大部分が解決されるアイデアか。
        <br />
        新規性：既に世の中にあるものと比較して自分ならではの要素が含まれたアイデアか。
        <br />
        実現性：実現までの道のりをイメージできるアイデアか。
        <br />
        意志：自分がやってみたいと思えるアイデアか。
        <br />
      </Card>
      {question1 && (
        <div className={styles.themEntry}>
          <p className={styles.title}>{question1?.title}</p>
          <p className={styles.read}>
            もらった意見を参考に、自分の発表の良かった点や今後に向けた改善点を整理してみよう。
          </p>
          <div className={styles.textAreaWrapper}>
            <MultipleTextArea
              activeLearningAnswers={state.active_learning_answers}
              currentIssueNo={currentIssueNo}
              question={question1}
              answer={answerToQ1}
              setAnswer={setAnswerToQ1}
              goodLabel={"良かった点"}
              mottoLabel={"改善点"}
            />
          </div>
        </div>
      )}
      {question2 && (
        <div className={styles.themEntry}>
          <p className={styles.title}>{question2?.title}</p>
          <p className={styles.read}>
            もらった意見を参考に、自分の発表の良かった点や今後に向けた改善点を整理してみよう。
          </p>
          <div className={styles.textAreaWrapper}>
            <MultipleTextArea
              activeLearningAnswers={state.active_learning_answers}
              currentIssueNo={currentIssueNo}
              question={question2}
              answer={answerToQ2}
              setAnswer={setAnswerToQ2}
              goodLabel={"良かった点"}
              mottoLabel={"改善点"}
            />
          </div>
        </div>
      )}
      {question3 && (
        <div className={styles.themEntry}>
          <p className={styles.title}>{question3?.title}</p>
          <p className={styles.read}>
            もらった意見を参考に、自分の発表の良かった点や今後に向けた改善点を整理してみよう。
          </p>
          <div className={styles.textAreaWrapper}>
            <MultipleTextArea
              activeLearningAnswers={state.active_learning_answers}
              currentIssueNo={currentIssueNo}
              question={question3}
              answer={answerToQ3}
              setAnswer={setAnswerToQ3}
              goodLabel={"良かった点"}
              mottoLabel={"改善点"}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default Issue2;
