import React from "react";
import { useMemo, useCallback, useEffect } from "react";
import clsx from "clsx";

import {
  IActiveLearningAnswers,
  IActiveLearningIssue,
} from "../../../../state";
import { Context, updateActiveLearningAnswers } from "../../../../studentStore";
import { getActiveLearningAnswers } from "../../../../libs/ActiveLearning";
import Header from "../../../../organisms/ActiveLearning/Header";
import TreeCard from "../../../../atoms/ActiveLearning/TreeCard";
import EndLesson from "../../../../organisms/ActiveLearning/EndLesson";
import Issue1 from "../../../../organisms/student/ActiveLearningLessons/Lesson8/Issue1";
import Issue2 from "../../../../organisms/student/ActiveLearningLessons/Lesson8/Issue2";
import styles from "../ActiveLearningLesson.module.scss";
import useWaitApiCall from "../../../../hooks/useWaitApiCall";
import SaveAnswerButton from "../../../../atoms/ActiveLearning/SaveAnswerButton";

export const Lesson8 = () => {
  const { state, dispatch } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const [answers, setAnswers] = React.useState<IActiveLearningAnswers[]>([]);

  useEffect(() => {
    setAnswers([...state.active_learning_answers]);
  }, [state.active_learning_answers]);

  const currentActiveLearning = useMemo(() => {
    return state.active_learning_data_by_lesson;
  }, [state.active_learning_data_by_lesson]);

  const findIssue = useCallback(
    (no: number): IActiveLearningIssue | undefined =>
      currentActiveLearning.issues?.find(issue => issue.no === no),
    [currentActiveLearning.issues],
  );

  const saveAnswers = () => {
    const params = answers;
    const { material_code, no } = currentActiveLearning;
    if (calling) return;
    dispatch(
      peep(
        updateActiveLearningAnswers(material_code, no, JSON.stringify(params)),
      ),
    );
  };

  const pastAnswerL5 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers =
      currentActiveLearning.material_code === "ECS001"
        ? getActiveLearningAnswers(answersByMaterial, 5, 2, 6)
        : getActiveLearningAnswers(answersByMaterial, 6, 2, 6);

    return currentAnswers.map(result => result.value).join("");
  }, [
    currentActiveLearning.material_code,
    state.active_learning_answers_by_material,
  ]);

  const pastAnswersL7 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers =
      currentActiveLearning.material_code === "ECS001"
        ? getActiveLearningAnswers(answersByMaterial, 7, 1, 1)
        : getActiveLearningAnswers(answersByMaterial, 8, 1, 1);

    return currentAnswers.map(result => ({
      stepNo: result.stepNo,
      value: result.value,
    }));
  }, [
    currentActiveLearning.material_code,
    state.active_learning_answers_by_material,
  ]);

  const pastAnswersL7Action = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const lastAnswersItem =
      currentActiveLearning.material_code === "ECS001"
        ? getActiveLearningAnswers(answersByMaterial, 7, 1, 1)
        : getActiveLearningAnswers(answersByMaterial, 8, 1, 1);
    const lastAnswersAction =
      currentActiveLearning.material_code === "ECS001"
        ? getActiveLearningAnswers(answersByMaterial, 7, 2, 1)
        : getActiveLearningAnswers(answersByMaterial, 8, 2, 1);
    const currentAnswers = lastAnswersAction.map(action => {
      return lastAnswersItem.find(item => item.stepNo === action.stepNo);
    });

    return currentAnswers[0]?.value;
  }, [
    currentActiveLearning.material_code,
    state.active_learning_answers_by_material,
  ]);

  const pastAnswersL7Prediction = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const lastLessonNo =
      currentActiveLearning.material_code === "ECS001" ? 7 : 8;
    const currentAnswers = getActiveLearningAnswers(
      answersByMaterial,
      lastLessonNo,
      2,
      2,
    );

    return currentAnswers[0]?.value;
  }, [
    currentActiveLearning.material_code,
    state.active_learning_answers_by_material,
  ]);

  return (
    <>
      <div className={styles.wrapper} id="accordion">
        <Header
          no={currentActiveLearning.no}
          label={"描いたことを実現する"}
          title={currentActiveLearning.title}
          read={
            "行動してみた結果どんな成果が得られたのかを考察して、解決のアイデアをさらに実現できそうな計画を立ててみよう。"
          }
        />
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles.review}>
              <p className={styles.title}>前回の振り返り</p>
              <div className={styles.tree}>
                <TreeCard
                  title={"実現したいと思う解決のアイデア"}
                  text={pastAnswerL5 || "回答データがありません。"}
                />
                <ul className={styles.branchList}>
                  {pastAnswersL7
                    .sort((a, b) => b.stepNo - a.stepNo)
                    .map(lastAnswer => (
                      <li key={lastAnswer.stepNo} className={styles.branch}>
                        <div className={styles.labelWrapper}>
                          <span className={styles.label}>
                            ステップ{lastAnswer.stepNo}
                          </span>
                        </div>
                        <ul className={styles.answerList}>
                          <li className={clsx(styles.answer, styles.single)}>
                            {lastAnswer.value}
                          </li>
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className={styles.themEntry}>
              <p className={styles.title}>選択した行動</p>
              <ul className={styles.pastAnswerList}>
                <li className={styles.pastAnswer}>
                  {pastAnswersL7Action || "回答データがありません。"}
                </li>
              </ul>
            </div>
            <div className={styles.themEntry}>
              <p className={styles.title}>得たい結果</p>
              <ul className={styles.pastAnswerList}>
                <li className={styles.pastAnswer}>
                  {pastAnswersL7Prediction || "回答データがありません。"}
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.section}>
            <Issue1
              issue={findIssue(1)}
              answers={answers}
              setAnswers={setAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <Issue2
              issue={findIssue(2)}
              answers={answers}
              setAnswers={setAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <EndLesson
              text={
                "次のLessonでは、今まで考えてきた内容をまとめ、発表の準備をする。次回までに、どのようにまとめれば相手に伝わりやすいかのイメージを膨らませておこう。"
              }
              saveAnswers={saveAnswers}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Lesson8;
