import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import QuestionnaireStatistics from "../../../templates/student/QuestionnaireStatistics";
import { Context, fetchSurveyAnswers } from "../../../studentStore";

export default () => {
  const { state, dispatch } = useContext(Context);
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(fetchSurveyAnswers(Number(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QuestionnaireStatistics
      student={state.student}
      class_name={state.class_name}
      survey_answers={state.survey_answers}
      survey_answers_per_teaching_material={
        state.survey_answers_per_teaching_material
      }
      has_post_answers={state.has_post_answers}
      has_pre_answers={state.has_pre_answers}
    />
  );
};
