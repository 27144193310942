import React from "react";
import { useHistory } from "react-router-dom";

import {
  Context,
  fetchPackages,
  questionnaireStatisticsPath,
} from "../../../studentStore";
import QuestionnaireList from "../../../templates/student/QuestionnaireList";

export default () => {
  const { state, dispatch } = React.useContext(Context);
  const history = useHistory();
  React.useEffect(() => {
    dispatch(fetchPackages());
  }, [dispatch]);

  const directTo = React.useCallback(
    (teaching_material_id: number) => {
      history.push(`${questionnaireStatisticsPath}/${teaching_material_id}`);
    },
    [history],
  );

  return (
    <QuestionnaireList packageQuestion={state.packages} directTo={directTo} />
  );
};
