import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  listActiveLearningPath,
  listMaterialPath,
  Context,
} from "../../../studentStore";
import Button from "../../../atoms/ActiveLearning/Button";
import Header from "../../../organisms/student/Header";

import styles from "./SelectMaterialsPage.module.scss";

export default () => {
  const { state } = React.useContext(Context);
  const history = useHistory();
  if (state.available_item_codes.length === 0) {
    history.push(listMaterialPath);
    return null;
  }

  return (
    <div className={styles.wrapper} id="accordion">
      <Header />
      <div className={styles.container}>
        <p className={styles.title}>授業を受ける教材を選んでください</p>
        <div className={styles.buttonWrapper}>
          <Link className={styles.link} to={listMaterialPath}>
            <Button width={"100%"}>BASE/CORE基礎編</Button>
          </Link>
          <Link
            className={styles.link}
            to={`${listActiveLearningPath}?code=${state.available_item_codes[0]}`}
          >
            <Button width={"100%"}>CORE実践編</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
