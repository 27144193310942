import React from "react";
import clsx from "clsx";

import { IActiveLearningQuestion } from "../../../state";
import { IAnswerInterest } from "../../../constants/active_learning";
import Button from "../../../atoms/ActiveLearning/Button";
import TextInputCell from "../../../atoms/ActiveLearning/TextInputCell";
import { ReactComponent as IconAdd } from "../../../assets/images/ActiveLearning/icon_add.svg";

import styles from "./InterestTable.module.scss";

type InterestTableProps = {
  isTeacher?: boolean;
  answers: IAnswerInterest[];
  fieldCategories?: { id: number; name: string }[];
  handleTextAnswerChange?: (e: any, attrName: "interest" | "reason") => void;
  setIsShowCheckModal?: React.Dispatch<
    React.SetStateAction<{
      attrName: "action_ids" | "field_ids" | null;
      answerId: string;
    }>
  >;
  question?: IActiveLearningQuestion;
  addAnswerField?: (question: IActiveLearningQuestion) => void;
};

export const InterestTable: React.FC<InterestTableProps> = ({
  isTeacher,
  answers,
  fieldCategories,
  handleTextAnswerChange,
  setIsShowCheckModal,
  question,
  addAnswerField,
}) => {
  return (
    <div className={clsx(styles.interestTable, isTeacher && styles.isTeacher)}>
      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              <th className={styles.interest}>やりたいこと/興味があること</th>
              <th className={styles.reason}>その理由</th>
              <th className={styles.field}>関連する分野</th>
            </tr>
          </thead>
          <tbody>
            {answers.map(answer => (
              <tr key={answer?.id}>
                <td className={clsx(styles.textInput, styles.interest)}>
                  <TextInputCell
                    name={answer?.id}
                    value={answer?.interest}
                    handleChange={e =>
                      handleTextAnswerChange &&
                      handleTextAnswerChange(e, "interest")
                    }
                  />
                </td>
                <td className={clsx(styles.textInput, styles.reason)}>
                  <TextInputCell
                    name={answer?.id}
                    value={answer?.reason}
                    handleChange={e =>
                      handleTextAnswerChange &&
                      handleTextAnswerChange(e, "reason")
                    }
                  />
                </td>
                <td
                  className={clsx(styles.check, styles.field)}
                  onClick={() =>
                    setIsShowCheckModal &&
                    setIsShowCheckModal({
                      answerId: answer?.id,
                      attrName: "field_ids",
                    })
                  }
                >
                  {fieldCategories?.map(category => {
                    return (
                      answer.field_ids.includes(category.id) && (
                        <span key={category?.id} className={styles.tag}>
                          {category?.name}
                        </span>
                      )
                    );
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          width={"152px"}
          height={"38px"}
          fontSize={"14px"}
          handleClick={() =>
            addAnswerField && question && addAnswerField(question)
          }
        >
          <IconAdd />
          項目を追加する
        </Button>
      </div>
    </div>
  );
};
export default InterestTable;
