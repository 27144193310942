import React from "react";
import clsx from "clsx";

import { IReasonExperience } from "../../../state";
import AssessmentFormComment from "../AssessmentFormComment";

import styles from "./AssessmentComment.module.scss";

export type Props = {
  contentId: number;
  currentReason: string | null;
  reasons: IReasonExperience[];
  enableSubmit: boolean;
  placeholder?: string;
  calling: boolean;
  onSaveReason: (reason: string) => void;
  onEdit: (id: number, reason: string) => void;
  onDelete: (id: number) => void;
};

const AssessmentComment: React.FC<Props> = ({
  contentId,
  currentReason,
  reasons,
  enableSubmit,
  placeholder,
  onSaveReason,
  onEdit,
  onDelete,
  calling,
}) => {
  const reason: string = React.useMemo(() => currentReason ?? "", [
    currentReason,
  ]);
  const [listReasons, setListReasons] = React.useState<IReasonExperience[]>([]);

  React.useEffect(() => {
    setListReasons(reasons);
  }, [reasons]);

  return (
    <div className={clsx(styles.wrapper, styles[`wrapper--${contentId}`])}>
      <div className={clsx(styles.comment, !enableSubmit && styles.disabled)}>
        <textarea
          value={reason}
          onChange={e =>
            onSaveReason(e.target.value.replace(/\r\n|\r|\n/g, "\n"))
          }
          disabled={!enableSubmit}
          placeholder={placeholder}
        />
      </div>
      {listReasons.length > 0 && (
        <div className={styles.listComment}>
          {listReasons.map(item => (
            <AssessmentFormComment
              calling={calling}
              assessmentReason={item}
              key={item.id}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AssessmentComment;
