import React from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import {
  homePath,
  questionnairePath,
  myDataPath,
  listMaterialPath,
  selectMaterialsPath,
  assessmentDescriptionPath,
  assessmentPath,
  defaultPath,
} from "../../../studentStore";
import { isAvailablePartItemCodes } from "../../../libs/ActiveLearning";
import LogoImg from "../../../assets/images/logo.png";
import { ReactComponent as HomeIcon } from "../../../assets/svg/home-menu.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/svg/category.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/svg/logout.svg";
import { ReactComponent as QuestionnaireIcon } from "../../../assets/svg/questionnaire.svg";
import { ReactComponent as MyDataIcon } from "../../../assets/svg/class-data.svg";
import { ReactComponent as AssessmentIcon } from "../../../assets/svg/assessment.svg";
import { ReactComponent as GearIcon } from "../../../assets/svg/gear.svg";
import Modal from "../../../atoms/Modal";

import styles from "./Sidebar.module.scss";

export interface SidebarProps {
  className?: string;
  isLoaded: boolean;
  isDlpEnabled: boolean;
  isSurveyEnabled: boolean;
  isOnlyAssessment: boolean;
  isG4sEnabled: boolean;
  isDummy: boolean;
  hasFadeInLeftAnimation?: boolean;
  availableItemCodes: string[];
  logout: () => void;
}

const Sidebar: React.FC<SidebarProps> = props => {
  const [showModal, setShowModal] = React.useState(false);
  const location = useLocation();
  const path = location.pathname;
  const checkActive = (...args: string[]) => {
    return clsx(args.some(compare => compare === path) && styles.active);
  };

  return (
    <>
      <nav
        className={clsx(
          styles.sidebar,
          props.hasFadeInLeftAnimation && styles.fadeInLeftAnimation,
          props.className,
        )}
      >
        <a className={styles.logo} href={defaultPath}>
          <img src={LogoImg} alt="Logo" />
        </a>
        {props.isLoaded && (
          <ul className={styles.menuList}>
            {!props.isOnlyAssessment && (
              <>
                <li className={checkActive(homePath)}>
                  <Link aria-current="page" to={homePath}>
                    <HomeIcon />
                    HOME
                  </Link>
                </li>
                <li
                  className={clsx(
                    checkActive(listMaterialPath),
                    !props.isDlpEnabled && styles.disable,
                  )}
                >
                  <Link
                    aria-current="page"
                    to={
                      isAvailablePartItemCodes(props.availableItemCodes)
                        ? selectMaterialsPath
                        : listMaterialPath
                    }
                  >
                    <CategoryIcon />
                    教材一覧
                  </Link>
                </li>
                {!props.isDummy && (
                  <li
                    className={clsx(
                      `${checkActive(myDataPath)} ${
                        path.includes(myDataPath) && styles.active
                      }`,
                      !props.isDlpEnabled && styles.disable,
                    )}
                  >
                    <Link aria-current="page" to={myDataPath}>
                      <MyDataIcon />
                      マイデータ
                    </Link>
                  </li>
                )}
              </>
            )}
            <li
              className={checkActive(assessmentDescriptionPath, assessmentPath)}
            >
              {props.isG4sEnabled ? (
                <a
                  href={`${process.env.REACT_APP_STUDENT_GEAR_URL}/student/assessment_description`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <AssessmentIcon />
                  自己評価ツール
                </a>
              ) : (
                <Link aria-current="page" to={assessmentDescriptionPath}>
                  <AssessmentIcon />
                  自己評価ツール
                </Link>
              )}
            </li>
            {props.isSurveyEnabled && !props.isOnlyAssessment && (
              <li
                className={clsx(
                  checkActive(questionnairePath),
                  !props.isDlpEnabled && styles.disable,
                )}
              >
                <Link aria-current="page" to={questionnairePath}>
                  <QuestionnaireIcon />
                  アンケート
                </Link>
              </li>
            )}
            <div className="mt-auto" />
            {props.isG4sEnabled && (
              <li className={styles.gearButton}>
                <a
                  href={process.env.REACT_APP_STUDENT_GEAR_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <GearIcon />
                </a>
              </li>
            )}
            <li>
              <button onClick={() => setShowModal(true)}>
                <LogoutIcon />
                ログアウト
              </button>
            </li>
          </ul>
        )}
      </nav>
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          className={styles.modalLogout}
        >
          <div className={styles.modalLogout__title}>ログアウトしますか？</div>
          <div className={styles.modalLogout__actions}>
            <button
              className={styles.btnCancel}
              onClick={() => setShowModal(false)}
            >
              キャンセル
            </button>
            <button className={styles.btnOk} onClick={props.logout}>
              ログアウト
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Sidebar;
