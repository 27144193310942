import React from "react";

import {
  Context,
  clearSuccessMessage,
  fetchMyAnswers,
  fetchLesson,
  registerAnswers,
  postEvaluateLesson,
} from "../../../studentStore";
import { REGISTER_ANSWERS_SUCCESS_MSG } from "../../../studentStore/messages";
import { parseQueryParams } from "../../../libs/Util";
import TextBookForImage from "../../../templates/student/TextBookForImage";
import { ILessonRetrospectiveScore, INewAnswer } from "../../../state";

export default () => {
  const { state, dispatch } = React.useContext(Context);
  const lesson_id = Number(parseQueryParams()["lesson"]);

  React.useEffect(() => {
    dispatch(fetchLesson(lesson_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson_id]);

  const getMyAnswers = (issueId: number) => {
    dispatch(fetchMyAnswers(issueId));
  };

  const sendAnswers = (issueId: number, answers: INewAnswer[]) => {
    dispatch(registerAnswers(issueId, answers));
  };

  const sendEvaluateLesson = (
    student_lesson_retrospective: ILessonRetrospectiveScore,
  ) => {
    dispatch(postEvaluateLesson(student_lesson_retrospective, lesson_id));
  };

  const handleClearSuccessMessage = () => {
    clearSuccessMessage();
  };

  return (
    <div className="d-flex h-100">
      {state.lesson && state.lesson.id === lesson_id && (
        <TextBookForImage
          lesson={state.lesson}
          myAnswers={state.my_answers}
          nextLessonId={state.next_lesson_id}
          prevLessonId={state.prev_lesson_id}
          isG4sEnabled={!!state.student?.g4s_enabled || state.g4s_basic_enabled}
          isSuccessSendAnswers={
            state.success_message === REGISTER_ANSWERS_SUCCESS_MSG
          }
          getMyAnswers={getMyAnswers}
          sendAnswers={sendAnswers}
          sendEvaluateLesson={sendEvaluateLesson}
          clearSuccessMessage={handleClearSuccessMessage}
        />
      )}
    </div>
  );
};
