import React, { useCallback, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { parseQueryParams } from "../../../libs/Util";
import Lesson1 from "../../../templates/student/ActiveLearningLessons/Lesson1";
import Lesson2 from "../../../templates/student/ActiveLearningLessons/Lesson2";
import Lesson3 from "../../../templates/student/ActiveLearningLessons/Lesson3";
import Lesson4 from "../../../templates/student/ActiveLearningLessons/Lesson4";
import Lesson5 from "../../../templates/student/ActiveLearningLessons/Lesson5";
import Lesson6 from "../../../templates/student/ActiveLearningLessons/Lesson6";
import Lesson7 from "../../../templates/student/ActiveLearningLessons/Lesson7";
import Lesson8 from "../../../templates/student/ActiveLearningLessons/Lesson8";
import Lesson9 from "../../../templates/student/ActiveLearningLessons/Lesson9";
import Lesson10 from "../../../templates/student/ActiveLearningLessons/Lesson10";
import Lesson11 from "../../../templates/student/ActiveLearningLessons/Lesson11";
import Lesson12 from "../../../templates/student/ActiveLearningLessons/Lesson12";
import Lesson13 from "../../../templates/student/ActiveLearningLessons/Lesson13";
import Lesson14 from "../../../templates/student/ActiveLearningLessons/Lesson14";
import Lesson15 from "../../../templates/student/ActiveLearningLessons/Lesson15";
import {
  Context,
  fetchActiveLearningLesson,
  fetchActiveLearningThemes,
  fetchActiveLearningAnswers,
  fetchActiveLearningAnswersByMaterial,
  fetchActiveLearningMaterials,
  clearActiveLearningAnswers,
  notFoundPath,
} from "../../../studentStore";

import styles from "./ActiveLearningLessonPage.module.scss";

export default () => {
  const { state, dispatch } = React.useContext(Context);
  const material_code = parseQueryParams()["code"];
  const history = useHistory();
  const { no } = useParams();

  if (
    state.available_item_codes.length === 0 ||
    !state.available_item_codes.includes(material_code)
  ) {
    history.push(notFoundPath);
    return null;
  }

  useEffect(() => {
    dispatch(fetchActiveLearningThemes());
    dispatch(fetchActiveLearningMaterials());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearActiveLearningAnswers());
    dispatch(fetchActiveLearningLesson(no, material_code));
    dispatch(fetchActiveLearningAnswersByMaterial(material_code));
  }, [dispatch, no, material_code]);

  useEffect(() => {
    dispatch(fetchActiveLearningAnswers(material_code, no));
  }, [material_code, no, dispatch, state.active_learning_data_by_lesson]);

  const howRoundsOfLesson = useCallback((): number => {
    switch (material_code) {
      case "ECS001":
        return 1;
      case "ECS002":
        return 2;
      default:
        return NaN;
    }
  }, [material_code]);

  const LessonComponentsRound1 = () => {
    return (() => {
      switch (Number(no)) {
        case 1:
          return <Lesson1 />;
        case 2:
          return <Lesson2 />;
        case 3:
          return <Lesson3 />;
        case 4:
          return <Lesson4 />;
        case 5:
          return <Lesson5 />;
        case 6:
          return <Lesson6 />;
        case 7:
          return <Lesson7 />;
        case 8:
          return <Lesson8 />;
        case 9:
          return <Lesson9 />;
        case 10:
          return <Lesson10 />;
        case 11:
          return <Lesson11 />;
        default:
          return "Not Found";
      }
    })();
  };

  const LessonComponentsRound2 = () => {
    return (() => {
      switch (Number(no)) {
        case 1:
          return <Lesson1 />;
        case 2:
          return <Lesson12 />;
        case 3:
          return <Lesson13 />;
        case 4:
          return <Lesson14 />;
        case 5:
          return <Lesson4 />;
        case 6:
          return <Lesson5 />;
        case 7:
          return <Lesson6 />;
        case 8:
          return <Lesson7 />;
        case 9:
          return <Lesson8 />;
        case 10:
          return <Lesson9 />;
        case 11:
          return <Lesson10 />;
        case 12:
          return <Lesson15 />;
        default:
          return "Not Found";
      }
    })();
  };

  return (
    <div className={styles.wrapper}>
      {howRoundsOfLesson() === 1
        ? LessonComponentsRound1()
        : LessonComponentsRound2()}
    </div>
  );
};
