import React from "react";
import ReactDOM from "react-dom";
import { datadogRum } from "@datadog/browser-rum";

import StudentApp from "./StudentApp";
import "bootstrap/dist/js/bootstrap.js";
import "./index.scss";
import "./styles/reset.scss";
import "slick-carousel/slick/slick.css";
import * as serviceWorker from "./serviceWorker";

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID!,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
  site: "datadoghq.com",
  service: "dlp",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

ReactDOM.render(
  <React.StrictMode>
    <StudentApp />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
document.title = "ENAGEED";
