import * as React from "react";
import { addHours } from "date-fns";

import {
  ITeacher,
  ILesson,
  IActiveLearningLesson,
  IActiveLearningMaterial,
  IActiveLearningMaterialLessons,
  IActiveLearningStudentAnswers,
  IActiveLearningKlassStudentAnswers,
  IActiveLearningTheme,
  IActiveLearningStoryBoard,
  ITeachingMaterial,
  IStudent,
  IAssessmentStudent,
  IGroup,
  IClass,
  IAnswer,
  IAnswersByClass,
  ITeachingMaterialAnswerSummary,
  IAnswerComment,
  IPackage,
  ISurveyAnswers,
  IDetailsSurvey,
  ILessonByTeachingMaterial,
  ILessonCSV,
  ITextBook,
  ISchoolCountAnswers,
  ISchoolGoals,
  IAssessmentTeacher,
  IAssessment,
  IAssessmentDistribution,
  IAssessmentAvgScores,
  IAnswersFromOtherSchoolByClass,
  IIssue,
  IAssessmentCSV,
  IDistribution,
  IPortfolio,
  ISimpleTeachingMaterial,
  ILoginResponse,
  ILoginMFAResponse,
  IAllDistributions,
  IAssessmentAnswer,
  IClassScores,
  IReferenceScores,
} from "../state";

export const defaultPath = "/";
export const prefixPath = "/te";
export const homePath = `/home`;
export const loginPath = `/login`;
export const authenticationPath = `/authentication`;
export const guidelinePath = `/guideline`;
export const activeLearningGuidelinePath = `/active_learning_guideline`;
export const activeLearningProjectionPath = `/active_learning_projection`;
export const activeLearningLessonPath = `/active_learning_lessons`;
export const activeLearningThemesPath = `/active_learning_themes`;
export const activeLearningStoryBookPath = `/active_learning_story_board`;
export const projectionPath = `/projection`;
export const selectTextbookPath = `/select_textbook`;
export const textbookPath = `/textbook`;
export const selectDataPath = `/select_data`;
export const activeLearningTextbookPath = `/active_learning_textbook`;
export const chooseTextbookPath = `/choose`;
export const classPath = `/class`;
export const notFoundPath = `/not-found`;
export const updateTeacherPasswordPath = `/update_teacher_password`;
export const questionnairePath = `/questionnaire_list`;
export const questionnaireStatisticsPath = `/questionnaire_statistics`;
export const forgotPasswordPath = `/forgot_password`;
export const resetPasswordCompletedPath = `/reset_password_completed`;
export const classDataPath = `/data`;
export const activeLearningClassDataPath = `/active_learning_data`;
export const assessmentDescriptionPath = `/assessment_description`;
export const assessmentByClassPath = `/assessment_by_class`;
export const assessmentByGradePath = `/assessment_by_grade`;
export const assessmentPath = `/assessments`;
export const allAssessmentDistributionPath = `/all_assessment_distribution`;

export const CLEAR_SUCCESS_MESSAGE = "CLEAR_SUCCESS_MESSAGE";
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export const CLEAR_OTT = "CLEAR_OTT";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const VERIFY_MFA = "VERIFY_MFA";
export const VERIFY_MFA_SUCCESS = "VERIFY_MFA_SUCCESS";
export const VERIFY_MFA_FAILURE = "VERIFY_MFA_FAILURE";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const FETCH_INITIAL_DATA = "FETCH_INITIAL_DATA";
export const FETCH_INITIAL_DATA_SUCCESS = "FETCH_INITIAL_DATA_SUCCESS";
export const FETCH_INITIAL_DATA_FAILURE = "FETCH_INITIAL_DATA_FAILURE";
export const FETCH_SCHOOL_GOALS = "FETCH_SCHOOL_GOALS";
export const FETCH_SCHOOL_GOALS_SUCCESS = "FETCH_SCHOOL_GOALS_SUCCESS";
export const FETCH_COUNT_ANSWERS = "FETCH_COUNT_ANSWERS";
export const FETCH_COUNT_ANSWERS_SUCCESS = "FETCH_COUNT_ANSWERS_SUCCESS";
export const FETCH_TEACHING_MATERIALS = "FETCH_TEACHING_MATERIALS";
export const FETCH_TEACHING_MATERIALS_SUCCESS =
  "FETCH_TEACHING_MATERIALS_SUCCESS";
export const FETCH_TEACHING_MATERIALS_FAILURE =
  "FETCH_TEACHING_MATERIALS_FAILURE";
export const FETCH_ACTIVE_LEARNING_MATERIALS =
  "FETCH_ACTIVE_LEARNING_MATERIALS";
export const FETCH_ACTIVE_LEARNING_MATERIALS_SUCCESS =
  "FETCH_ACTIVE_LEARNING_MATERIALS_SUCCESS";
export const FETCH_ACTIVE_LEARNING_MATERIAL_LESSONS =
  "FETCH_ACTIVE_LEARNING_MATERIAL_LESSONS";
export const FETCH_ACTIVE_LEARNING_MATERIAL_LESSONS_SUCCESS =
  "FETCH_ACTIVE_LEARNING_MATERIAL_LESSONS_SUCCESS";
export const FETCH_ACTIVE_LEARNING_MATERIALS_FAILURE =
  "FETCH_ACTIVE_LEARNING_MATERIALS_FAILURE";
export const FETCH_ACTIVE_LEARNING_ANSWERS = "FETCH_ACTIVE_LEARNING_ANSWERS";
export const FETCH_ACTIVE_LEARNING_ANSWERS_SUCCESS =
  "FETCH_ACTIVE_LEARNING_ANSWERS_SUCCESS";
export const FETCH_ACTIVE_LEARNING_STUDENT_ANSWERS =
  "FETCH_ACTIVE_LEARNING_STUDENT_ANSWERS";
export const FETCH_ACTIVE_LEARNING_STUDENT_ANSWERS_SUCCESS =
  "FETCH_ACTIVE_LEARNING_STUDENT_ANSWERS_SUCCESS";
export const FETCH_ACTIVE_LEARNING_STUDENT_ANSWERS_FAILURE =
  "FETCH_ACTIVE_LEARNING_STUDENT_ANSWERS_FAILURE";
export const FETCH_ACTIVE_LEARNING_THEMES = "FETCH_ACTIVE_LEARNING_THEMES";
export const FETCH_ACTIVE_LEARNING_THEMES_SUCCESS =
  "FETCH_ACTIVE_LEARNING_THEMES_SUCCESS";
export const FETCH_ACTIVE_LEARNING_THEMES_FAILURE =
  "FETCH_ACTIVE_LEARNING_THEMES_FAILURE";
export const CLEAR_TEACHING_MATERIALS = "CLEAR_TEACHING_MATERIALS";
export const FETCH_TEACHING_MATERIALS_BY_CLASS =
  "FETCH_TEACHING_MATERIALS_BY_CLASS";
export const FETCH_TEACHING_MATERIALS_BY_CLASS_SUCCESS =
  "FETCH_TEACHING_MATERIALS_BY_CLASS_SUCCESS";
export const FETCH_TEACHING_MATERIALS_BY_CLASS_FAILURE =
  "FETCH_TEACHING_MATERIALS_BY_CLASS_FAILURE";
export const CLEAR_TEACHING_MATERIALS_BY_CLASS =
  "CLEAR_TEACHING_MATERIALS_BY_CLASS";
export const FETCH_SIMPLE_TEACHING_MATERIALS =
  "FETCH_SIMPLE_TEACHING_MATERIALS";
export const FETCH_SIMPLE_TEACHING_MATERIALS_SUCCESS =
  "FETCH_SIMPLE_TEACHING_MATERIALS_SUCCESS";
export const FETCH_SIMPLE_TEACHING_MATERIALS_FAILURE =
  "FETCH_SIMPLE_TEACHING_MATERIALS_FAILURE";
export const CREATE_TEACHING_MATERIAL_VIEW_LOG =
  "CREATE_TEACHING_MATERIAL_VIEW_LOG";
export const CREATE_TEACHING_MATERIAL_VIEW_LOG_SUCCESS =
  "CREATE_TEACHING_MATERIAL_VIEW_LOG_SUCCESS";
export const FETCH_LESSONS = "FETCH_LESSONS";
export const FETCH_LESSONS_SUCCESS = "FETCH_LESSONS_SUCCESS";
export const FETCH_LESSONS_FAILURE = "FETCH_LESSONS_FAILURE";
export const FETCH_LESSON = "FETCH_LESSON";
export const FETCH_LESSON_SUCCESS = "FETCH_LESSON_SUCCESS";
export const FETCH_LESSON_FAILURE = "FETCH_LESSON_FAILURE";
export const FETCH_LESSON_ACTIVE_LEARNING = "FETCH_LESSON_ACTIVE_LEARNING";
export const FETCH_LESSON_ACTIVE_LEARNING_SUCCESS =
  "FETCH_LESSON_ACTIVE_LEARNING_SUCCESS";
export const FETCH_LESSON_ACTIVE_LEARNING_FAILURE =
  "FETCH_LESSON_ACTIVE_LEARNING_FAILURE";
export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAILURE = "UPDATE_GROUP_FAILURE";
export const FETCH_STUDENTS = "FETCH_STUDENTS";
export const FETCH_STUDENTS_SUCCESS = "FETCH_STUDENTS_SUCCESS";
export const FETCH_STUDENTS_FAILURE = "FETCH_STUDENTS_FAILURE";
export const FETCH_ASSESSMENT_STUDENTS = "FETCH_ASSESSMENT_STUDENTS";
export const FETCH_ASSESSMENT_STUDENTS_SUCCESS =
  "FETCH_ASSESSMENT_STUDENTS_SUCCESS";
export const FETCH_ASSESSMENT_STUDENTS_FAILURE =
  "FETCH_ASSESSMENT_STUDENTS_FAILURE";
export const FETCH_ACTIVE_LEARNING_STORY_BOARD =
  "FETCH_ACTIVE_LEARNING_STORY_BOARD";
export const FETCH_ACTIVE_LEARNING_STORY_BOARD_SUCCESS =
  "FETCH_ACTIVE_LEARNING_STORY_BOARD_SUCCESS";
export const FETCH_ACTIVE_LEARNING_STORY_BOARD_FAILURE =
  "FETCH_ACTIVE_LEARNING_STORY_BOARD_FAILURE";
export const SEND_FEEDBACK_COMMENT = "SEND_FEEDBACK_COMMENT";
export const SEND_FEEDBACK_COMMENT_SUCCESS = "SEND_FEEDBACK_COMMENT_SUCCESS";
export const SEND_FEEDBACK_COMMENT_FAILURE = "SEND_FEEDBACK_COMMENT_FAILURE";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_FAILURE = "FETCH_GROUPS_FAILURE";
export const FETCH_CLASSES = "FETCH_CLASSES";
export const FETCH_CLASSES_SUCCESS = "FETCH_CLASSES_SUCCESS";
export const FETCH_CLASSES_FAILURE = "FETCH_CLASSES_FAILURE";
export const FETCH_KLASS_IDS_WITH_ACTIVE_LEARNING_MATERIALS =
  "FETCH_KLASS_IDS_WITH_ACTIVE_LEARNING_MATERIALS";
export const FETCH_KLASS_IDS_WITH_ACTIVE_LEARNING_MATERIALS_SUCCESS =
  "FETCH_KLASS_IDS_WITH_ACTIVE_LEARNING_MATERIALS_SUCCESS";
export const FETCH_ANSWERS = "FETCH_ANSWERS";
export const FETCH_ANSWERS_SUCCESS = "FETCH_ANSWERS_SUCCESS";
export const FETCH_ANSWERS_FAILURE = "FETCH_ANSWERS_FAILURE";
export const FETCH_ANSWERS_FROM_OTHER_SCHOOLS =
  "FETCH_ANSWERS_FROM_OTHER_SCHOOLS";
export const FETCH_ANSWERS_FROM_OTHER_SCHOOLS_SUCCESS =
  "FETCH_ANSWERS_FROM_OTHER_SCHOOLS_SUCCESS";
export const FETCH_ANSWERS_FROM_OTHER_SCHOOLS_FAILURE =
  "FETCH_ANSWERS_FROM_OTHER_SCHOOLS_FAILURE";
export const FETCH_ANSWER_SUMMARIES = "FETCH_ANSWER_SUMMARIES";
export const FETCH_ANSWER_SUMMARIES_SUCCESS = "FETCH_ANSWER_SUMMARIES_SUCCESS";
export const FETCH_ANSWER_SUMMARIES_FAILURE = "FETCH_ANSWER_SUMMARIES_FAILURE";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILURE = "CREATE_COMMENT_FAILURE";
export const PUBLISH_TEACHING_MATERIAL = "PUBLISH_TEACHING_MATERIAL";
export const PUBLISH_TEACHING_MATERIAL_SUCCESS =
  "PUBLISH_TEACHING_MATERIAL_SUCCESS";
export const PUBLISH_TEACHING_MATERIAL_FAILURE =
  "PUBLISH_TEACHING_MATERIAL_FAILURE";
export const UNPUBLISH_TEACHING_MATERIAL = "UNPUBLISH_TEACHING_MATERIAL";
export const UNPUBLISH_TEACHING_MATERIAL_SUCCESS =
  "UNPUBLISH_TEACHING_MATERIAL_SUCCESS";
export const UNPUBLISH_TEACHING_MATERIAL_FAILURE =
  "UNPUBLISH_TEACHING_MATERIAL_FAILURE";
export const PUBLISH_LESSON = "PUBLISH_LESSON";
export const PUBLISH_LESSON_SUCCESS = "PUBLISH_LESSON_SUCCESS";
export const PUBLISH_LESSON_FAILURE = "PUBLISH_LESSON_FAILURE";
export const UNPUBLISH_LESSON = "UNPUBLISH_LESSON";
export const UNPUBLISH_LESSON_FAILURE = "UNPUBLISH_LESSON_FAILURE";
export const UNPUBLISH_LESSON_SUCCESS = "UNPUBLISH_LESSON_SUCCESS";
export const NAME_OPENED = "NAME_OPENED";
export const NAME_OPENED_SUCCESS = "NAME_OPENED_SUCCESS";
export const NAME_OPENED_FAILURE = "NAME_OPENED_FAILURE";
export const NAME_CLOSED = "NAME_CLOSED";
export const NAME_CLOSED_SUCCESS = "NAME_CLOSED_SUCCESS";
export const NAME_CLOSED_FAILURE = "NAME_CLOSED_FAILURE";
export const ANSWER_OPENED = "ANSWER_OPENED";
export const ANSWER_OPENED_SUCCESS = "ANSWER_OPENED_SUCCESS";
export const ANSWER_OPENED_FAILURE = "ANSWER_OPENED_FAILURE";
export const ANSWER_CLOSED = "ANSWER_CLOSED";
export const ANSWER_CLOSED_SUCCESS = "ANSWER_CLOSED_SUCCESS";
export const ANSWER_CLOSED_FAILURE = "ANSWER_CLOSED_FAILURE";
export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE";

export const CLEAR_TEMPORARY_TOKEN = "CLEAR_TEMPORARY_TOKEN";
export const FETCH_TEACHING_MATERIAL = "FETCH_TEACHING_MATERIAL";
export const FETCH_TEACHING_MATERIAL_SUCCESS =
  "FETCH_TEACHING_MATERIAL_SUCCESS";
export const FETCH_TEACHING_MATERIAL_FAILURE =
  "FETCH_TEACHING_MATERIAL_FAILURE";
export const UPDATE_TEACHER_PASSWORD = "UPDATE_TEACHER_PASSWORD";
export const UPDATE_TEACHER_PASSWORD_SUCCESS =
  "UPDATE_TEACHER_PASSWORD_SUCCESS";
export const UPDATE_TEACHER_PASSWORD_FAILURE =
  "UPDATE_TEACHER_PASSWORD_FAILURE";
export const RESET_TEACHER_PASSWORD = "RESET_TEACHER_PASSWORD";
export const RESET_TEACHER_PASSWORD_SUCCESS = "RESET_TEACHER_PASSWORD_SUCCESS";
export const RESET_TEACHER_PASSWORD_FAILURE = "RESET_TEACHER_PASSWORD_FAILURE";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const FETCH_PACKAGES = "FETCH_PACKAGES";
export const FETCH_PACKAGES_SUCCESS = "FETCH_PACKAGES_SUCCESS";
export const FETCH_PACKAGES_FAILURE = "FETCH_PACKAGES_FAILURE";
export const FETCH_SURVEY_ANSWERS = "FETCH_SURVEY_ANSWERS";
export const FETCH_SURVEY_ANSWERS_SUCCESS = "FETCH_SURVEY_ANSWERS_SUCCESS";
export const FETCH_SURVEY_ANSWERS_FAILURE = "FETCH_SURVEY_ANSWERS_FAILURE";
export const FETCH_DETAILS_SURVEY = "FETCH_DETAILS_SURVEY";
export const FETCH_DETAILS_SURVEY_SUCCESS = "FETCH_DETAILS_SURVEY_SUCCESS";
export const FETCH_DETAILS_SURVEY_FAILURE = "FETCH_DETAILS_SURVEY_FAILURE";
export const TOGGLE_COMMENT = "TOGGLE_COMMENT";
export const TOGGLE_COMMENT_SUCCESS = "TOGGLE_COMMENT_SUCCESS";
export const TOGGLE_COMMENT_FAILURE = "TOGGLE_COMMENT_FAILURE";
export const FETCH_LIST_LESSONS = "FETCH_LIST_LESSONS";
export const FETCH_LIST_LESSONS_SUCCESS = "FETCH_LIST_LESSONS_SUCCESS";
export const FETCH_LIST_LESSONS_FAILURE = "FETCH_LIST_LESSONS_FAILURE";
export const FETCH_PORTFOLIO = "FETCH_PORTFOLIO";
export const FETCH_PORTFOLIO_SUCCESS = "FETCH_PORTFOLIO_SUCCESS";
export const FETCH_PORTFOLIO_FAILURE = "FETCH_PORTFOLIO_FAILURE";
export const FETCH_DATA_DOWNLOAD_CSV = "FETCH_DATA_DOWNLOAD_CSV";
export const FETCH_DATA_DOWNLOAD_CSV_SUCCESS =
  "FETCH_DATA_DOWNLOAD_CSV_SUCCESS";
export const FETCH_DATA_DOWNLOAD_CSV_FAILURE =
  "FETCH_DATA_DOWNLOAD_CSV_FAILURE";
export const FETCH_DATA_ASSESSMENTS_CSV = "FETCH_DATA_ASSESSMENTS_CSV";
export const FETCH_DATA_ASSESSMENTS_CSV_SUCCESS =
  "FETCH_DATA_ASSESSMENTS_CSV_SUCCESS";
export const FETCH_DATA_ASSESSMENTS_CSV_FAILURE =
  "FETCH_DATA_ASSESSMENTS_CSV_FAILURE";
export const FETCH_ASSESSMENT = "FETCH_ASSESSMENT";
export const FETCH_ASSESSMENT_SUCCESS = "FETCH_ASSESSMENT_SUCCESS";
export const FETCH_ASSESSMENT_FAILURE = "FETCH_ASSESSMENT_FAILURE";
export const FETCH_ASSESSMENT_PAST = "FETCH_ASSESSMENT_PAST";
export const FETCH_ASSESSMENT_PAST_SUCCESS = "FETCH_ASSESSMENT_PAST_SUCCESS";
export const FETCH_ASSESSMENT_PAST_FAILURE = "FETCH_ASSESSMENT_PAST_FAILURE";
export const FETCH_AVERAGE_ASSESSMENT = "FETCH_AVERAGE_ASSESSMENT";
export const FETCH_AVERAGE_ASSESSMENT_SUCCESS =
  "FETCH_AVERAGE_ASSESSMENT_SUCCESS";
export const FETCH_AVERAGE_ASSESSMENT_FAILURE =
  "FETCH_AVERAGE_ASSESSMENT_FAILURE";
export const FETCH_TEMPORARY_TOKEN = "FETCH_TEMPORARY_TOKEN";
export const FETCH_TEMPORARY_TOKEN_SUCCESS = "FETCH_TEMPORARY_TOKEN_SUCCESS";
export const FETCH_TEMPORARY_TOKEN_FAILURE = "FETCH_TEMPORARY_TOKEN_FAILURE";
export const FETCH_AVERAGE_ASSESSMENT_BY_GRADE =
  "FETCH_AVERAGE_ASSESSMENT_BY_GRADE";
export const FETCH_AVERAGE_ASSESSMENT_BY_GRADE_SUCCESS =
  "FETCH_AVERAGE_ASSESSMENT_BY_GRADE_SUCCESS";
export const FETCH_AVERAGE_ASSESSMENT_BY_GRADE_FAILURE =
  "FETCH_AVERAGE_ASSESSMENT_BY_GRADE_FAILURE";
export const FETCH_ALL_DISTRIBUTIONS_ASSESSMENT =
  "FETCH_ALL_DISTRIBUTIONS_ASSESSMENT";
export const FETCH_ALL_DISTRIBUTIONS_ASSESSMENT_SUCCESS =
  "FETCH_ALL_DISTRIBUTIONS_ASSESSMENT_SUCCESS";
export const FETCH_ALL_DISTRIBUTIONS_ASSESSMENT_FAILURE =
  "FETCH_ALL_DISTRIBUTIONS_ASSESSMENT_FAILURE";
export const UPDATE_ASSESSMENT_LAST_ACTIVE = "UPDATE_ASSESSMENT_LAST_ACTIVE";
export const UPDATE_ASSESSMENT_LAST_ACTIVE_SUCCESS =
  "UPDATE_ASSESSMENT_LAST_ACTIVE_SUCCESS";
export const RESET_STUDENT_PASSWORD = "RESET_STUDENT_PASSWORD";
export const RESET_STUDENT_PASSWORD_SUCCESS = "RESET_STUDENT_PASSWORD_SUCCESS";
export const CLEAR_STUDENTS = "CLEAR_STUDENTS";
export const CREATE_GROUP_SUCCESS_MESSAGE = "グループを作成しました";
export const CREATE_GROUP_FAILURE_MESSAGE = "このグループは既に存在します";
export const UPDATE_GROUP_SUCCESS_MESSAGE = "グループを更新しました";
export const UPDATE_GROUP_FAILURE_MESSAGE = "このグループは既に存在します";
export const UPDATE_TEACHER_PASSWORD_SUCCESS_MESSAGE =
  "パスワードの更新に成功しました";
export const RESET_TEACHER_PASSWORD_SUCCESS_MESSAGE =
  "パスワードリセットメールを送信しました。";
export const RESET_TEACHER_PASSWORD_FAILURE_MESSAGE =
  "送信プロセス中にエラーが発生しました。";
export const DELETE_COMMENT_SUCCESS_MSG = "コメントを削除しました";
export const CREATE_COMMENT_SUCCESS_MSG = "コメントを送信しました";
export const UPDATE_COMMENT_SUCCESS_MSG = "コメントを更新しました";
export const CREATE_EVALUATION_SUCCESS_MSG = "評価を保存しました";
export const TOGGLE_COMMENT_SUCCESS_MSG = "コメントを更新しました";
export const SEND_FEEDBACK_COMMENT_SUCCESS_MSG = "保存しました";
export const TOGGLE_ANSWER_ERROR_MSG =
  "学生向けの教材を公開していないため、この機能を使用できません";
export const PUBLISH_LESSON_SUCCESS_MSG = "レッスンを公開しました";
export const UNPUBLISH_LESSON_SUCCESS_MSG = "レッスンを非公開しました";
export const PUBLISH_TEACHING_MATERIAL_SUCCESS_MSG = "教材を公開しました";
export const UNPUBLISH_TEACHING_MATERIAL_SUCCESS_MSG = "教材を非公開しました";

export const LOGOUT_SUCCESS_MESSAGE = "ログアウトしました。";
export const CHECK_UUID = "CHECK_UUID";
export const CHECK_UUID_SUCCESS = "CHECK_UUID_SUCCESS";
export const CHECK_UUID_FAILURE = "CHECK_UUID_FAILURE";
export const CHECK_UUID_FAILURE_MSG =
  "有効期限が切れています。再度パスワードのリセットの対応をお願いします";
export const RESET_STUDENT_PASSWORD_SUCCESS_MESSAGE =
  "パスワードの初期化に成功しました";

export const ACTION_503 = "ACTION_503";
export const ACTION_503_MESSAGE = "サービスが利用できません";

export const API_URL = `${
  process.env.REACT_APP_API_URL ??
  window.location.protocol + "//" + window.location.host
}/api/v1/teacher`;

export const getUuidStatus = (uuid: string) => ({
  types: CHECK_UUID as typeof CHECK_UUID,
  payload: {
    request: {
      data: { uuid },
      method: "get",
      url: `${API_URL}/teachers/check_reset_password_token`,
    },
  },
});

export const login = (email: string, password: string, remember: boolean) => ({
  types: LOGIN as typeof LOGIN,
  payload: {
    request: {
      data: { email, password, remember },
      method: "post",
      url: `${API_URL}/login`,
    },
  },
});

export const verifyMFA = (ott: string, mfa_code: string) => ({
  types: VERIFY_MFA as typeof VERIFY_MFA,
  payload: {
    request: {
      data: { ott, mfa_code },
      method: "post",
      url: `${API_URL}/teachers/verify_mfa`,
    },
  },
});

export const logout = () => ({
  types: LOGOUT as typeof LOGOUT,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/logout`,
    },
  },
});

export const fetchInitialData = () => ({
  types: FETCH_INITIAL_DATA as typeof FETCH_INITIAL_DATA,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/teachers/initial_data`,
    },
  },
});

export const updateTeacherPassword = (uuid: string, password: string) => ({
  types: UPDATE_TEACHER_PASSWORD as typeof UPDATE_TEACHER_PASSWORD,
  payload: {
    request: {
      data: { uuid, password },
      method: "post",
      url: `${API_URL}/teachers/update_password`,
    },
  },
});

export const resetTeacherPassword = (email: string) => ({
  types: RESET_TEACHER_PASSWORD as typeof RESET_TEACHER_PASSWORD,
  payload: {
    request: {
      data: { email, type: "dlp" },
      method: "post",
      url: `${API_URL}/teachers/reset_password`,
    },
  },
});

export const fetchTeachingMaterial = (id: number) => {
  return {
    types: FETCH_TEACHING_MATERIAL as typeof FETCH_TEACHING_MATERIAL,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/teaching_materials/${id}`,
      },
    },
  };
};

export const fetchCountAnswers = (school_id: number) => ({
  types: FETCH_COUNT_ANSWERS as typeof FETCH_COUNT_ANSWERS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/schools/${school_id}/count_answers`,
    },
  },
});

export const fetchActiveLearningStoryBoard = (id: number) => ({
  types: FETCH_ACTIVE_LEARNING_STORY_BOARD as typeof FETCH_ACTIVE_LEARNING_STORY_BOARD,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/active_learning_themes/${id}/story_board`,
    },
  },
});

export const fetchTeachingMaterials = (class_ids?: number[]) => {
  const data: any = {};
  if (class_ids) data.class_ids = class_ids;

  return {
    types: FETCH_TEACHING_MATERIALS as typeof FETCH_TEACHING_MATERIALS,
    payload: {
      request: {
        data,
        method: "get",
        url: `${API_URL}/teaching_materials`,
      },
    },
  };
};

export const fetchActiveLearningMaterials = (class_ids?: number[]) => {
  const data: any = {};
  if (class_ids) data.class_ids = class_ids;

  return {
    types: FETCH_ACTIVE_LEARNING_MATERIALS as typeof FETCH_ACTIVE_LEARNING_MATERIALS,
    payload: {
      request: {
        data,
        method: "get",
        url: `${API_URL}/active_learning_materials`,
      },
    },
  };
};

export const fetchActiveLearningMaterialLessons = (
  material_code: string,
  id: string,
) => ({
  types: FETCH_ACTIVE_LEARNING_MATERIAL_LESSONS as typeof FETCH_ACTIVE_LEARNING_MATERIAL_LESSONS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/active_learning_materials/${material_code}/active_learning_material_lessons/${id}`,
    },
  },
});

export const fetchActiveLearningAnswers = (class_ids: string) => {
  return {
    types: FETCH_ACTIVE_LEARNING_ANSWERS as typeof FETCH_ACTIVE_LEARNING_ANSWERS,
    payload: {
      request: {
        data: { class_ids },
        method: "get",
        url: `${API_URL}/active_learning_answers`,
      },
    },
  };
};

export const fetchActiveLearningStudentAnswers = (student_id: number) => {
  return {
    types: FETCH_ACTIVE_LEARNING_STUDENT_ANSWERS as typeof FETCH_ACTIVE_LEARNING_STUDENT_ANSWERS,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/active_learning_answers/student_answer?student_id=${student_id}`,
      },
    },
  };
};

export const clearTeachingMaterials = () => ({
  types: CLEAR_TEACHING_MATERIALS as typeof CLEAR_TEACHING_MATERIALS,
});

export const fetchTeachingMaterialsByClass = (class_id: number) => ({
  types: FETCH_TEACHING_MATERIALS_BY_CLASS as typeof FETCH_TEACHING_MATERIALS_BY_CLASS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/classes/${class_id}/teaching_materials`,
    },
  },
});

export const clearTeachingMaterialsByClass = () => ({
  types: CLEAR_TEACHING_MATERIALS_BY_CLASS as typeof CLEAR_TEACHING_MATERIALS_BY_CLASS,
  payload: {},
});

export const fetchSimpleTeachingMaterials = (class_id: number) => ({
  types: FETCH_SIMPLE_TEACHING_MATERIALS as typeof FETCH_SIMPLE_TEACHING_MATERIALS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/classes/${class_id}/list_teaching_materials`,
    },
  },
});

export const createTeachingMaterialViewLog = (
  teaching_material_id: number,
  lesson_id: number,
  page_no: number,
  klass_ids: string,
) => ({
  types: CREATE_TEACHING_MATERIAL_VIEW_LOG as typeof CREATE_TEACHING_MATERIAL_VIEW_LOG,
  payload: {
    request: {
      method: "post",
      data: {
        teaching_material_id,
        lesson_id,
        page_no,
        klass_ids,
      },
      url: `${API_URL}/teaching_materials/${teaching_material_id}/view_logs`,
    },
  },
});

export const fetchStudents = (class_ids: number[]) => ({
  types: FETCH_STUDENTS as typeof FETCH_STUDENTS,
  payload: {
    request: {
      data: { class_ids: class_ids.join(","), load_answered_status: true },
      method: "get",
      url: `${API_URL}/students`,
    },
  },
});

export const fetchAssessmentStudents = (class_id: number) => ({
  types: FETCH_ASSESSMENT_STUDENTS as typeof FETCH_ASSESSMENT_STUDENTS,
  payload: {
    request: {
      data: { class_id },
      method: "get",
      url: `${API_URL}/assessments/students`,
    },
  },
});

export const fetchGroups = (class_ids: number[]) => ({
  types: FETCH_GROUPS as typeof FETCH_GROUPS,
  payload: {
    request: {
      data: { class_ids: class_ids.join(",") },
      method: "get",
      url: `${API_URL}/groups`,
    },
  },
});

export const fetchListLessons = (
  student_id: number,
  class_id: number,
  teaching_material_id: number,
) => ({
  types: FETCH_LIST_LESSONS as typeof FETCH_LIST_LESSONS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/teaching_materials/${teaching_material_id}/lessons?class_id=${class_id}&student_id=${student_id}`,
    },
  },
});

export const fetchPortfolio = (
  student_id: number,
  class_id: number,
  teaching_material_id: number,
) => ({
  types: FETCH_PORTFOLIO as typeof FETCH_PORTFOLIO,
  payload: {
    request: {
      data: { student_id, class_id },
      method: "get",
      url: `${API_URL}/teaching_materials/${teaching_material_id}/portfolio`,
    },
  },
});

export const fetchClasses = () => ({
  types: FETCH_CLASSES as typeof FETCH_CLASSES,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/classes/`,
    },
  },
});

export const fetchKlassIdsWithActiveLearningMaterials = () => ({
  types: FETCH_KLASS_IDS_WITH_ACTIVE_LEARNING_MATERIALS as typeof FETCH_KLASS_IDS_WITH_ACTIVE_LEARNING_MATERIALS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/classes/klass_ids_with_active_learning_materials`,
    },
  },
});

export const fetchSchoolGoals = () => ({
  types: FETCH_SCHOOL_GOALS as typeof FETCH_SCHOOL_GOALS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/schools`,
    },
  },
});

export const fetchLessons = () => ({
  types: FETCH_LESSONS as typeof FETCH_LESSONS,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/lessons`,
    },
  },
});

export const fetchLesson = (id: number) => ({
  types: FETCH_LESSON as typeof FETCH_LESSON,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/lessons/${id}`,
    },
  },
});

export const fetchActiveLearningLesson = (
  id: number,
  active_learning_code: string,
  projection?: boolean,
) => ({
  types: FETCH_LESSON_ACTIVE_LEARNING as typeof FETCH_LESSON_ACTIVE_LEARNING,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/active_learning_lessons/${id}?active_learning_code=${active_learning_code}&projection=${
        projection ?? false
      }`,
    },
  },
});

export const fetchActiveLearningThemes = () => ({
  types: FETCH_ACTIVE_LEARNING_THEMES as typeof FETCH_ACTIVE_LEARNING_THEMES,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/active_learning_themes`,
    },
  },
});

export const fetchDataDownloadCSV = (
  lesson_id: number,
  class_id: number,
  teaching_material_id: number,
) => ({
  types: FETCH_DATA_DOWNLOAD_CSV as typeof FETCH_DATA_DOWNLOAD_CSV,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/lessons/${lesson_id}/download_csv?class_id=${class_id}&teaching_material_id=${teaching_material_id}`,
    },
  },
});

export const fetchDataAssessmentCSV = (class_id: number) => ({
  types: FETCH_DATA_ASSESSMENTS_CSV as typeof FETCH_DATA_ASSESSMENTS_CSV,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/assessments/download_csv?class_id=${class_id}`,
    },
  },
});

export const createGroup = (class_id: number, name: string) => {
  return {
    types: CREATE_GROUP as typeof CREATE_GROUP,
    payload: {
      request: {
        data: {
          name,
          class_id,
        },
        method: "post",
        url: `${API_URL}/groups`,
      },
    },
  };
};

export const updateGroup = (group: IGroup) => {
  return {
    types: UPDATE_GROUP as typeof UPDATE_GROUP,
    payload: {
      request: {
        data: {
          id: group.id,
          name: group.name,
        },
        method: "put",
        url: `${API_URL}/groups/${group.id}`,
      },
    },
  };
};

export const createComment = (answer_id: number, content: string) => {
  return {
    types: CREATE_COMMENT as typeof CREATE_COMMENT,
    payload: {
      request: {
        data: { content },
        method: "post",
        url: `${API_URL}/answers/${answer_id}/answer_comments`,
      },
    },
  };
};

export const updateComment = (
  answer_id: number,
  content: string,
  comment_id: number,
) => {
  return {
    types: UPDATE_COMMENT as typeof UPDATE_COMMENT,
    payload: {
      request: {
        data: { content },
        method: "put",
        url: `${API_URL}/answers/${answer_id}/answer_comments/${comment_id}`,
      },
    },
  };
};

export const deleteComment = (answer_id: number, comment_id: number) => {
  return {
    types: DELETE_COMMENT as typeof DELETE_COMMENT,
    payload: {
      request: {
        data: {},
        method: "delete",
        url: `${API_URL}/answers/${answer_id}/answer_comments/${comment_id}`,
      },
    },
  };
};

export const fetchAnswers = (issue_id: number, class_ids: number[]) => {
  return {
    types: FETCH_ANSWERS as typeof FETCH_ANSWERS,
    payload: {
      request: {
        data: { class_ids: class_ids.join(",") },
        method: "get",
        url: `${API_URL}/issues/${issue_id}/answers`,
      },
    },
  };
};

export const fetchAnswersFromOtherSchools = (
  issue_id: number,
  class_ids: number[],
) => {
  return {
    types: FETCH_ANSWERS_FROM_OTHER_SCHOOLS as typeof FETCH_ANSWERS_FROM_OTHER_SCHOOLS,
    payload: {
      request: {
        data: { class_ids: class_ids.join(",") },
        method: "get",
        url: `${API_URL}/issues/${issue_id}/public_answers`,
      },
    },
  };
};

export const sendFeedbackComment = (
  student_id: number,
  feedback_comment: string,
) => {
  return {
    types: SEND_FEEDBACK_COMMENT as typeof SEND_FEEDBACK_COMMENT,
    payload: {
      request: {
        data: { student_id, feedback_comment },
        method: "POST",
        url: `${API_URL}/assessments/feedback_comment`,
      },
    },
  };
};

export const fetchAnswerSummaries = (student_id: number) => {
  return {
    types: FETCH_ANSWER_SUMMARIES as typeof FETCH_ANSWER_SUMMARIES,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/students/${student_id}/answers`,
      },
    },
  };
};

export const publishTeachingMaterial = (
  class_id: number,
  teaching_material_id: number,
) => {
  return {
    types: PUBLISH_TEACHING_MATERIAL as typeof PUBLISH_TEACHING_MATERIAL,
    payload: {
      request: {
        data: { class_id },
        method: "post",
        url: `${API_URL}/teaching_materials/${teaching_material_id}/publish`,
      },
    },
  };
};

export const unpublishTeachingMaterial = (
  class_id: number,
  teaching_material_id: number,
) => {
  return {
    types: UNPUBLISH_TEACHING_MATERIAL as typeof UNPUBLISH_TEACHING_MATERIAL,
    payload: {
      request: {
        data: { class_id },
        method: "post",
        url: `${API_URL}/teaching_materials/${teaching_material_id}/unpublish`,
      },
    },
  };
};

export const publishLesson = (class_ids: number[], lesson_id: number) => {
  return {
    types: PUBLISH_LESSON as typeof PUBLISH_LESSON,
    payload: {
      request: {
        data: { class_ids },
        method: "POST",
        url: `${API_URL}/lessons/${lesson_id}/publish`,
      },
    },
  };
};

export const unpublishLesson = (class_id: number, lesson_id: number) => {
  return {
    types: UNPUBLISH_LESSON as typeof UNPUBLISH_LESSON,
    payload: {
      request: {
        data: { class_id },
        method: "post",
        url: `${API_URL}/lessons/${lesson_id}/unpublish`,
      },
    },
  };
};

export const toggleComment = (
  answer_id: number,
  comment_id: number,
  is_hidden: boolean,
) => {
  return {
    types: TOGGLE_COMMENT as typeof TOGGLE_COMMENT,
    payload: {
      request: {
        data: { is_hidden },
        method: "PUT",
        url: `${API_URL}/answers/${answer_id}/answer_comments/${comment_id}/hidden`,
      },
    },
  };
};

export const nameOpened = (issue_id: number, class_id: number) => {
  return {
    types: NAME_OPENED as typeof NAME_OPENED,
    payload: {
      request: {
        data: { class_id },
        method: "post",
        url: `${API_URL}/issues/${issue_id}/name_opened`,
      },
    },
  };
};

export const nameClosed = (issue_id: number, class_id: number) => {
  return {
    types: NAME_CLOSED as typeof NAME_CLOSED,
    payload: {
      request: {
        data: { class_id },
        method: "post",
        url: `${API_URL}/issues/${issue_id}/name_closed`,
      },
    },
  };
};

export const answerOpened = (issue_id: number, class_id: number) => {
  return {
    types: ANSWER_OPENED as typeof ANSWER_OPENED,
    payload: {
      request: {
        data: { class_id },
        method: "post",
        url: `${API_URL}/issues/${issue_id}/answer_opened`,
      },
    },
  };
};

export const answerClosed = (issue_id: number, class_id: number) => {
  return {
    types: ANSWER_CLOSED as typeof ANSWER_CLOSED,
    payload: {
      request: {
        data: { class_id },
        method: "post",
        url: `${API_URL}/issues/${issue_id}/answer_closed`,
      },
    },
  };
};

export const fetchPackages = (class_id: number) => {
  return {
    types: FETCH_PACKAGES as typeof FETCH_PACKAGES,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/packages?class_id=${class_id}`,
      },
    },
  };
};

export const fetchSurveyAnswers = (
  teaching_material_id: number,
  student_id: number,
) => {
  return {
    types: FETCH_SURVEY_ANSWERS as typeof FETCH_SURVEY_ANSWERS,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/survey_answers/${teaching_material_id}/student_answer?student_id=${student_id}`,
      },
    },
  };
};

export const fetchDetailsSurvey = (student_id: number, package_id: number) => {
  return {
    types: FETCH_DETAILS_SURVEY as typeof FETCH_DETAILS_SURVEY,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/survey_answers?student_id=${student_id}&package_id=${package_id}`,
      },
    },
  };
};

export const fetchAssessment = (student_id: number, class_id: number) => ({
  types: FETCH_ASSESSMENT as typeof FETCH_ASSESSMENT,
  payload: {
    request: {
      data: { student_id, class_id },
      method: "get",
      url: `${API_URL}/assessments`,
    },
  },
});

export const fetchAssessmentPast = (student_id: number, class_id: number) => ({
  types: FETCH_ASSESSMENT_PAST as typeof FETCH_ASSESSMENT_PAST,
  payload: {
    request: {
      data: { student_id, class_id },
      method: "get",
      url: `${API_URL}/assessments/in_past`,
    },
  },
});

export const fetchAvgAssessment = (class_id: number) => ({
  types: FETCH_AVERAGE_ASSESSMENT as typeof FETCH_AVERAGE_ASSESSMENT,
  payload: {
    request: {
      data: { class_id },
      method: "get",
      url: `${API_URL}/assessments/summary_by_class`,
    },
  },
});

export const fetchAvgAssessmentByGrade = (grade: number) => ({
  types: FETCH_AVERAGE_ASSESSMENT_BY_GRADE as typeof FETCH_AVERAGE_ASSESSMENT_BY_GRADE,
  payload: {
    request: {
      data: { grade },
      method: "get",
      url: `${API_URL}/assessments/summary_by_grade`,
    },
  },
});

export const fetchTemporaryToken = () => ({
  types: FETCH_TEMPORARY_TOKEN as typeof FETCH_TEMPORARY_TOKEN,
  payload: {
    request: {
      method: "post",
      url: `${API_URL}/sessions/temporary`,
      data: {},
    },
  },
});

export const fetchAllDistributionsAssessment = (class_id: number) => ({
  types: FETCH_ALL_DISTRIBUTIONS_ASSESSMENT as typeof FETCH_ALL_DISTRIBUTIONS_ASSESSMENT,
  payload: {
    request: {
      data: { class_id },
      method: "get",
      url: `${API_URL}/assessments/all_distributions`,
    },
  },
});

export const clearSuccessMessage = () => ({
  types: CLEAR_SUCCESS_MESSAGE as typeof CLEAR_SUCCESS_MESSAGE,
  payload: {},
});

export const clearErrorMessage = () => ({
  types: CLEAR_ERROR_MESSAGE as typeof CLEAR_ERROR_MESSAGE,
  payload: {},
});

export const clearTemporaryToken = () => ({
  types: CLEAR_TEMPORARY_TOKEN as typeof CLEAR_TEMPORARY_TOKEN,
  payload: {},
});

export const clearOTT = () => ({
  types: CLEAR_OTT as typeof CLEAR_OTT,
  payload: {},
});

export const deleteGroup = (group_id: number) => {
  return {
    types: DELETE_GROUP as typeof DELETE_GROUP,
    payload: {
      request: {
        data: {},
        method: "delete",
        url: `${API_URL}/groups/${group_id}`,
      },
    },
  };
};

export const updateAssessmentLastActive = () => ({
  types: UPDATE_ASSESSMENT_LAST_ACTIVE as typeof UPDATE_ASSESSMENT_LAST_ACTIVE,
  payload: {
    request: {
      data: {},
      method: "put",
      url: `${API_URL}/teachers/update_assessment_last_active_date`,
    },
  },
});

export const resetStudentPassword = (id: number) => ({
  types: RESET_STUDENT_PASSWORD as typeof RESET_STUDENT_PASSWORD,
  payload: {
    request: {
      data: {},
      method: "post",
      url: `${API_URL}/students/${id}/reset_password`,
    },
  },
});

export const clearStudents = () => ({
  types: CLEAR_STUDENTS as typeof CLEAR_STUDENTS,
  payload: {},
});

type fetchAllDistributionsAssessmentSuccess = {
  types: typeof FETCH_ALL_DISTRIBUTIONS_ASSESSMENT_SUCCESS;
  payload: {
    data: {
      all_distributions: {
        first_term_no_distributions: IDistribution[] | null;
        last_term_no_distributions: IDistribution[] | null;
        previous_term_no_distributions: IDistribution[] | null;
      };
    };
  };
};

type fetchAssessmentPastSuccess = {
  types: typeof FETCH_ASSESSMENT_PAST_SUCCESS;
  payload: {
    data: {
      assessments: IAssessment[];
    };
  };
};

type fetchDataDownloadCSVSuccess = {
  types: typeof FETCH_DATA_DOWNLOAD_CSV_SUCCESS;
  payload: {
    data: {
      lesson: ILessonCSV;
    };
  };
};

type fetchDataAssessmentsCSVSuccess = {
  types: typeof FETCH_DATA_ASSESSMENTS_CSV_SUCCESS;
  payload: {
    data: {
      assessments: IAssessmentCSV[];
    };
  };
};

type loginSuccess = {
  types: typeof LOGIN_SUCCESS;
  payload: {
    data: ILoginResponse | ILoginMFAResponse;
  };
};

type loginFailure = {
  types: typeof LOGIN_FAILURE;
  payload: {
    error: {
      message: string;
      field: string;
      status: number;
    };
  };
};

type verifyMFASuccess = {
  types: typeof VERIFY_MFA_SUCCESS;
  payload: {
    data: ILoginResponse;
  };
};

type verifyMFAFailure = {
  types: typeof VERIFY_MFA_FAILURE;
  payload: {
    error: {
      message: string;
      field: string;
      status: number;
    };
  };
};

type checkUuidSuccess = {
  types: typeof CHECK_UUID_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type checkUuidFailure = {
  types: typeof CHECK_UUID_FAILURE;
  payload: {
    error: {
      message: string;
      field: string;
      status: number;
    };
  };
};

type logoutSuccess = {
  types: typeof LOGOUT_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type fetchTeachingMaterialSuccess = {
  types: typeof FETCH_TEACHING_MATERIAL_SUCCESS;
  payload: {
    data: {
      data: {
        teaching_material: ITeachingMaterial;
      };
    };
  };
};

type fetchCountAnswersSuccess = {
  types: typeof FETCH_COUNT_ANSWERS_SUCCESS;
  payload: {
    data: {
      data: {
        school: ISchoolCountAnswers;
      };
    };
  };
};

type fetchInitialDataSuccess = {
  types: typeof FETCH_INITIAL_DATA_SUCCESS;
  payload: {
    data: {
      teacher: ITeacher;
      school_name: string;
      survey_enabled: boolean;
      is_manager: boolean;
      only_assessment: boolean;
      assessment_last_active_date: string | null;
      available_item_codes: string[];
      is_unread_news: boolean;
    };
  };
};

type updateTeacherPasswordSuccess = {
  types: typeof UPDATE_TEACHER_PASSWORD_SUCCESS;
  payload: {
    data: Record<string, never>;
  };
};

type resetTeacherPasswordSuccess = {
  types: typeof RESET_TEACHER_PASSWORD_SUCCESS;
  payload: {
    data: Record<string, never>;
  };
};

type fetchTeachingMaterialsSuccess = {
  types: typeof FETCH_TEACHING_MATERIALS_SUCCESS;
  payload: {
    data: {
      data: {
        teaching_materials: ITeachingMaterial[];
      };
    };
  };
};

type fetchActiveLearningMaterialsSuccess = {
  types: typeof FETCH_ACTIVE_LEARNING_MATERIALS_SUCCESS;
  payload: {
    data: {
      data: {
        active_learning_materials: IActiveLearningMaterial[];
      };
    };
  };
};

type fetchActiveLearningMaterialLessonsSuccess = {
  types: typeof FETCH_ACTIVE_LEARNING_MATERIAL_LESSONS_SUCCESS;
  payload: {
    data: {
      data: IActiveLearningMaterialLessons;
    };
  };
};

type fetchActiveLearningAnswersSuccess = {
  types: typeof FETCH_ACTIVE_LEARNING_ANSWERS_SUCCESS;
  payload: {
    data: {
      students: IActiveLearningKlassStudentAnswers[];
    };
  };
};

type fetchActiveLearningStudentAnswersSuccess = {
  types: typeof FETCH_ACTIVE_LEARNING_STUDENT_ANSWERS_SUCCESS;
  payload: {
    data: {
      student: IActiveLearningStudentAnswers;
    };
  };
};

type fetchActiveLearningStoryBoardSuccess = {
  types: typeof FETCH_ACTIVE_LEARNING_STORY_BOARD_SUCCESS;
  payload: {
    data: {
      data: IActiveLearningStoryBoard;
    };
  };
};

type fetchTeachingMaterialsByClassSuccess = {
  types: typeof FETCH_TEACHING_MATERIALS_BY_CLASS_SUCCESS;
  payload: {
    data: {
      data: {
        klass: ITextBook;
      };
    };
  };
};

type fetchSimpleTeachingMaterialsSuccess = {
  types: typeof FETCH_SIMPLE_TEACHING_MATERIALS_SUCCESS;
  payload: {
    data: {
      teaching_materials: ISimpleTeachingMaterial[];
    };
  };
};

type createTeachingMaterialViewLogSuccess = {
  types: typeof CREATE_TEACHING_MATERIAL_VIEW_LOG_SUCCESS;
  payload: {
    data: {
      id: number;
    };
  };
};

type fetchListLessonsSuccess = {
  types: typeof FETCH_LIST_LESSONS_SUCCESS;
  payload: {
    data: {
      lessons: ILessonByTeachingMaterial[];
    };
  };
};

type fetchPortfolioSuccess = {
  types: typeof FETCH_PORTFOLIO_SUCCESS;
  payload: {
    data: {
      portfolio: IPortfolio;
    };
  };
};

type fetchPortfolioFailure = {
  types: typeof FETCH_PORTFOLIO_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type fetchLessonsSuccess = {
  types: typeof FETCH_LESSONS_SUCCESS;
  payload: {
    data: {
      lessons: ILesson[];
    };
  };
};

type fetchLessonSuccess = {
  types: typeof FETCH_LESSON_SUCCESS;
  payload: {
    data: {
      data: {
        lesson: ILesson;
      };
      meta: {
        next_lesson_id: number;
        prev_lesson_id: number;
      };
    };
  };
};

type fetchActiveLearningLessonSuccess = {
  types: typeof FETCH_LESSON_ACTIVE_LEARNING_SUCCESS;
  payload: {
    data: {
      data: {
        active_learning_lesson: IActiveLearningLesson;
      };
      meta: {
        next_lesson_id: number;
        prev_lesson_id: number;
      };
    };
  };
};

type fetchActiveLearningThemesSuccess = {
  types: typeof FETCH_ACTIVE_LEARNING_THEMES_SUCCESS;
  payload: {
    data: {
      data: IActiveLearningTheme[];
    };
  };
};

type fetchInitialDataFailure = {
  types: typeof FETCH_INITIAL_DATA_FAILURE;
  payload: {
    error: { message: string; field: string };
  };
};

type toggleCommentSuccess = {
  types: typeof TOGGLE_COMMENT_SUCCESS;
  payload: {
    params: { url: string };
    data: Record<string, unknown>;
  };
};

type createGroupSuccess = {
  types: typeof CREATE_GROUP_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type createGroupFailure = {
  types: typeof CREATE_GROUP_FAILURE;
  payload: {
    data: Record<string, unknown>;
  };
};

type updateGroupSuccess = {
  types: typeof UPDATE_GROUP_SUCCESS;
  payload: {
    data: { id: number; name: string };
  };
};

type updateGroupFailure = {
  types: typeof UPDATE_GROUP_FAILURE;
  payload: {
    data: Record<string, unknown>;
  };
};

type updateAnswerOpenFailure = {
  types: typeof ANSWER_OPENED_FAILURE;
  payload: {
    error: {
      message: string;
      status: number;
    };
  };
};

type updateNameOpenFailure = {
  types: typeof NAME_OPENED_FAILURE;
  payload: {
    error: {
      message: string;
      status: number;
    };
  };
};

type updateNameCloseFailure = {
  types: typeof NAME_CLOSED_FAILURE;
  payload: {
    error: {
      message: string;
      status: number;
    };
  };
};

type updateAnswerCloseFailure = {
  types: typeof ANSWER_CLOSED_FAILURE;
  payload: {
    error: {
      message: string;
      status: number;
    };
  };
};

type updatePasswordFailure = {
  types: typeof UPDATE_TEACHER_PASSWORD_FAILURE;
  payload: {
    error: Record<string, string>;
  };
};

type resetPasswordFailure = {
  types: typeof RESET_TEACHER_PASSWORD_FAILURE;
  payload: {
    error: Record<string, string>;
  };
};

type fetchStudentsSuccess = {
  types: typeof FETCH_STUDENTS_SUCCESS;
  payload: {
    data: { students: IStudent[] };
  };
};

type fetchAssessmentStudentsSuccess = {
  types: typeof FETCH_ASSESSMENT_STUDENTS_SUCCESS;
  payload: {
    data: {
      students: IAssessmentStudent[];
      class_scores: IClassScores;
      grade_scores: IReferenceScores;
      national_scores: IReferenceScores;
    };
  };
};

type fetchGroupsSuccess = {
  types: typeof FETCH_GROUPS_SUCCESS;
  payload: {
    data: { groups: IGroup[] };
  };
};

type fetchClassesSuccess = {
  types: typeof FETCH_CLASSES_SUCCESS;
  payload: {
    data: { classes: IClass[] };
  };
};

type fetchKlassIdsWithActiveLearningMaterialsSuccess = {
  types: typeof FETCH_KLASS_IDS_WITH_ACTIVE_LEARNING_MATERIALS_SUCCESS;
  payload: {
    data: { classes: IClass[] };
  };
};

type createCommentSuccess = {
  types: typeof CREATE_COMMENT_SUCCESS;
  payload: {
    data: { answer_comment: IAnswerComment };
  };
};

type updateCommentSuccess = {
  types: typeof UPDATE_COMMENT_SUCCESS;
  payload: {
    data: { answer_comment: IAnswerComment; message: string };
  };
};

type deleteCommentSuccess = {
  types: typeof DELETE_COMMENT_SUCCESS;
  payload: {
    params: { url: string };
    data: { message: string };
  };
};

type fetchAnswersSuccess = {
  types: typeof FETCH_ANSWERS_SUCCESS;
  payload: {
    data: {
      data: {
        issue: {
          klasses: Array<{
            id: number;
            name: string;
            full_name: string;
            name_opened: boolean;
            answer_opened: boolean;
            answers: IAnswer[];
          }>;
        };
      };
    };
  };
};

type fetchAnswersFromOtherSchoolsSuccess = {
  types: typeof FETCH_ANSWERS_FROM_OTHER_SCHOOLS_SUCCESS;
  payload: {
    data: {
      issue: {
        classes: IAnswersFromOtherSchoolByClass[];
      };
    };
  };
};

type fetchAnswerSummariesSuccess = {
  types: typeof FETCH_ANSWER_SUMMARIES_SUCCESS;
  payload: {
    data: { teaching_materials: ITeachingMaterialAnswerSummary[] };
  };
};

type publishTeachingMaterialSuccess = {
  types: typeof PUBLISH_TEACHING_MATERIAL_SUCCESS;
  payload: {
    data: {
      teaching_material_id: number;
      class_id: number;
      opened: boolean;
    };
  };
};

type publishTeachingMaterialFailure = {
  types: typeof PUBLISH_TEACHING_MATERIAL_FAILURE;
  payload: {
    error: { message: string };
  };
};

type unpublishTeachingMaterialSuccess = {
  types: typeof UNPUBLISH_TEACHING_MATERIAL_SUCCESS;
  payload: {
    data: {
      teaching_material_id: number;
      class_id: number;
      opened: boolean;
    };
  };
};

type unpublishTeachingMaterialFailure = {
  types: typeof UNPUBLISH_TEACHING_MATERIAL_FAILURE;
  payload: {
    error: { message: string };
  };
};

type publishLessonSuccess = {
  types: typeof PUBLISH_LESSON_SUCCESS;
  payload: {
    data: {
      data: {
        lesson: {
          id: number;
          teaching_material_id: number;
          klass_id: number;
          published: boolean;
          issues: IIssue[];
        };
      };
    };
  };
};

type publishLessonFailure = {
  types: typeof PUBLISH_LESSON_FAILURE;
  payload: {
    error: { message: string };
  };
};

type unpublishLessonSuccess = {
  types: typeof UNPUBLISH_LESSON_SUCCESS;
  payload: {
    data: {
      data: {
        lesson: {
          id: number;
          teaching_material_id: number;
          klass_id: number;
          published: boolean;
          issues: IIssue[];
        };
      };
    };
  };
};

type unpublishLessonFailure = {
  types: typeof UNPUBLISH_LESSON_FAILURE;
  payload: {
    error: { message: string };
  };
};

type nameOpenedSuccess = {
  types: typeof NAME_OPENED_SUCCESS;
  payload: {
    data: {
      data: {
        issue: {
          lesson_id: number;
          issue_id: number;
          klass_id: number;
          name_opened: boolean;
        };
      };
    };
  };
};

type nameClosedSuccess = {
  types: typeof NAME_CLOSED_SUCCESS;
  payload: {
    data: {
      data: {
        issue: {
          lesson_id: number;
          issue_id: number;
          klass_id: number;
          name_opened: boolean;
        };
      };
    };
  };
};

type answerOpenedSuccess = {
  types: typeof ANSWER_OPENED_SUCCESS;
  payload: {
    data: {
      data: {
        issue: {
          lesson_id: number;
          issue_id: number;
          klass_id: number;
          answer_opened: boolean;
        };
      };
    };
  };
};

type answerClosedSuccess = {
  types: typeof ANSWER_CLOSED_SUCCESS;
  payload: {
    data: {
      data: {
        issue: {
          lesson_id: number;
          issue_id: number;
          klass_id: number;
          answer_opened: boolean;
        };
      };
    };
  };
};

type sendFeedbackCommentSuccess = {
  types: typeof SEND_FEEDBACK_COMMENT_SUCCESS;
  payload: {
    params: {
      feedback_comment: string;
    };
  };
};

type fetchPackagesSuccess = {
  types: typeof FETCH_PACKAGES_SUCCESS;
  payload: {
    params: { url: string };
    data: {
      data: {
        packages: IPackage[];
      };
    };
  };
};

type fetchSurveyAnswersSuccess = {
  types: typeof FETCH_SURVEY_ANSWERS_SUCCESS;
  payload: {
    params: { url: string };
    data: {
      survey_answers: ISurveyAnswers[];
      survey_answers_per_teaching_material: ISurveyAnswers[];
    };
  };
};

type fetchDetailsSurveySuccess = {
  types: typeof FETCH_DETAILS_SURVEY_SUCCESS;
  payload: {
    params: { url: string };
    data: {
      teaching_materials: IDetailsSurvey[];
    };
  };
};

type fetchAssessmentSuccess = {
  types: typeof FETCH_ASSESSMENT_SUCCESS;
  payload: {
    data: {
      student: {
        id: number;
        full_name: string;
      };
      assessment: IAssessmentTeacher;
      answers: IAssessmentAnswer[];
    };
  };
};

type fetchTemporaryTokenSuccess = {
  types: typeof FETCH_TEMPORARY_TOKEN_SUCCESS;
  payload: {
    data: {
      token: string;
    };
  };
};

type fetchAvgAssessmentSuccess = {
  types: typeof FETCH_AVERAGE_ASSESSMENT_SUCCESS;
  payload: {
    data: {
      avg_scores: {
        first_term_no_avg: IAssessmentAvgScores[] | null;
        previous_term_no_avg: IAssessmentAvgScores[] | null;
        last_term_no_avg: IAssessmentAvgScores[] | null;
      };
      distributions: IAssessmentDistribution[];
    };
  };
};

type fetchAvgAssessmentByGradeSuccess = {
  types: typeof FETCH_AVERAGE_ASSESSMENT_BY_GRADE_SUCCESS;
  payload: {
    data: {
      avg_scores: {
        first_term_no_avg: IAssessmentAvgScores[] | null;
        previous_term_no_avg: IAssessmentAvgScores[] | null;
        last_term_no_avg: IAssessmentAvgScores[] | null;
      };
    };
  };
};

type deleteSuccess = {
  types: typeof DELETE_GROUP_SUCCESS;
  payload: {
    data: {
      id: number;
    };
  };
};

type fetchSchoolGoalsSuccess = {
  types: typeof FETCH_SCHOOL_GOALS_SUCCESS;
  payload: {
    data: {
      data: {
        school: ISchoolGoals;
      };
    };
  };
};

type updateAssessmentLastActiveSuccess = {
  types: typeof UPDATE_ASSESSMENT_LAST_ACTIVE_SUCCESS;
  payload: {
    data: { assessment_last_active_date: string | null };
  };
};

type resetStudentPasswordSuccess = {
  types: typeof RESET_STUDENT_PASSWORD_SUCCESS;
  payload: {
    data: { student: IStudent };
  };
};

type action503 = {
  types: typeof ACTION_503;
};

export type Actions =
  | ReturnType<typeof login>
  | ReturnType<typeof verifyMFA>
  | ReturnType<typeof logout>
  | ReturnType<typeof fetchInitialData>
  | ReturnType<typeof fetchTeachingMaterials>
  | ReturnType<typeof clearTeachingMaterials>
  | ReturnType<typeof fetchActiveLearningMaterials>
  | ReturnType<typeof fetchActiveLearningMaterialLessons>
  | ReturnType<typeof fetchActiveLearningAnswers>
  | ReturnType<typeof fetchActiveLearningStudentAnswers>
  | ReturnType<typeof fetchActiveLearningThemes>
  | ReturnType<typeof fetchActiveLearningStoryBoard>
  | ReturnType<typeof fetchTeachingMaterial>
  | ReturnType<typeof fetchCountAnswers>
  | ReturnType<typeof fetchLessons>
  | ReturnType<typeof fetchLesson>
  | ReturnType<typeof fetchActiveLearningLesson>
  | ReturnType<typeof fetchSchoolGoals>
  | ReturnType<typeof fetchStudents>
  | ReturnType<typeof fetchAssessmentStudents>
  | ReturnType<typeof fetchGroups>
  | ReturnType<typeof fetchClasses>
  | ReturnType<typeof fetchKlassIdsWithActiveLearningMaterials>
  | ReturnType<typeof createGroup>
  | ReturnType<typeof updateGroup>
  | ReturnType<typeof fetchAnswers>
  | ReturnType<typeof fetchTeachingMaterialsByClass>
  | ReturnType<typeof clearTeachingMaterialsByClass>
  | ReturnType<typeof fetchSimpleTeachingMaterials>
  | ReturnType<typeof createTeachingMaterialViewLog>
  | ReturnType<typeof fetchAnswerSummaries>
  | ReturnType<typeof fetchAvgAssessmentByGrade>
  | ReturnType<typeof createComment>
  | ReturnType<typeof deleteComment>
  | ReturnType<typeof updateComment>
  | ReturnType<typeof publishTeachingMaterial>
  | ReturnType<typeof unpublishTeachingMaterial>
  | ReturnType<typeof nameOpened>
  | ReturnType<typeof nameClosed>
  | ReturnType<typeof updateTeacherPassword>
  | ReturnType<typeof resetTeacherPassword>
  | ReturnType<typeof fetchDetailsSurvey>
  | ReturnType<typeof clearSuccessMessage>
  | ReturnType<typeof clearErrorMessage>
  | ReturnType<typeof clearOTT>
  | ReturnType<typeof fetchPackages>
  | ReturnType<typeof fetchSurveyAnswers>
  | ReturnType<typeof deleteGroup>
  | ReturnType<typeof toggleComment>
  | ReturnType<typeof fetchListLessons>
  | ReturnType<typeof fetchPortfolio>
  | ReturnType<typeof answerOpened>
  | ReturnType<typeof answerClosed>
  | ReturnType<typeof fetchDataDownloadCSV>
  | ReturnType<typeof publishLesson>
  | ReturnType<typeof unpublishLesson>
  | ReturnType<typeof fetchAnswersFromOtherSchools>
  | ReturnType<typeof sendFeedbackComment>
  | ReturnType<typeof fetchAssessment>
  | ReturnType<typeof fetchAssessmentPast>
  | ReturnType<typeof fetchDataAssessmentCSV>
  | ReturnType<typeof clearTemporaryToken>
  | ReturnType<typeof fetchAvgAssessment>
  | ReturnType<typeof fetchTemporaryToken>
  | ReturnType<typeof fetchAllDistributionsAssessment>
  | ReturnType<typeof updateAssessmentLastActive>
  | ReturnType<typeof clearStudents>
  | ReturnType<typeof getUuidStatus>
  | loginSuccess
  | loginFailure
  | verifyMFASuccess
  | verifyMFAFailure
  | fetchInitialDataSuccess
  | fetchInitialDataFailure
  | fetchTeachingMaterialsSuccess
  | fetchActiveLearningMaterialsSuccess
  | fetchActiveLearningMaterialLessonsSuccess
  | fetchActiveLearningAnswersSuccess
  | fetchActiveLearningStudentAnswersSuccess
  | fetchActiveLearningStoryBoardSuccess
  | fetchAnswersFromOtherSchoolsSuccess
  | fetchCountAnswersSuccess
  | fetchTeachingMaterialSuccess
  | sendFeedbackCommentSuccess
  | fetchAvgAssessmentByGradeSuccess
  | fetchLessonsSuccess
  | fetchSchoolGoalsSuccess
  | fetchTeachingMaterialsByClassSuccess
  | fetchSimpleTeachingMaterialsSuccess
  | createTeachingMaterialViewLogSuccess
  | fetchLessonSuccess
  | fetchActiveLearningLessonSuccess
  | fetchActiveLearningThemesSuccess
  | fetchStudentsSuccess
  | fetchAssessmentStudentsSuccess
  | fetchTemporaryTokenSuccess
  | answerOpenedSuccess
  | answerClosedSuccess
  | fetchGroupsSuccess
  | fetchClassesSuccess
  | fetchKlassIdsWithActiveLearningMaterialsSuccess
  | createGroupSuccess
  | createGroupFailure
  | updateGroupSuccess
  | updateGroupFailure
  | fetchAnswersSuccess
  | fetchAnswerSummariesSuccess
  | createCommentSuccess
  | updateCommentSuccess
  | deleteCommentSuccess
  | updateNameOpenFailure
  | updateNameCloseFailure
  | publishTeachingMaterialSuccess
  | publishTeachingMaterialFailure
  | unpublishTeachingMaterialSuccess
  | unpublishTeachingMaterialFailure
  | nameOpenedSuccess
  | nameClosedSuccess
  | fetchSurveyAnswersSuccess
  | fetchPackagesSuccess
  | updateTeacherPasswordSuccess
  | resetTeacherPasswordSuccess
  | resetPasswordFailure
  | updatePasswordFailure
  | updateAnswerOpenFailure
  | updateAnswerCloseFailure
  | deleteSuccess
  | toggleCommentSuccess
  | logoutSuccess
  | fetchDetailsSurveySuccess
  | fetchListLessonsSuccess
  | fetchPortfolioSuccess
  | fetchPortfolioFailure
  | fetchDataDownloadCSVSuccess
  | fetchDataAssessmentsCSVSuccess
  | publishLessonSuccess
  | publishLessonFailure
  | unpublishLessonSuccess
  | unpublishLessonFailure
  | fetchAssessmentSuccess
  | fetchAssessmentPastSuccess
  | fetchAvgAssessmentSuccess
  | fetchAllDistributionsAssessmentSuccess
  | updateAssessmentLastActiveSuccess
  | resetStudentPasswordSuccess
  | checkUuidSuccess
  | checkUuidFailure
  | action503;

export type AppState = {
  session_id: string;
  school?: ISchoolCountAnswers;
  error: string;
  error_field: string;
  success_message: string;
  isServiceUnavailable: boolean;
  isLoaderVisible: boolean;
  teaching_materials: ITeachingMaterial[];
  active_learning_materials: IActiveLearningMaterial[];
  active_learning_material_lessons: IActiveLearningMaterialLessons;
  active_learning_answers: IActiveLearningKlassStudentAnswers[] | null;
  active_learning_student_answers: IActiveLearningStudentAnswers | null;
  active_learning_themes: IActiveLearningTheme[];
  active_learning_story_board: IActiveLearningStoryBoard;
  simple_teaching_materials: ISimpleTeachingMaterial[];
  teaching_materials_by_class: ITeachingMaterial[];
  teaching_material?: ITeachingMaterial;
  lessonsByTeachingMaterial?: ILessonByTeachingMaterial[];
  lessons: ILesson[];
  school_goals: ISchoolGoals;
  lesson?: ILesson;
  activeLearningLesson?: IActiveLearningLesson;
  teacher: ITeacher;
  students: IStudent[];
  assessmentStudents: IAssessmentStudent[];
  classes: IClass[];
  klass_ids_with_active_learning_materials: IClass[];
  groups: IGroup[];
  answersByClasses: IAnswersByClass[];
  packages: IPackage[];
  survey_answers: ISurveyAnswers[];
  survey_answers_per_teaching_material: ISurveyAnswers[];
  teaching_material_answer_summaries: ITeachingMaterialAnswerSummary[];
  details_survey: IDetailsSurvey[] | null;
  lessonCSV?: ILessonCSV;
  assessmentsCSV: IAssessmentCSV[];
  next_lesson_id: number | null;
  prev_lesson_id: number | null;
  school_name: string;
  assessment: IAssessmentTeacher;
  assessmentStudent: {
    id: number;
    full_name: string;
  };
  assessmentAnswers: IAssessmentAnswer[];
  assessment_avg_scores: IAssessmentAvgScores[] | null;
  past_assessment_avg_scores: {
    first_assessment_avg_scores: IAssessmentAvgScores[] | null;
    previous_assessment_avg_scores: IAssessmentAvgScores[] | null;
  };
  assessment_avg_scores_by_grade: IAssessmentAvgScores[] | null;
  past_assessment_avg_scores_by_grade: {
    first_assessment_avg_scores: IAssessmentAvgScores[] | null;
    previous_assessment_avg_scores: IAssessmentAvgScores[] | null;
  };
  distributions: IAssessmentDistribution[];
  survey_enabled: boolean;
  available_item_codes: string[];
  is_manager: boolean;
  only_assessment: boolean;
  lastAction: string;
  max_term_no_by_grade: number;
  assessments_past: IAssessment[];
  answersOtherSchoolsByClasses: IAnswersFromOtherSchoolByClass[];
  allDistributions: IAllDistributions;
  temporaryToken: string | null;
  portfolio: IPortfolio | null;
  ott: string;
  isUuidExpired: boolean;
  expires: Date;
  is_unread_news: boolean;
  assessmentReferenceScores: {
    classScores: IClassScores | null;
    gradeScores: IReferenceScores | null;
    nationalScores: IReferenceScores | null;
  };
};

export const InitialAppState: AppState = {
  session_id: "",
  error: "",
  error_field: "",
  success_message: "",
  isServiceUnavailable: false,
  isLoaderVisible: false,
  teaching_materials: [],
  active_learning_materials: [],
  active_learning_material_lessons: {
    material_code: "",
    no: NaN,
    title: "",
    issues: [],
  },
  active_learning_answers: null,
  active_learning_student_answers: null,
  active_learning_themes: [],
  active_learning_story_board: {
    id: NaN,
    enageed_theme_name: "",
    image_urls: [],
  },
  simple_teaching_materials: [],
  lessons: [],
  school_goals: {
    id: 0,
    purpose: "",
    specific_state: "",
    theme: "",
  },
  students: [],
  assessmentStudents: [],
  groups: [],
  classes: [],
  klass_ids_with_active_learning_materials: [],
  teacher: {
    id: 0,
    name: "",
    classes: [],
    class_ids: [],
    email: "",
    first_name: "",
    last_name: "",
    first_name_kana: "",
    last_name_kana: "",
    read_last_notification_id: 0,
    school_id: 0,
    serviced: false,
  },
  answersByClasses: [],
  packages: [],
  survey_answers: [],
  survey_answers_per_teaching_material: [],
  teaching_material_answer_summaries: [],
  details_survey: null,
  lessonCSV: undefined,
  assessmentsCSV: [],
  teaching_materials_by_class: [],
  next_lesson_id: null,
  prev_lesson_id: null,
  school_name: "",
  assessment: {
    id: 0,
    assessment_experiences: [],
    current_assessment_next_actions: [],
    past_assessment_next_actions: [],
    term_no: 0,
    feedback_comment: null,
    created_at: "",
    reasons: [],
  },
  assessmentStudent: {
    id: 0,
    full_name: "",
  },
  assessmentAnswers: [],
  assessment_avg_scores: null,
  past_assessment_avg_scores: {
    first_assessment_avg_scores: null,
    previous_assessment_avg_scores: null,
  },
  assessment_avg_scores_by_grade: null,
  past_assessment_avg_scores_by_grade: {
    first_assessment_avg_scores: null,
    previous_assessment_avg_scores: null,
  },
  distributions: [],
  survey_enabled: false,
  available_item_codes: [],
  is_manager: false,
  only_assessment: false,
  lastAction: "",
  max_term_no_by_grade: 0,
  assessments_past: [],
  answersOtherSchoolsByClasses: [],
  allDistributions: {
    first_term_no_distributions: null,
    last_term_no_distributions: null,
    previous_term_no_distributions: null,
  },
  temporaryToken: null,
  portfolio: null,
  ott: "",
  isUuidExpired: false,
  expires: addHours(new Date(), 6),
  is_unread_news: false,
  assessmentReferenceScores: {
    classScores: null,
    gradeScores: null,
    nationalScores: null,
  },
};

function isLoginMFA(
  data: ILoginResponse | ILoginMFAResponse,
): data is ILoginMFAResponse {
  return (data as ILoginMFAResponse).ott !== undefined;
}

export const reducer = (state: AppState, action: Actions): AppState => {
  const newState = { ...state, lastAction: action.types };
  switch (action.types) {
    case LOGIN_SUCCESS: {
      if (isLoginMFA(action.payload.data)) {
        const { ott } = action.payload.data;
        return {
          ...newState,
          error: "",
          error_field: "",
          ott,
        };
      }
      const {
        session_id,
        teacher,
        is_manager,
        only_assessment,
      } = action.payload.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        session_id,
        teacher,
        is_manager,
        only_assessment,
      };
    }
    case VERIFY_MFA_SUCCESS: {
      const {
        session_id,
        teacher,
        is_manager,
        only_assessment,
      } = action.payload.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        session_id,
        teacher,
        is_manager,
        only_assessment,
      };
    }
    case VERIFY_MFA_FAILURE: {
      const { message, field } = action.payload.error;
      return {
        ...newState,
        error: message,
        error_field: field,
      };
    }
    case FETCH_INITIAL_DATA: {
      return { ...newState };
    }
    case FETCH_INITIAL_DATA_SUCCESS: {
      const {
        teacher,
        school_name,
        survey_enabled,
        available_item_codes,
        is_manager,
        only_assessment,
        is_unread_news,
      } = action.payload.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        teacher: {
          ...teacher,
          assessment_last_active_date: teacher.assessment_last_active_date
            ? new Date(teacher.assessment_last_active_date)
            : null,
        },
        school_name,
        session_id: teacher.id ? state.session_id : "",
        survey_enabled,
        available_item_codes,
        is_manager,
        only_assessment,
        is_unread_news,
      };
    }
    case UPDATE_ASSESSMENT_LAST_ACTIVE_SUCCESS: {
      const { assessment_last_active_date } = action.payload.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        teacher: {
          ...(state.teacher as ITeacher),
          assessment_last_active_date: assessment_last_active_date
            ? new Date(assessment_last_active_date)
            : null,
        },
      };
    }
    case FETCH_LIST_LESSONS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        error: "",
        error_field: "",
        lessonsByTeachingMaterial: data.lessons,
      };
    }
    case FETCH_PORTFOLIO: {
      return {
        ...newState,
        portfolio: null,
      };
    }
    case FETCH_PORTFOLIO_SUCCESS: {
      const { portfolio } = action.payload.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        portfolio,
      };
    }
    case FETCH_PORTFOLIO_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: "",
        success_message: "",
      };
    }
    case UPDATE_TEACHER_PASSWORD_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: UPDATE_TEACHER_PASSWORD_SUCCESS_MESSAGE,
      };
    }
    case RESET_TEACHER_PASSWORD_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: RESET_TEACHER_PASSWORD_SUCCESS_MESSAGE,
      };
    }
    case TOGGLE_COMMENT_SUCCESS: {
      const paths = action.payload.params.url.split("/");
      const comment_id = Number(paths[paths.length - 2]);

      return {
        ...newState,
        answersByClasses: state.answersByClasses.map(k => ({
          ...k,
          answers: k.answers.map(a => ({
            ...a,
            comments: a.comments.map(c => ({
              ...c,
              hidden_by:
                c.id !== comment_id
                  ? c.hidden_by
                  : c.hidden_by !== null
                  ? null
                  : state.teacher,
            })),
          })),
        })),
        success_message: TOGGLE_COMMENT_SUCCESS_MSG,
      };
    }
    case CREATE_GROUP_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: CREATE_GROUP_SUCCESS_MESSAGE,
      };
    }
    case CHECK_UUID:
    case CHECK_UUID_SUCCESS: {
      return {
        ...newState,
        isUuidExpired: false,
      };
    }
    case CREATE_GROUP_FAILURE: {
      return {
        ...newState,
        error: CREATE_GROUP_FAILURE_MESSAGE,
        error_field: "",
        success_message: "",
      };
    }
    case UPDATE_TEACHER_PASSWORD_FAILURE: {
      const { error } = action.payload;
      return {
        ...newState,
        error: error.message,
        error_field: "",
        success_message: "",
      };
    }
    case RESET_TEACHER_PASSWORD_FAILURE: {
      const { error } = action.payload;
      return {
        ...newState,
        error: error.message || RESET_TEACHER_PASSWORD_FAILURE_MESSAGE,
        error_field: "",
        success_message: "",
      };
    }
    case CHECK_UUID_FAILURE: {
      return {
        ...newState,
        isUuidExpired: true,
      };
    }
    case UPDATE_GROUP_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: UPDATE_GROUP_SUCCESS_MESSAGE,
        groups: state.groups!.map(g =>
          g.id === action.payload.data.id
            ? {
                ...g,
                name: action.payload.data.name,
              }
            : g,
        ),
      };
    }
    case UPDATE_GROUP_FAILURE: {
      return {
        ...newState,
        error: UPDATE_GROUP_FAILURE_MESSAGE,
        error_field: "",
        success_message: "",
      };
    }
    case FETCH_DATA_DOWNLOAD_CSV_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        error: "",
        error_field: "",
        lessonCSV: data.lesson,
      };
    }
    case FETCH_DATA_ASSESSMENTS_CSV: {
      return {
        ...newState,
        assessmentsCSV: [],
      };
    }
    case FETCH_DATA_ASSESSMENTS_CSV_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        assessmentsCSV: data.assessments,
      };
    }
    case FETCH_ANSWERS_FROM_OTHER_SCHOOLS_SUCCESS: {
      const { issue } = action.payload.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        answersOtherSchoolsByClasses: issue.classes,
      };
    }
    case FETCH_COUNT_ANSWERS_SUCCESS: {
      const { school } = action.payload.data.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        school,
      };
    }
    case FETCH_TEACHING_MATERIAL_SUCCESS: {
      const t = action.payload.data.data.teaching_material;
      return {
        ...newState,
        error: "",
        error_field: "",
        teaching_material: {
          ...t,
        },
      };
    }
    case FETCH_TEACHING_MATERIALS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        teaching_materials: [...data.data.teaching_materials],
        lesson: undefined,
      };
    }
    case FETCH_ACTIVE_LEARNING_MATERIALS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        active_learning_materials: [...data.data.active_learning_materials],
      };
    }
    case FETCH_ACTIVE_LEARNING_MATERIAL_LESSONS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        active_learning_material_lessons: data.data,
      };
    }
    case FETCH_ACTIVE_LEARNING_ANSWERS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        active_learning_answers: data.students,
      };
    }
    case FETCH_ACTIVE_LEARNING_STUDENT_ANSWERS_SUCCESS: {
      return {
        ...newState,
        active_learning_student_answers: action.payload.data.student,
      };
    }
    case FETCH_ACTIVE_LEARNING_STORY_BOARD_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        active_learning_story_board: data,
      };
    }
    case FETCH_ACTIVE_LEARNING_THEMES_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        active_learning_themes: data,
      };
    }
    case CLEAR_TEACHING_MATERIALS: {
      return {
        ...newState,
        teaching_materials: [],
      };
    }
    case FETCH_TEACHING_MATERIALS_BY_CLASS_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        teaching_materials_by_class: [...data.klass.teaching_materials],
      };
    }
    case CLEAR_TEACHING_MATERIALS_BY_CLASS: {
      return {
        ...newState,
        teaching_materials_by_class: [],
      };
    }
    case FETCH_SIMPLE_TEACHING_MATERIALS_SUCCESS: {
      const { teaching_materials } = action.payload.data;
      return {
        ...newState,
        simple_teaching_materials: teaching_materials,
      };
    }
    case CREATE_TEACHING_MATERIAL_VIEW_LOG_SUCCESS: {
      return { ...newState };
    }
    case FETCH_LESSONS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        lessons: data.lessons,
      };
    }
    case FETCH_SCHOOL_GOALS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        school_goals: data.data.school,
      };
    }
    case ANSWER_CLOSED_SUCCESS:
    case ANSWER_OPENED_SUCCESS: {
      const { klass_id, answer_opened } = action.payload.data.data.issue;
      return {
        ...newState,
        answersByClasses: state.answersByClasses.map(k => ({
          ...k,
          answer_opened: k.id === klass_id ? answer_opened : k.answer_opened,
        })),
      };
    }
    case ANSWER_OPENED_FAILURE: {
      return {
        ...newState,
        error: TOGGLE_ANSWER_ERROR_MSG,
        error_field: "",
        success_message: "",
      };
    }
    case NAME_OPENED_SUCCESS:
    case NAME_CLOSED_SUCCESS: {
      const { klass_id, name_opened } = action.payload.data.data.issue;
      return {
        ...newState,
        answersByClasses: state.answersByClasses.map(k => ({
          ...k,
          name_opened: k.id === klass_id ? name_opened : k.name_opened,
        })),
      };
    }
    case NAME_CLOSED_FAILURE: {
      return {
        ...newState,
        error: TOGGLE_ANSWER_ERROR_MSG,
        error_field: "",
        success_message: "",
      };
    }
    case NAME_OPENED_FAILURE: {
      return {
        ...newState,
        error: TOGGLE_ANSWER_ERROR_MSG,
        error_field: "",
        success_message: "",
      };
    }
    case ANSWER_CLOSED_FAILURE: {
      return {
        ...newState,
        error: TOGGLE_ANSWER_ERROR_MSG,
        error_field: "",
        success_message: "",
      };
    }
    case FETCH_LESSON_SUCCESS: {
      const { data, meta } = action.payload.data;
      return {
        ...newState,
        next_lesson_id: meta.next_lesson_id,
        prev_lesson_id: meta.prev_lesson_id,
        lesson: data.lesson,
      };
    }
    case FETCH_LESSON_ACTIVE_LEARNING_SUCCESS: {
      const { data, meta } = action.payload.data;
      return {
        ...newState,
        next_lesson_id: meta.next_lesson_id,
        prev_lesson_id: meta.prev_lesson_id,
        activeLearningLesson: data.active_learning_lesson,
      };
    }
    case FETCH_STUDENTS_SUCCESS: {
      return { ...newState, students: action.payload.data.students };
    }
    case FETCH_ASSESSMENT_STUDENTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        assessmentStudents: data.students,
        assessmentReferenceScores: {
          classScores: data.class_scores,
          gradeScores: data.grade_scores,
          nationalScores: data.national_scores,
        },
      };
    }
    case FETCH_ALL_DISTRIBUTIONS_ASSESSMENT_SUCCESS: {
      return {
        ...newState,
        allDistributions: action.payload.data.all_distributions,
      };
    }
    case FETCH_GROUPS_SUCCESS: {
      return { ...newState, groups: action.payload.data.groups };
    }
    case FETCH_CLASSES_SUCCESS: {
      return { ...newState, classes: action.payload.data.classes };
    }
    case FETCH_KLASS_IDS_WITH_ACTIVE_LEARNING_MATERIALS_SUCCESS: {
      return {
        ...newState,
        klass_ids_with_active_learning_materials: action.payload.data.classes,
      };
    }
    case FETCH_ANSWERS_SUCCESS: {
      return {
        ...newState,
        answersByClasses: action.payload.data.data.issue.klasses,
      };
    }
    case FETCH_ANSWER_SUMMARIES_SUCCESS: {
      return {
        ...newState,
        teaching_material_answer_summaries:
          action.payload.data.teaching_materials,
      };
    }
    case CREATE_COMMENT_SUCCESS: {
      return {
        ...newState,
        answersByClasses: state.answersByClasses.map(k => ({
          ...k,
          answers: k.answers.map(a =>
            a.id === action.payload.data.answer_comment.answer_id
              ? {
                  ...a,
                  comments: [
                    ...a.comments,
                    {
                      ...action.payload.data.answer_comment,
                      updated_at: new Date(
                        action.payload.data.answer_comment.updated_at,
                      ),
                      hidden_by: null,
                    },
                  ],
                }
              : a,
          ),
        })),
        success_message: CREATE_COMMENT_SUCCESS_MSG,
      };
    }
    case CREATE_COMMENT:
    case UPDATE_COMMENT:
    case TOGGLE_COMMENT:
    case DELETE_COMMENT: {
      return newState;
    }
    case UPDATE_COMMENT_SUCCESS: {
      return {
        ...newState,
        answersByClasses: state.answersByClasses.map(k => ({
          ...k,
          answers: k.answers.map(a =>
            a.id === action.payload.data.answer_comment.answer_id
              ? {
                  ...a,
                  comments: a.comments.map(c =>
                    c.id === action.payload.data.answer_comment.id
                      ? {
                          ...c,
                          content: action.payload.data.answer_comment.content,
                        }
                      : c,
                  ),
                }
              : a,
          ),
        })),
        success_message: UPDATE_COMMENT_SUCCESS_MSG,
      };
    }
    case DELETE_COMMENT_SUCCESS: {
      const paths = action.payload.params.url.split("/");
      const comment_id = Number(paths[paths.length - 1]);

      return {
        ...newState,
        answersByClasses: state.answersByClasses.map(k => ({
          ...k,
          answers: k.answers.map(a => ({
            ...a,
            comments: a.comments.filter(c => c.id !== comment_id),
          })),
        })),
        success_message: DELETE_COMMENT_SUCCESS_MSG,
      };
    }
    case PUBLISH_TEACHING_MATERIAL_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: "",
        success_message: "",
      };
    }
    case PUBLISH_TEACHING_MATERIAL_SUCCESS: {
      return {
        ...newState,
        teaching_materials_by_class: state.teaching_materials_by_class.map(t =>
          t.id === action.payload.data.teaching_material_id
            ? {
                ...t,
                opened: true,
                lessons: t.lessons?.map(lesson => ({
                  ...lesson,
                  published: true,
                  issues: lesson.issues?.map(issue => ({
                    ...issue,
                    answer_opened: true,
                  })),
                })),
              }
            : t,
        ),
        success_message: PUBLISH_TEACHING_MATERIAL_SUCCESS_MSG,
      };
    }
    case UNPUBLISH_TEACHING_MATERIAL_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: "",
        success_message: "",
      };
    }
    case UNPUBLISH_TEACHING_MATERIAL_SUCCESS: {
      return {
        ...newState,
        teaching_materials_by_class: state.teaching_materials_by_class.map(t =>
          t.id === action.payload.data.teaching_material_id
            ? {
                ...t,
                opened: false,
                lessons: t.lessons?.map(lesson => ({
                  ...lesson,
                  published: false,
                  issues: lesson.issues?.map(issue => ({
                    ...issue,
                    answer_opened: false,
                    name_opened: false,
                  })),
                })),
              }
            : t,
        ),
        success_message: UNPUBLISH_TEACHING_MATERIAL_SUCCESS_MSG,
      };
    }
    case UNPUBLISH_LESSON_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: "",
        success_message: "",
      };
    }
    case UNPUBLISH_LESSON_SUCCESS: {
      const {
        id,
        teaching_material_id,
        published,
        issues,
      } = action.payload.data.data.lesson;
      return {
        ...newState,
        teaching_materials_by_class: state.teaching_materials_by_class.map(t =>
          t.id === teaching_material_id
            ? {
                ...t,
                lessons: t.lessons?.map(lesson =>
                  lesson.id === id
                    ? {
                        ...lesson,
                        published,
                        issues,
                      }
                    : lesson,
                ),
              }
            : t,
        ),
        success_message: UNPUBLISH_LESSON_SUCCESS_MSG,
      };
    }
    case PUBLISH_LESSON_FAILURE: {
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: "",
        success_message: "",
      };
    }
    case PUBLISH_LESSON_SUCCESS: {
      const {
        id,
        teaching_material_id,
        published,
        issues,
      } = action.payload.data.data.lesson;
      return {
        ...newState,
        teaching_materials_by_class: state.teaching_materials_by_class.map(t =>
          t.id === teaching_material_id
            ? {
                ...t,
                opened: true,
                lessons: t.lessons?.map(lesson =>
                  lesson.id === id
                    ? {
                        ...lesson,
                        published,
                        issues,
                      }
                    : lesson,
                ),
              }
            : t,
        ),
        teaching_materials: state.teaching_materials.map(t =>
          t.id === teaching_material_id
            ? {
                ...t,
                opened: true,
                lessons: t.lessons?.map(lesson =>
                  lesson.id === id
                    ? {
                        ...lesson,
                        published,
                        issues,
                      }
                    : lesson,
                ),
              }
            : t,
        ),
        success_message: PUBLISH_LESSON_SUCCESS_MSG,
      };
    }
    case SEND_FEEDBACK_COMMENT_SUCCESS: {
      const { feedback_comment } = action.payload.params;
      return {
        ...newState,
        assessment: {
          ...state.assessment,
          feedback_comment,
        },
        success_message: SEND_FEEDBACK_COMMENT_SUCCESS_MSG,
      };
    }
    case DELETE_GROUP_SUCCESS: {
      const groupId = action.payload.data.id;
      return {
        ...newState,
        groups: state.groups.filter(group => group.id !== groupId),
      };
    }
    case FETCH_PACKAGES_SUCCESS: {
      return {
        ...newState,
        packages: action.payload.data.data.packages,
      };
    }
    case FETCH_SURVEY_ANSWERS_SUCCESS: {
      return {
        ...newState,
        survey_answers: action.payload.data.survey_answers,
        survey_answers_per_teaching_material:
          action.payload.data.survey_answers_per_teaching_material,
      };
    }
    case FETCH_DETAILS_SURVEY_SUCCESS: {
      return {
        ...newState,
        details_survey: action.payload.data.teaching_materials,
      };
    }
    case FETCH_ASSESSMENT_SUCCESS: {
      const { assessment, student, answers } = action.payload.data;
      return {
        ...newState,
        assessment,
        assessmentStudent: student,
        assessmentAnswers: answers,
      };
    }
    case FETCH_ASSESSMENT: {
      return {
        ...newState,
        assessment: {
          id: 0,
          assessment_experiences: [],
          current_assessment_next_actions: [],
          past_assessment_next_actions: [],
          term_no: 0,
          feedback_comment: null,
          created_at: "",
          reasons: [],
        },
        assessmentStudent: {
          id: 0,
          full_name: "",
        },
        assessmentAnswers: [],
      };
    }
    case FETCH_ASSESSMENT_PAST_SUCCESS: {
      const { assessments } = action.payload.data;
      return {
        ...newState,
        assessments_past: assessments,
      };
    }
    case FETCH_TEMPORARY_TOKEN_SUCCESS: {
      return {
        ...newState,
        temporaryToken: action.payload.data.token,
      };
    }
    case CLEAR_TEMPORARY_TOKEN: {
      return {
        ...newState,
        temporaryToken: null,
      };
    }
    case FETCH_AVERAGE_ASSESSMENT_SUCCESS: {
      const { avg_scores, distributions } = action.payload.data;
      return {
        ...newState,
        assessment_avg_scores: avg_scores.last_term_no_avg,
        past_assessment_avg_scores: {
          first_assessment_avg_scores: avg_scores.first_term_no_avg,
          previous_assessment_avg_scores: avg_scores.previous_term_no_avg,
        },
        distributions,
      };
    }
    case FETCH_AVERAGE_ASSESSMENT_BY_GRADE_SUCCESS: {
      const { avg_scores } = action.payload.data;
      return {
        ...newState,
        assessment_avg_scores_by_grade: avg_scores.last_term_no_avg,
        past_assessment_avg_scores_by_grade: {
          first_assessment_avg_scores: avg_scores.first_term_no_avg,
          previous_assessment_avg_scores: avg_scores.previous_term_no_avg,
        },
      };
    }
    case RESET_STUDENT_PASSWORD_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        error: "",
        error_field: "",
        success_message: RESET_STUDENT_PASSWORD_SUCCESS_MESSAGE,
        students: state.students.map(s =>
          s.id === data.student.id ? data.student : s,
        ),
      };
    }
    case CLEAR_OTT: {
      return { ...newState, ott: "" };
    }
    case CLEAR_STUDENTS: {
      return {
        ...newState,
        students: [],
      };
    }
    case FETCH_INITIAL_DATA_FAILURE: {
      return { ...InitialAppState };
    }
    case LOGIN_FAILURE:
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    case LOGOUT:
      return { ...InitialAppState };
    case LOGOUT_SUCCESS:
      return { ...InitialAppState, success_message: LOGOUT_SUCCESS_MESSAGE };
    case CLEAR_SUCCESS_MESSAGE:
      return { ...newState, success_message: "" };
    case CLEAR_ERROR_MESSAGE:
      return { ...newState, error: "", error_field: "" };
    case ACTION_503:
      return {
        ...newState,
        isServiceUnavailable: true,
        success_message: ACTION_503_MESSAGE,
      };
    default:
      return newState;
  }
};

type ContextState = {
  state: AppState;
  dispatch(action: Actions): void;
};

export const Context = React.createContext<ContextState>({
  state: InitialAppState,
  dispatch(_) {
    console.warn("Context.Provider外からの呼び出し");
  },
});
