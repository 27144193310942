export const ROLES = {
  master: { flag: 1, message: "すべて" },
  viewer: { flag: 2, message: "閲覧" },
  editor: { flag: 4, message: "登録" },
};

export const TEACHER_SESSION_KEY = "teacher_session_id";

export const IMAGE_TYPE = {
  vertical: 1,
  horizontal: 2,
};
