import React from "react";

import { ISimpleTeachingMaterial, ITeachingMaterial } from "../../state";

import styles from "./TeachingMaterialHeader.module.scss";

type Props = {
  teachingMaterial: ITeachingMaterial | ISimpleTeachingMaterial;
  is_portfolio?: boolean;
};

export const TeachingMaterialHeader: React.FC<Props> = ({
  teachingMaterial,
  is_portfolio = false,
}) => {
  return (
    <div
      className={styles.header}
      style={{
        backgroundImage: `url("${teachingMaterial.image_url}")`,
      }}
    >
      <span>{`${teachingMaterial.name} ${teachingMaterial.title}`}</span>
      {is_portfolio && <span className={styles.portfolio}>振り返りシート</span>}
    </div>
  );
};
export default TeachingMaterialHeader;
