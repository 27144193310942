import React from "react";
import clsx from "clsx";

import styles from "./RadioButton.module.scss";

type Props = {
  className?: string;
  id: string | number;
  label: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Radio = React.forwardRef<HTMLInputElement, Props>(
  ({ className, id, label, ...rest }, ref) => {
    return (
      <div className={clsx(styles.radio, className)}>
        <input type="radio" id={`radio-${id}`} ref={ref} {...rest} />
        <label htmlFor={`radio-${id}`}>{label}</label>
      </div>
    );
  },
);

export default Radio;
