import React from "react";

import styles from "./Card.module.scss";

interface ICardProps {
  label: string;
  title: string;
  icon: any;
  children: React.ReactNode;
}

export const Card: React.FC<ICardProps> = ({
  label,
  title,
  icon,
  children,
}) => {
  return (
    <div className={styles.card}>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <p className={styles.label}>
            {icon}
            {label}
          </p>
          <p className={styles.title}>{title}</p>
        </div>
        <p className={styles.text}>{children}</p>
      </div>
    </div>
  );
};

export default Card;
