import React from "react";

import { keyGenerator } from "../../../libs/Util";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/caret_down.svg";
import { ReactComponent as LikedIcon } from "../../../assets/svg/like.svg";
import { ReactComponent as ChatIcon } from "../../../assets/svg/chat.svg";
import {
  ITeachingMaterial,
  ILessonByTeachingMaterial,
  IIssueN,
} from "../../../state";
import Dropdown from "../../../atoms/Dropdown";
import TeachingMaterialHeader from "../../../molecules/TeachingMaterialHeader";

import styles from "./DataAnswersList.module.scss";

export type DataAnswersListProps = {
  teaching_materials: ITeachingMaterial[];
  listLessons: ILessonByTeachingMaterial[];
  onChangeTeachingMaterial: (id: number) => void;
};

const DataAnswersList: React.FC<DataAnswersListProps> = ({
  teaching_materials,
  onChangeTeachingMaterial,
  listLessons,
}) => {
  const [
    teachingMaterial,
    setTeachingMaterial,
  ] = React.useState<ITeachingMaterial>();

  const SortIssueByID = (objs: IIssueN[]) => {
    return objs.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
  };

  return (
    <div className={styles.wrapperListQuestion}>
      <Dropdown
        options={teaching_materials.map(material => ({
          value: material.id,
          label: `${material.name} ${material.title}`,
        }))}
        onChange={data => {
          onChangeTeachingMaterial(data);
          setTeachingMaterial(
            teaching_materials.filter(material => material.id === data)[0],
          );
        }}
      />
      <div className={styles.contents}>
        {teachingMaterial && (
          <TeachingMaterialHeader teachingMaterial={teachingMaterial} />
        )}

        {listLessons &&
          listLessons.map(lesson => (
            <div
              key={lesson.id}
              className={styles.wrapperListQuestion__questions}
            >
              <p>{`${lesson.name} ${lesson.title}`}</p>
              {SortIssueByID(lesson.issues).map(issue => (
                <div key={keyGenerator(issue)}>
                  <div
                    className={styles.titleQuestion}
                    data-bs-toggle="collapse"
                    data-bs-target={`#item${keyGenerator(issue)}`}
                    aria-expanded="false"
                    aria-controls={`#item${keyGenerator(issue)}`}
                  >
                    <ArrowIcon />
                    <span>{issue.title}</span>
                  </div>
                  <div
                    id={`item${keyGenerator(issue)}`}
                    className={`collapse ${styles.listQuestion}`}
                  >
                    {issue.answers.map(answer => (
                      <div
                        key={keyGenerator(answer)}
                        className={styles.section}
                      >
                        {answer.contents?.map(answerContent => (
                          <div
                            className={`${styles.itemContent}`}
                            key={answerContent.question_id}
                          >
                            <div className={styles.question}>
                              {answerContent.statement}
                            </div>
                            <div className={styles.answer}>
                              {answerContent.content}
                            </div>
                          </div>
                        ))}
                        <div className={styles.interactive}>
                          <LikedIcon /> <span>{answer.like_num}</span>
                          <ChatIcon /> <span>{answer.comment_num}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default DataAnswersList;
