import { useCallback, useMemo, useState } from "react";

import { IAssessment, IAssessmentBulkUpdate } from "../state";
import { NextActionKind } from "../constants/enum";

interface IAssessmentStorageState {
  data: IAssessmentBulkUpdate;
  isChange: boolean;
}

const INITIAL_DATA: IAssessmentStorageState = {
  data: {
    experience_score_1: null,
    experience_score_2: null,
    experience_score_3: null,
    experience_score_4: null,
    experience_score_5: null,
    experience_score_6: null,
    experience_score_7: null,
    reasons: [],
    next_actions: [],
  },
  isChange: false,
};

const readLocalStorage = () => {
  const p: any = {};
  const reasons: { experience_no: number; reason: string }[] = [];
  const next_actions: { kind: NextActionKind; next_action: string }[] = [];

  Array.from(Array(7).keys()).forEach(idx => {
    const s = localStorage.getItem(`assessment_score_${idx + 1}`);
    if (s) p[`experience_score_${idx + 1}`] = Number(s);

    const r = localStorage.getItem(`assessment_reason_${idx + 1}`);
    if (r) reasons.push({ experience_no: idx + 1, reason: r });
  });
  if (reasons.length > 0) {
    p.reasons = reasons;
  }

  Array.from(Array(3).keys()).forEach(idx => {
    const n = localStorage.getItem(`assessment_next_action_kind_${idx + 1}`);
    if (n) next_actions.push({ kind: idx + 1, next_action: n });
  });
  if (next_actions.length > 0) {
    p.next_actions = next_actions;
  }
  return p;
};

const deleteLocalStorage = () => {
  Array.from(Array(7).keys()).forEach(idx => {
    localStorage.removeItem(`assessment_score_${idx + 1}`);
    localStorage.removeItem(`assessment_reason_${idx + 1}`);
  });
  Array.from(Array(3).keys()).forEach(idx => {
    localStorage.removeItem(`assessment_next_action_kind_${idx + 1}`);
  });
};

const checkChange = (p: any, c: any): boolean => {
  if (c.reasons) return true;
  if (c.next_actions) return true;
  return Array.from(Array(7).keys()).some(idx => {
    const x = p[`experience_score_${idx + 1}`];
    const y = c[`experience_score_${idx + 1}`];
    return y > 0 && x !== y;
  });
};

const useAssessmentStorage = (): [
  {
    data: IAssessmentBulkUpdate;
    isChange: boolean;
    scores: number[];
    isAllScore: boolean;
  },
  {
    loadAssessment: (assessment: IAssessment) => void;
    setScore: (experience_no: number, score: number) => void;
    setReason: (experience_no: number, reason: string) => void;
    setNextAction: (kind: NextActionKind, next_action: string) => void;
    clearAfterSubmit: () => void;
  },
] => {
  const [state, setState] = useState<IAssessmentStorageState>(INITIAL_DATA);

  const scores = useMemo(
    () => [
      state.data.experience_score_1 ?? 0,
      state.data.experience_score_2 ?? 0,
      state.data.experience_score_3 ?? 0,
      state.data.experience_score_4 ?? 0,
      state.data.experience_score_5 ?? 0,
      state.data.experience_score_6 ?? 0,
      state.data.experience_score_7 ?? 0,
    ],
    [state.data],
  );

  const isAllScore = useMemo(() => scores.every(s => s > 0), [scores]);

  const update = useCallback((fragment: any, isChange: boolean) => {
    setState(prevState => {
      return {
        data: {
          ...prevState.data,
          ...fragment,
        },
        isChange,
      };
    });
  }, []);

  const loadAssessment = (assessment: IAssessment) => {
    const p: any = {};
    assessment.assessment_experiences.forEach(e => {
      p[`experience_score_${e.experience_no}`] = e.score;
    });
    const cache = readLocalStorage();
    const isChange = checkChange(p, cache);
    update({ ...p, ...cache }, isChange);
  };

  const setScore = (experience_no: number, score: number) => {
    localStorage.setItem(`assessment_score_${experience_no}`, "" + score);
    const p = { [`experience_score_${experience_no}`]: score };
    update(p, true);
  };

  const setReason = (experience_no: number, reason: string) => {
    localStorage.setItem(`assessment_reason_${experience_no}`, reason);
    const p = {
      reasons: [
        ...state.data.reasons.filter(r => r.experience_no !== experience_no),
        { experience_no, reason },
      ],
    };
    update(p, true);
  };

  const setNextAction = (kind: NextActionKind, next_action: string) => {
    localStorage.setItem(`assessment_next_action_kind_${kind}`, next_action);
    const p = {
      next_actions: [
        ...state.data.next_actions.filter(n => n.kind !== kind),
        { next_action, kind },
      ],
    };
    update(p, true);
  };

  const clearAfterSubmit = () => {
    deleteLocalStorage();
    const p = {
      reasons: [],
      next_actions: [],
    };
    update(p, false);
  };

  return [
    {
      data: state.data,
      isChange: state.isChange,
      scores,
      isAllScore,
    },
    {
      loadAssessment,
      setScore,
      setReason,
      setNextAction,
      clearAfterSubmit,
    },
  ];
};

export default useAssessmentStorage;
