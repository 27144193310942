import React from "react";
import clsx from "clsx";

import Modal from "../../../atoms/ActiveLearning/Modal";
import { ReactComponent as IconClose } from "../../../assets/images/ActiveLearning/icon_close.svg";

import styles from "./CheckModal.module.scss";

interface ICheckModalProps {
  checkCategories: { id: number; name: string }[];
  name: string;
  checkedAnswerIds: number[];
  changeCategory: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClose: () => void;
}
const CheckModal: React.FC<ICheckModalProps> = ({
  checkCategories,
  name,
  checkedAnswerIds,
  handleClose,
  changeCategory,
}) => {
  const [checked, setChecked] = React.useState<number[]>(checkedAnswerIds);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value);
      if (checked.includes(value)) {
        setChecked(checked.filter(category => category !== value));
        changeCategory(e);
        return;
      }
      if (checked.length >= 3) return;
      setChecked([...checked, value]);
      changeCategory(e);
    },
    [checked, changeCategory],
  );

  return (
    <Modal
      className={styles.modal}
      isTransparentBackground
      handleClose={handleClose}
    >
      <IconClose className={styles.close} onClick={handleClose} />
      <div className={styles.wrapper}>
        <p className={styles.heading}>3つまで選択可能</p>
        <ul className={styles.categoryList}>
          {checkCategories.map(category => (
            <li key={category.id} className={styles.category}>
              <label
                id={String(category.id)}
                className={clsx(
                  styles.tag,
                  checked.includes(category.id) && styles.isChecked,
                )}
              >
                {category.name}
                <input
                  id={String(category.id)}
                  name={name}
                  type="checkbox"
                  value={category.id}
                  checked={checked.includes(category.id)}
                  onChange={e => handleChange(e)}
                />
              </label>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
};

export default CheckModal;
