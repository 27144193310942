import React from "react";

import LogoImg from "../../../assets/images/logo.png";
import { ReactComponent as IconHidePass } from "../../../assets/svg/eye.svg";
import { ReactComponent as IconShowPass } from "../../../assets/svg/invisible.svg";
import { API_URL } from "../../../studentStore";

import styles from "./LoginForm.module.scss";

export interface LoginFormProps {
  error: string;
  calling: boolean;
  login: (identity: string, password: string) => void;
}

const validates: { [key: string]: (value: any) => string } = {};

validates["identity"] = val => {
  return val.identity ? "" : "IDを入力してください";
};

validates["password"] = val => {
  return val.password.length < 6 || val.password.length > 32
    ? "パスワードは6文字以上32文字以内で入力してください。"
    : "";
};

export const LoginForm = (props: LoginFormProps) => {
  const [isShowPassword, setIsShowPassword] = React.useState(false);
  const [state, setState] = React.useState({
    identity: "",
    password: "",
    isSubmitted: false,
    errorMessages: {} as { [key: string]: string },
  });

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const errorMessages = {} as { [key: string]: string };
      Object.keys(validates).forEach(key => {
        const ret = validates[key](state);
        if (ret) {
          errorMessages[key] = ret;
        }
      });
      if (Object.keys(errorMessages).length > 0) {
        setState(s => ({ ...s, errorMessages }));
        return;
      }
      props.login(state.identity, state.password);
    },
    [state, props],
  );

  const onChange = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      e.preventDefault();
      const val = e.currentTarget.value;
      const key = e.currentTarget.name;
      const args = { [key]: val };
      const ret = validates[key](args);
      setState(prevState => ({
        ...prevState,
        [key]: val,
        errorMessages: { ...prevState.errorMessages, [key]: ret },
      }));
    },
    [],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>
          <div>
            <img src={LogoImg} alt="" />
          </div>
        </h1>
      </div>
      <main className={styles.main}>
        <h2>LOGIN</h2>
        <p className={styles.subTitle}>生徒</p>
        <div>
          <form onSubmit={onSubmit}>
            {props.error && (
              <div className={styles.errorMessage}>{props.error}</div>
            )}
            <div className="mb-3">
              <label htmlFor="email">ID</label>
              <div className={styles.wrapInput}>
                <input
                  type="text"
                  name="identity"
                  className={
                    state.errorMessages["identity"]
                      ? "is-invalid"
                      : state.identity
                      ? "is-valid"
                      : ""
                  }
                  id="identity"
                  value={state.identity}
                  onChange={onChange}
                />
                <div className="invalid-feedback">
                  {state.errorMessages["identity"]}
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="password">パスワード</label>
              <div className={styles.wrapInput}>
                <div className={styles.wrapInput__inputPass}>
                  <input
                    type={isShowPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className={
                      state.errorMessages["password"]
                        ? "is-invalid"
                        : state.password
                        ? "is-valid"
                        : ""
                    }
                    value={state.password}
                    onChange={onChange}
                  />
                  <span
                    onClick={() => {
                      setIsShowPassword(!isShowPassword);
                    }}
                  >
                    {isShowPassword ? <IconShowPass /> : <IconHidePass />}
                  </span>
                </div>
                <div className="invalid-feedback">
                  {state.errorMessages["password"]}
                </div>
              </div>
            </div>
            <button
              className={styles.btnSubmit}
              onClick={onSubmit}
              disabled={
                Object.keys(state.errorMessages).filter(
                  k => state.errorMessages[k],
                ).length > 0 || props.calling
              }
            >
              ログイン
            </button>
            <div className={styles.btnArea}>
              <button
                className={styles.googleBtn}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.location.href = `${API_URL?.replace(
                    "/api/v1/student",
                    "",
                  )}/auth/google_oauth2?kind=student`;
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                  <path
                    fill="#FFC107"
                    d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"
                  />
                  <path
                    fill="#FF3D00"
                    d="m6.306 14.691 6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691z"
                  />
                  <path
                    fill="#4CAF50"
                    d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z"
                  />
                  <path
                    fill="#1976D2"
                    d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002 6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"
                  />
                </svg>
                <p className={styles.btnTitle}>Google</p>
              </button>
              <button
                className={styles.microsoftBtn}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.location.href = `${API_URL?.replace(
                    "/api/v1/student",
                    "",
                  )}/auth/azure_activedirectory_v2?kind=student&prompt=select_account`;
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                  <path fill="#ff5722" d="M22 22H6V6h16z" />
                  <path fill="#4caf50" d="M42 22H26V6h16z" />
                  <path fill="#ffc107" d="M42 42H26V26h16z" />
                  <path fill="#03a9f4" d="M22 42H6V26h16z" />
                </svg>
                <p className={styles.btnTitle}>Microsoft</p>
              </button>
            </div>
          </form>
        </div>
        <div className={styles.usageGuideline}>
          <h6>ログインできない場合</h6>
          <ol>
            <li> 先生が発行した「ID／仮パスワード」を入力してログイン。</li>
            <li> パスワードを登録。</li>
            <li>「ID／新しく設定したパスワード」でログイン。</li>
          </ol>
        </div>
        <div className={styles.usageNotice}>
          <h6>ログイン時は以下の点に注意してください</h6>
          <ul>
            <li>・全角で入力していませんか？</li>
            <li>・入力する内容を間違えていませんか？</li>
          </ul>
        </div>
      </main>
    </div>
  );
};

export default LoginForm;
