import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useContext,
  useEffect,
  useState,
} from "react";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { IAnswer } from "../../../../../constants/active_learning";
import Card from "../../../../../atoms/ActiveLearning/Card";
import TextInput from "../../../../../atoms/ActiveLearning/TextInput";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import styles from "../../ActiveLearningIssue.module.scss";
import { ReactComponent as IconLive } from "../../../../../assets/images/ActiveLearning/icon_live.svg";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface ICurrentAnswer extends IAnswer {
  value: string;
}

export const Issue2 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 2;
  const question1 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);
  const question2 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 2);
  }, [props.issue?.questions]);

  const [answer1, setAnswer1] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question1?.order_num || NaN,
    question_id: question1?.id || NaN,
    value: "",
  });
  const [answer2, setAnswer2] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question2?.order_num || NaN,
    question_id: question2?.id || NaN,
    value: "",
  });

  const setAnswerWraper = useCallback(
    (questionNo: number, setter: Dispatch<React.SetStateAction<any>>) => {
      const targetAnswer = state.active_learning_answers.find(
        answer =>
          answer.issue_no === currentIssueNo &&
          answer.question_no === questionNo,
      );
      const answer: ICurrentAnswer = {
        id: targetAnswer?.id || null,
        issue_no: currentIssueNo,
        question_no: questionNo,
        question_id: targetAnswer?.question_id || 0,
        value: targetAnswer?.contents?.value || "",
      };
      setter({ ...answer });
    },
    [state.active_learning_answers],
  );

  useEffect(() => {
    setAnswerWraper(1, setAnswer1);
    setAnswerWraper(2, setAnswer2);
  }, [setAnswerWraper]);

  useEffect(() => {
    const answers = props.answers.filter(
      answer =>
        !(
          answer.issue_no === currentIssueNo &&
          [question1?.order_num, question2?.order_num].includes(
            answer.question_no,
          )
        ),
    );
    props.setAnswers([
      ...answers,
      ...[
        {
          id: answer1.id,
          issue_no: currentIssueNo,
          question_no: 1,
          question_id: question1?.id || NaN,
          contents: {
            value: answer1.value,
          },
        },
        {
          id: answer2.id,
          issue_no: currentIssueNo,
          question_no: 2,
          question_id: question2?.id || NaN,
          contents: {
            value: answer2.value,
          },
        },
      ],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer1, answer2, question1, question2]);

  const handleChangeAnswer = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    answer: ICurrentAnswer,
    setter: Dispatch<React.SetStateAction<any>>,
  ) => {
    const { value } = e.target;
    setter({
      id: answer.id,
      question_id: question1?.id || NaN,
      question_no: question1?.order_num || NaN,
      issue_no: currentIssueNo,
      value,
    });
  };

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          様々な視点から「問い」を考えたことで、取り組みたいことの幅が広がった。
          <br />
          WORK01で考えた内容を参考にして、自分が取り組む「問い」を決めよう。
          <br />
          <br />
          例：「地元の若者離れを解消するには？」→「〇〇県〇〇市の若者が大学を卒業したときに地元を離れないようにするためには？」
        </p>
      </div>
      {question1 && (
        <TextInput
          value={answer1.value}
          handleChange={e => handleChangeAnswer(e, answer1, setAnswer1)}
        />
      )}
      {question2 && (
        <div className={styles.themEntry}>
          <p className={styles.title}>{question2.title}</p>
          <p className={styles.read}>
            Lesson01〜03で、自分だけのワクワクできる「問い」を考えて具体的にし、取り組む「問い」を立てた。
            <br />
            どんな事を考えて「問い」を立てたのか、なぜそのように具体的にしたいと思ったのかを思い出しながら、「問い」を立てるまでに気づいたことや考えたことを入力しよう。
            <br />
            <br />
            例：自分は普段から、「地元がもっと賑わっていたら楽しいのにな」と思っていたため、地元の活性化に関する「問い」を立てた。中でも地元の人がいなくなってしまうのは大学卒業のタイミングだと思ったので──
          </p>
          <div className={styles.textAreaWrapper}>
            <TextArea
              value={answer2.value}
              handleChange={e => handleChangeAnswer(e, answer2, setAnswer2)}
            />
          </div>
        </div>
      )}
      <Card icon={<IconLive />} label={"TRY"} title={"グループワーク"}>
        ①同じテーマ同士でグループをつくろう。
        <br />
        ②自分が立てた「問い」とその理由を順番に発表し合おう。
        <br />
        ③良い「問い」に仕上げるポイントを参考に話し合い、周りの意見を参考にして「問い」を調整しよう。
        <br />
        <br />
        <span className={styles.bold}>良い「問い」に仕上げるヒント</span>
        <br />
        <span className={styles.bold}>1.自分ならではの「問い」。</span>
        <br />
        自分の考え/感情/経験などから派生した「問い」になっているかを確認しよう。
        <br />
        例：「自分は〇〇と考えたからこの「問い」にしたよ。」
        <br />
        <span className={styles.bold}>2.具体性のある「問い」。</span>
        <br />
        「問い」が抽象的だとこれからの取り組みで迷いが生まれやすくなる。できる限り具体的な「問い」にしよう。
        <br />
        例：「健康になるためには？」→「子供の頃から健康意識を上げて、虫歯を減らす取り組みとは？」
        <br />
        <span className={styles.bold}>
          3.答えが簡単に見つからない「問い」。
        </span>
        <br />
        調べた結果多くの人が同じ答えに行き着くものではなく、自分ならではの答えが出せる「問い」を設定しよう。
        <br />
        例：「教育は必要か？」→「金融教育は何歳から実施するのが一番効果的だろう？」
        <br />
      </Card>
    </div>
  );
};
export default Issue2;
