import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { parseQueryParams } from "../../../libs/Util";
import { listActiveLearningPath } from "../../../studentStore";
import { activeLearningTextbookPath } from "../../../teacherStore";
import { CONFIRM_PAGE_LEAVE_TEXT } from "../../../constants/active_learning";
import Button from "../../../atoms/ActiveLearning/Button";
import ActiveLearningModalConfirm from "../../../molecules/ActiveLearningModalConfirm";
import { ReactComponent as IconCheck } from "../../../assets/images/ActiveLearning/icon_check.svg";

import styles from "./EndLesson.module.scss";

type EndLessonProps = {
  text?: string;
  saveAnswers?: () => void;
  isTeacher?: boolean;
};

export const EndLesson: React.FC<EndLessonProps> = ({
  text,
  saveAnswers,
  isTeacher,
}) => {
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const history = useHistory();
  const material_code = parseQueryParams()["code"];
  const class_ids = parseQueryParams()["classList"];
  const listPath = isTeacher
    ? activeLearningTextbookPath
    : listActiveLearningPath;

  useEffect(() => {
    const blockOfHistoryPop = history.block((_location, action) => {
      if (action === "POP" && !isTeacher) {
        setIsShowModalConfirm(true);
        return false;
      }
    });

    return () => blockOfHistoryPop();
  }, [history, isTeacher]);

  return (
    <>
      <div className={styles.endLesson}>
        <div className={styles.wrapper}>
          <IconCheck className={styles.check} />
          <p className={styles.title}>
            本日のLessonは以上です。
            <br />
            お疲れさまでした！
          </p>
          <p className={styles.text}>{text}</p>
          <div className={styles.buttonWrapper}>
            <Button
              handleClick={() => {
                isTeacher
                  ? history.push(
                      `${listPath}?classList=${class_ids}&code=${material_code}`,
                    )
                  : setIsShowModalConfirm(true);
              }}
            >
              一覧に戻る
            </Button>
          </div>
        </div>
      </div>
      {isShowModalConfirm && (
        <ActiveLearningModalConfirm
          onClose={() => setIsShowModalConfirm(false)}
          title={CONFIRM_PAGE_LEAVE_TEXT}
          buttons={[
            {
              title: "いいえ",
              action: () => {
                setIsShowModalConfirm(false);
              },
              type: "None",
            },
            {
              title: "はい",
              action: () => {
                saveAnswers && saveAnswers();
                setIsShowModalConfirm(false);
                history.push(`${listPath}?code=${material_code}`);
              },
              type: "Success",
            },
          ]}
        />
      )}
    </>
  );
};

export default EndLesson;
