import React from "react";

import { IActiveLearningMaterial } from "../../../state";
import { ReactComponent as IconArrowRight } from "../../../assets/images/ActiveLearning/icon_arrow_right_black.svg";

import styles from "./ActiveLearningMaterial.module.scss";

export interface ActiveLearningMaterialProps {
  active_learning_material: IActiveLearningMaterial;
  directTo: (active_learning_material_code: string, lesson_no: number) => void;
}

const ActiveLearningMaterial = ({
  active_learning_material,
  directTo,
}: ActiveLearningMaterialProps) => {
  return (
    <div className={styles.activeLearningMaterial}>
      {active_learning_material.lessons
        ?.filter(l => l.no !== 0)
        ?.map(l => (
          <div
            className={styles.lesson}
            key={l.id}
            onClick={() => directTo(active_learning_material.code, l.no)}
          >
            <div className={styles.label}>
              <span className={styles.text}>LESSON</span>
              <span className={styles.no}>{l.no < 10 ? `0${l.no}` : l.no}</span>
            </div>
            <div className={styles.title}>{l.title}</div>
            <IconArrowRight className={styles.iconArrow} />
          </div>
        ))}
    </div>
  );
};

export default ActiveLearningMaterial;
