import React from "react";
import clsx from "clsx";

import { ReactComponent as IconCaret } from "../../assets/svg/caret_up.svg";
import { ReactComponent as IconCheck } from "../../assets/svg/check.svg";
import { formatDate } from "../../libs/Util";

import styles from "./DropdownTerm.module.scss";

export interface ITerm {
  term_no: number;
  name: string;
  date?: string;
}

export type Props = {
  previousTerms: ITerm[];
  className?: string;
  onChange: (values: number[]) => void;
};

const DropdownTerm: React.FC<Props> = ({
  previousTerms,
  className,
  onChange,
}) => {
  const [showItems, setShowItems] = React.useState(false);
  const [termsSelected, setTermsSelected] = React.useState<number[]>([]);

  const handleSelectTerm = (id: number) => {
    let newArray: number[] = [];

    if (termsSelected.includes(id)) {
      newArray = termsSelected.filter(item => item !== id);
    } else if (termsSelected.length < 3) {
      termsSelected.push(id);
      newArray = [...termsSelected].sort();
    } else {
      newArray = termsSelected;
    }

    setTermsSelected(newArray);
  };

  React.useEffect(() => {
    onChange(termsSelected);
  }, [onChange, termsSelected]);

  React.useEffect(() => {
    const newArray = previousTerms
      .map(t => t.term_no)
      .slice(0, 3)
      .sort();
    setTermsSelected(newArray);
  }, [previousTerms]);

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div
        className={clsx(
          styles.currentTerm,
          styles.hasItem,
          "d-flex align-items-center justify-content-between w-100",
        )}
        onClick={() => setShowItems(!showItems)}
      >
        以前の記録
        <IconCaret />
      </div>
      {showItems && (
        <div className={styles.dropdown}>
          {previousTerms.map(term => (
            <p
              key={term.term_no}
              onClick={() => handleSelectTerm(term.term_no)}
            >
              {term.name}
              {term.date && <span>〜{formatDate(term.date, ".")}</span>}
              {termsSelected.includes(term.term_no) && <IconCheck />}
            </p>
          ))}
        </div>
      )}
      {showItems && (
        <>
          <div className={styles.dash_1} />
          <div className={styles.dash_2} />
        </>
      )}
    </div>
  );
};

export default DropdownTerm;
