import React, { useRef, useEffect, useMemo } from "react";
import ChartComponent, { Radar, ChartComponentProps } from "react-chartjs-2";
import clsx from "clsx";

import { ISurveyAnswers } from "../../state";

import styles from "./RadarChart.module.scss";

const options = {
  scale: {
    pointLabels: {
      display: true,
      fontColor: "black",
      fontSize: 14,
      callback: (label: any) => {
        return label.length > 10 ? label.substr(0, 10) + "..." : label;
      },
    },
    ticks: {
      min: 0,
      max: 5,
    },
  },
  legend: {
    display: false,
  },
  legendCallback: (chart: { data: { datasets: any[] } }) => {
    const ul = document.createElement("ul");
    const { datasets } = chart.data;
    datasets.forEach(dataset => {
      const { backgroundColor, label, borderColor } = dataset;
      ul.innerHTML += `
                <li>
                  <div>
                    <span style="background-color: ${backgroundColor}; border-top: 2px solid ${borderColor}; border-bottom: 2px solid ${borderColor}; z-index: -1;"></span>
                    ${label}
                  </div>
                 </li>
              `;
    });
    return ul.outerHTML;
  },
};
const DEVIATION_X = 12;
const DEVIATION_Y = 7;
const ECCENTRICITY = 15;

export type Props = {
  survey_answers: ISurveyAnswers[];
  survey_answers_per_teaching_material: ISurveyAnswers[];
  className?: string;
  isTeacher?: boolean;
};

const RadarChart: React.FC<Props> = ({
  survey_answers,
  survey_answers_per_teaching_material,
  className,
  isTeacher = false,
}) => {
  const chartRef = useRef<ChartComponent<ChartComponentProps>>(null);
  const wrapperPointLabelsRef = useRef<HTMLDivElement>(null);
  const [widthWindow, setWidthWindow] = React.useState(-1);

  const handleClick = (e: React.MouseEvent, index: number) => {
    const ctx = chartRef.current!.chartInstance;
    const meta: any[] = [];
    ctx.data.datasets.forEach((_: any, index: any) => {
      meta.push(ctx.getDatasetMeta(index));
    });
    const alreadyHidden =
      meta[index].hidden === null ? false : meta[index].hidden;

    if (!alreadyHidden) {
      meta[index].hidden =
        meta[index].hidden === null ? !meta[index].hidden : null;
    } else {
      meta[index].hidden = null;
    }

    // Toggle strikethrough class
    if (e.currentTarget.classList.contains("disable-legend")) {
      e.currentTarget.classList.remove("disable-legend");
    } else {
      e.currentTarget.classList.add("disable-legend");
    }
    ctx.update();
  };

  useEffect(() => {
    document.getElementById(
      "legend",
    )!.innerHTML = chartRef.current?.chartInstance.generateLegend();

    window.addEventListener("resize", () => {
      setWidthWindow(window.innerWidth);
    });

    document.querySelectorAll("#legend li").forEach((item, index) => {
      item.firstElementChild!.addEventListener("click", function (e) {
        // @ts-ignore
        handleClick(e, index);
      });
    });

    return () => {
      document.querySelectorAll("#legend li").forEach((item, index) => {
        item.firstElementChild!.removeEventListener("click", function (e) {
          // @ts-ignore
          handleClick(e, index);
        });
      });
      window.removeEventListener("resize", () => {
        setWidthWindow(window.innerWidth);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = useMemo(() => {
    let data = {
      labels: survey_answers.map(survey => survey.name),
      datasets: [
        {
          label: "授業前アンケート",
          backgroundColor: "rgba(145,145,145,0.5)",
          borderWidth: 1,
          borderColor: "rgba(145,145,145,1.0)",
          pointBorderColor: "#fff",
          pointBackgroundColor: "rgba(145,145,145,1.0)",
          data: survey_answers.map(survey => survey.score),
        },
        {
          label: "授業後アンケート",
          backgroundColor: "rgba(51, 69, 230, 0.3)",
          borderWidth: 1,
          borderColor: "#3345e6",
          pointBorderColor: "#fff",
          pointBackgroundColor: "#3345e6",
          data: survey_answers_per_teaching_material.map(
            survey => survey.score,
          ),
        },
      ],
    };

    if (isTeacher) {
      data = {
        ...data,
        datasets: [
          ...data.datasets,
          {
            label: "授業前クラス平均",
            backgroundColor: "#ffffff4a",
            borderWidth: 1,
            borderColor: "#e4c828",
            pointBorderColor: "#e4c828",
            pointBackgroundColor: "#e4c828",
            data: survey_answers.map(
              survey => survey.avg_class_answer_score ?? 0,
            ),
          },
          {
            label: "授業後クラス平均",
            backgroundColor: "#ffffff4a",
            borderWidth: 1,
            borderColor: "#46c2c2",
            pointBorderColor: "#46c2c2",
            pointBackgroundColor: "#46c2c2",
            data: survey_answers_per_teaching_material.map(
              survey => survey.avg_class_answer_score ?? 0,
            ),
          },
        ],
      };
    }
    return data;
  }, [survey_answers, survey_answers_per_teaching_material, isTeacher]);

  useEffect(() => {
    const pointO = {
      x: chartRef.current!.chartInstance.scale.xCenter - DEVIATION_X,
      y: chartRef.current!.chartInstance.scale.yCenter - DEVIATION_Y,
    };
    const listLabelSize = chartRef.current!.chartInstance.scale
      ._pointLabelSizes;

    while (wrapperPointLabelsRef.current?.firstChild)
      wrapperPointLabelsRef.current.removeChild(
        wrapperPointLabelsRef.current.firstChild,
      );

    for (let i = 0; i < data.labels.length; i++) {
      const pointPosition = chartRef.current!.chartInstance.scale.getPointPosition(
        i,
        Math.ceil(chartRef.current!.chartInstance.scale.yCenter - ECCENTRICITY),
      );
      const minX = Math.ceil(
        pointPosition.x < pointO.x
          ? pointPosition.x - listLabelSize[i].w
          : pointPosition.x,
      );

      const minY = Math.ceil(
        pointPosition.y < pointO.y
          ? pointPosition.y - listLabelSize[i].h
          : pointPosition.y,
      );

      const pointLabel = document.createElement("div");
      pointLabel.style.cssText = `position: absolute;
                                  top:${Math.ceil(
                                    pointPosition.y > pointO.y
                                      ? minY - DEVIATION_Y
                                      : minY,
                                  )}px;
                                  left:${Math.ceil(
                                    pointPosition.x === pointO.x + DEVIATION_X
                                      ? pointPosition.x - listLabelSize[i].w / 2
                                      : pointPosition.x < pointO.x
                                      ? minX
                                      : minX - DEVIATION_X,
                                  )}px;
                                  width:${listLabelSize[i].w}px;
                                  height: ${listLabelSize[i].h}px;
                                  background-color: transparent;`;

      pointLabel.className = "pointLabel";
      pointLabel.setAttribute("data-bs-tooltip", data.labels[i]);
      wrapperPointLabelsRef.current!.appendChild(pointLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef.current, widthWindow, data]);

  return (
    <div className={clsx(styles.wrapperChart, className)}>
      <div ref={wrapperPointLabelsRef} />
      <Radar data={data} options={options} ref={chartRef} />
      <div id="legend" className={styles.legend} />
    </div>
  );
};

export default React.memo(RadarChart);
