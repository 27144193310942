import React from "react";
import { useMemo, useCallback, useEffect } from "react";
import clsx from "clsx";

import {
  IActiveLearningAnswers,
  IActiveLearningIssue,
} from "../../../../state";
import { Context, updateActiveLearningAnswers } from "../../../../studentStore";
import { getActiveLearningAnswers } from "../../../../libs/ActiveLearning";
import Header from "../../../../organisms/ActiveLearning/Header";
import TreeCard from "../../../../atoms/ActiveLearning/TreeCard";
import EndLesson from "../../../../organisms/ActiveLearning/EndLesson";
import Issue1 from "../../../../organisms/student/ActiveLearningLessons/Lesson5/Issue1";
import Issue2 from "../../../../organisms/student/ActiveLearningLessons/Lesson5/issue2";
import Issue3 from "../../../../organisms/student/ActiveLearningLessons/Lesson5/Issue3";
import styles from "../ActiveLearningLesson.module.scss";
import useWaitApiCall from "../../../../hooks/useWaitApiCall";
import SaveAnswerButton from "../../../../atoms/ActiveLearning/SaveAnswerButton";

export const Lesson5 = () => {
  const { state, dispatch } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const [answers, setAnswers] = React.useState<IActiveLearningAnswers[]>([]);
  const [commonAnswers, setCommonAnswers] = React.useState<
    IActiveLearningAnswers[]
  >([]);

  useEffect(() => {
    setAnswers([...state.active_learning_answers]);
  }, [state.active_learning_answers]);

  const currentLessonNo = useMemo(() => {
    return state.active_learning_data_by_lesson.no;
  }, [state.active_learning_data_by_lesson]);

  const materialCode = useMemo(() => {
    return state.active_learning_data_by_lesson.material_code;
  }, [state.active_learning_data_by_lesson]);

  const currentActiveLearning = useMemo(() => {
    return state.active_learning_data_by_lesson;
  }, [state.active_learning_data_by_lesson]);

  const findIssue = useCallback(
    (no: number): IActiveLearningIssue | undefined =>
      currentActiveLearning.issues?.find(issue => issue.no === no),
    [currentActiveLearning.issues],
  );

  const saveAnswers = () => {
    const params = [...answers];
    if (calling) return;
    dispatch(
      peep(
        updateActiveLearningAnswers(
          materialCode,
          currentLessonNo,
          JSON.stringify(params),
        ),
      ),
    );
  };

  const lastLessonNo = useMemo(() => {
    return materialCode === "ECS001" ? 4 : 5;
  }, [materialCode]);

  const pastAnswerL3I2Q1 = useMemo(() => {
    const allAnswers = state.active_learning_answers_by_material;
    return getActiveLearningAnswers(allAnswers, 3, 2, 1)
      .map(result => result.value)
      .join("");
  }, [state.active_learning_answers_by_material]);

  const lastAnswers = useMemo(() => {
    const allAnswers = state.active_learning_answers_by_material;
    return [
      {
        label: "問題",
        data: getActiveLearningAnswers(
          allAnswers,
          lastLessonNo,
          1,
        ).map(result =>
          result.subject ? result.subject : "回答データがありません。",
        ),
      },
      {
        label: "原因",
        data: getActiveLearningAnswers(
          allAnswers,
          lastLessonNo,
          1,
        ).map(result =>
          result.value ? result.value : "回答データがありません。",
        ),
      },
      {
        label: "理想状況",
        data: getActiveLearningAnswers(allAnswers, lastLessonNo, 2).map(
          result => result.value,
        ),
      },
      {
        label: "課題",
        data: getActiveLearningAnswers(allAnswers, lastLessonNo, 3, 1).map(
          result => result.value,
        ),
      },
    ];
  }, [state.active_learning_answers_by_material, lastLessonNo]);

  return (
    <>
      <div className={styles.wrapper} id="accordion">
        <Header
          no={currentActiveLearning.no}
          label={"実現までの道筋を描く"}
          title={currentActiveLearning.title}
          read={
            "意見交換を通じて、設定した「課題」に対する解決のアイデアをつくり上げよう。"
          }
        />
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles.review}>
              <p className={styles.title}>前回の振り返り</p>
              <div className={styles.tree}>
                <TreeCard
                  title={"取り組む問い"}
                  text={pastAnswerL3I2Q1 || "回答データがありません。"}
                />
                <ul className={styles.branchList}>
                  {lastAnswers.map(lastAnswer => (
                    <li key={lastAnswer.label} className={styles.branch}>
                      <div className={styles.labelWrapper}>
                        <span className={styles.label}>{lastAnswer.label}</span>
                      </div>
                      <ul className={styles.answerList}>
                        {lastAnswer.data.length > 0 ? (
                          lastAnswer.data.map((answer, index) => (
                            <li
                              key={`${answer}${index}`}
                              className={clsx(
                                styles.answer,
                                lastAnswer.data.length < 2 && styles.single,
                              )}
                            >
                              {answer}
                            </li>
                          ))
                        ) : (
                          <li className={clsx(styles.answer, styles.single)}>
                            回答データがありません。
                          </li>
                        )}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <Issue1
              issue={findIssue(1)}
              answers={answers}
              setAnswers={setAnswers}
              setCommonAnswers={setCommonAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <Issue2
              issue={findIssue(2)}
              answers={answers}
              setAnswers={setAnswers}
              commonAnswers={commonAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <Issue3
              issue={findIssue(3)}
              answers={answers}
              setAnswers={setAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <EndLesson
              text={
                "次のLessonでは、今まで考えた内容を発表し合う。次回までに、どのように話したら相手に内容が伝わりやすいかのイメージを膨らませておこう。"
              }
              saveAnswers={saveAnswers}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Lesson5;
