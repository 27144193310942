import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
  useContext,
  useEffect,
} from "react";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
  IActiveLearningQuestion,
} from "../../../../../state";
import { getActiveLearningAnswers } from "../../../../../libs/ActiveLearning";
import { IAnswer } from "../../../../../constants/active_learning";
import Button from "../../../../../atoms/ActiveLearning/Button";
import TextInput from "../../../../../atoms/ActiveLearning/TextInput";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import Selector from "../../../../../atoms/ActiveLearning/Selector";
import styles from "../../ActiveLearningIssue.module.scss";
import { ReactComponent as IconAdd } from "../../../../../assets/images/ActiveLearning/icon_add.svg";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface IAnswerQ1 extends IAnswer<string> {
  value: string;
  uid: string;
}

export interface IAnswerQ2 extends IAnswer {
  parentUid: string;
}

export interface IAnswerQ3 extends IAnswer {
  value: string;
}

export const Issue3 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 3;
  const question = useMemo(() => {
    const q = props.issue?.questions.find(q => q.order_num === 1);
    if (q) return q;
  }, [props.issue]);
  const question2 = useMemo(() => {
    const q = props.issue?.questions.find(q => q.order_num === 2);
    if (q) return q;
  }, [props.issue]);
  const question3 = useMemo(() => {
    const q = props.issue?.questions.find(q => q.order_num === 3);
    if (q) return q;
  }, [props.issue]);

  const [selectedUid, setSelectedUid] = useState("");
  const [answersToQ1, setAnswersToQ1] = useState<IAnswerQ1[]>([]);
  const [answerToQ2, setAnswerToQ2] = useState<IAnswerQ2>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question2?.order_num || NaN,
    question_id: question2?.id || NaN,
    parentUid: "",
  });
  const [answerToQ3, setAnswerToQ3] = useState<IAnswerQ3>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question3?.order_num || NaN,
    question_id: question3?.id || NaN,
    value: "",
  });

  useEffect(() => {
    const currentAnswersQ1: IActiveLearningAnswers[] = answersToQ1
      .filter((answer: IAnswerQ1) => answer.value.length > 0)
      .map((answer: IAnswerQ1) => {
        return {
          id: answer.id?.includes("#") ? null : Number(answer.id),
          issue_no: answer.issue_no,
          question_no: question?.order_num || NaN,
          question_id: question?.id || NaN,
          contents: {
            value: answer.value,
            uid: answer.uid,
          },
        };
      });

    const currentAnswerQ2: IActiveLearningAnswers = {
      id: answerToQ2?.id || null,
      issue_no: answerToQ2.issue_no,
      question_no: question2?.order_num || NaN,
      question_id: question2?.id || NaN,
      contents: {
        parentUid: answerToQ2.parentUid,
      },
    };
    setSelectedUid(answerToQ2.parentUid);

    const currentAnswerQ3: IActiveLearningAnswers = {
      id: answerToQ3?.id || null,
      issue_no: answerToQ3.issue_no,
      question_no: question3?.order_num || NaN,
      question_id: question3?.id || NaN,
      contents: {
        value: answerToQ3.value,
      },
    };

    // この設問以外の回答情報
    const otherAnswers = props.answers.filter(
      answer => !(answer.issue_no === currentIssueNo),
    );
    props.setAnswers([
      ...otherAnswers,
      ...currentAnswersQ1,
      currentAnswerQ2,
      currentAnswerQ3,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersToQ1, answerToQ2, answerToQ3]);

  useEffect(() => {
    const answersQ1 = state.active_learning_answers.filter(
      answer => answer.issue_no === currentIssueNo && answer.question_no === 1,
    );

    const initAnswersQ1: IAnswerQ1[] =
      answersQ1.length > 0
        ? answersQ1.map(answer => ({
            id: String(answer.id),
            issue_no: answer.issue_no,
            question_no: answer.question_no,
            question_id: answer.question_id,
            value: answer.contents.value,
            uid: answer.contents.uid,
          }))
        : [...Array(3)].map(_ => {
            const oneTimeId = `#${Math.random().toString(36).slice(-8)}`;
            return {
              id: oneTimeId,
              issue_no: currentIssueNo,
              question_id: question?.id || NaN,
              question_no: question?.order_num || NaN,
              value: "",
              uid: oneTimeId,
            };
          });
    setAnswersToQ1([...initAnswersQ1]);

    const answerQ2 = state.active_learning_answers.find(
      answer => answer.issue_no === currentIssueNo && answer.question_no === 2,
    );
    setAnswerToQ2({
      id: answerQ2?.id || null,
      issue_no: answerQ2?.issue_no || currentIssueNo,
      question_no: answerQ2?.question_no || NaN,
      question_id: answerQ2?.question_id || NaN,
      parentUid: answerQ2?.contents.parentUid,
    });
    setSelectedUid(answerQ2?.contents.parentUid);

    const answerQ3 = state.active_learning_answers.find(
      answer => answer.issue_no === currentIssueNo && answer.question_no === 3,
    );
    setAnswerToQ3({
      id: answerQ3?.id || null,
      issue_no: answerQ3?.issue_no || currentIssueNo,
      question_no: answerQ3?.question_no || NaN,
      question_id: answerQ3?.question_id || NaN,
      value: answerQ3?.contents.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.active_learning_answers]);

  const changeAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    const targetAnswer = answersToQ1.find(answer => answer.id === id);
    if (!targetAnswer) throw new Error(`Not found answer id: #{id}`);

    const newAnswer = {
      id: targetAnswer.id,
      issue_no: currentIssueNo,
      question_id: question?.id || NaN,
      question_no: question?.order_num || NaN,
      value,
      uid: targetAnswer.uid,
    };

    const updatedAnswers = answersToQ1.map(existingAnswer =>
      existingAnswer.id === targetAnswer.id ? newAnswer : existingAnswer,
    );

    !answersToQ1.some(
      existingAnswer => existingAnswer.id === targetAnswer.id,
    ) && updatedAnswers.push(newAnswer);

    setAnswersToQ1(updatedAnswers);
  };

  const addAnswerField = (question: IActiveLearningQuestion) => {
    const oneTimeId = `#${Math.random().toString(36).slice(-8)}`;
    setAnswersToQ1([
      ...answersToQ1,
      {
        id: oneTimeId,
        issue_no: props.issue?.no || NaN,
        question_id: question.id,
        question_no: question.order_num,
        value: "",
        uid: oneTimeId,
      },
    ]);
  };

  const currentActiveLearning = useMemo(() => {
    return state.active_learning_data_by_lesson;
  }, [state.active_learning_data_by_lesson]);

  const materialCode = useMemo(() => {
    return currentActiveLearning.material_code;
  }, [currentActiveLearning.material_code]);

  const actionCategories = useMemo(() => {
    return (
      state.active_learning_materials.find(
        material => material.code === materialCode,
      )?.action_categories || []
    );
  }, [materialCode, state.active_learning_materials]);

  const selectedActionCategories = useMemo(() => {
    const allAnswers = state.active_learning_answers_by_material;
    const actionCategoryAnswers = getActiveLearningAnswers(allAnswers, 1, 1, 2);

    return actionCategoryAnswers.flatMap(answers =>
      answers.action_ids.map((id: number) =>
        actionCategories.find(category => category.id === id),
      ),
    );
  }, [state.active_learning_answers_by_material, actionCategories]);

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          社会で行われている課題解決も、元は小さな「もっと〇〇」から生まれている。今までの学習で自分が考えた課題解決のアイデアが、社会で実現できるとしたら、どのような仕事が関係するかを考えて入力しよう。
          <br />
          <br />
          操作：「項目を追加する」ボタンで項目を増やすことができます。
        </p>
      </div>
      {question && (
        <div className={styles.question}>
          <ul className={styles.listActionCategory}>
            {selectedActionCategories?.map(
              (category: { id: number; name: string }) => (
                <li key={category?.id} className={styles.actionCategory}>
                  {category?.name}
                </li>
              ),
            )}
          </ul>
          <div className={styles.itemAddition}>
            <ul className={styles.listItem}>
              {answersToQ1.map(answer => (
                <li key={answer.id} className={styles.item}>
                  <TextInput
                    id={answer.id}
                    value={answer.value}
                    width={"100%"}
                    handleChange={e => changeAnswer(e)}
                  />
                </li>
              ))}
            </ul>
            <div className={styles.buttonWrapper}>
              <Button
                width={"150px"}
                height={"37px"}
                fontSize={"14px"}
                handleClick={() => addAnswerField(question)}
              >
                <IconAdd />
                項目を追加する
              </Button>
            </div>
          </div>
          <div className={styles.themEntry}>
            <p className={styles.title}>{question2?.title}</p>
            <p className={styles.read}>
              操作：「選択してください。」と表示されている回答枠をクリックすると、上の回答から問題を選択できます。
            </p>
            <div className={styles.textAreaWrapper}>
              <Selector
                selectedValue={selectedUid}
                options={answersToQ1.map(answer => {
                  return { value: answer.uid, label: answer.value };
                })}
                handleChange={e =>
                  setAnswerToQ2({
                    ...answerToQ2,
                    parentUid: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className={styles.themEntry}>
            <p className={styles.title}>{question3?.title}</p>
            <p className={styles.read}>
              仕事が生み出す価値や感情を想像して入力しよう。
            </p>
            <div className={styles.textAreaWrapper}>
              <TextArea
                value={answerToQ3.value}
                handleChange={e =>
                  setAnswerToQ3({
                    id: answerToQ3.id,
                    question_id: question3?.id || NaN,
                    question_no: question3?.order_num || NaN,
                    issue_no: currentIssueNo,
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Issue3;
