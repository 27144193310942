import React from "react";
import clsx from "clsx";

import styles from "./StepProcessBar.module.scss";

interface Props {
  totalPage: number;
  currentPage: number;
}

const StepProcessBar = React.forwardRef<HTMLDivElement, Props>(
  ({ totalPage, currentPage }, ref) => {
    const [arrayPages, setArrayPages] = React.useState<number[]>([]);
    const [stateChangePage, setStateChangePage] = React.useState<boolean>(
      false,
    );

    React.useEffect(() => {
      setArrayPages([]);
    }, [totalPage]);

    React.useEffect(() => {
      const rangeMaxPage = 13;
      const rangeMinPage = 2;
      if (totalPage < rangeMinPage) return;
      if (totalPage <= rangeMaxPage && arrayPages.length === 0) {
        setArrayPages(Array.from({ length: totalPage }, (_, i) => i + 1));
      } else if (totalPage > rangeMaxPage && arrayPages.length === 0) {
        currentPage > rangeMaxPage
          ? setArrayPages(
              Array.from({ length: rangeMaxPage }, (_, i) => i + currentPage),
            )
          : setArrayPages(
              Array.from({ length: rangeMaxPage }, (_, i) => i + 1),
            );
      } else if (
        currentPage > arrayPages[arrayPages.length - 1] ||
        currentPage < arrayPages[0]
      ) {
        setArrayPages(
          Array.from({ length: rangeMaxPage }, (_, i) =>
            currentPage > rangeMaxPage &&
            currentPage > arrayPages[arrayPages.length - 1]
              ? i + currentPage - rangeMaxPage + 1
              : i + currentPage,
          ),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, totalPage]);

    React.useEffect(() => {
      setStateChangePage(true);
      const timer = setTimeout(() => {
        setStateChangePage(false);
      }, 1500);
      return () => clearTimeout(timer);
    }, [currentPage]);

    return (
      <div
        className={clsx(styles.wrapper, stateChangePage ? "" : styles.fadeOut)}
        ref={ref}
      >
        {arrayPages.map((page: number) => (
          <div
            key={page}
            className={clsx(
              styles.StepProgress,
              currentPage === page && styles.is_current,
            )}
          />
        ))}
      </div>
    );
  },
);

export default StepProcessBar;
