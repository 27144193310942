import React from "react";

import DataSurvey from "../../../templates/student/DataSurvey";
import {
  Context,
  fetchPackages,
  fetchSurveyAnswers,
  fetchSurveyAnswersStatistic,
} from "../../../studentStore";

export default () => {
  const { state, dispatch } = React.useContext(Context);

  React.useEffect(() => {
    dispatch(fetchPackages());
  }, [dispatch]);

  const fetchSurveyFunc = (material_id: number) => {
    dispatch(fetchSurveyAnswers(material_id));
  };

  const fetchDetailsSurveyFunc = (package_id: number) => {
    dispatch(fetchSurveyAnswersStatistic(package_id));
  };

  return (
    <DataSurvey
      details_survey={state.survey_answers_statistic.package.teaching_materials}
      packages={state.packages}
      survey_answers={state.survey_answers}
      survey_answers_per_teaching_material={
        state.survey_answers_per_teaching_material
      }
      fetchSurveyFunc={fetchSurveyFunc}
      fetchDetailsSurveyFunc={fetchDetailsSurveyFunc}
    />
  );
};
