import React from "react";
import { Bar } from "react-chartjs-2";

import {
  SCORE_LABELS_JSX,
  SCORE_LABELS_JSX_SP,
  X_LABELS,
} from "../../constants/assessment-content";

import styles from "./BarChart.module.scss";

export interface IPastAssessmentScore {
  term_no: number;
  scores: number[];
}

export type Props = {
  currentData: number[];
  pastData: IPastAssessmentScore[];
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          display: false,
          min: 0.96,
          max: 8,
        },
        gridLines: {
          color: "#5A667F",
        },
      },
    ],
    xAxes: [
      {
        id: "main",
        ticks: {
          display: false,
        },
        gridLines: {
          color: "#5A667F",
        },
      },
      {
        id: "bottom",
        ticks: {
          display: false,
        },
        display: false,
        offset: true,
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  hover: {
    mode: null,
  },
};

const settingStyleBarCurrent = {
  barPercentage: 0.5,
  backgroundColor: [
    "#93FF89",
    "#EA7F7F",
    "#FFFFA5",
    "#9FFFE5",
    "#D9C06B",
    "#C7C7C7",
    "#629EFF",
  ],
  label: "student_score",
};

const settingStyleBarPast = {
  barPercentage: 0.5,
  backgroundColor: [
    "rgba(147, 255, 137, 0.4)",
    "rgba(234, 127, 127, 0.4)",
    "rgba(255, 255, 165, 0.4)",
    "rgba(159, 255, 229, 0.4)",
    "rgba(217, 192, 107, 0.4)",
    "rgba(199, 199, 199, 0.4)",
    "rgba(98, 158, 255, 0.4)",
  ],
};

const BarChart: React.FC<Props> = ({ currentData, pastData }) => {
  const data = React.useMemo(() => {
    const pastDatasets = pastData.map(data => ({
      ...settingStyleBarPast,
      data: data.scores,
      label: `term${data.term_no}`,
      xAxisID: "main",
    }));
    return {
      labels: X_LABELS,
      datasets: [
        {
          data: currentData.map(() => 1.03),
          label: "bottom",
          barPercentage: 1,
          backgroundColor: currentData.map((d, i) => {
            return d > 0
              ? [
                  "#93FF89",
                  "#EA7F7F",
                  "#FFFFA5",
                  "#9FFFE5",
                  "#D9C06B",
                  "#C7C7C7",
                  "#629EFF",
                ][i]
              : "#707070";
          }),
          xAxisID: "bottom",
        },
        ...pastDatasets,
        {
          ...settingStyleBarCurrent,
          data: currentData,
          xAxisID: "main",
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData, pastData]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperChart}>
        <Bar options={options} data={data} />
      </div>
      <div className={styles.yAxies}>
        <p>{SCORE_LABELS_JSX.label8}</p>
        <p>{SCORE_LABELS_JSX.label7}</p>
        <p>{SCORE_LABELS_JSX.label6}</p>
        <p>{SCORE_LABELS_JSX.label5}</p>
        <p>{SCORE_LABELS_JSX.label4}</p>
        <p>{SCORE_LABELS_JSX.label3}</p>
        <p>{SCORE_LABELS_JSX.label2}</p>
        <p>{SCORE_LABELS_JSX.label1}</p>
      </div>
      <div className={styles.yAxiesSP}>
        <p>{SCORE_LABELS_JSX_SP.label8}</p>
        <p>{SCORE_LABELS_JSX_SP.label7}</p>
        <p>{SCORE_LABELS_JSX_SP.label6}</p>
        <p>{SCORE_LABELS_JSX_SP.label5}</p>
        <p>{SCORE_LABELS_JSX_SP.label4}</p>
        <p>{SCORE_LABELS_JSX_SP.label3}</p>
        <p>{SCORE_LABELS_JSX_SP.label2}</p>
        <p>{SCORE_LABELS_JSX_SP.label1}</p>
      </div>
    </div>
  );
};
export default BarChart;
