import React from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Context,
  notFoundPath,
  fetchActiveLearningStoryBoard,
} from "../../../studentStore";
import ActiveLearningStoryboard from "../../../templates/student/ActiveLearningStoryboard";

export default () => {
  const { state, dispatch } = React.useContext(Context);
  const history = useHistory();
  const { id } = useParams();

  if (!id) {
    history.push(notFoundPath);
    return null;
  }

  React.useEffect(() => {
    dispatch(fetchActiveLearningStoryBoard(id));
  }, [dispatch, id]);

  return (
    <ActiveLearningStoryboard
      activeLearningStoryBoard={state.active_learning_story_board}
    />
  );
};
