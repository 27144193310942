import React from "react";
import { useEffect, useMemo, useCallback } from "react";
import clsx from "clsx";

import {
  IActiveLearningAnswers,
  IActiveLearningIssue,
} from "../../../../state";
import { Context, updateActiveLearningAnswers } from "../../../../studentStore";
import { getActiveLearningAnswers } from "../../../../libs/ActiveLearning";
import TreeCard from "../../../../atoms/ActiveLearning/TreeCard";
import Header from "../../../../organisms/ActiveLearning/Header";
import EndLesson from "../../../../organisms/ActiveLearning/EndLesson";
import Issue1 from "../../../../organisms/student/ActiveLearningLessons/Lesson4/Issue1";
import Issue2 from "../../../../organisms/student/ActiveLearningLessons/Lesson4/Issue2";
import Issue3 from "../../../../organisms/student/ActiveLearningLessons/Lesson4/Issue3";
import styles from "../ActiveLearningLesson.module.scss";
import useWaitApiCall from "../../../../hooks/useWaitApiCall";
import SaveAnswerButton from "../../../../atoms/ActiveLearning/SaveAnswerButton";

export const Lesson4 = () => {
  const { state, dispatch } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const [answers, setAnswers] = React.useState<IActiveLearningAnswers[]>([]);
  const [commonAnswers, setCommonAnswers] = React.useState<
    IActiveLearningAnswers[]
  >([]);

  const currentLessonNo = useMemo(() => {
    return state.active_learning_data_by_lesson.no;
  }, [state.active_learning_data_by_lesson]);

  const materialCode = useMemo(() => {
    return state.active_learning_data_by_lesson.material_code;
  }, [state.active_learning_data_by_lesson]);

  useEffect(() => {
    setAnswers([...state.active_learning_answers]);
  }, [state.active_learning_answers]);

  const currentActiveLearning = useMemo(() => {
    return state.active_learning_data_by_lesson;
  }, [state.active_learning_data_by_lesson]);

  const findIssue = useCallback(
    (no: number): IActiveLearningIssue | undefined =>
      currentActiveLearning.issues?.find(issue => issue.no === no),
    [currentActiveLearning.issues],
  );

  const saveAnswers = () => {
    const params = answers;
    if (calling) return;
    dispatch(
      peep(
        updateActiveLearningAnswers(
          materialCode,
          currentLessonNo,
          JSON.stringify(params),
        ),
      ),
    );
  };

  const lastAnswerQ2 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers = getActiveLearningAnswers(answersByMaterial, 3, 2, 1);

    return currentAnswers.map(result => result.value).join("");
  }, [state.active_learning_answers_by_material]);

  const lastAnswersQ3 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    return materialCode === "ECS001"
      ? getActiveLearningAnswers(answersByMaterial, 3, 3, 1).map(
          result => result.value,
        )
      : getActiveLearningAnswers(answersByMaterial, 4, 2, 1).map(
          result => result.value,
        );
  }, [state.active_learning_answers_by_material, materialCode]);

  return (
    <>
      <div className={styles.wrapper} id="accordion">
        <Header
          no={currentActiveLearning.no}
          label={"実現までの道筋を描く"}
          title={currentActiveLearning.title}
          read={
            "「問い」の内容を実現させるときに、立ちはだかる問題について調査しよう。\nその問題を解決したときの理想状況を想像して、取り組んでいく「課題」を決定しよう。"
          }
        />
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles.review}>
              <p className={styles.title}>前回の振り返り</p>
              <div className={styles.tree}>
                <TreeCard
                  title={"取り組む問い"}
                  text={lastAnswerQ2 || "回答データがありません。"}
                />
                <ul className={styles.branchList}>
                  <li className={styles.branch}>
                    <div className={styles.labelWrapper}>
                      <span className={styles.label}>問題</span>
                    </div>
                    <ul className={styles.answerList}>
                      {lastAnswersQ3.length > 0 ? (
                        lastAnswersQ3.map((answer, index) => (
                          <li
                            key={`${answer}${index}`}
                            className={clsx(
                              styles.answer,
                              lastAnswersQ3.length < 2 && styles.single,
                            )}
                          >
                            {answer}
                          </li>
                        ))
                      ) : (
                        <li className={clsx(styles.answer, styles.single)}>
                          回答データがありません。
                        </li>
                      )}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <Issue1
              lastAnswers={lastAnswersQ3}
              issue={findIssue(1)}
              answers={answers}
              setAnswers={setAnswers}
              setCommonAnswers={setCommonAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <Issue2
              issue={findIssue(2)}
              answers={answers}
              commonAnswers={commonAnswers}
              setAnswers={setAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <Issue3
              issue={findIssue(3)}
              answers={answers}
              setAnswers={setAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <EndLesson
              text={
                "次のLessonでは、設定した「課題」を解決するアイデアを考える。次回までに、どんなアイデアなら「課題」を解決できそうかのイメージを膨らませておこう。"
              }
              saveAnswers={saveAnswers}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Lesson4;
