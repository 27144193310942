import React from "react";
import Slider, { Settings } from "react-slick";
import clsx from "clsx";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";

import Radio from "../../../atoms/RadioButton";
import { ReactComponent as ArrowUpIcon } from "../../../assets/svg/up-arrow.svg";
import { ReactComponent as CheckMarkIcon } from "../../../assets/svg/check_mark.svg";
import Button from "../../../atoms/Button";
import Toast from "../../../molecules/Toast";
import useDebounceCallback from "../../../hooks/useDebounceCallback";
import { questionnairePath } from "../../../studentStore";
import { ISurvey, SurveyAnswersUpdate } from "../../../state";
import { Context } from "../../../studentStore";

import styles from "./Survey.module.scss";

const settings: Settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  swipe: false,
  vertical: true,
};

export type SurveyProps = {
  survey: ISurvey[];
  handleUpdateSurveyAnswers: (
    is_pre_answer: boolean,
    student_survey_answers: SurveyAnswersUpdate[],
  ) => void;
  handlePostSurveyAnswers: (
    is_pre_answer: boolean,
    student_survey_answers: SurveyAnswersUpdate[],
  ) => void;
  postAnswers: any[];
  preAnswers: any[];
  isPreAnswer: boolean;
  calling: boolean;
};

const Survey: React.FC<SurveyProps> = ({
  survey,
  handleUpdateSurveyAnswers,
  handlePostSurveyAnswers,
  postAnswers,
  preAnswers,
  isPreAnswer,
  calling,
}) => {
  const slider = React.useRef<Slider>(null);
  const [page, setPage] = React.useState(0);
  const [formValue, setFormValue] = React.useState<SurveyAnswersUpdate[]>([]);
  const [isComplete, setIsComplete] = React.useState(false);
  const [showMsg, setShowMsg] = React.useState<{
    text: string;
    type: string;
  }>({
    text: "",
    type: "",
  });
  const { state } = React.useContext(Context);
  const [debounced] = useDebounceCallback((changePage: number) =>
    handleChangePage(changePage),
  );

  React.useEffect(() => {
    if (state.success_message) {
      setIsComplete(true);
    }
  }, [state.success_message]);

  React.useEffect(() => {
    if (!!postAnswers.length || !!preAnswers.length) {
      const listValue = (!!postAnswers.length
        ? [...postAnswers]
        : [...preAnswers]
      ).map(answer => ({
        id: answer.survey_question.id,
        score: answer.score,
      }));
      setFormValue([...listValue]);
    }
  }, [postAnswers, preAnswers]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const index = formValue.findIndex(value => value.id === Number(name));

    if (index >= 0) {
      formValue[index].score = Number(value);
    } else {
      formValue.push({
        id: Number(name),
        score: Number(value),
      });
    }
    setFormValue([...formValue]);
  };

  const handleChangePage = (changePage: number) => {
    if (changePage > 0) {
      if (page + 1 !== survey.length) {
        const index = formValue.findIndex(
          value => value.id === survey[page].id,
        );
        if (index >= 0) {
          slider.current?.slickNext();
          setPage(page + changePage);
        } else {
          setShowMsg({
            text: "次のページに進むためには回答を入力してください。",
            type: "error",
          });
        }
      }
    } else {
      if (page !== 0) {
        slider.current?.slickPrev();
        setPage(page + changePage);
      }
    }
  };

  const handlers = useSwipeable({
    onSwipedUp: () => debounced(1),
    onSwipedDown: () => debounced(-1),
    delta: 120,
  });

  const isChecked = (id: number, score: number): boolean => {
    const checkExist = formValue.filter(
      data => data.id === id && data.score === score,
    );
    return !!checkExist.length;
  };

  return (
    <>
      {!isComplete ? (
        <form
          className={styles.form}
          onSubmit={e => {
            e.preventDefault();
            !!postAnswers.length || !!preAnswers.length
              ? handleUpdateSurveyAnswers(isPreAnswer, formValue)
              : handlePostSurveyAnswers(isPreAnswer, formValue);
          }}
          {...handlers}
        >
          <Slider ref={slider} {...settings}>
            {survey.map((s, i) => (
              <div
                className={clsx(
                  styles.question,
                  i % 2 !== 0 && styles.question__bg,
                )}
                key={s.id}
              >
                <p className={styles.question__content}>{s.content}</p>
                <div className={styles.question__labelScore}>
                  <span>{s.min_score_label}</span>
                  <span className={styles.question__dash} />
                  <span>{s.max_score_label}</span>
                </div>
                <div className={styles.question__groupRadio}>
                  {new Array(9).fill("a").map((_, i) => (
                    <Radio
                      id={`${s.id}-${i}`}
                      label={(1 + i / 2).toString()}
                      value={1 + i / 2}
                      name={s.id.toString()}
                      onChange={handleInputChange}
                      checked={isChecked(s.id, 1 + i / 2)}
                      key={`${s.id}-${i}`}
                    />
                  ))}
                </div>
                {i + 1 === survey.length && (
                  <Button
                    name={`${
                      !!postAnswers.length || !!preAnswers.length
                        ? "更新"
                        : "終了"
                    }`}
                    variant="normal"
                    className={styles.buttonSubmit}
                    disabled={formValue.length !== survey.length || calling}
                  />
                )}
                <span className={styles.page}>
                  {i + 1} / {survey.length}
                </span>
              </div>
            ))}
          </Slider>
          <button
            hidden={page === 0}
            className={clsx(styles.buttonSwipe, styles.buttonUp)}
            onClick={e => {
              e.preventDefault();
              debounced(-1);
            }}
          >
            <ArrowUpIcon />
          </button>
          <button
            hidden={page === survey.length - 1}
            className={clsx(styles.buttonSwipe, styles.buttonDown)}
            onClick={e => {
              e.preventDefault();
              debounced(1);
            }}
          >
            <ArrowUpIcon />
          </button>

          {showMsg.text && (
            <Toast
              onClose={() =>
                setShowMsg({
                  text: "",
                  type: "",
                })
              }
              message={showMsg}
            />
          )}
        </form>
      ) : (
        <div className={styles.completePage}>
          <p>Thank You!</p>
          <CheckMarkIcon />
          <Link aria-current="page" to={questionnairePath}>
            ホームに戻る
          </Link>
        </div>
      )}
    </>
  );
};

export default Survey;
