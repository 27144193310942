import React from "react";

import styles from "./LessonNo.module.scss";

interface ILessonNoProps {
  no: number;
}

export const LessonNo: React.FC<ILessonNoProps> = ({ no }) => {
  const labelNo = React.useMemo(() => {
    if (isNaN(no)) return;
    return no < 10 ? `0${no}` : no;
  }, [no]);

  return (
    <div className={styles.lessonNo}>
      <p className={styles.title}>Lesson</p>
      <p className={styles.no}>{labelNo}</p>
    </div>
  );
};

export default LessonNo;
