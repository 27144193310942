import React from "react";
import clsx from "clsx";

import { NEXT_ACTIONS_PAGE } from "../../../constants/assessment-content";
import { SCORE_LABELS } from "../../../constants/assessment-content";
import { IAssessmentContent } from "../../../constants/assessment-content";
import { ReactComponent as PreviousScoreIcon } from "../../../assets/svg/icon_student_previous.svg";
import { ReactComponent as Talk1Icon } from "../../../assets/svg/glide_talk_1.svg";
import { ReactComponent as Talk2Icon } from "../../../assets/svg/glide_talk_2.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/glide_arrow.svg";
import AssessmentComment from "../AssessmentComment";
import {
  IAssessmentExperience,
  IReasonExperience,
  INextAction,
  IAssessment,
  IAssessmentPast,
} from "../../../state";
import { NextActionKind, NextActionKinds } from "../../../constants/enum";
import { REASON_LABEL } from "../../../constants/assessment-content";

import styles from "./AssessmentContent.module.scss";

export type Props = {
  isActiveNextAction: boolean;
  content: IAssessmentContent;
  selectedScore: number | null;
  currentReason: string | null;
  currentNextActions: { [key: number]: string | null };
  assessmentExperience: IAssessmentExperience | null;
  assessmentNextActions: INextAction[];
  calling: boolean;
  previousAssessment: IAssessment | IAssessmentPast | null;
  onSaveScore: (score: number) => void;
  onSaveReason: (reason: string) => void;
  onSaveNextAction: (kind: NextActionKind, next_action: string) => void;
  onEditReason: (id: number, reason: string) => void;
  onDeleteReason: (id: number) => void;
  onEditNextAction: (id: number, reason: string) => void;
  onDeleteNextAction: (id: number) => void;
};

const AssessmentContent: React.FC<Props> = ({
  isActiveNextAction,
  content,
  selectedScore,
  currentReason,
  currentNextActions,
  assessmentExperience,
  assessmentNextActions,
  previousAssessment,
  calling,
  onSaveScore,
  onSaveReason,
  onSaveNextAction,
  onEditReason,
  onDeleteReason,
  onEditNextAction,
  onDeleteNextAction,
}) => {
  const score: number = React.useMemo(() => selectedScore ?? 0, [
    selectedScore,
  ]);
  const previousScore: number = React.useMemo(
    () =>
      previousAssessment?.assessment_experiences[content.id - 1]?.score ?? 0,
    [previousAssessment, content],
  );

  const reasons: IReasonExperience[] = React.useMemo(() => {
    return assessmentExperience?.reasons || [];
  }, [assessmentExperience]);

  const filteredNextActions = React.useMemo(() => {
    const res: { [key: number]: IReasonExperience[] } = {};
    NextActionKinds.forEach(k => {
      res[k.kind] = assessmentNextActions
        .filter(item => item.kind === k.kind)
        .map(item => ({
          id: item.id,
          created_at: item.created_at,
          reason: item.next_action,
        }));
    });
    return res;
  }, [assessmentNextActions]);

  const enableCommentSubmit = React.useMemo(
    () => score > 0 || isActiveNextAction,
    [score, isActiveNextAction],
  );

  const reasonLabel = React.useMemo(() => {
    if (previousScore === 0 || score === 0) {
      return REASON_LABEL.defaultText;
    } else if (score < previousScore) {
      return REASON_LABEL.downText;
    } else {
      return REASON_LABEL.upTexts[score];
    }
  }, [previousScore, score]);

  return (
    <div className={clsx(styles.wrapper, styles[`wrapper--${content.id}`])}>
      <div className={styles.questionContent}>
        <h6 className={styles.title}>{content.titleForStudentGlide}</h6>
        <h3>
          {content.questionTitle}
          {content.subQuestionTitle && <span>{content.subQuestionTitle}</span>}
        </h3>
        <p className={styles.textContent}>
          {content.questionContentForStudent}
        </p>
        {content.personA && (
          <div className={styles.conversations}>
            <div className={styles.conversations__personA}>
              {content.iconGlide1 && (
                <img src={content.iconGlide1} alt="person A" />
              )}
            </div>
            <div className={styles.conversations__conversation}>
              <p>この素養がないと…</p>
              <div className="position-relative">
                <Talk1Icon />
                <span>{content.personA}</span>
              </div>
            </div>
            <div className={styles.conversations__arrow}>
              <ArrowIcon />
            </div>
            <div
              className={clsx(
                styles.conversations__conversation,
                styles[`conversations__conversation--b-${content.id}`],
              )}
            >
              <p>この素養があると…</p>
              <div className="position-relative">
                <Talk2Icon />
                <span>{content.personB}</span>
              </div>
            </div>
            <div className={styles.conversations__personB}>
              {content.iconGlide2 && (
                <img src={content.iconGlide2} alt="person B" />
              )}
            </div>
          </div>
        )}
      </div>
      {content.id !== NEXT_ACTIONS_PAGE.id && (
        <div className={styles.body}>
          <p className={styles.remind}>
            この素養について、今自分はどの段階にいるのか、当てはまるものを選んでみましょう。
          </p>
          <div className={styles.answers}>
            <div className={styles[`answers__${content.id}`]}>
              <div className={clsx(styles.answers__item)}>
                <div
                  className={clsx(score === 1 && styles.active)}
                  onClick={() => onSaveScore(1)}
                >
                  <p>{SCORE_LABELS.label1}</p>
                  {content.score1}
                  {!isActiveNextAction && previousScore === 1 && (
                    <PreviousScoreIcon />
                  )}
                </div>
                <span />
              </div>
              <div className={clsx(styles.answers__item)}>
                <div
                  className={clsx(score === 2 && styles.active)}
                  onClick={() => onSaveScore(2)}
                >
                  <p>{SCORE_LABELS.label2}</p>
                  {content.score2}
                  {!isActiveNextAction && previousScore === 2 && (
                    <PreviousScoreIcon />
                  )}
                </div>
                <span />
              </div>
              <div className={clsx(styles.answers__item)}>
                <div
                  className={clsx(score === 3 && styles.active)}
                  onClick={() => onSaveScore(3)}
                >
                  <p>{SCORE_LABELS.label3}</p>
                  {content.score3}
                  {!isActiveNextAction && previousScore === 3 && (
                    <PreviousScoreIcon />
                  )}
                </div>
                <span />
              </div>
              <div className={clsx(styles.answers__item)}>
                <div
                  className={clsx(score === 4 && styles.active)}
                  onClick={() => onSaveScore(4)}
                >
                  <p>{SCORE_LABELS.label4}</p>
                  {content.score4}
                  {!isActiveNextAction && previousScore === 4 && (
                    <PreviousScoreIcon />
                  )}
                </div>
              </div>
            </div>
            <div
              className={clsx(styles[`answers__${content.id}`], "mt-4 ms-auto")}
            >
              <div className={clsx(styles.answers__item)}>
                <div
                  className={clsx(score === 5 && styles.active)}
                  onClick={() => onSaveScore(5)}
                >
                  <p>{SCORE_LABELS.label5}</p>
                  {content.score5}
                  {!isActiveNextAction && previousScore === 5 && (
                    <PreviousScoreIcon />
                  )}
                </div>
                <span />
              </div>
              <div className={clsx(styles.answers__item)}>
                <div
                  className={clsx(score === 6 && styles.active)}
                  onClick={() => onSaveScore(6)}
                >
                  <p>{SCORE_LABELS.label6}</p>
                  {content.score6}
                  {!isActiveNextAction && previousScore === 6 && (
                    <PreviousScoreIcon />
                  )}
                </div>
                <span />
              </div>
              <div className={clsx(styles.answers__item)}>
                <div
                  className={clsx(score === 7 && styles.active)}
                  onClick={() => onSaveScore(7)}
                >
                  <p>{SCORE_LABELS.label7}</p>
                  {content.score7}
                  {!isActiveNextAction && previousScore === 7 && (
                    <PreviousScoreIcon />
                  )}
                </div>
                <span />
              </div>
              <div className={clsx(styles.answers__item)}>
                <div
                  className={clsx(score === 8 && styles.active)}
                  onClick={() => onSaveScore(8)}
                >
                  <p>{SCORE_LABELS.label8}</p>
                  {content.score8}
                  {!isActiveNextAction && previousScore === 8 && (
                    <PreviousScoreIcon />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <p
              className={clsx(
                styles.titleComment,
                !enableCommentSubmit && styles.disabled,
              )}
            >
              {reasonLabel}
            </p>
            <AssessmentComment
              calling={calling}
              currentReason={currentReason}
              reasons={reasons}
              contentId={content.id}
              onSaveReason={reason => onSaveReason(reason)}
              onEdit={(id, reason) => onEditReason(id, reason)}
              onDelete={id => onDeleteReason(id)}
              enableSubmit={enableCommentSubmit}
              placeholder={content.placeholder}
            />
          </div>
        </div>
      )}
      {content.id === NEXT_ACTIONS_PAGE.id && (
        <div className={styles.body}>
          {NextActionKinds.map(k => (
            <div key={k.kind}>
              <p
                className={clsx(
                  styles.titleComment,
                  !enableCommentSubmit && styles.disabled,
                )}
              >
                {k.title}
              </p>
              <AssessmentComment
                calling={calling}
                currentReason={currentNextActions[k.kind]}
                reasons={filteredNextActions[k.kind]}
                contentId={content.id}
                onSaveReason={reason => onSaveNextAction(k.kind, reason)}
                onEdit={(id, reason) => onEditNextAction(id, reason)}
                onDelete={id => onDeleteNextAction(id)}
                enableSubmit={enableCommentSubmit}
                placeholder={k.placeholder}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AssessmentContent;
