import React from "react";

import styles from "./TextArea.module.scss";

interface ITextAreaProps {
  value?: string;
  placeholder?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea: React.FC<ITextAreaProps> = ({
  value,
  placeholder,
  width = "100%",
  height = "176px",
  disabled,
  handleChange,
}) => {
  return (
    <textarea
      className={styles.textArea}
      style={{ width, height }}
      value={value}
      placeholder={placeholder ? placeholder : "テキストを入力してください。"}
      disabled={disabled}
      onChange={e => handleChange && handleChange(e)}
    />
  );
};

export default TextArea;
