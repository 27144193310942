import React from "react";
import clsx from "clsx";

import { ReactComponent as IconPencil } from "../../../assets/svg/pencil.svg";
import { ReactComponent as IconDelete } from "../../../assets/svg/delete.svg";
import { ReactComponent as IconStudent } from "../../../assets/svg/student-comment.svg";
import { ReactComponent as IconTeacher } from "../../../assets/svg/teacher-comment.svg";
import { IStudent, IAnswerComment } from "../../../state";
import ModalDeleteConfirm from "../../../molecules/ModalDeleteConfirm";

import style from "./FormReply.module.scss";

type Props = {
  comment: IAnswerComment;
  currentUser?: IStudent;
  answerId: number;
  name_opend: boolean;
  calling?: boolean;
  onDeleteReply: (answerId: number, commentId: number) => void;
  onSendComment: (text: string, answerId: number, commentId?: number) => void;
};

const FormReply: React.FC<Props> = ({
  comment,
  onDeleteReply,
  onSendComment,
  currentUser,
  answerId,
  name_opend,
  calling,
}) => {
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<string>(comment.content);
  const [openModal, setOpenModal] = React.useState<{
    state: boolean;
    answerId: number;
    commentId: number;
  }>({
    state: false,
    answerId: 0,
    commentId: 0,
  });

  const updateReply = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSendComment(content, comment.student!.id, comment.id);
    setShowForm(!showForm);
  };

  return (
    <div className={style.listReplies}>
      {showForm ? (
        <form onSubmit={updateReply} className={style.replyAnswer}>
          <span>
            {`${comment.student?.last_name} ${comment.student?.first_name}`} (
            編集中 )
          </span>
          <textarea
            value={content}
            rows={3}
            placeholder="コメントを書く"
            onChange={e => setContent(e.target.value)}
          />
          <div>
            <button
              type="submit"
              disabled={calling || !content || content === comment.content}
            >
              送信
            </button>
            <button
              onClick={() => {
                setShowForm(!showForm);
                setContent(comment.content);
              }}
            >
              キャンセル
            </button>
          </div>
        </form>
      ) : (
        <>
          <span>
            {comment.student ? (
              name_opend ? (
                <>
                  <IconStudent />
                  {`${comment.student?.last_name} ${comment.student?.first_name}`}
                </>
              ) : (
                ""
              )
            ) : (
              <>
                <IconTeacher />
                {`${comment.teacher?.last_name} ${comment.teacher?.first_name}`}
              </>
            )}
          </span>
          <p className={clsx(comment.hidden_by !== null && style.hideComment)}>
            {comment.hidden_by === null
              ? comment.content
              : `${comment.hidden_by.last_name} ${comment.hidden_by.first_name}先生が、非表示にしました。`}
          </p>
          {currentUser && currentUser.id === comment.student?.id && (
            <div className={style.actionReply}>
              <IconPencil
                onClick={() => setShowForm(!showForm)}
                className={clsx(calling && style.disabled)}
              />
              <IconDelete
                className={clsx(calling && style.disabled)}
                onClick={() => {
                  setOpenModal({
                    state: true,
                    answerId,
                    commentId: comment.id,
                  });
                }}
              />
            </div>
          )}
        </>
      )}
      {openModal.state && (
        <ModalDeleteConfirm
          title="本当に削除しますか ?"
          onConfirm={() =>
            onDeleteReply(openModal.answerId, openModal.commentId)
          }
          onClose={() =>
            setOpenModal({
              state: false,
              answerId: 0,
              commentId: 0,
            })
          }
        />
      )}
    </div>
  );
};

export default FormReply;
