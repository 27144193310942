import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Context, fetchGroups, participateGroup } from "../../../studentStore";
import GroupList from "../../../templates/student/GroupList";
import { notFoundPath } from "../../../teacherStore";

const GroupPage: React.FC = () => {
  const { state, dispatch } = React.useContext(Context);
  const location = useLocation<{
    materialId: number;
    lessonId: number;
    page: number;
    index: number;
    issueId: number;
  }>();
  const history = useHistory();

  React.useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  const participateGroupFunc = React.useCallback(
    (group_id: number) => {
      if (group_id !== state.student?.group_id) {
        dispatch(participateGroup(group_id));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, state.student?.group_id],
  );

  if (!location.state) {
    history.push(notFoundPath);
    return null;
  }

  return (
    <div className="d-flex h-100">
      <GroupList
        groups={state.groups}
        currentUser={state.student!}
        chooseGroup={participateGroupFunc}
      />
    </div>
  );
};

export default GroupPage;
