import React from "react";

import TeachingMaterial from "../../../organisms/student/TeachingMaterial";
import { ITeachingMaterial } from "../../../state";
import Header from "../../../organisms/student/Header";

import styles from "./MaterialsList.module.scss";

export interface IMaterialsListProps {
  teaching_materials: ITeachingMaterial[];
  directTo: (teaching_material_id: number, lesson_id: number) => void;
}

export const MaterialsList = (props: IMaterialsListProps) => {
  return (
    <>
      <div className={styles.wrapper} id="accordion">
        <Header />
        {props.teaching_materials.length > 0 &&
          props.teaching_materials
            .filter(material => !material.title.includes("オリエンテーション"))
            .map(material => (
              <TeachingMaterial
                key={material.id}
                teaching_material={material}
                directTo={props.directTo}
              />
            ))}
      </div>
    </>
  );
};

export default MaterialsList;
