import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useContext,
  useEffect,
  useState,
} from "react";
import clsx from "clsx";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
  IActiveLearningQuestion,
} from "../../../../../state";
import { IAnswer, HintList } from "../../../../../constants/active_learning";
import { getActiveLearningAnswers } from "../../../../../libs/ActiveLearning";
import HintCard from "../../../../../atoms/ActiveLearning/HintCard";
import Button from "../../../../../atoms/ActiveLearning/Button";
import Card from "../../../../../atoms/ActiveLearning/Card";
import TextInput from "../../../../../atoms/ActiveLearning/TextInput";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import TreeCard from "../../../../../atoms/ActiveLearning/TreeCard";
import ExchangeStepAnswerButton from "../../../../../molecules/ActiveLearning/ExchangeStepAnswerButton";
import styles from "../../ActiveLearningIssue.module.scss";
import { ReactComponent as IconAdd } from "../../../../../assets/images/ActiveLearning/icon_add.svg";
import { ReactComponent as IconDelete } from "../../../../../assets/images/ActiveLearning/icon_delete.svg";
import { ReactComponent as IconLive } from "../../../../../assets/images/ActiveLearning/icon_live.svg";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
  setCommonAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface IAnswerToQ1 extends IAnswer<string> {
  value: string;
  stepNo: number;
  _destroy?: boolean;
}
export interface IAnswerToQ2 extends IAnswer {
  value: string;
}

export const Issue1 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 1;
  const question = useMemo(() => {
    const q = props.issue?.questions.find(q => q.order_num === 1);
    if (q) return q;
  }, [props.issue]);
  const question2 = useMemo(() => {
    const q = props.issue?.questions.find(q => q.order_num === 2);
    if (q) return q;
  }, [props.issue]);

  const materialCode = useMemo(() => {
    return state.active_learning_data_by_lesson.material_code;
  }, [state.active_learning_data_by_lesson]);

  const [answersToQ1, setAnswersToQ1] = useState<IAnswerToQ1[]>([]);

  const [answerToQ2, setAnswerToQ2] = useState<IAnswerToQ2>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question2?.order_num || NaN,
    question_id: question2?.id || NaN,
    value: "",
  });

  useEffect(() => {
    const answersQ1: IActiveLearningAnswers[] = answersToQ1.map(
      (answer: IAnswerToQ1) => {
        return {
          id: answer.id?.includes("#") ? null : Number(answer.id),
          issue_no: answer.issue_no,
          question_no: answer.question_no,
          question_id: answer.question_id,
          contents: {
            value: answer.value,
            stepNo: answer.stepNo,
            _destroy: answer._destroy,
          },
        };
      },
    );

    const answerQ2: IActiveLearningAnswers = {
      id: answerToQ2.id,
      issue_no: answerToQ2.issue_no,
      question_no: question2?.order_num || NaN,
      question_id: question2?.id || NaN,
      contents: {
        value: answerToQ2.value,
      },
    };

    // この設問以外の回答情報
    const otherAnswers = props.answers.filter(
      answer => !(answer.issue_no === currentIssueNo),
    );
    props.setAnswers([...otherAnswers, ...answersQ1, answerQ2]);
    props.setCommonAnswers([...answersQ1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersToQ1, answerToQ2]);

  useEffect(() => {
    const answersQ1 = state.active_learning_answers.filter(
      answer => answer.issue_no === 1 && answer.question_no === 1,
    );
    const initAnswersQ1: IAnswerToQ1[] =
      answersQ1.length > 0
        ? answersQ1.map(answer => ({
            id: String(answer.id),
            issue_no: answer.issue_no,
            question_no: answer.question_no,
            question_id: answer.question_id,
            value: answer.contents.value,
            stepNo: Number(answer.contents.stepNo),
          }))
        : [...Array(5)].map((_, i) => {
            return {
              id: `#${Math.random().toString(36).slice(-8)}`,
              issue_no: currentIssueNo,
              question_id: question?.id || NaN,
              question_no: question?.order_num || NaN,
              value: "",
              stepNo: i + 1,
            };
          });
    setAnswersToQ1([...initAnswersQ1]);

    const answerQ2 = state.active_learning_answers.find(
      answer =>
        answer.issue_no === currentIssueNo &&
        answer.question_no === question2?.order_num,
    );
    setAnswerToQ2({
      id: answerQ2?.id || null,
      issue_no: currentIssueNo,
      question_no: 1,
      question_id: answerQ2?.question_id || NaN,
      value: answerQ2?.contents?.value || "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.active_learning_answers]);

  const changeAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    const targetAnswer = answersToQ1.find(answer => answer.id === id);
    if (!targetAnswer) throw new Error(`Not found answer id: #{id}`);

    const newAnswer = {
      id: targetAnswer.id,
      issue_no: currentIssueNo,
      question_id: question?.id || NaN,
      question_no: question?.order_num || NaN,
      value,
      stepNo: targetAnswer.stepNo,
    };

    const updatedAnswers = answersToQ1.map(existingAnswer =>
      existingAnswer.id === targetAnswer.id ? newAnswer : existingAnswer,
    );

    !answersToQ1.some(
      existingAnswer => existingAnswer.id === targetAnswer.id,
    ) && updatedAnswers.push(newAnswer);

    setAnswersToQ1(updatedAnswers);
  };

  const changeAnswerQ2 = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setAnswerToQ2({
      id: answerToQ2.id,
      question_id: question?.id || NaN,
      question_no: question?.order_num || NaN,
      issue_no: currentIssueNo,
      value,
    });
  };

  const addAnswerField = (question: IActiveLearningQuestion) => {
    const oneTimeId = `#${Math.random().toString(36).slice(-8)}`;
    const unRemovedAnswers = answersToQ1.filter(answer => !answer._destroy);
    setAnswersToQ1([
      ...answersToQ1,
      {
        id: oneTimeId,
        issue_no: props.issue?.no || NaN,
        question_id: question.id,
        question_no: question.order_num,
        value: "",
        stepNo: unRemovedAnswers.length + 1,
      },
    ]);
  };

  const lastAnswer = useMemo(() => {
    const lessonNo = materialCode === "ECS001" ? 5 : 6;
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers = getActiveLearningAnswers(
      answersByMaterial,
      lessonNo,
      2,
      6,
    );

    return currentAnswers.map(result => result.value).join("");
  }, [state.active_learning_answers_by_material, materialCode]);

  const removeAnswer = (id: string) => {
    const targetAnswer = answersToQ1.find(answer => answer.id === id);
    if (!targetAnswer) return;
    const removedAnswers = answersToQ1.filter(
      answer => answer.id !== id && answer._destroy,
    );
    const unRemovedAnswers = answersToQ1
      .filter(answer => answer.id !== id && !answer._destroy)
      .sort((a, b) => a.stepNo - b.stepNo)
      .map((answer, index) => ({ ...answer, stepNo: index + 1 }));

    setAnswersToQ1([
      {
        ...targetAnswer,
        _destroy: true,
      },
      ...removedAnswers,
      ...unRemovedAnswers,
    ]);
  };

  const exchangeStepAnswers = (currentStepNo: number, targetStepNo: number) => {
    const findAnswerValue = (stepNo: number) =>
      answersToQ1.find(answer => !answer._destroy && answer.stepNo === stepNo)
        ?.value;

    const exchangeAnswerValue = (
      answer: IAnswerToQ1,
      exchangeValue: string,
    ) => ({
      ...answer,
      value: exchangeValue,
    });

    const currentValue = findAnswerValue(currentStepNo);
    const targetValue = findAnswerValue(targetStepNo);

    const exchangedAnswers = answersToQ1.map(answer => {
      switch (answer.stepNo) {
        case currentStepNo:
          return exchangeAnswerValue(answer, targetValue ?? answer.value);
        case targetStepNo:
          return exchangeAnswerValue(answer, currentValue ?? answer.value);
        default:
          return answer;
      }
    });

    setAnswersToQ1(exchangedAnswers);
  };

  const incrementStepAnswer = (currentStepNo: number) =>
    exchangeStepAnswers(currentStepNo, currentStepNo + 1);

  const decrementStepAnswer = (currentStepNo: number) =>
    exchangeStepAnswers(currentStepNo, currentStepNo - 1);

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          解決のアイデアを実現することをゴールとしたときに、ゴールに辿り着くためにはどのようなステップが必要かを考えよう。
          <br />
          ヒントを参考に、まずはやることを洗い出し、その優先度を決めて具体的なステップを設定しよう。
          <br />
          <br />
          操作：「項目を追加する」ボタンをクリックすると項目を増やすことができます。「削除」ボタンをクリックすると、項目を削除することができます。
        </p>
      </div>
      <div className={styles.hint}>
        <p className={styles.title}>計画を立てるヒント</p>
        <div className={clsx(styles.hintList, styles.isPc)}>
          {HintList.lesson7.issue1.hint1.map((hint, index) => {
            return (
              <HintCard
                key={index}
                title={hint.title}
                width={"calc((100% - 16px) / 2)"}
              >
                {hint.text}
              </HintCard>
            );
          })}
        </div>
        <div className={clsx(styles.hintList, styles.isSp)}>
          {HintList.lesson7.issue1.hint1.map((hint, index) => {
            return (
              <HintCard key={index} title={hint.title}>
                {hint.text}
              </HintCard>
            );
          })}
        </div>
      </div>
      <div className={styles.hint}>
        <p className={styles.title}>やることを考えるためのヒント</p>
        <div className={clsx(styles.hintList, styles.isPc)}>
          {HintList.lesson7.issue1.hint2.map((hint, index) => {
            return (
              <HintCard
                key={index}
                title={hint.title}
                width={"calc((100% - (16px * 2)) / 3)"}
              >
                {hint.text}
              </HintCard>
            );
          })}
        </div>
        <div className={clsx(styles.hintList, styles.isSp)}>
          {HintList.lesson7.issue1.hint2.map((hint, index) => {
            return (
              <HintCard key={index} title={hint.title}>
                {hint.text}
              </HintCard>
            );
          })}
        </div>
      </div>
      {question2 && (
        <div className={styles.themEntry}>
          <p className={styles.title}>{question2.title}</p>
          <div className={styles.textAreaWrapper}>
            <TextArea value={answerToQ2.value} handleChange={changeAnswerQ2} />
          </div>
        </div>
      )}
      {question && (
        <div className={styles.question}>
          <div className={styles.tree}>
            <TreeCard
              title={"実現したいと思う解決のアイデア"}
              text={lastAnswer || "回答データがありません。"}
            />
            <ul className={styles.branchList}>
              {answersToQ1
                .filter(answer => !answer._destroy)
                .sort((a, b) => b.stepNo - a.stepNo)
                .map((answer, index, array) => (
                  <li key={answer.id} className={styles.branch}>
                    <div className={styles.labelWrapper}>
                      <span className={styles.label}>
                        ステップ{answer.stepNo}
                      </span>
                    </div>
                    <div className={styles.inputField}>
                      <TextInput
                        id={answer.id}
                        value={answer.value}
                        width={"100%"}
                        handleChange={e => changeAnswer(e)}
                      />
                    </div>
                    <ExchangeStepAnswerButton
                      index={index}
                      length={array.length - 1}
                      incrementStepAnswer={() =>
                        incrementStepAnswer(answer.stepNo)
                      }
                      decrementStepAnswer={() =>
                        decrementStepAnswer(answer.stepNo)
                      }
                    />
                    <div className={styles.deleteButton}>
                      <Button
                        variant={"secondary"}
                        width={"80px"}
                        height={"37px"}
                        fontSize={"14px"}
                        handleClick={() => removeAnswer(answer.id)}
                      >
                        <IconDelete />
                        削除
                      </Button>
                    </div>
                  </li>
                ))}
            </ul>
            <div className={styles.buttonWrapper}>
              <Button
                width={"150px"}
                height={"37px"}
                fontSize={"14px"}
                handleClick={() => addAnswerField(question)}
              >
                <IconAdd />
                項目を追加する
              </Button>
            </div>
          </div>
        </div>
      )}
      <Card label={"TRY"} icon={<IconLive />} title={"グループワーク"}>
        ①同じテーマ同士でグループをつくろう。
        <br />
        ②実現したいと思う解決のアイデアと実現のためのステップを発表し合おう。
        <br />
        ③ステップをより具体的にするための話し合いをし、ステップを調整しよう。
      </Card>
    </div>
  );
};
export default Issue1;
