import React from "react";

import { IAnswerR, IAnswer } from "../../../state";

type Props = {
  answer: IAnswerR | IAnswer;
  calling?: boolean;
  replyAnswer: (content: string, answer_id: number) => void;
};

const FormComment: React.FC<Props> = ({ answer, replyAnswer, calling }) => {
  const [content, setContent] = React.useState<string>("");
  return (
    <form>
      <textarea
        value={content}
        rows={3}
        placeholder="コメントを書く"
        onChange={e => setContent(e.target.value)}
      />
      <div>
        <button
          disabled={calling || !content}
          onClick={e => {
            e.preventDefault();
            replyAnswer(content, answer.id);
            setContent("");
          }}
        >
          送信
        </button>
      </div>
    </form>
  );
};

export default FormComment;
