import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react";

import {
  IActiveLearningAnswers,
  IActiveLearningQuestion,
} from "../../../../state";
import { IAnswer } from "../../../../constants/active_learning";
import styles from "../AssessmentTable.module.scss";

interface IAuthenticityAnswer extends IAnswer {
  parentUid: string;
  parentLabel: string;
  authenticity: number;
}
interface IAuthenticitySelectorProps {
  activeLearningAnswers?: IActiveLearningAnswers[];
  question?: IActiveLearningQuestion;
  answers?: IAuthenticityAnswer[];
  setAnswers?: Dispatch<SetStateAction<IAuthenticityAnswer[]>>;
  selectedUid?: string;
  currentIssueNo?: number;
}
enum authenticityValues {
  Easy = 1,
  Normal = 2,
  Hard = 3,
}

export const AuthenticitySelector = (props: IAuthenticitySelectorProps) => {
  useEffect(() => {
    // Store側のデータを各ステートに同期
    const targetAnswers = props.activeLearningAnswers?.filter(
      answer =>
        answer.issue_no === props.currentIssueNo &&
        answer.question_no === props.question?.order_num,
    );

    const answersValue = targetAnswers?.map(answer => {
      return {
        id: answer?.id || null,
        issue_no: props.currentIssueNo || NaN,
        question_no: props.question?.order_num || NaN,
        question_id: answer?.question_id || NaN,
        parentUid: answer.contents?.parentUid,
        parentLabel: answer.contents?.parentLabel,
        authenticity: answer.contents?.authenticity | NaN,
      };
    });
    props.setAnswers && answersValue && props.setAnswers(answersValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeLearningAnswers, props.question]);

  const isCheckedBy = useCallback(
    (authenticityNo: number) => {
      const targetAnswer = props.answers?.find(
        answer =>
          answer.parentUid === props.selectedUid &&
          answer.question_no === props.question?.order_num,
      );
      return Number(targetAnswer?.authenticity) === authenticityNo;
    },
    [props.selectedUid, props.answers, props.question],
  );

  const getAuthenticityJa = (authenticityNo: number) => {
    const currentAuthenticity = authenticityValues[authenticityNo];
    switch (currentAuthenticity) {
      case "Easy":
        return "出来そう";
      case "Normal":
        return "工夫次第";
      case "Hard":
        return "難しそう";
      default:
        throw new Error(
          `Error: unauthorized arg. authenticityNo :${authenticityNo}`,
        );
    }
  };

  const setAuthenticityToAnswer = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const targetAnswer = props.answers?.find(
        answer =>
          answer.parentUid === props.selectedUid &&
          answer.question_no === props.question?.order_num,
      );
      const answersExcludingTarget = props.answers?.filter(
        answer => answer.parentUid !== targetAnswer?.parentUid,
      );
      const currentAnswer = targetAnswer
        ? {
            ...targetAnswer,
            authenticity: Number(value),
            parentLabel: getAuthenticityJa(Number(value)),
          }
        : {
            id: null,
            issue_no: props.currentIssueNo || NaN,
            question_no: props.question?.order_num || NaN,
            question_id: props.question?.id || NaN,
            parentUid: props.selectedUid || "",
            parentLabel: getAuthenticityJa(Number(value)),
            authenticity: Number(value),
          };

      props.setAnswers &&
        answersExcludingTarget &&
        props.setAnswers([currentAnswer, ...answersExcludingTarget]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.selectedUid, props.setAnswers, getAuthenticityJa],
  );

  return (
    <>
      {[
        authenticityValues.Easy,
        authenticityValues.Normal,
        authenticityValues.Hard,
      ].map(authenticityNo => (
        <td key={authenticityNo} className={styles.radio}>
          <label>
            <input
              type="radio"
              value={authenticityNo}
              checked={isCheckedBy(authenticityNo)}
              onChange={setAuthenticityToAnswer}
            />
            <div className={styles.mark}>
              <span className={styles.icon}>
                {getAuthenticityJa(authenticityNo) === "出来そう" && "😊"}
                {getAuthenticityJa(authenticityNo) === "工夫次第" && "🤔"}
                {getAuthenticityJa(authenticityNo) === "難しそう" && "😢"}
              </span>
              <span className={styles.text}>
                {getAuthenticityJa(authenticityNo)}
              </span>
            </div>
          </label>
        </td>
      ))}
    </>
  );
};
export default AuthenticitySelector;
