import React from "react";
import clsx from "clsx";

import styles from "./SaveAnswerButton.module.scss";

interface ISaveAnswerButtonProps {
  width?: string;
  height?: string;
  fontSize?: string;
  disabled?: boolean;
  handleClick?: () => void;
}

export const SaveAnswerButton: React.FC<ISaveAnswerButtonProps> = ({
  width = "272px",
  height = "58px",
  fontSize = "16px",
  disabled,
  handleClick,
}) => {
  return (
    <button
      className={clsx(styles.button, styles["secondary"])}
      style={{ width, height, fontSize }}
      onClick={handleClick}
      disabled={disabled}
    >
      回答を保存する
    </button>
  );
};

export default SaveAnswerButton;
