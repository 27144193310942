import React from "react";
import { useMemo, useCallback, useEffect, useContext, useState } from "react";

import {
  IActiveLearningAnswers,
  IActiveLearningIssue,
} from "../../../../state";
import { Context, updateActiveLearningAnswers } from "../../../../studentStore";
import { getActiveLearningAnswers } from "../../../../libs/ActiveLearning";
import TreeCard from "../../../../atoms/ActiveLearning/TreeCard";
import Header from "../../../../organisms/ActiveLearning/Header";
import EndLesson from "../../../../organisms/ActiveLearning/EndLesson";
import Issue1 from "../../../../organisms/student/ActiveLearningLessons/Lesson3/Issue1";
import Issue2 from "../../../../organisms/student/ActiveLearningLessons/Lesson3/Issue2";
import Issue3 from "../../../../organisms/student/ActiveLearningLessons/Lesson3/Issue3";
import styles from "../ActiveLearningLesson.module.scss";
import useWaitApiCall from "../../../../hooks/useWaitApiCall";
import SaveAnswerButton from "../../../../atoms/ActiveLearning/SaveAnswerButton";

export const Lesson3 = () => {
  const { state, dispatch } = useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const [answers, setAnswers] = useState<IActiveLearningAnswers[]>([]);

  useEffect(() => {
    setAnswers([...state.active_learning_answers]);
  }, [state.active_learning_answers]);

  const currentActiveLearnig = useMemo(() => {
    return state.active_learning_data_by_lesson;
  }, [state.active_learning_data_by_lesson]);

  const findIssue = useCallback(
    (no: number): IActiveLearningIssue | undefined =>
      currentActiveLearnig.issues?.find(issue => issue.no === no),
    [currentActiveLearnig],
  );

  const saveAnswers = () => {
    const params = answers;
    const { material_code, no } = state.active_learning_data_by_lesson;
    if (calling) return;
    return dispatch(
      peep(
        updateActiveLearningAnswers(material_code, no, JSON.stringify(params)),
      ),
    );
  };

  const lastAnswerQ3 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers = getActiveLearningAnswers(answersByMaterial, 2, 3, 1);

    return currentAnswers.map(result => result.value).join("");
  }, [state.active_learning_answers_by_material]);

  return (
    <div className={styles.wrapper} id="accordion">
      <Header
        no={currentActiveLearnig.no}
        label={"やりたいこと/もっと〇〇に気づく"}
        title={currentActiveLearnig.title}
        read={
          "設定した「問い」を様々な観点から具体的にして、もっと取り組みたいと思える「問い」にしよう。\nその「問い」の内容を実現させるときに、立ちはだかる問題を考えてみよう。"
        }
      />
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.review}>
            <p className={styles.title}>前回の振り返り</p>
            <TreeCard
              title={"取り組む問い"}
              text={lastAnswerQ3 || "回答データがありません。"}
            />
          </div>
        </div>
        <div className={styles.section}>
          <Issue1
            issue={findIssue(1)}
            answers={answers}
            setAnswers={setAnswers}
          />
        </div>
        <div className={styles.saveAnswer}>
          <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
        </div>
        <div className={styles.section}>
          <Issue2
            issue={findIssue(2)}
            answers={answers}
            setAnswers={setAnswers}
          />
        </div>
        <div className={styles.saveAnswer}>
          <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
        </div>
        <div className={styles.section}>
          <Issue3
            issue={findIssue(3)}
            answers={answers}
            setAnswers={setAnswers}
          />
        </div>
        <div className={styles.saveAnswer}>
          <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
        </div>
        <div className={styles.section}>
          <EndLesson
            text={
              "次のLessonでは、考えた問題の中から重要な問題を選び、その原因を深堀って「課題」を設定する。次回までに、どの問題を取り除いたらいいかイメージを膨らませておこう。"
            }
            saveAnswers={saveAnswers}
          />
        </div>
      </div>
    </div>
  );
};
export default Lesson3;
