import * as React from "react";
import { useHistory } from "react-router-dom";

import { parseQueryParams } from "../../../libs/Util";
import {
  Context,
  login,
  updateStudentPasswordPath,
} from "../../../studentStore";
import LoginForm from "../../../templates/student/LoginForm";
import useWaitApiCall from "../../../hooks/useWaitApiCall";

export default () => {
  const { dispatch, state } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const history = useHistory();
  const loginFunc = React.useCallback(
    (id: string, password: string) => {
      dispatch(peep(login(id, password)));
    },
    [dispatch, peep],
  );
  React.useEffect(() => {
    if (state.uuid) {
      history.push(`${updateStudentPasswordPath}/${state.uuid}`);
    }
  }, [history, state.uuid]);
  const [error, setError] = React.useState(parseQueryParams()["message"]);
  React.useEffect(() => {
    if (state.error) {
      setError(state.error);
    }
  }, [state.error]);

  return <LoginForm login={loginFunc} error={error} calling={calling} />;
};
