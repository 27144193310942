import * as React from "react";
import { detect } from "detect-browser";

import Icons from "../templates/student/Home/icons";

const Chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

export function delimitize(num: number) {
  return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

export function zeroPadding(num: number, padding = 2) {
  const paddingStr = Array.from(Array(padding), () => 0).join("");
  return (paddingStr + num.toString()).slice(-1 * padding);
}

export function randomStr(n: number) {
  let s = "";
  while (n > 0) {
    s += Chars[Math.floor(Math.random() * Chars.length)];
    n -= 1;
  }
  return s;
}

export function uniqueStr() {
  const now = new Date();
  return (
    now.getFullYear() +
    zeroPadding(now.getMonth() + 1) +
    zeroPadding(now.getDate()) +
    randomStr(6)
  );
}

export function thisSchoolYear() {
  return new Date().getMonth() < 3
    ? new Date().getFullYear() - 1
    : new Date().getFullYear();
}

export function strToTag(child: any) {
  if (typeof child !== "string") {
    return;
  }
  const ret: any = [];
  let str = "";
  child.split("").forEach((n: string, at: number) => {
    if (n === " ") {
      if (str !== "") {
        ret.push(<span key={`s_${at}`}>{str}</span>);
        str = "";
      }
      ret.push(<span key={`b_${at}`}>&nbsp;</span>);
    } else if (n === "\n") {
      if (str !== "") {
        ret.push(
          <span key={`s_${at}`}>
            {str}
            <br />
          </span>,
        );
        str = "";
      } else {
        ret.push(
          <span key={`b_${at}`}>
            <br />
          </span>,
        );
      }
    } else {
      str += n;
    }
  });
  if (str !== "") {
    ret.push(<span key={`s_last`}>{str}</span>);
  }
  return ret;
}

export function timeStr(date: Date | undefined | null) {
  if (!date) return "";
  return zeroPadding(date.getHours()) + ":" + zeroPadding(date.getMinutes());
}

export function addDate(date: Date, num: number) {
  return new Date(
    new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() +
      1000 * 60 * 60 * 24 * num,
  );
}

export function age(birthday: Date) {
  const today = new Date();
  let bias = 0;
  if (
    zeroPadding(today.getMonth()) + zeroPadding(today.getDate()) <
    zeroPadding(birthday.getMonth()) + zeroPadding(birthday.getDate())
  ) {
    bias = -1;
  }
  return today.getFullYear() - birthday.getFullYear() + bias;
}

export function convertUidToUuid(uid: string) {
  return (
    uid.slice(0, 8) +
    "-0000-4000-8000-" +
    uid
      .slice(8)
      .split("")
      .map(c => c.charCodeAt(0).toString(16))
      .join("")
  );
}

export function dateStr(date: Date | undefined | null, withoutYear?: boolean) {
  if (!date) return "";
  return (
    (withoutYear ? "" : date.getFullYear().toString() + "年") +
    (date.getMonth() + 1) +
    "月" +
    date.getDate() +
    "日"
  );
}

export function monthTimeStr(date: Date | undefined | null) {
  if (!date) return "";
  return date.getMonth() + 1 + "月" + date.getDate() + "日 " + timeStr(date);
}

export function dToS(date: Date) {
  return (
    date.getFullYear().toString() +
    "-" +
    zeroPadding(date.getMonth() + 1) +
    "-" +
    zeroPadding(date.getDate())
  );
}

export function formatTimeStr(date: Date) {
  return dToS(date) + "T" + timeStr(date) + "+09:00";
}

export function dayStr(date: Date | undefined | null) {
  if (!date) return "";
  return ["日", "月", "火", "水", "木", "金", "土"][date.getDay()];
}

export function dateTimeStr(
  date: Date | undefined | null,
  withoutYear?: boolean,
) {
  if (!date) return "";
  return dateStr(date, withoutYear) + "(" + dayStr(date) + ") " + timeStr(date);
}

export function simpleDateTimeStr(date: Date) {
  return dateStr(date, true) + timeStr(date);
}

export function buildDate(date: Date | string) {
  if (typeof date === "string") {
    return new Date(date.replace(" +0900", "+09:00").replace(" ", "T"));
  } else {
    return new Date(date);
  }
}

export function fetchIDFromPath() {
  const id = window.location.pathname.split("/").pop() as string;
  return Number(id);
}

export function parseQueryParams() {
  const result: { [key: string]: any } = {};
  if (!window.location.href.match(/\?/)) {
    return result;
  }
  const query = window.location.href.replace(/.*\?/, "").split("&");
  query.forEach(r => {
    const pair = r.split("=");
    const val = decodeURI(pair[1]);
    if (pair[0].match(/\[\]$/)) {
      const key = pair[0].replace("[]", "");
      if (!result[key]) {
        result[key] = [];
      }
      if (val !== "") {
        result[key].push(val);
      }
    } else {
      result[pair[0]] = val;
    }
  });
  return result;
}

/**
 * Check if two arrays are equal
 * @param  {Array}   arr1 The first array
 * @param  {Array}   arr2 The second array
 * @return {Array}   different element array
 */
function arraysMissMatch(arr1: any[], arr2: any[]) {
  const arr = [];
  // Check if all items exist and are in the same order
  for (let i = 0; i < arr1.length; i++) {
    const d = diff(arr1[i], arr2[i]);
    if (typeof d !== "object" || Object.keys(d).length > 0) {
      arr.push(d);
    }
  }
  if (arr1.length < arr2.length) {
    for (let i = arr1.length; i < arr2.length; i++) {
      arr.push(arr2[i]);
    }
  }
  // Otherwise, return true
  return arr;
}

/**
 * Compare two items and push non-matches to object
 * @param  {*}      item1 The first item
 * @param  {*}      item2 The second item
 * @param  {String} key   The key in our object
 */
function compare(item1: any, item2: any, key: string) {
  const type1 = Object.prototype.toString.call(item1);
  const type2 = Object.prototype.toString.call(item2);
  const diffs: { [key: string]: any } = {};

  if (type1 !== type2) {
    diffs[key] = item2;
    return diffs;
  }

  if (item1 instanceof Date && item2 instanceof Date) {
    if (item1.getTime() !== item2.getTime()) {
      diffs[key] = item2;
    }
    return diffs;
  }

  if (type1 === "[object Object]") {
    const objDiff = diff(item1, item2);
    if (typeof objDiff !== "object" || Object.keys(objDiff).length > 0) {
      diffs[key] = objDiff;
    }
    return diffs;
  }

  if (type1 === "[object Array]") {
    const missMatch = arraysMissMatch(item1, item2);
    if (missMatch.length > 0) {
      diffs[key] = missMatch;
    }
    return diffs;
  }

  if (type1 === "[object Function]") {
    if (item1.toString() !== item2.toString()) {
      diffs[key] = item2;
    }
    return diffs;
  }

  if (item1 !== item2) {
    diffs[key] = item2;
  }
  return diffs;
}

export function diff(obj1: any, obj2: any) {
  let diffs: { [key: string]: any } = {};
  let key: string;

  if (obj1 === obj2) {
    return diffs;
  }
  if (typeof obj1 === "number" && typeof obj2 === "number") {
    if (obj1 !== obj2) {
      return obj2;
    }
    return diffs;
  }
  if (typeof obj1 === "string" && typeof obj2 === "string") {
    if (obj1 !== obj2) {
      return obj2;
    }
    return diffs;
  }
  if (typeof obj1 === "undefined" || obj1 === null) {
    if (typeof obj2 === "undefined" || obj2 === null) {
      return diffs;
    }
    return obj2;
  }

  if (Object.prototype.toString.call(obj1) === "[object Function]") {
    if (obj1.toString() === obj2.toString()) {
      return diffs;
    }
    return obj2;
  }

  if (obj1 instanceof Date && obj2 instanceof Date) {
    if (obj1.getTime() !== obj2.getTime()) {
      return obj2;
    }
    return diffs;
  }

  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (obj2) {
        diffs = { ...diffs, ...compare(obj1[key], obj2[key], key) };
      } else {
        diffs[key] = undefined;
      }
    }
  }
  for (key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (!obj1.hasOwnProperty(key)) {
        diffs[key] = obj2[key];
      }
    }
  }
  return diffs;
}

export function stringToBuffer(src: string) {
  return new Uint16Array(
    [].map.call(src, (c: string) => c.charCodeAt(0)) as any,
  ).buffer;
}

export function bToS(buf: ArrayBufferLike) {
  return String.fromCharCode.apply("", new Uint16Array(buf) as any);
}

// ArrayBuffer から文字列への変換
export function bufferToString(buf: ArrayBufferLike) {
  const tmp = [] as string[];
  const len = 1024;
  for (let p = 0; p < buf.byteLength; p += len) {
    tmp.push(bToS(buf.slice(p, p + len)));
  }
  return tmp.join("");
}

// hash
const FNV_PRIME_32 = 0x1000193;
const FNV_OFFSET_32 = 0x811c9dc5;

/**
 * Creates FNV-1a hash from a json string
 */
function hash32Fnv1a(data: string) {
  const hash = data.split("").reduce((hash, _, index) => {
    hash ^= data.charCodeAt(index);
    hash *= FNV_PRIME_32;
    return hash;
  }, FNV_OFFSET_32);
  return hash.toString(16);
}

export const keyGenerator = (props: any = {}) =>
  hash32Fnv1a(JSON.stringify(props));

export function generateFullName(
  nameList: (string | undefined)[],
  separator = " ",
) {
  if (
    !Array.isArray(nameList) ||
    nameList.length === 0 ||
    (nameList.length === 2 && !nameList[0] && !nameList[1])
  ) {
    return "------";
  }
  return nameList.filter(Boolean).join(separator);
}

export function mapDataToArray(data: any[], rest: any): any[] {
  return data.map((f, index) => {
    return { ...f, index, ...rest };
  });
}

export function convertObjectToArray(items: any) {
  const result = [];
  for (const item in items) {
    result.push({ key: item, value: items[item] });
  }

  return result;
}

export function isMobile() {
  const browser = detect();

  switch (browser && browser.os) {
    case "iOS":
    case "Android OS":
    case "Chrome OS":
      return true;

    default:
      return false;
  }
}

export function setCookie(cname: string, cvalue: string, hours: number) {
  const d = new Date();
  d.setTime(d.getTime() + hours * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie =
    cname + "=" + cvalue + ";" + expires + ";domain=.enageed.net;path=/";
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name +
      "=;domain=.enageed.net;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function formatDate(date: Date | string, char = "-") {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join(char);
}

export function getListGradientColor(
  startHexColorCode: string,
  endHexColorCode: string,
  numItemOnList: number,
  ratioUnit: number,
) {
  const hex = (x: string | number | any[]) => {
    x = x.toString(16);
    return x.length === 1 ? `0${x}` : x;
  };
  return Array.from(Array(numItemOnList).keys()).map(idx => {
    const r = Math.ceil(
      parseInt(startHexColorCode.substring(0, 2), 16) * ratioUnit * idx +
        parseInt(endHexColorCode.substring(0, 2), 16) * (1 - ratioUnit * idx),
    );
    const g = Math.ceil(
      parseInt(startHexColorCode.substring(2, 4), 16) * ratioUnit * idx +
        parseInt(endHexColorCode.substring(2, 4), 16) * (1 - ratioUnit * idx),
    );
    const b = Math.ceil(
      parseInt(startHexColorCode.substring(4, 6), 16) * ratioUnit * idx +
        parseInt(endHexColorCode.substring(4, 6), 16) * (1 - ratioUnit * idx),
    );
    return hex(r) + hex(g) + hex(b);
  });
}

export const renderIconByIndex = (index: number) => {
  const Icon = Icons[index];
  return <Icon />;
};
