import * as React from "react";
import { addHours } from "date-fns";

import {
  IStudent,
  ILesson,
  ITeachingMaterial,
  IActiveLearningMaterial,
  IActiveLearningDataByLesson,
  IActiveLearningTheme,
  IActiveLearningStoryBoard,
  IActiveLearningAnswers,
  IActiveLearningAnswersByMaterial,
  IGroup,
  ITeachingMaterialAnswerSummary,
  IAnswerComment,
  INewAnswer,
  ISurveyAnswers,
  IPackage,
  ISurvey,
  SurveyAnswersUpdate,
  IIssueWithAnswers,
  IIssueR,
  IAnswerR,
  ILessonByTeachingMaterial,
  IDetailsSurvey,
  IStudentCountAnswers,
  IAssessment,
  IAssessmentPast,
  ILessonRetrospectiveScore,
  IAssessmentBulkUpdate,
} from "../state";

import * as MESSAGE from "./messages";

export const defaultPath = "/";
export const prefixPath = "/st";
export const homePath = `/home`;
export const loginPath = `/login`;
export const selectMaterialsPath = `/select_materials`;
export const listMaterialPath = `/list_materials`;
export const listActiveLearningPath = `/list_active_learning_materials`;
export const activeLearningLessonPath = `/active_learning_lessons`;
export const activeLearningStoryBookPath = `/active_learning_story_board`;
export const teachingMaterialPath = `/teaching_material`;
export const activeLearningProjectionPath = `/active_learning_projection`;
export const groupPath = `/groups`;
export const questionnairePath = `/questionnaire_list`;
export const questionnaireStatisticsPath = `/questionnaire_statistics`;
export const updateStudentPasswordPath = `/update_student_password`;
export const surveyPath = `/surveys`;
export const oecdSurveyQuestionPath = `/oecd_survey_questions`;
export const assessmentDescriptionPath = `/assessment_description`;
export const assessmentPath = `/assessment`;
export const myDataPath = `/data`;
export const notFoundPath = `/404`;

export const CLEAR_SUCCESS_MESSAGE = "CLEAR_SUCCESS_MESSAGE";
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const CLEAR_ACTIVE_LEARNING_ANSWERS = "CLEAR_ACTIVE_LEARNING_ANSWERS";
export const CLEAR_LESSON = "CLEAR_LESSON";
export const CLEAR_LESSON_SUCCESS = "CLEAR_LESSON_SUCCESS";
export const CLEAR_LESSON_FAILURE = "CLEAR_LESSON_FAILURE";
export const FETCH_COUNT_ANSWERS = "FETCH_COUNT_ANSWERS";
export const FETCH_COUNT_ANSWERS_SUCCESS = "FETCH_COUNT_ANSWERS_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const FETCH_INITIAL_DATA = "FETCH_INITIAL_DATA";
export const FETCH_INITIAL_DATA_SUCCESS = "FETCH_INITIAL_DATA_SUCCESS";
export const FETCH_INITIAL_DATA_FAILURE = "FETCH_INITIAL_DATA_FAILURE";
export const FETCH_ASSESSMENT = "FETCH_ASSESSMENT";
export const FETCH_ASSESSMENT_SUCCESS = "FETCH_ASSESSMENT_SUCCESS";
export const FETCH_ASSESSMENT_FAILURE = "FETCH_ASSESSMENT_FAILURE";
export const FETCH_ASSESSMENT_PAST = "FETCH_ASSESSMENT_PAST";
export const FETCH_ASSESSMENT_PAST_SUCCESS = "FETCH_ASSESSMENT_PAST_SUCCESS";
export const FETCH_ASSESSMENT_PAST_FAILURE = "FETCH_ASSESSMENT_PAST_FAILURE";
export const SUBMIT_EXPERIENCE = "SUBMIT_EXPERIENCE";
export const SUBMIT_EXPERIENCE_SUCCESS = "SUBMIT_EXPERIENCE_SUCCESS";
export const SUBMIT_EXPERIENCE_FAILURE = "SUBMIT_EXPERIENCE_FAILURE";
export const EDIT_EXPERIENCE_REASON = "EDIT_EXPERIENCE_REASON";
export const EDIT_EXPERIENCE_REASON_SUCCESS = "EDIT_EXPERIENCE_REASON_SUCCESS";
export const EDIT_EXPERIENCE_REASON_FAILURE = "EDIT_EXPERIENCE_REASON_FAILURE";
export const DELETE_EXPERIENCE_REASON = "DELETE_EXPERIENCE_REASON";
export const DELETE_EXPERIENCE_REASON_SUCCESS =
  "DELETE_EXPERIENCE_REASON_SUCCESS";
export const DELETE_EXPERIENCE_REASON_FAILURE =
  "DELETE_EXPERIENCE_REASON_FAILURE";
export const EDIT_NEXT_ACTION = "EDIT_NEXT_ACTION";
export const EDIT_NEXT_ACTION_SUCCESS = "EDIT_NEXT_ACTION_SUCCESS";
export const EDIT_NEXT_ACTION_FAILURE = "EDIT_NEXT_ACTION_FAILURE";
export const DELETE_NEXT_ACTION = "DELETE_NEXT_ACTION";
export const DELETE_NEXT_ACTION_SUCCESS = "DELETE_NEXT_ACTION_SUCCESS";
export const DELETE_NEXT_ACTION_FAILURE = "DELETE_NEXT_ACTION_FAILURE";
export const PARTICIPATE_GROUP = "PARTICIPATE_GROUP";
export const PARTICIPATE_GROUP_SUCCESS = "PARTICIPATE_GROUP_SUCCESS";
export const PARTICIPATE_GROUP_FAILURE = "PARTICIPATE_GROUP_FAILURE";
export const FETCH_SCHOOLS = "FETCH_SCHOOLS";
export const FETCH_TEACHING_MATERIALS = "FETCH_TEACHING_MATERIALS";
export const FETCH_TEACHING_MATERIALS_SUCCESS =
  "FETCH_TEACHING_MATERIALS_SUCCESS";
export const FETCH_TEACHING_MATERIALS_FAILURE =
  "FETCH_TEACHING_MATERIALS_FAILURE";
export const FETCH_ACTIVE_LEARNING_MATERIALS =
  "FETCH_ACTIVE_LEARNING_MATERIALS";
export const FETCH_ACTIVE_LEARNING_MATERIALS_SUCCESS =
  "FETCH_ACTIVE_LEARNING_MATERIALS_SUCCESS";
export const FETCH_ACTIVE_LEARNING_MATERIALS_FAILURE =
  "FETCH_ACTIVE_LEARNING_MATERIALS_FAILURE";
export const FETCH_ACTIVE_LEARNING_LESSON = "FETCH_ACTIVE_LEARNING_LESSON";
export const FETCH_ACTIVE_LEARNING_LESSON_SUCCESS =
  "FETCH_ACTIVE_LEARNING_LESSON_SUCCESS";
export const FETCH_ACTIVE_LEARNING_LESSON_FAILURE =
  "FETCH_ACTIVE_LEARNING_LESSON_FAILURE";
export const FETCH_ACTIVE_LEARNING_THEMES = "FETCH_ACTIVE_LEARNING_THEMES";
export const FETCH_ACTIVE_LEARNING_THEMES_SUCCESS =
  "FETCH_ACTIVE_LEARNING_THEMES_SUCCESS";
export const FETCH_ACTIVE_LEARNING_THEMES_FAILURE =
  "FETCH_ACTIVE_LEARNING_THEMES_FAILURE";
export const FETCH_ACTIVE_LEARNING_STORY_BOARD =
  "FETCH_ACTIVE_LEARNING_STORY_BOARD";
export const FETCH_ACTIVE_LEARNING_STORY_BOARD_SUCCESS =
  "FETCH_ACTIVE_LEARNING_STORY_BOARD_SUCCESS";
export const FETCH_ACTIVE_LEARNING_STORY_BOARD_FAILURE =
  "FETCH_ACTIVE_LEARNING_STORY_BOARD_FAILURE";
export const UPDATE_ACTIVE_LEARNING_ANSWERS = "UPDATE_ACTIVE_LEARNING_ANSWERS";
export const UPDATE_ACTIVE_LEARNING_ANSWERS_SUCCESS =
  "UPDATE_ACTIVE_LEARNING_ANSWERS_SUCCESS";
export const UPDATE_ACTIVE_LEARNING_ANSWERS_FAILURE =
  "UPDATE_ACTIVE_LEARNING_ANSWERS_FAILURE";
export const FETCH_ACTIVE_LEARNING_ANSWERS = "FETCH_ACTIVE_LEARNING_ANSWERS";
export const FETCH_ACTIVE_LEARNING_ANSWERS_SUCCESS =
  "FETCH_ACTIVE_LEARNING_ANSWERS_SUCCESS";
export const FETCH_ACTIVE_LEARNING_ANSWERS_FAILURE =
  "FETCH_ACTIVE_LEARNING_ANSWERS_FAILURE";
export const FETCH_ACTIVE_LEARNING_ANSWERS_BY_MATERIAL =
  "FETCH_ACTIVE_LEARNING_ANSWERS_BY_MATERIAL";
export const FETCH_ACTIVE_LEARNING_ANSWERS_BY_MATERIAL_SUCCESS =
  "FETCH_ACTIVE_LEARNING_ANSWERS_BY_MATERIAL_SUCCESS";
export const FETCH_ACTIVE_LEARNING_ANSWERS_BY_MATERIAL_FAILURE =
  "FETCH_ACTIVE_LEARNING_ANSWERS_BY_MATERIAL_FAILURE";
export const FETCH_LESSONS = "FETCH_LESSONS";
export const FETCH_LESSONS_SUCCESS = "FETCH_LESSONS_SUCCESS";
export const FETCH_LESSONS_FAILURE = "FETCH_LESSONS_FAILURE";
export const FETCH_ANSWERS = "FETCH_ANSWERS";
export const FETCH_ANSWERS_SUCCESS = "FETCH_ANSWERS_SUCCESS";
export const FETCH_ANSWERS_FAILURE = "FETCH_ANSWERS_FAILURE";
export const FETCH_MY_ANSWERS = "FETCH_MY_ANSWERS";
export const FETCH_MY_ANSWERS_SUCCESS = "FETCH_MY_ANSWERS_SUCCESS";
export const FETCH_MY_ANSWERS_FAILURE = "FETCH_MY_ANSWERS_FAILURE";
export const FETCH_ANSWER_SUMMARIES = "FETCH_ANSWER_SUMMARIES";
export const FETCH_ANSWER_SUMMARIES_SUCCESS = "FETCH_ANSWER_SUMMARIES_SUCCESS";
export const FETCH_ANSWER_SUMMARIES_FAILURE = "FETCH_ANSWER_SUMMARIES_FAILURE";
export const FETCH_LESSON = "FETCH_LESSON";
export const FETCH_LESSON_SUCCESS = "FETCH_LESSON_SUCCESS";
export const FETCH_LESSON_FAILURE = "FETCH_LESSON_FAILURE";
export const FETCH_STUDENTS = "FETCH_STUDENTS";
export const FETCH_STUDENTS_SUCCESS = "FETCH_STUDENTS_SUCCESS";
export const FETCH_STUDENTS_FAILURE = "FETCH_STUDENTS_FAILURE";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_FAILURE = "FETCH_GROUPS_FAILURE";
export const REGISTER_ANSWERS = "REGISTER_ANSWERS";
export const REGISTER_ANSWERS_SUCCESS = "REGISTER_ANSWERS_SUCCESS";
export const REGISTER_ANSWERS_FAILURE = "REGISTER_ANSWERS_FAILURE";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILURE = "CREATE_COMMENT_FAILURE";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILURE = "UPDATE_COMMENT_FAILURE";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";
export const SEND_LIKE = "SEND_LIKE";
export const SEND_LIKE_SUCCESS = "SEND_LIKE_SUCCESS";
export const SEND_LIKE_FAILURE = "SEND_LIKE_FAILURE";
export const CANCEL_LIKE = "CANCEL_LIKE";
export const CANCEL_LIKE_SUCCESS = "CANCEL_LIKE_SUCCESS";
export const CANCEL_LIKE_FAILURE = "CANCEL_LIKE_FAILURE";
export const UPDATE_STUDENT_PASSWORD = "UPDATE_STUDENT_PASSWORD";
export const UPDATE_STUDENT_PASSWORD_SUCCESS =
  "UPDATE_STUDENT_PASSWORD_SUCCESS";
export const UPDATE_STUDENT_PASSWORD_FAILURE =
  "UPDATE_STUDENT_PASSWORD_FAILURE";
export const FETCH_TEACHING_MATERIAL = "FETCH_TEACHING_MATERIAL";
export const FETCH_TEACHING_MATERIAL_SUCCESS =
  "FETCH_TEACHING_MATERIAL_SUCCESS";
export const FETCH_TEACHING_MATERIAL_FAILURE =
  "FETCH_TEACHING_MATERIAL_FAILURE";
export const FETCH_SURVEY_ANSWERS = "FETCH_SURVEY_ANSWERS";
export const FETCH_SURVEY_ANSWERS_SUCCESS = "FETCH_SURVEY_ANSWERS_SUCCESS";
export const FETCH_SURVEY_ANSWERS_FAILURE = "FETCH_SURVEY_ANSWERS_FAILURE";
export const FETCH_PACKAGES = "FETCH_PACKAGES";
export const FETCH_PACKAGES_SUCCESS = "FETCH_PACKAGES_SUCCESS";
export const FETCH_PACKAGES_FAILURE = "FETCH_PACKAGES_FAILURE";
export const FETCH_SURVEY_DATA = "FETCH_SURVEY_DATA";
export const FETCH_SURVEY_DATA_SUCCESS = "FETCH_SURVEY_DATA_SUCCESS";
export const FETCH_SURVEY_DATA_FAILURE = "FETCH_SURVEY_DATA_FAILURE";
export const UPDATE_SURVEY_ANSWERS = "UPDATE_SURVEY_ANSWERS";
export const UPDATE_SURVEY_ANSWERS_SUCCESS = "UPDATE_SURVEY_ANSWERS_SUCCESS";
export const UPDATE_SURVEY_ANSWERS_FAILURE = "UPDATE_SURVEY_ANSWERS_FAILURE";
export const POST_SURVEY_ANSWERS = "POST_SURVEY_ANSWERS";
export const POST_SURVEY_ANSWERS_SUCCESS = "POST_SURVEY_ANSWERS_SUCCESS";
export const POST_SURVEY_ANSWERS_FAILURE = "POST_SURVEY_ANSWERS_FAILURE";
export const POST_OECD_SURVEY_ANSWERS = "POST_OECD_SURVEY_ANSWERS";
export const POST_OECD_SURVEY_ANSWERS_SUCCESS =
  "POST_OECD_SURVEY_ANSWERS_SUCCESS";
export const POST_EVALUATE_LESSON = "POST_EVALUATE_LESSON";
export const POST_EVALUATE_LESSON_SUCCESS = "POST_EVALUATE_LESSON_SUCCESS";
export const FETCH_ANSWERS_STATISTIC = "FETCH_ANSWERS_STATISTIC";
export const FETCH_ANSWERS_STATISTIC_SUCCESS =
  "FETCH_ANSWERS_STATISTIC_SUCCESS";
export const FETCH_ANSWERS_STATISTIC_FAILURE =
  "FETCH_ANSWERS_STATISTIC_FAILURE";
export const FETCH_SURVEY_ANSWERS_STATISTIC = "FETCH_SURVEY_ANSWERS_STATISTIC";
export const FETCH_SURVEY_ANSWERS_STATISTIC_FAILURE =
  "FETCH_SURVEY_ANSWERS_STATISTIC_FAILURE";
export const FETCH_SURVEY_ANSWERS_STATISTIC_SUCCESS =
  "FETCH_SURVEY_ANSWERS_STATISTIC_SUCCESS";
export const UPDATE_ASSESSMENT_LAST_ACTIVE = "UPDATE_ASSESSMENT_LAST_ACTIVE";
export const UPDATE_ASSESSMENT_LAST_ACTIVE_SUCCESS =
  "UPDATE_ASSESSMENT_LAST_ACTIVE_SUCCESS";

export const LOGOUT_SUCCESS_MESSAGE = "ログアウトしました。";

export const ACTION_503 = "ACTION_503";
export const ACTION_503_MESSAGE = "サービスが利用できません";

export const SET_CURRENT_ASSESSMENT_MESSAGE = "SET_CURRENT_ASSESSMENT_MESSAGE";

export const API_URL = `${
  process.env.REACT_APP_API_URL ??
  window.location.protocol + "//" + window.location.host
}/api/v1/student`;

export const login = (identity: string, password: string) => ({
  types: LOGIN as typeof LOGIN,
  payload: {
    request: {
      data: {
        identity,
        password,
      },
      method: "post",
      url: `${API_URL}/login`,
    },
  },
});

export const logout = () => ({
  types: LOGOUT as typeof LOGOUT,
  payload: {
    request: {
      data: {},
      method: "delete",
      url: `${API_URL}/logout`,
    },
  },
});

export const fetchInitialData = () => ({
  types: FETCH_INITIAL_DATA as typeof FETCH_INITIAL_DATA,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/students/initial_data`,
    },
  },
});

export const fetchAssessment = () => ({
  types: FETCH_ASSESSMENT as typeof FETCH_ASSESSMENT,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/assessments`,
    },
  },
});

export const fetchAssessmentPast = () => ({
  types: FETCH_ASSESSMENT_PAST as typeof FETCH_ASSESSMENT_PAST,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/assessments/in_past`,
    },
  },
});

export const updateStudentPassword = (uuid: string, password: string) => ({
  types: UPDATE_STUDENT_PASSWORD as typeof UPDATE_STUDENT_PASSWORD,
  payload: {
    request: {
      data: {
        uuid,
        password,
      },
      method: "put",
      url: `${API_URL}/passwords`,
    },
  },
});

export const fetchCountAnswers = () => ({
  types: FETCH_COUNT_ANSWERS as typeof FETCH_COUNT_ANSWERS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/answers/count_answers`,
    },
  },
});

export const fetchTeachingMaterial = (id: number) => {
  return {
    types: FETCH_TEACHING_MATERIAL as typeof FETCH_TEACHING_MATERIAL,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/teaching_materials/${id}`,
      },
    },
  };
};

export const fetchTeachingMaterials = () => ({
  types: FETCH_TEACHING_MATERIALS as typeof FETCH_TEACHING_MATERIALS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/teaching_materials`,
    },
  },
});

export const fetchActiveLearningMaterials = () => ({
  types: FETCH_ACTIVE_LEARNING_MATERIALS as typeof FETCH_ACTIVE_LEARNING_MATERIALS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/active_learning_materials`,
    },
  },
});

export const fetchActiveLearningLesson = (id: number, code: string) => ({
  types: FETCH_ACTIVE_LEARNING_LESSON as typeof FETCH_ACTIVE_LEARNING_LESSON,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/active_learning_materials/${code}/active_learning_lessons/${id}`,
    },
  },
});

export const fetchActiveLearningThemes = () => ({
  types: FETCH_ACTIVE_LEARNING_THEMES as typeof FETCH_ACTIVE_LEARNING_THEMES,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/active_learning_themes`,
    },
  },
});

export const fetchActiveLearningStoryBoard = (id: number) => ({
  types: FETCH_ACTIVE_LEARNING_STORY_BOARD as typeof FETCH_ACTIVE_LEARNING_STORY_BOARD,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/active_learning_themes/${id}/story_board`,
    },
  },
});

export const fetchActiveLearningAnswers = (
  active_learning_material_code: string,
  active_learning_lesson_no: number,
) => {
  return {
    types: FETCH_ACTIVE_LEARNING_ANSWERS as typeof FETCH_ACTIVE_LEARNING_ANSWERS,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/active_learning_materials/${active_learning_material_code}/active_learning_lessons/${active_learning_lesson_no}/answers`,
      },
    },
  };
};

export const fetchActiveLearningAnswersByMaterial = (
  active_learning_material_code: string,
) => {
  return {
    types: FETCH_ACTIVE_LEARNING_ANSWERS_BY_MATERIAL as typeof FETCH_ACTIVE_LEARNING_ANSWERS_BY_MATERIAL,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/active_learning_materials/${active_learning_material_code}/active_learning_answers`,
      },
    },
  };
};

export const updateActiveLearningAnswers = (
  active_learning_material_code: string,
  active_learning_lesson_no: number,
  content: string,
) => {
  return {
    types: UPDATE_ACTIVE_LEARNING_ANSWERS as typeof UPDATE_ACTIVE_LEARNING_ANSWERS,
    payload: {
      request: {
        data: { content },
        method: "put",
        url: `${API_URL}/active_learning_materials/${active_learning_material_code}/active_learning_lessons/${active_learning_lesson_no}/update_answers`,
      },
    },
  };
};

export const clearActiveLearningAnswers = () => ({
  types: CLEAR_ACTIVE_LEARNING_ANSWERS as typeof CLEAR_ACTIVE_LEARNING_ANSWERS,
  payload: {},
});

export const fetchLessons = () => ({
  types: FETCH_LESSONS as typeof FETCH_LESSONS,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/lessons`,
    },
  },
});

export const fetchLesson = (id: number) => ({
  types: FETCH_LESSON as typeof FETCH_LESSON,
  payload: {
    request: {
      data: {},
      method: "get",
      url: `${API_URL}/lessons/${id}`,
    },
  },
});

export const fetchStudents = () => ({
  types: FETCH_STUDENTS as typeof FETCH_STUDENTS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/students`,
    },
  },
});

export const fetchGroups = () => ({
  types: FETCH_GROUPS as typeof FETCH_GROUPS,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/groups`,
    },
  },
});

export const fetchAnswersStatistic = (teaching_material_id: number) => ({
  types: FETCH_ANSWERS_STATISTIC as typeof FETCH_ANSWERS_STATISTIC,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/teaching_materials/${teaching_material_id}/answers_statistic`,
    },
  },
});

export const fetchSurveyAnswersStatistic = (package_id: number) => ({
  types: FETCH_SURVEY_ANSWERS_STATISTIC as typeof FETCH_SURVEY_ANSWERS_STATISTIC,
  payload: {
    request: {
      method: "get",
      url: `${API_URL}/packages/${package_id}/survey_answers_statistic`,
    },
  },
});

export const participateGroup = (group_id: number) => {
  return {
    types: PARTICIPATE_GROUP as typeof PARTICIPATE_GROUP,
    payload: {
      request: {
        data: {},
        method: "post",
        url: `${API_URL}/groups/${group_id}/participate`,
      },
    },
  };
};

export const registerAnswers = (issue_id: number, answers: INewAnswer[]) => {
  return {
    types: REGISTER_ANSWERS as typeof REGISTER_ANSWERS,
    payload: {
      request: {
        data: { answers },
        method: "POST",
        url: `${API_URL}/issues/${issue_id}/answer`,
      },
    },
  };
};

export const submitExperience = (data: IAssessmentBulkUpdate) => {
  return {
    types: SUBMIT_EXPERIENCE as typeof SUBMIT_EXPERIENCE,
    payload: {
      request: {
        data,
        method: "PUT",
        url: `${API_URL}/assessments/bulk_update`,
      },
    },
  };
};

export const editExperienceReason = (id: number, reason: string) => {
  return {
    types: EDIT_EXPERIENCE_REASON as typeof EDIT_EXPERIENCE_REASON,
    payload: {
      request: {
        data: { reason },
        method: "PUT",
        url: `${API_URL}/assessment_reasons/${id}`,
      },
    },
  };
};

export const deleteExperienceReason = (id: number) => {
  return {
    types: DELETE_EXPERIENCE_REASON as typeof DELETE_EXPERIENCE_REASON,
    payload: {
      request: {
        data: {},
        method: "delete",
        url: `${API_URL}/assessment_reasons/${id}`,
      },
    },
  };
};

export const editNextAction = (id: number, next_action: string) => {
  return {
    types: EDIT_NEXT_ACTION as typeof EDIT_NEXT_ACTION,
    payload: {
      request: {
        data: {
          next_action,
        },
        method: "PUT",
        url: `${API_URL}/assessment_next_actions/${id}`,
      },
    },
  };
};

export const deleteNextAction = (id: number) => {
  return {
    types: DELETE_NEXT_ACTION as typeof DELETE_NEXT_ACTION,
    payload: {
      request: {
        data: {},
        method: "delete",
        url: `${API_URL}/assessment_next_actions/${id}`,
      },
    },
  };
};

export const createComment = (answer_id: number, content: string) => {
  return {
    types: CREATE_COMMENT as typeof CREATE_COMMENT,
    payload: {
      request: {
        data: { content },
        method: "post",
        url: `${API_URL}/answers/${answer_id}/answer_comments`,
      },
    },
  };
};

export const updateComment = (
  answer_id: number,
  content: string,
  comment_id: number,
) => {
  return {
    types: UPDATE_COMMENT as typeof UPDATE_COMMENT,
    payload: {
      request: {
        data: { content },
        method: "put",
        url: `${API_URL}/answers/${answer_id}/answer_comments/${comment_id}`,
      },
    },
  };
};

export const deleteComment = (answer_id: number, comment_id: number) => {
  return {
    types: DELETE_COMMENT as typeof DELETE_COMMENT,
    payload: {
      request: {
        data: {},
        method: "delete",
        url: `${API_URL}/answers/${answer_id}/answer_comments/${comment_id}`,
      },
    },
  };
};

export const sendLike = (answer_id: number) => {
  return {
    types: SEND_LIKE as typeof SEND_LIKE,
    payload: {
      request: {
        data: {},
        method: "post",
        url: `${API_URL}/answers/${answer_id}/like`,
      },
    },
  };
};

export const cancelLike = (answer_id: number) => {
  return {
    types: CANCEL_LIKE as typeof CANCEL_LIKE,
    payload: {
      request: {
        data: {},
        method: "post",
        url: `${API_URL}/answers/${answer_id}/unlike`,
      },
    },
  };
};

export const fetchAnswers = (issue_id: number) => {
  return {
    types: FETCH_ANSWERS as typeof FETCH_ANSWERS,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/issues/${issue_id}/answers`,
      },
    },
  };
};

export const fetchAnswerSummaries = () => {
  return {
    types: FETCH_ANSWER_SUMMARIES as typeof FETCH_ANSWER_SUMMARIES,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/answers`,
      },
    },
  };
};

export const clearSuccessMessage = () => ({
  types: CLEAR_SUCCESS_MESSAGE as typeof CLEAR_SUCCESS_MESSAGE,
  payload: {},
});

export const clearErrorMessage = () => ({
  types: CLEAR_ERROR_MESSAGE as typeof CLEAR_ERROR_MESSAGE,
  payload: {},
});

export const fetchMyAnswers = (issue_id: number) => {
  return {
    types: FETCH_MY_ANSWERS as typeof FETCH_MY_ANSWERS,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/issues/${issue_id}/my_answers`,
      },
    },
  };
};

export const fetchSurveyAnswers = (teaching_material_id: number) => {
  return {
    types: FETCH_SURVEY_ANSWERS as typeof FETCH_SURVEY_ANSWERS,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/survey_answers/${teaching_material_id}`,
      },
    },
  };
};
export const fetchSurveyData = (teaching_material_id: number) => {
  return {
    types: FETCH_SURVEY_DATA as typeof FETCH_SURVEY_DATA,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/surveys/${teaching_material_id}`,
      },
    },
  };
};

export const fetchPackages = () => {
  return {
    types: FETCH_PACKAGES as typeof FETCH_PACKAGES,
    payload: {
      request: {
        method: "get",
        url: `${API_URL}/packages`,
      },
    },
  };
};

export const postSurveyAnswers = (
  is_pre_answer: boolean,
  student_survey_answers: SurveyAnswersUpdate[],
) => ({
  types: POST_SURVEY_ANSWERS as typeof POST_SURVEY_ANSWERS,
  payload: {
    request: {
      data: {
        is_pre_answer,
        student_survey_answers: JSON.stringify(student_survey_answers),
      },
      method: "POST",
      url: `${API_URL}/survey_answers`,
    },
  },
});

export const postOecdSurveyAnswers = (student_survey_answers: any) => ({
  types: POST_OECD_SURVEY_ANSWERS as typeof POST_OECD_SURVEY_ANSWERS,
  payload: {
    request: {
      data: {
        student_survey_answers: JSON.stringify(student_survey_answers),
      },
      method: "POST",
      url: `${API_URL}/oecd_survey_answers`,
    },
  },
});

export const updateSurveyAnswers = (
  is_pre_answer: boolean,
  student_survey_answers: SurveyAnswersUpdate[],
) => ({
  types: UPDATE_SURVEY_ANSWERS as typeof UPDATE_SURVEY_ANSWERS,
  payload: {
    request: {
      data: {
        is_pre_answer,
        student_survey_answers: JSON.stringify(student_survey_answers),
      },
      method: "put",
      url: `${API_URL}/survey_answers`,
    },
  },
});

export const postEvaluateLesson = (
  student_lesson_retrospective: ILessonRetrospectiveScore,
  id_lesson: number,
) => ({
  types: POST_EVALUATE_LESSON as typeof POST_EVALUATE_LESSON,
  payload: {
    request: {
      data: {
        student_lesson_retrospective,
      },
      method: "POST",
      url: `${API_URL}/lessons/${id_lesson}/evaluate`,
    },
  },
});

export const updateAssessmentLastActive = () => ({
  types: UPDATE_ASSESSMENT_LAST_ACTIVE as typeof UPDATE_ASSESSMENT_LAST_ACTIVE,
  payload: {
    request: {
      data: {},
      method: "put",
      url: `${API_URL}/students/update_assessment_last_active_date`,
    },
  },
});

export const setCurrentAssessment = (assessment: IAssessmentPast) => ({
  types: SET_CURRENT_ASSESSMENT_MESSAGE as typeof SET_CURRENT_ASSESSMENT_MESSAGE,
  payload: { assessment },
});

type fetchSurveyDataSuccess = {
  types: typeof FETCH_SURVEY_DATA_SUCCESS;
  payload: {
    data: {
      surveys: ISurvey[];
    };
  };
};

type fetchAssessmentSuccess = {
  types: typeof FETCH_ASSESSMENT_SUCCESS;
  payload: {
    data: {
      assessment: IAssessment;
    };
  };
};

type fetchAssessmentPastSuccess = {
  types: typeof FETCH_ASSESSMENT_PAST_SUCCESS;
  payload: {
    data: {
      assessments: IAssessmentPast[];
    };
  };
};

type fetchAnswersStatisticSuccess = {
  types: typeof FETCH_ANSWERS_STATISTIC_SUCCESS;
  payload: {
    data: {
      data: {
        teaching_material: {
          id: number;
          name: string;
          title: string;
          lessons: ILessonByTeachingMaterial[];
        };
      };
    };
  };
};

type fetchSurveyAnswersStatisticSuccess = {
  types: typeof FETCH_SURVEY_ANSWERS_STATISTIC_SUCCESS;
  payload: {
    data: {
      data: {
        package: {
          teaching_materials: IDetailsSurvey[];
        };
      };
    };
  };
};

type fetchSurveyDataFailure = {
  types: typeof FETCH_SURVEY_DATA_FAILURE;
  payload: {
    error: {
      message: string;
      field: string;
    };
  };
};

type updateSurveyAnswersSuccess = {
  types: typeof UPDATE_SURVEY_ANSWERS_SUCCESS;
  payload: {
    data: {
      message: string;
    };
  };
};

type postSurveyAnswersSuccess = {
  types: typeof POST_SURVEY_ANSWERS_SUCCESS;
  payload: {
    data: {
      message: string;
    };
  };
};

type updateSurveyAnswersFailure = {
  types: typeof UPDATE_SURVEY_ANSWERS_FAILURE;
  payload: {
    data: {
      message: string;
    };
  };
};

type postSurveyAnswersFailure = {
  types: typeof POST_SURVEY_ANSWERS_FAILURE;
  payload: {
    data: {
      message: string;
    };
  };
};

type postOecdSurveyAnswersSuccess = {
  types: typeof POST_OECD_SURVEY_ANSWERS_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type loginSuccess = {
  types: typeof LOGIN_SUCCESS;
  payload: {
    data: {
      uuid?: string;
      session_id?: string;
      student?: IStudent;
      only_assessment: boolean;
    };
  };
};

type loginFailure = {
  types: typeof LOGIN_FAILURE;
  payload: {
    error: {
      message: string;
      field: string;
      status: number;
    };
  };
};

type logoutSuccess = {
  types: typeof LOGOUT_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type fetchTeachingMaterialSuccess = {
  types: typeof FETCH_TEACHING_MATERIAL_SUCCESS;
  payload: {
    data: {
      data: {
        teaching_material: ITeachingMaterial;
      };
    };
  };
};

type fetchInitialDataSuccess = {
  types: typeof FETCH_INITIAL_DATA_SUCCESS;
  payload: {
    data: {
      class_name: string;
      student: IStudent;
      survey_enabled: boolean;
      oecd_survey_enabled: boolean;
      only_assessment: boolean;
      g4s_basic_enabled: boolean;
      is_dummy: boolean;
      available_item_codes: string[];
      assessment_last_active_date: string | null;
    };
  };
};

type updateStudentPasswordSuccess = {
  types: typeof UPDATE_STUDENT_PASSWORD_SUCCESS;
  payload: {
    data: Record<string, unknown>;
  };
};

type fetchTeachingMaterialsSuccess = {
  types: typeof FETCH_TEACHING_MATERIALS_SUCCESS;
  payload: {
    data: {
      data: {
        teaching_materials: ITeachingMaterial[];
      };
    };
  };
};

type fetchActiveLearningMaterialsSuccess = {
  types: typeof FETCH_ACTIVE_LEARNING_MATERIALS_SUCCESS;
  payload: {
    data: {
      active_learning_materials: IActiveLearningMaterial[];
    };
  };
};

type fetchActiveLearningLessonSuccess = {
  types: typeof FETCH_ACTIVE_LEARNING_LESSON_SUCCESS;
  payload: {
    data: {
      data: IActiveLearningDataByLesson;
    };
  };
};

type fetchActiveLearningThemesSuccess = {
  types: typeof FETCH_ACTIVE_LEARNING_THEMES_SUCCESS;
  payload: {
    data: {
      data: IActiveLearningTheme[];
    };
  };
};

type fetchActiveLearningStoryBoardSuccess = {
  types: typeof FETCH_ACTIVE_LEARNING_STORY_BOARD_SUCCESS;
  payload: {
    data: {
      data: IActiveLearningStoryBoard;
    };
  };
};

type fetchActiveLearningAnswersSuccess = {
  types: typeof FETCH_ACTIVE_LEARNING_ANSWERS_SUCCESS;
  payload: {
    data: {
      data: IActiveLearningAnswers[];
    };
  };
};

type fetchActiveLearningAnswersByMaterialSuccess = {
  types: typeof FETCH_ACTIVE_LEARNING_ANSWERS_BY_MATERIAL_SUCCESS;
  payload: {
    data: {
      data: IActiveLearningAnswersByMaterial[];
    };
  };
};

type updateActiveLearningAnswersSuccess = {
  types: typeof UPDATE_ACTIVE_LEARNING_ANSWERS_SUCCESS;
  payload: {
    data: {
      data: IActiveLearningAnswers[];
    };
  };
};

type fetchLessonsSuccess = {
  types: typeof FETCH_LESSONS_SUCCESS;
  payload: {
    data: {
      data: {
        lessons: ILesson[];
      };
    };
  };
};

type fetchLessonSuccess = {
  types: typeof FETCH_LESSON_SUCCESS;
  payload: {
    data: {
      data: {
        lesson: ILesson;
      };
      meta: {
        next_lesson_id: number | null;
        prev_lesson_id: number | null;
      };
    };
  };
};

type fetchInitialDataFailure = {
  types: typeof FETCH_INITIAL_DATA_FAILURE;
  payload: {
    error: {
      message: string;
      field: string;
    };
  };
};

type participateGroupSuccess = {
  types: typeof PARTICIPATE_GROUP_SUCCESS;
  payload: {
    params: { url: string };
    data: Record<string, unknown>;
  };
};

type registerAnswersSuccess = {
  types: typeof REGISTER_ANSWERS_SUCCESS;
  payload: {
    data: {
      message: string;
      answers: INewAnswer[];
    };
  };
};

type registerAnswersFailure = {
  types: typeof REGISTER_ANSWERS_FAILURE;
  payload: {
    error: {
      message: string;
      field: string;
    };
  };
};

type submitExperienceSuccess = {
  types: typeof SUBMIT_EXPERIENCE_SUCCESS;
  payload: {
    data: {
      assessment: IAssessment;
    };
  };
};

type editExperienceReasonSuccess = {
  types: typeof EDIT_EXPERIENCE_REASON_SUCCESS;
  payload: {
    data: {
      assessment_reason: {
        id: number;
        experience_no: number;
        assessment_reason: string;
      };
    };
  };
};
type deleteExperienceReasonSuccess = {
  types: typeof DELETE_EXPERIENCE_REASON_SUCCESS;
  payload: {
    data: {
      id: number;
    };
  };
};
type editNextActionSuccess = {
  types: typeof EDIT_NEXT_ACTION_SUCCESS;
  payload: {
    data: {
      assessment_next_action: {
        id: number;
        next_action: string;
      };
    };
  };
};
type deleteNextActionSuccess = {
  types: typeof DELETE_NEXT_ACTION_SUCCESS;
  payload: {
    data: {
      id: number;
    };
  };
};

type createCommentSuccess = {
  types: typeof CREATE_COMMENT_SUCCESS;
  payload: {
    data: {
      data: {
        answer_comment: IAnswerComment;
        message: string;
      };
    };
  };
};

type updateCommentSuccess = {
  types: typeof UPDATE_COMMENT_SUCCESS;
  payload: {
    data: {
      data: {
        answer_comment: IAnswerComment;
        message: string;
      };
    };
  };
};

type deleteCommentSuccess = {
  types: typeof DELETE_COMMENT_SUCCESS;
  payload: {
    params: { url: string };
    data: { message: string };
  };
};

type sendLikeSuccess = {
  types: typeof SEND_LIKE_SUCCESS;
  payload: {
    data: {
      answer_id: number;
    };
  };
};

type cancelLikeSuccess = {
  types: typeof CANCEL_LIKE_SUCCESS;
  payload: {
    data: {
      answer_id: number;
    };
  };
};

type fetchStudentsSuccess = {
  types: typeof FETCH_STUDENTS_SUCCESS;
  payload: {
    data: {
      students: IStudent[];
    };
  };
};

type fetchGroupsSuccess = {
  types: typeof FETCH_GROUPS_SUCCESS;
  payload: {
    data: {
      groups: IGroup[];
    };
  };
};

type fetchAnswersSuccess = {
  types: typeof FETCH_ANSWERS_SUCCESS;
  payload: {
    data: {
      data: {
        issue: IIssueWithAnswers;
      };
    };
  };
};

type fetchAnswerSummariesSuccess = {
  types: typeof FETCH_ANSWER_SUMMARIES_SUCCESS;
  payload: {
    data: {
      data: {
        teaching_materials: ITeachingMaterialAnswerSummary[];
      };
    };
  };
};

type fetchCountAnswersSuccess = {
  types: typeof FETCH_COUNT_ANSWERS_SUCCESS;
  payload: {
    data: {
      data: {
        student: IStudentCountAnswers;
      };
    };
  };
};

type fetchMyAnswersSuccess = {
  types: typeof FETCH_MY_ANSWERS_SUCCESS;
  payload: {
    params: { url: string };
    data: {
      answers: INewAnswer[];
    };
  };
};

type fetchSurveyAnswersSuccess = {
  types: typeof FETCH_SURVEY_ANSWERS_SUCCESS;
  payload: {
    params: { url: string };
    data: {
      has_post_answers: boolean;
      has_pre_answers: boolean;
      post_answers: any[];
      pre_answers: any[];
      survey_answers: ISurveyAnswers[];
      survey_answers_per_teaching_material: ISurveyAnswers[];
    };
  };
};

type fetchPackagesSuccess = {
  types: typeof FETCH_PACKAGES_SUCCESS;
  payload: {
    params: { url: string };
    data: {
      data: {
        packages: IPackage[];
      };
    };
  };
};

type postEvaluateLessonSuccess = {
  types: typeof POST_EVALUATE_LESSON_SUCCESS;
  payload: {
    data: {
      student_lesson_retrospective: ILessonRetrospectiveScore;
    };
    params: ILessonRetrospectiveScore;
  };
};

type updateAssessmentLastActiveSuccess = {
  types: typeof UPDATE_ASSESSMENT_LAST_ACTIVE_SUCCESS;
  payload: {
    data: {
      assessment_last_active_date: string | null;
    };
  };
};

type action503 = {
  types: typeof ACTION_503;
};

export type Actions =
  | ReturnType<typeof login>
  | ReturnType<typeof logout>
  | ReturnType<typeof fetchInitialData>
  | ReturnType<typeof fetchTeachingMaterials>
  | ReturnType<typeof fetchActiveLearningMaterials>
  | ReturnType<typeof fetchActiveLearningLesson>
  | ReturnType<typeof fetchActiveLearningThemes>
  | ReturnType<typeof fetchActiveLearningStoryBoard>
  | ReturnType<typeof fetchActiveLearningAnswers>
  | ReturnType<typeof fetchActiveLearningAnswersByMaterial>
  | ReturnType<typeof updateActiveLearningAnswers>
  | ReturnType<typeof clearActiveLearningAnswers>
  | ReturnType<typeof fetchTeachingMaterial>
  | ReturnType<typeof fetchLessons>
  | ReturnType<typeof fetchLesson>
  | ReturnType<typeof fetchStudents>
  | ReturnType<typeof fetchGroups>
  | ReturnType<typeof participateGroup>
  | ReturnType<typeof registerAnswers>
  | ReturnType<typeof fetchAnswers>
  | ReturnType<typeof fetchMyAnswers>
  | ReturnType<typeof fetchAnswerSummaries>
  | ReturnType<typeof fetchAssessment>
  | ReturnType<typeof createComment>
  | ReturnType<typeof updateComment>
  | ReturnType<typeof deleteComment>
  | ReturnType<typeof sendLike>
  | ReturnType<typeof cancelLike>
  | ReturnType<typeof updateStudentPassword>
  | ReturnType<typeof clearSuccessMessage>
  | ReturnType<typeof clearErrorMessage>
  | ReturnType<typeof fetchSurveyAnswers>
  | ReturnType<typeof fetchPackages>
  | ReturnType<typeof fetchSurveyData>
  | ReturnType<typeof fetchAnswersStatistic>
  | ReturnType<typeof updateSurveyAnswers>
  | ReturnType<typeof fetchSurveyAnswersStatistic>
  | ReturnType<typeof fetchAssessmentPast>
  | ReturnType<typeof fetchCountAnswers>
  | ReturnType<typeof postSurveyAnswers>
  | ReturnType<typeof postEvaluateLesson>
  | ReturnType<typeof editExperienceReason>
  | ReturnType<typeof deleteExperienceReason>
  | ReturnType<typeof editNextAction>
  | ReturnType<typeof deleteNextAction>
  | ReturnType<typeof updateAssessmentLastActive>
  | ReturnType<typeof submitExperience>
  | ReturnType<typeof setCurrentAssessment>
  | loginSuccess
  | loginFailure
  | fetchInitialDataSuccess
  | fetchAssessmentSuccess
  | fetchInitialDataFailure
  | fetchTeachingMaterialsSuccess
  | fetchActiveLearningMaterialsSuccess
  | fetchActiveLearningLessonSuccess
  | fetchActiveLearningThemesSuccess
  | fetchActiveLearningStoryBoardSuccess
  | fetchActiveLearningAnswersSuccess
  | fetchActiveLearningAnswersByMaterialSuccess
  | updateActiveLearningAnswersSuccess
  | fetchTeachingMaterialSuccess
  | fetchCountAnswersSuccess
  | fetchMyAnswersSuccess
  | fetchLessonsSuccess
  | fetchAssessmentPastSuccess
  | fetchLessonSuccess
  | fetchStudentsSuccess
  | fetchGroupsSuccess
  | participateGroupSuccess
  | registerAnswersSuccess
  | registerAnswersFailure
  | editExperienceReasonSuccess
  | deleteExperienceReasonSuccess
  | editNextActionSuccess
  | deleteNextActionSuccess
  | fetchAnswersSuccess
  | fetchAnswerSummariesSuccess
  | createCommentSuccess
  | updateCommentSuccess
  | deleteCommentSuccess
  | sendLikeSuccess
  | cancelLikeSuccess
  | updateStudentPasswordSuccess
  | logoutSuccess
  | fetchSurveyAnswersSuccess
  | fetchPackagesSuccess
  | fetchSurveyDataSuccess
  | fetchSurveyDataFailure
  | updateSurveyAnswersSuccess
  | fetchAnswersStatisticSuccess
  | fetchSurveyAnswersStatisticSuccess
  | updateSurveyAnswersFailure
  | postSurveyAnswersSuccess
  | postSurveyAnswersFailure
  | postOecdSurveyAnswersSuccess
  | postEvaluateLessonSuccess
  | updateAssessmentLastActiveSuccess
  | submitExperienceSuccess
  | action503;

export type AppState = {
  session_id: string;
  error: string;
  error_field: string;
  success_message: string;
  isServiceUnavailable: boolean;
  isLoaderVisible: boolean;
  teaching_materials: ITeachingMaterial[];
  active_learning_materials: IActiveLearningMaterial[];
  active_learning_data_by_lesson: IActiveLearningDataByLesson;
  active_learning_themes: IActiveLearningTheme[];
  active_learning_story_board: IActiveLearningStoryBoard;
  active_learning_answers: IActiveLearningAnswers[];
  active_learning_answers_by_material: IActiveLearningAnswersByMaterial[];
  teaching_material?: ITeachingMaterial;
  lessons: ILesson[];
  lesson?: ILesson;
  student?: IStudent;
  students: IStudent[];
  issue: IIssueR;
  answers: IAnswerR[];
  teaching_material_answer_summaries: ITeachingMaterialAnswerSummary[];
  groups: IGroup[];
  class_name: string;
  uuid: string;
  readingLesson?: {
    material_id: number;
    lesson_id: number;
    page: number;
    index: number;
  };
  my_answers: INewAnswer[];
  packages: IPackage[];
  has_post_answers: boolean;
  has_pre_answers: boolean;
  post_answers: any[];
  pre_answers: any[];
  survey_answers: ISurveyAnswers[];
  survey_answers_per_teaching_material: ISurveyAnswers[];
  surveys: ISurvey[];
  survey_answers_statistic: {
    package: {
      teaching_materials: IDetailsSurvey[];
    };
  };
  answers_statistic: {
    teaching_material: {
      id: number;
      name: string;
      title: string;
      lessons: ILessonByTeachingMaterial[];
    };
  };
  studentAnswer?: IStudentCountAnswers;
  oecd_survey_answers: any[];
  assessment: IAssessment;
  assessments_past: IAssessmentPast[];
  next_lesson_id: number | null;
  prev_lesson_id: number | null;
  survey_enabled: boolean;
  oecd_survey_enabled: boolean;
  only_assessment: boolean;
  g4s_basic_enabled: boolean;
  is_dummy: boolean;
  available_item_codes: string[];
  lastAction: string;
  expires: Date;
};

export const InitialAppState: AppState = {
  session_id: "",
  error: "",
  error_field: "",
  success_message: "",
  isServiceUnavailable: false,
  isLoaderVisible: false,
  teaching_materials: [],
  active_learning_materials: [],
  active_learning_data_by_lesson: {
    title: "",
    material_code: "",
    no: NaN,
    issues: [],
  },
  active_learning_themes: [],
  active_learning_story_board: {
    id: NaN,
    enageed_theme_name: "",
    image_urls: [],
  },
  active_learning_answers: [],
  active_learning_answers_by_material: [],
  students: [],
  groups: [],
  lessons: [],
  issue: {
    id: 0,
    title: "",
    name_opened: false,
    answer_opened: true,
  },
  answers: [],
  teaching_material_answer_summaries: [],
  class_name: "",
  uuid: "",
  readingLesson: undefined,
  my_answers: [],
  packages: [],
  has_post_answers: false,
  has_pre_answers: false,
  post_answers: [],
  pre_answers: [],
  survey_answers: [],
  survey_answers_per_teaching_material: [],
  surveys: [],
  oecd_survey_answers: [],
  answers_statistic: {
    teaching_material: {
      id: 0,
      name: "",
      title: "",
      lessons: [],
    },
  },
  survey_answers_statistic: {
    package: {
      teaching_materials: [],
    },
  },
  assessment: {
    id: 0,
    assessment_experiences: [],
    assessment_next_actions: [],
    term_no: 0,
    created_at: "",
  },
  assessments_past: [],
  next_lesson_id: null,
  prev_lesson_id: null,
  survey_enabled: false,
  only_assessment: false,
  is_dummy: false,
  oecd_survey_enabled: false,
  g4s_basic_enabled: false,
  available_item_codes: [],
  lastAction: "",
  expires: addHours(new Date(), 6),
};

export const reducer = (state: AppState, action: Actions): AppState => {
  const newState = {
    ...state,
    lastAction: action.types,
  };
  switch (action.types) {
    case LOGIN_SUCCESS: {
      const {
        uuid,
        session_id,
        student,
        only_assessment,
      } = action.payload.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        uuid: uuid ?? "",
        session_id: session_id ?? "",
        student: student ?? state.student,
        only_assessment,
      };
    }
    case FETCH_INITIAL_DATA_SUCCESS: {
      const {
        class_name,
        student,
        survey_enabled,
        oecd_survey_enabled,
        only_assessment,
        g4s_basic_enabled,
        is_dummy,
        available_item_codes,
      } = action.payload.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        class_name,
        student: {
          ...student,
          assessment_last_active_date: student.assessment_last_active_date
            ? new Date(student.assessment_last_active_date)
            : null,
        },
        session_id: student.id ? state.session_id : "",
        survey_enabled,
        oecd_survey_enabled,
        is_dummy,
        only_assessment,
        g4s_basic_enabled,
        available_item_codes,
      };
    }
    case UPDATE_ASSESSMENT_LAST_ACTIVE_SUCCESS: {
      const { assessment_last_active_date } = action.payload.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        student: {
          ...(state.student as IStudent),
          assessment_last_active_date: assessment_last_active_date
            ? new Date(assessment_last_active_date)
            : null,
        },
      };
    }
    case UPDATE_STUDENT_PASSWORD_SUCCESS: {
      return {
        ...newState,
        uuid: "",
        error: "",
        error_field: "",
        success_message: MESSAGE.UPDATE_STUDENT_PASSWORD_SUCCESS_MESSAGE,
      };
    }
    case FETCH_TEACHING_MATERIAL_SUCCESS: {
      return {
        ...newState,
        error: "",
        error_field: "",
        teaching_material: action.payload.data.data.teaching_material,
      };
    }
    case FETCH_TEACHING_MATERIALS_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        teaching_materials: data.teaching_materials,
        lesson: undefined,
      };
    }
    case FETCH_ACTIVE_LEARNING_MATERIALS_SUCCESS: {
      const { active_learning_materials } = action.payload.data;
      return {
        ...newState,
        active_learning_materials,
        lesson: undefined,
      };
    }
    case FETCH_ACTIVE_LEARNING_LESSON_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        active_learning_data_by_lesson: data,
      };
    }
    case FETCH_ACTIVE_LEARNING_THEMES_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        active_learning_themes: data,
      };
    }
    case FETCH_ACTIVE_LEARNING_STORY_BOARD_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        active_learning_story_board: data,
      };
    }
    case FETCH_ACTIVE_LEARNING_ANSWERS_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        active_learning_answers: data,
      };
    }
    case FETCH_ACTIVE_LEARNING_ANSWERS_BY_MATERIAL_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        active_learning_answers_by_material: data,
      };
    }
    case UPDATE_ACTIVE_LEARNING_ANSWERS_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        active_learning_answers: data,
        success_message: MESSAGE.REGISTER_ANSWERS_SUCCESS_MSG,
      };
    }
    case CLEAR_ACTIVE_LEARNING_ANSWERS:
      return {
        ...newState,
        active_learning_answers: [],
      };
    case FETCH_LESSONS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        lessons: data.data.lessons,
      };
    }
    case FETCH_LESSON_SUCCESS: {
      const { data } = action.payload;
      return {
        ...newState,
        lesson: data.data.lesson,
        next_lesson_id: data.meta.next_lesson_id,
        prev_lesson_id: data.meta.prev_lesson_id,
      };
    }
    case POST_EVALUATE_LESSON_SUCCESS: {
      const { data } = action.payload;

      return {
        ...newState,
        lesson: state.lesson
          ? {
              ...state.lesson,
              student_lesson_retrospective: data.student_lesson_retrospective,
            }
          : undefined,
      };
    }
    case FETCH_STUDENTS_SUCCESS: {
      return {
        ...newState,
        students: action.payload.data.students,
      };
    }
    case FETCH_GROUPS_SUCCESS: {
      return {
        ...newState,
        groups: action.payload.data.groups,
      };
    }
    case FETCH_ANSWERS_SUCCESS: {
      const { data } = action.payload.data;
      const { answers, ...issue } = data.issue;
      return {
        ...newState,
        issue,
        answers: answers.map(a => ({
          ...a,
          comments: a.comments.map(c => ({
            ...c,
            updated_at: new Date(c.updated_at),
          })),
        })),
      };
    }
    case FETCH_COUNT_ANSWERS_SUCCESS: {
      const { student } = action.payload.data.data;
      return {
        ...newState,
        error: "",
        error_field: "",
        studentAnswer: {
          ...student,
        },
      };
    }
    case FETCH_ANSWER_SUMMARIES_SUCCESS: {
      return {
        ...newState,
        teaching_material_answer_summaries:
          action.payload.data.data.teaching_materials,
      };
    }
    case FETCH_ANSWERS_STATISTIC_SUCCESS: {
      return {
        ...newState,
        answers_statistic: action.payload.data.data,
      };
    }
    case FETCH_SURVEY_ANSWERS_STATISTIC_SUCCESS: {
      return {
        ...newState,
        survey_answers_statistic: action.payload.data.data,
      };
    }
    case POST_OECD_SURVEY_ANSWERS_SUCCESS: {
      return {
        ...newState,
        success_message: MESSAGE.UPDATE_SURVEY_SUCCESS_MSG,
        student: {
          ...newState.student,
          already_answered_oecd_survey: true,
        } as IStudent,
      };
    }
    case CREATE_COMMENT_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        answers: state.answers.map(a =>
          a.id === data.answer_comment.answer_id
            ? {
                ...a,
                comments: [
                  ...a.comments,
                  {
                    ...data.answer_comment,
                    updated_at: new Date(data.answer_comment.updated_at),
                  },
                ],
              }
            : a,
        ),
        success_message: MESSAGE.CREATE_COMMENT_SUCCESS_MSG,
      };
    }
    case SEND_LIKE_SUCCESS: {
      return {
        ...newState,
        answers: state.answers.map(a =>
          a.id === action.payload.data.answer_id
            ? {
                ...a,
                like_num: a.like_num + 1,
                is_like: true,
              }
            : a,
        ),
      };
    }
    case CANCEL_LIKE_SUCCESS: {
      return {
        ...newState,
        answers: state.answers.map(a =>
          a.id === action.payload.data.answer_id
            ? {
                ...a,
                like_num: a.like_num - 1,
                is_like: false,
              }
            : a,
        ),
      };
    }
    case FETCH_SURVEY_DATA_SUCCESS: {
      return {
        ...newState,
        surveys: action.payload.data.surveys,
      };
    }
    case FETCH_ASSESSMENT_SUCCESS: {
      return {
        ...newState,
        assessment: action.payload.data.assessment,
      };
    }
    case FETCH_ASSESSMENT_PAST_SUCCESS: {
      const { assessments } = action.payload.data;
      return {
        ...newState,
        assessments_past: assessments,
      };
    }
    case FETCH_SURVEY_DATA_FAILURE: {
      return {
        ...InitialAppState,
        lastAction: action.types,
      };
    }
    case FETCH_INITIAL_DATA_FAILURE: {
      return {
        ...InitialAppState,
        lastAction: action.types,
      };
    }
    case LOGIN_FAILURE:
      return {
        ...newState,
        error: action.payload.error.message,
        error_field: action.payload.error.field,
      };
    case LOGOUT:
      return {
        ...InitialAppState,
      };
    case LOGOUT_SUCCESS:
      return {
        ...InitialAppState,
        success_message: LOGOUT_SUCCESS_MESSAGE,
      };
    case CLEAR_SUCCESS_MESSAGE:
      return {
        ...newState,
        success_message: "",
      };
    case CLEAR_ERROR_MESSAGE:
      return {
        ...newState,
        error: "",
        error_field: "",
      };
    case PARTICIPATE_GROUP_SUCCESS:
      const paths = action.payload.params.url.split("/");
      const group_id = Number(paths[paths.length - 2]);

      return {
        ...newState,
        success_message: MESSAGE.PARTICIPATE_GROUP_SUCCESS_MSG,
        student: {
          ...(state.student as IStudent),
          group_id,
        },
      };
    case REGISTER_ANSWERS_SUCCESS:
      return {
        ...newState,
        success_message: MESSAGE.REGISTER_ANSWERS_SUCCESS_MSG,
        my_answers: action.payload.data.answers,
      };
    case REGISTER_ANSWERS_FAILURE: {
      return {
        ...newState,
        error: MESSAGE.REGISTER_ANSWERS_FAILURE_MSG,
      };
    }
    case SUBMIT_EXPERIENCE_SUCCESS:
      const { assessment } = action.payload.data;
      return {
        ...newState,
        student: {
          ...newState.student,
          max_term_no: assessment.term_no,
        } as IStudent,
        assessment,
        success_message: MESSAGE.CREATE_ASSESSMENT_SUCCESS_MSG,
      };
    case EDIT_EXPERIENCE_REASON_SUCCESS:
      const { assessment_reason: reason_edited } = action.payload.data;
      return {
        ...newState,
        assessment: {
          ...state.assessment,
          assessment_experiences: state.assessment.assessment_experiences.map(
            item =>
              item.experience_no === reason_edited.experience_no
                ? {
                    ...item,
                    reasons: item.reasons.map(reason =>
                      reason.id === reason_edited.id
                        ? {
                            ...reason,
                            reason: reason_edited.assessment_reason,
                          }
                        : reason,
                    ),
                  }
                : item,
          ),
        },
        success_message: MESSAGE.UPDATE_REASON_SUCCESS_MSG,
      };
    case EDIT_NEXT_ACTION_SUCCESS:
      const {
        assessment_next_action: next_action_edited,
      } = action.payload.data;
      return {
        ...newState,
        assessment: {
          ...(state.assessment as IAssessment),
          assessment_next_actions: state.assessment.assessment_next_actions.map(
            next_action =>
              next_action.id === next_action_edited.id
                ? {
                    ...next_action,
                    next_action: next_action_edited.next_action,
                  }
                : next_action,
          ),
        },
        success_message: MESSAGE.UPDATE_REASON_SUCCESS_MSG,
      };
    case DELETE_EXPERIENCE_REASON_SUCCESS:
      const { id } = action.payload.data;
      return {
        ...newState,
        assessment: {
          ...state.assessment,
          assessment_experiences: state.assessment.assessment_experiences.map(
            item => ({
              ...item,
              reasons: item.reasons.filter(reason => reason.id !== id),
            }),
          ),
        },
        success_message: MESSAGE.DELETE_REASON_SUCCESS_MSG,
      };
    case DELETE_NEXT_ACTION_SUCCESS:
      const { id: next_action_id } = action.payload.data;
      return {
        ...newState,
        assessment: {
          ...state.assessment,
          assessment_next_actions: state.assessment.assessment_next_actions.filter(
            next_action => next_action.id !== next_action_id,
          ),
        },
        success_message: MESSAGE.DELETE_REASON_SUCCESS_MSG,
      };
    case FETCH_MY_ANSWERS_SUCCESS: {
      return {
        ...newState,
        my_answers: action.payload.data.answers,
      };
    }
    case FETCH_SURVEY_ANSWERS_SUCCESS: {
      return {
        ...newState,
        has_post_answers: action.payload.data.has_post_answers,
        has_pre_answers: action.payload.data.has_pre_answers,
        post_answers: action.payload.data.post_answers,
        pre_answers: action.payload.data.pre_answers,
        survey_answers: action.payload.data.survey_answers,
        survey_answers_per_teaching_material:
          action.payload.data.survey_answers_per_teaching_material,
      };
    }
    case FETCH_PACKAGES_SUCCESS: {
      return {
        ...newState,
        packages: action.payload.data.data.packages,
      };
    }
    case UPDATE_COMMENT_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...newState,
        answers: state.answers.map(a =>
          a.id === data.answer_comment.answer_id
            ? {
                ...a,
                comments: a.comments.map(c =>
                  c.id === data.answer_comment.id
                    ? {
                        ...c,
                        content: data.answer_comment.content,
                      }
                    : c,
                ),
              }
            : a,
        ),
        success_message: MESSAGE.UPDATE_COMMENT_SUCCESS_MSG,
      };
    }
    case POST_SURVEY_ANSWERS_SUCCESS: {
      return {
        ...newState,
        success_message: MESSAGE.UPDATE_SURVEY_SUCCESS_MSG,
      };
    }
    case POST_SURVEY_ANSWERS_FAILURE: {
      return {
        ...newState,
        error: MESSAGE.UPDATE_SURVEY_FAILURE_MSG,
      };
    }
    case UPDATE_SURVEY_ANSWERS_SUCCESS: {
      return {
        ...newState,
        success_message: MESSAGE.UPDATE_SURVEY_SUCCESS_MSG,
      };
    }
    case UPDATE_SURVEY_ANSWERS_FAILURE: {
      return {
        ...newState,
        error: MESSAGE.UPDATE_SURVEY_FAILURE_MSG,
      };
    }
    case DELETE_COMMENT_SUCCESS: {
      const paths = action.payload.params.url.split("/");
      const comment_id = Number(paths[paths.length - 1]);

      return {
        ...newState,
        answers: state.answers.map(a => ({
          ...a,
          comments: a.comments.filter(c => c.id !== comment_id),
        })),
        success_message: MESSAGE.DELETE_COMMENT_SUCCESS_MSG,
      };
    }
    case SET_CURRENT_ASSESSMENT_MESSAGE: {
      const { assessment } = action.payload;
      return {
        ...newState,
        assessment: {
          ...newState.assessment,
          assessment_experiences: assessment.assessment_experiences,
          term_no: assessment.term_no,
          id: assessment.id,
        },
      };
    }
    case ACTION_503:
      return {
        ...newState,
        isServiceUnavailable: true,
        success_message: ACTION_503_MESSAGE,
      };
    default:
      return newState;
  }
};

type ContextState = {
  state: AppState;
  dispatch(action: Actions): void;
};

export const Context = React.createContext<ContextState>({
  state: InitialAppState,
  dispatch(_) {
    console.warn("Context.Provider外からの呼び出し");
  },
});
