import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react";

import { IAnswer } from "../../../../constants/active_learning";
import {
  IActiveLearningAnswers,
  IActiveLearningQuestion,
} from "../../../../state";
import TextAreaCell from "../../../../atoms/ActiveLearning/TextAreaCell";

interface ITextAnswer extends IAnswer {
  parentUid: string;
  value: string;
}

interface IAuthenticitySelectorProps {
  activeLearningAnswers?: IActiveLearningAnswers[];
  question?: IActiveLearningQuestion;
  answers?: ITextAnswer[];
  setAnswers?: Dispatch<SetStateAction<ITextAnswer[]>>;
  selectedUid?: string;
  currentIssueNo?: number;
}

export const ResearchText = (props: IAuthenticitySelectorProps) => {
  useEffect(() => {
    // Store側のデータを各ステートに同期
    const targetAnswers = props.activeLearningAnswers?.filter(
      answer =>
        answer.issue_no === props.currentIssueNo &&
        answer.question_no === props.question?.order_num,
    );

    const answersValue = targetAnswers?.map(answer => {
      return {
        id: answer?.id || null,
        issue_no: props.currentIssueNo || NaN,
        question_no: props.question?.order_num || NaN,
        question_id: answer?.question_id || NaN,
        parentUid: answer.contents?.parentUid,
        value: answer.contents?.value,
      };
    });
    props.setAnswers && answersValue && props.setAnswers(answersValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeLearningAnswers, props.question]);

  const setResearchTextToAnswer = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      const targetAnswer = props.answers?.find(
        answer =>
          answer.parentUid === props.selectedUid &&
          answer.question_no === props.question?.order_num,
      );
      const answersExcludingTarget = props.answers?.filter(
        answer => answer.parentUid !== targetAnswer?.parentUid,
      );
      const currentAnswer = targetAnswer
        ? {
            ...targetAnswer,
            value,
          }
        : {
            id: null,
            issue_no: props.currentIssueNo || NaN,
            question_no: props.question?.order_num || NaN,
            question_id: props.question?.id || NaN,
            parentUid: props.selectedUid || "",
            value,
          };

      props.setAnswers &&
        answersExcludingTarget &&
        props.setAnswers([currentAnswer, ...answersExcludingTarget]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.selectedUid, props.setAnswers],
  );

  const getResearchTextToAnswer = useCallback(() => {
    const targetAnswer = props.answers?.find(
      answer =>
        answer.parentUid === props.selectedUid &&
        answer.question_no === props.question?.order_num,
    );

    return targetAnswer?.value || "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedUid, props.answers]);

  return (
    <TextAreaCell
      value={getResearchTextToAnswer()}
      handleChange={e => setResearchTextToAnswer(e)}
    />
  );
};
export default ResearchText;
