import React from "react";

import styles from "./Selector.module.scss";

export interface ISelectorProps {
  maxWidth?: string;
  selectedValue?: string | number;
  options?: { value: string | number; label: string }[];
  handleChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Selector: React.FC<ISelectorProps> = ({
  maxWidth,
  selectedValue,
  options,
  handleChange,
}) => {
  return (
    <label className={styles.selector} style={{ maxWidth }}>
      <select
        value={
          typeof selectedValue === "number" && isNaN(selectedValue)
            ? ""
            : selectedValue ?? ""
        }
        onChange={e => handleChange && handleChange(e)}
      >
        <option value="">選択してください。</option>
        {options?.map((option, index) => (
          <option key={`option-${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};

export default Selector;
