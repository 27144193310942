import React from "react";
import { useMemo, useCallback, useEffect, useContext, useState } from "react";

import { IActiveLearningAnswers } from "../../../../state";
import { Context, updateActiveLearningAnswers } from "../../../../studentStore";
import Header from "../../../../organisms/ActiveLearning/Header";
import EndLesson from "../../../../organisms/ActiveLearning/EndLesson";
import Issue1 from "../../../../organisms/student/ActiveLearningLessons/Lesson1/Issue1";
import Issue2 from "../../../../organisms/student/ActiveLearningLessons/Lesson1/Issue2";
import styles from "../ActiveLearningLesson.module.scss";
import useWaitApiCall from "../../../../hooks/useWaitApiCall";
import SaveAnswerButton from "../../../../atoms/ActiveLearning/SaveAnswerButton";

export const Lesson1 = () => {
  const { state, dispatch } = useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const [answers, setAnswers] = useState<IActiveLearningAnswers[]>([]);
  const [fieldCounts, setFieldCounts] = useState<{
    [fieldId: number]: number;
  }>({});
  const [actionCounts, setActionCounts] = useState<{
    [actionId: number]: number;
  }>({});

  useEffect(() => {
    setAnswers([...state.active_learning_answers]);
  }, [state.active_learning_answers]);

  const currentActiveLearning = useMemo(() => {
    return state.active_learning_data_by_lesson;
  }, [state.active_learning_data_by_lesson]);

  const materialCode = useMemo(() => {
    return currentActiveLearning.material_code;
  }, [currentActiveLearning.material_code]);

  const findIssue = useCallback(
    (no: number) =>
      currentActiveLearning.issues?.find(issue => issue.no === no),
    [currentActiveLearning],
  );

  const actionCategories = useMemo(() => {
    return (
      state.active_learning_materials.find(
        material => material.code === materialCode,
      )?.action_categories || []
    );
  }, [materialCode, state.active_learning_materials]);

  const fieldCategories = useMemo(() => {
    return (
      state.active_learning_materials.find(
        material => material.code === materialCode,
      )?.field_categories || []
    );
  }, [materialCode, state.active_learning_materials]);

  const saveAnswers = () => {
    const params = answers;
    const { material_code, no } = state.active_learning_data_by_lesson;
    if (calling) return;
    return dispatch(
      peep(
        updateActiveLearningAnswers(material_code, no, JSON.stringify(params)),
      ),
    );
  };

  return (
    <>
      <div className={styles.wrapper} id="accordion">
        {/* <p>answer: {JSON.stringify(answers)}</p> */}
        <Header
          no={currentActiveLearning.no}
          label={"やりたいこと/もっと〇〇に気づく"}
          title={currentActiveLearning.title}
          read={
            "これから取り組んでいく活動について理解しよう。\n感情の動く「問い」を立てるために、自分がどんなものに興味・関心を持っているかを調査して、意志を持って取り組めるテーマを選択しよう。"
          }
        />
        <div className={styles.container}>
          <div className={styles.section}>
            <Issue1
              actionCategories={actionCategories}
              fieldCategories={fieldCategories}
              issue={findIssue(1)}
              answers={answers}
              setAnswers={setAnswers}
              setFieldCounts={setFieldCounts}
              setActionCounts={setActionCounts}
              saveAnswers={saveAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <Issue2
              fieldCategories={fieldCategories}
              fieldCounts={fieldCounts}
              actionCounts={actionCounts}
              issue={findIssue(2)}
              themes={state.active_learning_themes}
              answers={answers}
              setAnswers={setAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <EndLesson
              text={
                "次のLessonでは、企業の動画を参考に「問い」について考える。次回までに取り組みたい「問い」についてイメージを膨らませておこう。"
              }
              saveAnswers={saveAnswers}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Lesson1;
