import React from "react";
import clsx from "clsx";

import styles from "./Button.module.scss";

interface IButtonProps {
  variant?:
    | "primary"
    | "secondary"
    | "secondaryBgWhite"
    | "tertiary"
    | "tertiaryBgWhite";
  width?: string;
  height?: string;
  fontSize?: string;
  disabled?: boolean;
  children: React.ReactNode;
  isEnabled?: boolean;
  handleClick?: () => void;
}

export const Button: React.FC<IButtonProps> = ({
  variant = "primary",
  width = "272px",
  height = "58px",
  fontSize = "16px",
  disabled,
  children,
  isEnabled,
  handleClick,
}) => {
  return (
    <button
      className={clsx(
        styles.button,
        styles[variant],
        isEnabled && styles.isEnabled,
      )}
      style={{ width, height, fontSize }}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
