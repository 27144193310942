import React from "react";
import clsx from "clsx";

import { ReactComponent as IconLike } from "../../assets/svg/like_outline.svg";
import { ReactComponent as IconLiked } from "../../assets/svg/like.svg";
import { ReactComponent as IconChat } from "../../assets/svg/chat.svg";
import { ReactComponent as IconArrow } from "../../assets/svg/caret_down.svg";
import OneAnswerContents from "../OneAnswerContents";
import FormReply from "../../organisms/student/FormReply";
import FormComment from "../../organisms/student/FormComment";
import { IAnswerR, IStudent } from "../../state";

import style from "./OneAnswerBox.module.scss";

type OneAnswerBoxProps = {
  name_opened: boolean;
  answer: IAnswerR;
  isMyAnswer?: boolean;
  handleLike: (answerId: number) => void;
  replyAnswer: (content: string, answerId: number) => void;
  updateReply: (content: string, answerId: number, commentId?: number) => void;
  currentUser?: IStudent;
  onDeleteReply: (answerId: number, commentId: number) => void;
  calling: boolean;
};

export const OneAnswerBox: React.FC<OneAnswerBoxProps> = ({
  name_opened,
  answer,
  isMyAnswer,
  handleLike,
  replyAnswer,
  currentUser,
  onDeleteReply,
  updateReply,
  calling,
}) => {
  return (
    <div className="mb-4" key={answer.id}>
      <div className={style.boxAnswer}>
        <div
          className={clsx(style.wrapper_content, isMyAnswer && style.myAnswer)}
        >
          {answer.contents && <OneAnswerContents contents={answer.contents} />}
          <div className={style.reaction}>
            <button
              disabled={calling}
              onClick={() => {
                handleLike(answer.id);
              }}
            >
              {answer.is_like ? <IconLiked /> : <IconLike />}
              <span>{answer.like_num}人</span>
            </button>
            <button
              data-bs-toggle="collapse"
              data-bs-target={`#reply-${answer.id}`}
              aria-expanded="false"
              aria-controls={`reply-${answer.id}`}
            >
              <IconChat />
              <span>コメントする</span>
            </button>
          </div>
          <div
            className={`${style.replyAnswer} collapse`}
            id={`reply-${answer.id}`}
          >
            <FormComment
              answer={answer}
              replyAnswer={replyAnswer}
              calling={calling}
            />
          </div>
        </div>
        {answer.comments.length > 0 && (
          <div className={style.wrapper_comment}>
            <div
              className={style.toggleComment}
              data-bs-toggle="collapse"
              data-bs-target={`#comment${answer.id.toString()}content`}
              aria-expanded="false"
              aria-controls={`comment${answer.id.toString()}content`}
            >
              コメントを見る {answer.comments.length}件
              <IconArrow />
            </div>
            <div
              className="collapse"
              id={`comment${answer.id.toString()}content`}
            >
              {answer.comments.map(comment => (
                <FormReply
                  key={comment.id}
                  comment={comment}
                  onSendComment={updateReply}
                  onDeleteReply={onDeleteReply}
                  currentUser={currentUser}
                  answerId={answer.id}
                  name_opend={name_opened}
                  calling={calling}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OneAnswerBox;
