const labelStrs: { [key: string]: string[] } = {
  think: [
    "全くその通りでない",
    "その通りでない",
    "その通りだ",
    "全くその通りだ",
  ],
  satisfaction: ["まったく満足していない", "十分に満足している"],
  frequency: ["ない", "めったにない", "時々", "いつも"],
  well: [
    "全くその通りでない",
    "その通りでない",
    "その通りだ",
    "全くその通りだ",
  ],
};

export const radioMap: { [key: string]: number } = {
  mind: 4,
  satisfaction: 10,
  frequency: 4,
};

export const surveyColumnNames = [
  "mind_question1_1",
  "mind_question1_2",
  "mind_question1_3",
  "mind_question1_4",
  "mind_question2_1",
  "mind_question2_2",
  "mind_question2_3",
  "mind_question3_1",
  "mind_question3_2",
  "mind_question3_3",
  "mind_question4_1",
  "mind_question4_2",
  "mind_question4_3",
  "mind_question5_1",
  "mind_question5_2",
  "mind_question5_3",
  "mind_question5_4",
  "mind_question5_5",
  "mind_question6_1",
  "mind_question7_1",
  "feeling_question8_1",
  "feeling_question8_2",
  "feeling_question8_3",
  "feeling_question8_4",
  "feeling_question8_5",
  "feeling_question8_6",
  "feeling_question8_7",
  "feeling_question8_8",
  "feeling_question8_9",
  "school_mind_question9_1",
  "school_mind_question9_2",
  "school_mind_question9_3",
  "school_mind_question10_1",
  "school_mind_question10_2",
  "school_mind_question10_3",
  "school_mind_question11_1",
  "school_mind_question11_2",
  "school_mind_question11_3",
  "school_mind_question11_4",
  "school_mind_question11_5",
  "school_mind_question11_6",
  "school_mind_question12_1",
  "school_mind_question12_2",
  "school_mind_question12_3",
  "school_mind_question12_4",
  "school_mind_question12_5",
  "school_mind_question13_1",
  "school_mind_question13_2",
  "school_mind_question13_3",
  "school_mind_question13_4",
];

export const surveyData = [
  {
    title:
      "日常生活全体について、あなた自身の考えにもっとも近いものを選択してください",
    label: "mind_question",
    block: [
      {
        section: 1,
        type: "mind",
        questions: [
          {
            labels: labelStrs["think"],
            title: "（１）全力で取り組むことに満足を覚える",
          },
          {
            labels: labelStrs["think"],
            title: "（２）一度課題をやり始めたら、最後までやり遂げる",
          },
          {
            labels: labelStrs["think"],
            title:
              "（３）何かに取り組むことの楽しみの一つは、これまでの自分の成果を超えることである",
          },
          {
            labels: labelStrs["think"],
            title:
              "（４）苦手なことに対して、何か得意なことに逃げたりせずに、それができるまで努力するほうだ",
          },
        ],
      },
      {
        section: 2,
        type: "mind",
        questions: [
          {
            labels: labelStrs["think"],
            title: "（１）他の人と競争しながら勉強することを楽しむ",
          },
          {
            labels: labelStrs["think"],
            title:
              "（２）私にとって、課題を他の人より上手にこなすことは重要である",
          },
          {
            labels: labelStrs["think"],
            title: "（３）他の人と競争しているとき、一層頑張る",
          },
        ],
      },
      {
        section: 3,
        type: "mind",
        questions: [
          {
            labels: labelStrs["think"],
            title:
              "（１）失敗しそうなとき、他の人が自分のことをどう思うかが気になる",
          },
          {
            labels: labelStrs["think"],
            title:
              "（２）失敗しそうなとき、自分に十分な才能がないかもしれないと不安になる",
          },
          {
            labels: labelStrs["think"],
            title: "（３）失敗しそうなとき、自分の将来への計画に疑問をもつ",
          },
        ],
      },
      {
        section: 4,
        type: "mind",
        questions: [
          {
            labels: labelStrs["think"],
            title: "（１）自分の人生には明確な意義や目的がある",
          },
          {
            labels: labelStrs["think"],
            title: "（２）自分の人生に、満足のいく意義を見つけた",
          },
          {
            labels: labelStrs["think"],
            title:
              "（３）自分の人生に意味を与えるのは何か、はっきり分かっている",
          },
        ],
      },
      {
        section: 5,
        type: "mind",
        questions: [
          {
            labels: labelStrs["think"],
            title: "（１）物事はたいてい何とかできる",
          },
          {
            labels: labelStrs["think"],
            title: "（２）物事を達成すると、自分を誇らしく思う",
          },
          {
            labels: labelStrs["think"],
            title: "（３）同時に複数のことを行うことができる",
          },
          {
            labels: labelStrs["think"],
            title: "（４）自分を信じることで、困難を乗り越えられる",
          },
          {
            labels: labelStrs["think"],
            title:
              "（５）困難に直面したとき、たいてい解決策を見つけることができる",
          },
        ],
      },
      {
        section: 6,
        type: "mind",
        questions: [
          {
            labels: labelStrs["think"],
            title:
              "自分の知能は、自分ではほとんど変えることができないものである",
          },
        ],
      },
      {
        section: 7,
        type: "satisfaction",
        label: "詳細問題",
        questions: [
          {
            labels: labelStrs["satisfaction"],
            title:
              "全体として、あなたはあなたの最近の生活全般に、どのくらい満足していますか。",
          },
        ],
      },
    ],
  },
  {
    title:
      "日常生活全体について、以下のような気持ちになることはどのくらいありますか。",
    label: "feeling_question",
    block: [
      {
        section: 8,
        type: "frequency",
        questions: [
          {
            labels: labelStrs["frequency"],
            title: "（１）幸せ",
          },
          {
            labels: labelStrs["frequency"],
            title: "（２）怖い",
          },
          {
            labels: labelStrs["frequency"],
            title: "（３）元気",
          },
          {
            labels: labelStrs["frequency"],
            title: "（４）惨め",
          },
          {
            labels: labelStrs["frequency"],
            title: "（５）誇らしい",
          },
          {
            labels: labelStrs["frequency"],
            title: "（６）心配",
          },
          {
            labels: labelStrs["frequency"],
            title: "（７）うれしい",
          },
          {
            labels: labelStrs["frequency"],
            title: "（８）悲しい",
          },
          {
            labels: labelStrs["frequency"],
            title: "（９）陽気",
          },
        ],
      },
    ],
  },
  {
    title:
      "学校での生活について、あなた自身の考えにもっとも近いものを選択してください",
    label: "school_mind_question",
    block: [
      {
        section: 9,
        type: "mind",
        questions: [
          {
            labels: labelStrs["well"],
            title: "（１）学校で努力することは、良い仕事につくのに役立つ",
          },
          {
            labels: labelStrs["well"],
            title: "（２）学校で努力をすることは、良い大学に入るのに役立つ",
          },
          {
            labels: labelStrs["well"],
            title: "（３）学校で努力することは大切である",
          },
        ],
      },
      {
        section: 10,
        type: "mind",
        questions: [
          {
            labels: labelStrs["well"],
            title: "（１）自分の目標は、できる限り学ぶことである",
          },
          {
            labels: labelStrs["well"],
            title:
              "（２）自分の目標は、授業で出された課題を完全に習得することである",
          },
          {
            labels: labelStrs["well"],
            title:
              "（３）自分の目標は、できるだけ完璧に授業の内容を理解することである",
          },
        ],
      },
      {
        section: 11,
        type: "mind",
        questions: [
          {
            labels: labelStrs["well"],
            title: "（１）学校ではよそ者だ(またはのけ者にされている)と感じる ",
          },
          {
            labels: labelStrs["well"],
            title: "（２）学校ではすぐに友達ができる",
          },
          {
            labels: labelStrs["well"],
            title: "（３）学校の一員だと感じている",
          },
          {
            labels: labelStrs["well"],
            title: "（４）学校では気後れがして居心地が悪い",
          },
          {
            labels: labelStrs["well"],
            title: "（５）他の生徒たちは私をよく思ってくれている",
          },
          {
            labels: labelStrs["well"],
            title: "（６）学校にいるとさみしい",
          },
        ],
      },
      {
        section: 12,
        type: "mind",
        questions: [
          {
            labels: labelStrs["well"],
            title:
              "（１）いじめられている生徒を誰も守ってあげないことに腹が立つ",
          },
          {
            labels: labelStrs["well"],
            title: "（２）自分を守れない生徒に手助けすることはいいことだ",
          },
          {
            labels: labelStrs["well"],
            title: "（３）いじめに加わることは悪いことだ",
          },
          {
            labels: labelStrs["well"],
            title: "（４）他の生徒がいじめられているのを見るのは不愉快だ",
          },
          {
            labels: labelStrs["well"],
            title:
              "（５）いじめられている他の生徒に誰かが味方するのは、いいことだ",
          },
        ],
      },
      {
        section: 13,
        type: "mind",
        questions: [
          {
            labels: labelStrs["well"],
            title: "（１）生徒は協力することを重視している",
          },
          {
            labels: labelStrs["well"],
            title: "（２）生徒は互いに協力し合っている",
          },
          {
            labels: labelStrs["well"],
            title:
              "（３）互いに協力しあうことは重要だという意識を生徒は共有している",
          },
          {
            labels: labelStrs["well"],
            title:
              "（４）他の生徒と協力し合うようにすすめられていると生徒は感じている",
          },
        ],
      },
    ],
  },
];
