import React from "react";

import styles from "./SmallCard.module.scss";

interface ISmallCardProps {
  label: string;
  title: string;
  text: string;
  icon: any;
  width?: string;
}

export const SmallCard: React.FC<ISmallCardProps> = ({
  label,
  title,
  text,
  icon,
  width = "100%",
}) => {
  return (
    <div className={styles.smallCard} style={{ width }}>
      <div className={styles.titleWrapper}>
        <p className={styles.label}>
          {icon}
          {label}
        </p>
        <p className={styles.title}>{title}</p>
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default SmallCard;
