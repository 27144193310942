import React from "react";

import DataAnswersList from "../../../templates/student/DataAnswersList";
import {
  Context,
  fetchTeachingMaterials,
  fetchAnswersStatistic,
} from "../../../studentStore";

const excludeKind = "core_vol0";

export default () => {
  const { state, dispatch } = React.useContext(Context);

  React.useEffect(() => {
    dispatch(fetchTeachingMaterials());
  }, [dispatch]);

  const handleChangePage = (id: number) => {
    dispatch(fetchAnswersStatistic(id));
  };

  const filterdTeachingMaterials = React.useMemo(
    () => state.teaching_materials.filter(tm => tm.kind !== excludeKind),
    [state.teaching_materials],
  );

  return (
    <DataAnswersList
      teaching_materials={filterdTeachingMaterials}
      listLessons={state.answers_statistic.teaching_material.lessons}
      onChangeTeachingMaterial={handleChangePage}
    />
  );
};
