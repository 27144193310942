import React from "react";
import clsx from "clsx";

import { ReactComponent as IconStudent } from "../../assets/svg/student-comment.svg";
import { IAnswerR, IStudent } from "../../state";
import { generateFullName } from "../../libs/Util";
import OneAnswerBox from "../OneAnswerBox";

import style from "./AnswerBox.module.scss";

export type AnswerBoxProps = {
  student?: IStudent;
  name_opened: boolean;
  answer_opened?: boolean;
  answers: IAnswerR[];
  isMyAnswer?: boolean;
  currentUser?: IStudent;
  calling: boolean;
  onSendComment: (answerId: number, text: string, commentId?: number) => void;
  onDeleteReply: (answerId: number, commentId: number) => void;
  handleLike: (answerId: number) => void;
};

const AnswerBox: React.FC<AnswerBoxProps> = ({
  student,
  name_opened,
  answer_opened,
  answers,
  isMyAnswer,
  onSendComment,
  onDeleteReply,
  currentUser,
  handleLike,
  calling,
}) => {
  const myAnswer = React.useMemo(() => {
    if (!name_opened && answer_opened)
      return answers.find(answer => answer.student.id === currentUser?.id);
  }, [answers, currentUser, name_opened, answer_opened]);

  const answersWrap = React.useMemo(() => {
    if (name_opened || !answer_opened) return answers;
    return answers.filter(answer => answer.student.id !== currentUser?.id);
  }, [answers, currentUser, name_opened, answer_opened]);

  const replyAnswer = React.useCallback(
    (content: string, answerId: number) => {
      if (!content.length) return;
      onSendComment(answerId, content);
    },
    [onSendComment],
  );

  const updateReply = React.useCallback(
    (content: string, answerId: number, commentId?: number) => {
      onSendComment(answerId, content, commentId);
    },
    [onSendComment],
  );

  return (
    <div className={style.contentAll}>
      <div className={style.singleAnswer}>
        <div className={style.nameStudent}>
          {name_opened && student && (
            <span className={clsx(isMyAnswer && style.myAnswer)}>
              <IconStudent />
              {generateFullName([student.last_name, student.first_name])}
              {isMyAnswer && ` (あなたの回答)`}
            </span>
          )}
        </div>
        {myAnswer && (
          <div>
            <span className={style.myAnswerNo}>
              {!name_opened && `回答${myAnswer.index}(あなたの回答番号)`}
            </span>
            <OneAnswerBox
              handleLike={handleLike}
              replyAnswer={replyAnswer}
              updateReply={updateReply}
              name_opened={name_opened}
              onDeleteReply={onDeleteReply}
              answer={myAnswer}
              isMyAnswer
              currentUser={currentUser}
              calling={calling}
            />
          </div>
        )}
        {answersWrap.length ? (
          answersWrap.map(answer => (
            <div key={answer.id}>
              <span>{!name_opened && `回答${answer.index}`}</span>
              <OneAnswerBox
                key={answer.id}
                handleLike={handleLike}
                replyAnswer={replyAnswer}
                updateReply={updateReply}
                name_opened={name_opened}
                onDeleteReply={onDeleteReply}
                answer={answer}
                isMyAnswer={isMyAnswer}
                currentUser={currentUser}
                calling={calling}
              />
            </div>
          ))
        ) : (
          <div className={style.boxAnswer}>
            <p className={style.empty}>未記入</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnswerBox;
