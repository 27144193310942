import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { IAnswer } from "../../../../../constants/active_learning";
import TextInput from "../../../../../atoms/ActiveLearning/TextInput";
import styles from "../../ActiveLearningIssue.module.scss";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface ICurrentAnswer extends IAnswer {
  value: string;
}

export const Issue2 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 4;
  const question1 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);

  const [answer1, setAnswer1] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question1?.order_num || NaN,
    question_id: question1?.id || NaN,
    value: "",
  });

  const setAnswerWraper = useCallback(
    (questionNo: number, setter: Dispatch<React.SetStateAction<any>>) => {
      const targetAnswer = state.active_learning_answers.find(
        answer =>
          answer.issue_no === currentIssueNo &&
          answer.question_no === questionNo,
      );
      const answer: ICurrentAnswer = {
        id: targetAnswer?.id || null,
        issue_no: currentIssueNo,
        question_no: questionNo,
        question_id: targetAnswer?.question_id || 0,
        value: targetAnswer?.contents?.value || "",
      };
      setter({ ...answer });
    },
    [state.active_learning_answers],
  );

  useEffect(() => {
    setAnswerWraper(1, setAnswer1);
  }, [setAnswerWraper]);

  useEffect(() => {
    const answers = props.answers.filter(
      answer =>
        !(
          answer.issue_no === currentIssueNo &&
          question1?.order_num === answer.question_no
        ),
    );
    props.setAnswers([
      ...answers,
      ...[
        {
          id: answer1.id,
          issue_no: currentIssueNo,
          question_no: 1,
          question_id: question1?.id || NaN,
          contents: {
            value: answer1.value,
          },
        },
      ],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer1, question1]);

  const handleChangeAnswer = (
    e: React.ChangeEvent<HTMLInputElement>,
    answer: ICurrentAnswer,
    setter: Dispatch<React.SetStateAction<any>>,
  ) => {
    const { value } = e.target;
    setter({
      id: answer.id,
      question_id: question1?.id || NaN,
      question_no: question1?.order_num || NaN,
      issue_no: currentIssueNo,
      value,
    });
  };

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          「問い」に答えを出す過程で、ターゲットが問題に直面する場面が生まれる。
          <br />
          解決したいのはどのような場面かを想像して入力しよう。
          <br />
          <br />
          例：主婦→料理をする場面。/おばあさん→買い物に行く場面。/お店の人→お客さんを集める場面。/交通警備員→土日の交通整備の場面。
        </p>
      </div>
      {question1 && (
        <TextInput
          value={answer1.value}
          handleChange={e => handleChangeAnswer(e, answer1, setAnswer1)}
        />
      )}
    </div>
  );
};
export default Issue2;
