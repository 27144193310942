import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";
import clsx from "clsx";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
  IActiveLearningQuestion,
} from "../../../../../state";
import { IAnswer, HintList } from "../../../../../constants/active_learning";
import HintCard from "../../../../../atoms/ActiveLearning/HintCard";
import Selector from "../../../../../atoms/ActiveLearning/Selector";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import TextInput from "../../../../../atoms/ActiveLearning/TextInput";
import styles from "../../ActiveLearningIssue.module.scss";

interface IActiveLearningIssue1Props {
  lastAnswers: string[];
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
  setCommonAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface ICurrentAnswer extends IAnswer {
  subject: string;
  research: string;
  value: string;
}

export const Issue1 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 1;
  const question1 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);
  const question2 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 2);
  }, [props.issue?.questions]);
  const question3 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 3);
  }, [props.issue?.questions]);

  const [answerToQ1, setAnswerToQ1] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question1?.order_num || NaN,
    question_id: question1?.id || NaN,
    subject: "",
    research: "",
    value: "",
  });
  const [answerToQ2, setAnswerToQ2] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question2?.order_num || NaN,
    question_id: question2?.id || NaN,
    subject: "",
    research: "",
    value: "",
  });
  const [answerToQ3, setAnswerToQ3] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question3?.order_num || NaN,
    question_id: question3?.id || NaN,
    subject: "",
    research: "",
    value: "",
  });
  const answerData: {
    question: IActiveLearningQuestion | undefined;
    state: ICurrentAnswer;
    setter: Dispatch<React.SetStateAction<ICurrentAnswer>>;
  }[] = [
    {
      question: question1,
      state: answerToQ1,
      setter: setAnswerToQ1,
    },
    {
      question: question2,
      state: answerToQ2,
      setter: setAnswerToQ2,
    },
    {
      question: question3,
      state: answerToQ3,
      setter: setAnswerToQ3,
    },
  ];

  const syncStoreAnswerToState = useCallback(
    (
      questionNo: number,
      setter: Dispatch<React.SetStateAction<ICurrentAnswer>>,
    ) => {
      const targetAnswer = state.active_learning_answers.find(
        answer =>
          answer.issue_no === currentIssueNo &&
          answer.question_no === questionNo,
      );
      const answer: ICurrentAnswer = {
        id: targetAnswer?.id || null,
        issue_no: currentIssueNo,
        question_no: questionNo,
        question_id: targetAnswer?.question_id || 0,
        value: targetAnswer?.contents?.value || "",
        research: targetAnswer?.contents?.research || "",
        subject: targetAnswer?.contents?.subject,
      };
      setter({ ...answer });
    },
    [state.active_learning_answers],
  );

  useEffect(() => {
    // Store側のデータを各ステートに同期
    syncStoreAnswerToState(question1?.order_num || NaN, setAnswerToQ1);
    syncStoreAnswerToState(question2?.order_num || NaN, setAnswerToQ2);
    syncStoreAnswerToState(question3?.order_num || NaN, setAnswerToQ3);
  }, [
    state.active_learning_answers,
    syncStoreAnswerToState,
    question1,
    question2,
    question3,
  ]);

  const syncStateToParentAnswer = useCallback(() => {
    const answers = props.answers.filter(
      answer => answer.issue_no !== currentIssueNo,
    );

    const currentAnswers = answerData.map(data => {
      return {
        id: data.state.id,
        issue_no: currentIssueNo,
        question_no: data.question?.order_num || NaN,
        question_id: data.question?.id || NaN,
        contents: {
          value: data.state.value,
          subject: data.state.subject,
          research: data.state.research,
        },
      };
    });
    props.setAnswers([...answers, ...currentAnswers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerData, answerToQ1, answerToQ2, answerToQ3]);

  useEffect(() => {
    syncStateToParentAnswer();
    const commonAnswers = [answerToQ1, answerToQ2, answerToQ3].map(answer => ({
      id: answer.id,
      issue_no: answer.issue_no,
      question_no: answer.question_no,
      question_id: answer.question_id,
      contents: {
        value: answer.value,
        subject: answer.subject,
      },
    }));
    props.setCommonAnswers([...commonAnswers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerToQ1, answerToQ2, answerToQ3, props.setCommonAnswers]);

  const lastAnswers = useMemo(() => {
    return props.lastAnswers.map(answer => {
      return { label: answer, value: answer };
    });
  }, [props.lastAnswers]);

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          前回のLessonでは「問い」を立て、それを実現させるときに立ちはだかる問題を思いつく限りあげた。
          <br />
          ヒント①を参考にして、考えた問題の中から『この問題を取り除けば実現への近道になりそう。』と思えるものを、3つまで選ぼう。
          <br />
          次に、ヒント②③を参考にして、選んだ問題とその原因について調べよう。
          <br />
          <br />
          操作：「選択してください。」と表示されている回答枠をクリックすると、前回の回答から問題を選択できます。
        </p>
      </div>
      <div className={styles.hint}>
        <p className={styles.title}>ヒント① 問題を選ぶ視点のヒント</p>
        <div className={clsx(styles.hintList, styles.isPc)}>
          {HintList.lesson4.issue1.hint1.map((hint, index) => {
            return (
              <HintCard
                key={index}
                title={hint.title}
                width={"calc((100% - (16px * 2)) / 3)"}
              >
                {hint.text}
              </HintCard>
            );
          })}
        </div>
        <div className={clsx(styles.hintList, styles.isSp)}>
          {HintList.lesson4.issue1.hint1.map((hint, index) => {
            return (
              <HintCard key={index} title={hint.title}>
                {hint.text}
              </HintCard>
            );
          })}
        </div>
      </div>
      <div className={styles.hint}>
        <p className={styles.title}>ヒント② 原因を予測する視点のヒント</p>
        <p className={styles.text}>
          問題が起きているのは「なぜだろう？」と繰り返し質問することで、原因を予測することができる。
          <br />
          以下の視点を参考にして、原因を深堀りしよう。
        </p>
        <div className={clsx(styles.hintList, styles.isPc)}>
          {HintList.lesson4.issue1.hint2.map((hint, index) => {
            return (
              <HintCard
                key={index}
                title={hint.title}
                width={"calc((100% - (16px * 2)) / 3)"}
              >
                {hint.text}
              </HintCard>
            );
          })}
        </div>
        <div className={clsx(styles.hintList, styles.isSp)}>
          {HintList.lesson4.issue1.hint2.map((hint, index) => {
            return (
              <HintCard key={index} title={hint.title}>
                {hint.text}
              </HintCard>
            );
          })}
        </div>
      </div>
      <div className={styles.hint}>
        <p className={styles.title}>ヒント③ 調べる視点のヒント</p>
        <div className={clsx(styles.hintList, styles.isPc)}>
          {HintList.lesson4.issue1.hint3.map((hint, index) => {
            return (
              <HintCard
                key={index}
                title={hint.title}
                width={"calc((100% - 16px) / 2)"}
              >
                {hint.text}
              </HintCard>
            );
          })}
        </div>
        <div className={clsx(styles.hintList, styles.isSp)}>
          {HintList.lesson4.issue1.hint3.map((hint, index) => {
            return (
              <HintCard key={index} title={hint.title}>
                {hint.text}
              </HintCard>
            );
          })}
        </div>
      </div>
      {answerData.map((data, index) => (
        <div key={`question${index}`} className={styles.tree}>
          {data.question && (
            <>
              <Selector
                selectedValue={data.state.subject}
                options={lastAnswers}
                handleChange={e => {
                  data.setter({
                    ...data.state,
                    subject: e.target.value,
                  });
                }}
              />
              <ul className={styles.branchList}>
                <li className={styles.branch}>
                  <div className={styles.labelWrapper}>
                    <span className={styles.label}>原因は？</span>
                  </div>
                  <div className={styles.inputField}>
                    <TextInput
                      value={data.state.value}
                      handleChange={e =>
                        data.setter({
                          ...data.state,
                          value: e.target.value,
                        })
                      }
                    />
                  </div>
                </li>
                <li className={styles.branch}>
                  <div className={styles.labelWrapper}>
                    <span className={styles.label}>調べたこと</span>
                  </div>
                  <div className={styles.inputField}>
                    <TextArea
                      value={data.state.research}
                      handleChange={e =>
                        data.setter({
                          ...data.state,
                          research: e.target.value,
                        })
                      }
                    />
                  </div>
                </li>
              </ul>
            </>
          )}
        </div>
      ))}
    </div>
  );
};
export default Issue1;
