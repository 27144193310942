import React, { useEffect, useMemo, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

import ImagePageView from "../../../organisms/ImagePageView";
import {
  INewAnswer,
  IContent,
  ILesson,
  ILessonRetrospectiveScore,
  IQuestionData,
} from "../../../state";
import useAnswerStorage from "../../../hooks/useAnswerStorage";
import useDebounceCallback from "../../../hooks/useDebounceCallback";
import { mapDataToArray, parseQueryParams } from "../../../libs/Util";
import { ReactComponent as IconRecommend } from "../../../assets/svg/recomend-scroll.svg";
import { ReactComponent as GearIcon } from "../../../assets/svg/gear.svg";
import Modal from "../../../atoms/Modal";
import AnswerPage from "../../../pages/student/AnswerListPage";
import StepProcessBar from "../../../organisms/student/StepProcessBar";
import ControlLessonButtons from "../../../molecules/ControlLessonButtons";
import MaterialSidebar from "../../../molecules/MaterialSidebar";
import ModalConfirm from "../../../molecules/ModalConfirm";
import EvaluationLessonModal from "../../../organisms/student/EvaluationLessonModal";

import style from "./TextBookForImage.module.scss";

export type TextBookForImageProps = {
  lesson: ILesson;
  myAnswers: INewAnswer[] | [];
  nextLessonId: number | null;
  prevLessonId: number | null;
  isG4sEnabled: boolean;
  isSuccessSendAnswers: boolean;
  getMyAnswers: (issueId: number) => void;
  sendAnswers: (issueId: number, answers: INewAnswer[]) => void;
  sendEvaluateLesson: (
    student_lesson_retrospective: ILessonRetrospectiveScore,
  ) => void;
  clearSuccessMessage: () => void;
};

const ARROW_UP = "ArrowUp";
const ARROW_DOWN = "ArrowDown";
const ARROW_LEFT = "ArrowLeft";
const ARROW_RIGHT = "ArrowRight";
const MOVE_TO_LAST_PAGE_PARAM = "last";

const existsPage = (
  images: {
    page_title?: string;
    estimated_time?: number;
    image_url: string;
  }[],
  page: number,
) => !!images[page - 1].image_url;

const TextBookForImage: React.FC<TextBookForImageProps> = props => {
  const history = useHistory();
  const slider = useRef<Slider>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperSize, setWrapperSize] = useState({ width: 0, height: 0 });
  const stepRef = useRef<HTMLDivElement>(null);
  const controlPaginationRef = useRef<HTMLDivElement>(null);
  const [debounceClickOutside] = useDebounceCallback(() => {
    setTimeout(() => {
      if (stepRef.current) {
        stepRef.current.style.opacity = "";
        stepRef.current.style.transition = "";
      }
      if (controlPaginationRef.current) {
        controlPaginationRef.current.classList.remove(style.buttonFadeIn);
      }
    }, 1000);
  });
  const [{ storageValue, tempValue }, answerStorage] = useAnswerStorage();
  const [isChangeAnswer, setIsChangeAnswer] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [toGear, setToGear] = useState<boolean>(false);
  const [showRecommend, setShowRecommend] = useState(false);
  const [showModalAnswers, setShowModalAnswers] = useState<boolean>(false);
  const [showModalEvaluate, setShowModalEvaluate] = useState<boolean>(false);
  const [showModalRealization, setShowModalRealization] = useState<boolean>(
    false,
  );
  const [showModalResetAnswer, setShowModalResetAnswer] = useState<boolean>(
    false,
  );
  const prmPage = Number(parseQueryParams()["page"]) || 1;
  const currentPage = parseQueryParams()[MOVE_TO_LAST_PAGE_PARAM]
    ? props.lesson.images.length
    : existsPage(props.lesson.images, prmPage)
    ? prmPage
    : 1;

  const [debounceSwipe] = useDebounceCallback((action: () => void) => {
    action();
  }, 400);
  const [debouncedResize] = useDebounceCallback(() => {
    window.location.reload();
  });

  const settings: Settings = {
    accessibility: false,
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    swipe: false,
    waitForAnimate: false,
  };

  const questions: IQuestionData[] = useMemo(() => {
    return props.lesson
      .issues!.map(issue =>
        mapDataToArray(issue.questions, {
          issue_id: issue.id,
          page: issue.page,
        }),
      )
      .flat();
  }, [props.lesson.issues]);

  const issueId = questions.find(q => q.page === currentPage)?.issue_id;
  const hasIssue = questions.some(q => q.page === currentPage);
  const currentQuestions = useMemo(
    () => questions.filter(q => q.issue_id === issueId),
    [questions, issueId],
  );

  useEffect(() => {
    if (currentPage === 1) {
      setShowRecommend(true);
    }

    window.addEventListener("orientationchange", debouncedResize);

    return () => {
      window.removeEventListener("orientationchange", debouncedResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const changePageByKeyBoard = (e: any) => {
      if (showRecommend) {
        setShowRecommend(false);
      }

      if (/input|textarea/i.test(e.target.tagName)) return;

      if (e.key === ARROW_DOWN || e.key === ARROW_RIGHT) {
        debounceClick(nextFunc);
      }

      if (e.key === ARROW_UP || e.key === ARROW_LEFT) {
        debounceClick(prevFunc);
      }
    };

    if (showModalAnswers) {
      document.removeEventListener("keydown", changePageByKeyBoard);
    } else {
      document.addEventListener("keydown", changePageByKeyBoard);
    }

    return () => {
      document.removeEventListener("keydown", changePageByKeyBoard);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lesson, currentPage, showRecommend, showModalAnswers]);

  useEffect(() => {
    if (issueId) {
      props.getMyAnswers(issueId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueId]);

  const [debounceClick] = useDebounceCallback((action: () => void) => {
    action();
  });

  const disablePrev = currentPage === 1;

  const disableNext = currentPage === props.lesson.images.length;

  const prevFunc = () => {
    const isZoom = Math.abs(document.body.offsetWidth - window.innerWidth) > 3;
    if (isZoom || isEditMode || disablePrev) {
      return;
    }
    history.push({
      search: `?lesson=${props.lesson.id}&page=${currentPage - 1}`,
    });
  };

  const nextFunc = () => {
    const isZoom = Math.abs(document.body.offsetWidth - window.innerWidth) > 3;
    if (isZoom || isEditMode || disableNext) {
      return;
    }
    history.push({
      search: `?lesson=${props.lesson.id}&page=${currentPage + 1}`,
    });
  };

  useEffect(() => {
    const q = `?lesson=${props.lesson.id}&page=${currentPage}${
      isEditMode ? "&sidebar=no" : ""
    }`;
    history.push({ search: q });
  }, [isEditMode, currentPage, props.lesson.id, history]);

  useEffect(() => {
    const dontAnimate = !!parseQueryParams()[MOVE_TO_LAST_PAGE_PARAM];
    slider.current?.slickGoTo(currentPage - 1, dontAnimate);
  }, [currentPage]);

  useEffect(() => {
    // init answerStorage and get local values
    const ids = currentQuestions.map(q => q.id);
    const localValues = answerStorage.setQuestionIds(ids);

    // check and set isChangeAnswer
    const propsAnswer: INewAnswer[] = props.myAnswers;
    const newAnswerContents = propsAnswer.map(a => a.contents).flat();
    const isChange = currentQuestions.some(question => {
      const localValue = localValues[question.id];
      const findContent = newAnswerContents.find(
        c => c.question_id === question.id,
      );

      const isNewValue = localValue !== null && !findContent;
      const isEditedLocalValue =
        localValue !== null &&
        findContent &&
        findContent.content !== localValue;
      return isNewValue || isEditedLocalValue;
    });
    setIsChangeAnswer(isChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.myAnswers, currentQuestions]);

  const resetAnswer = () => {
    answerStorage.removeValues();
    setIsChangeAnswer(false);
    setIsEditMode(false);
  };

  const changeAnswersFunc = (questionId: number, value: string) => {
    setIsChangeAnswer(true);
    setIsEditMode(true);
    answerStorage.setTempValue(questionId, value);
  };

  const submitAnswer = () => {
    if (issueId) {
      const propsAnswer: INewAnswer[] = props.myAnswers;
      const newAnswers: INewAnswer[] = propsAnswer.map(a => {
        return {
          ...a,
          contents: a.contents.map(c => {
            return { ...c };
          }),
        };
      });

      currentQuestions.forEach(question => {
        const localValue = tempValue[question.id];
        if (localValue === null) return;

        const newContent: IContent = {
          question_id: question.id,
          statement: question.statement,
          content: localValue,
        };
        const targetAns = newAnswers.find(a => a.section === question.section);
        if (!targetAns) {
          newAnswers.push({
            issue_id: question.issue_id,
            section: question.section,
            contents: [newContent],
          });
        } else {
          const target = targetAns.contents.find(
            c => c.question_id === question.id,
          );
          targetAns.contents = target
            ? targetAns.contents.map(c =>
                c.question_id === question.id ? newContent : c,
              )
            : [...targetAns.contents, newContent];
        }
      });

      props.sendAnswers(issueId, newAnswers);
    }
  };

  // After sendAnswers
  useEffect(() => {
    if (props.isSuccessSendAnswers) {
      props.clearSuccessMessage();

      answerStorage.removeValues();
      setIsChangeAnswer(false);
      setIsEditMode(false);
      if (toGear) {
        setShowModalRealization(true);
      } else if (shouldEvaluate) {
        setShowModalEvaluate(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSuccessSendAnswers]);

  // Set toGear at initialization and when change myAnswers
  // * This useEffect must be written below the useEffect for "After sendAnswers"
  useEffect(() => {
    const issue = props.lesson.issues?.find(
      i => i.to_gear && i.page === currentPage,
    );
    const answer = props.myAnswers.some(a => a.issue_id === issue?.id);
    setToGear(props.isG4sEnabled && !!issue && !answer);
  }, [props.isG4sEnabled, props.lesson.issues, currentPage, props.myAnswers]);

  const handlers = useSwipeable(
    !showRecommend
      ? {
          onSwipedUp: () => debounceSwipe(nextFunc),
          onSwipedDown: () => debounceSwipe(prevFunc),
          delta: 60,
          swipeDuration: 500,
        }
      : {},
  );

  const refPassthrough = (el: HTMLDivElement) => {
    // call useSwipeable ref prop with el
    handlers.ref(el);

    // set myRef el so you can access it yourself
    //@ts-ignore
    wrapperRef.current = el;
  };

  const handleWrapperRef = () => {
    if (wrapperRef.current) {
      setWrapperSize({
        width: wrapperRef.current.offsetWidth,
        height: wrapperRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    handleWrapperRef();
    window.addEventListener("resize", handleWrapperRef);

    return () => {
      window.removeEventListener("resize", handleWrapperRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef.current]);

  const handleClickOutside = () => {
    if (stepRef.current) {
      stepRef.current.style.opacity = "1";
      stepRef.current.style.transition = "0s";
    }
    if (controlPaginationRef.current) {
      controlPaginationRef.current.classList.add(style.buttonFadeIn);
    }
    debounceClickOutside();
  };

  const lastIssuePageNum = useMemo(
    () =>
      props.lesson.issues?.length &&
      props.lesson.issues.reduce((lastIssuePage, issue) => {
        return Math.max(lastIssuePage, issue.page);
      }, 0),
    [props.lesson.issues],
  );

  const shouldEvaluate = useMemo(
    () =>
      !!props.lesson.is_retrospective &&
      !props.lesson.student_lesson_retrospective &&
      currentPage === lastIssuePageNum,
    [
      props.lesson.is_retrospective,
      props.lesson.student_lesson_retrospective,
      currentPage,
      lastIssuePageNum,
    ],
  );

  const onSubmit = () => submitAnswer();
  const onResetAnswer = () => setShowModalResetAnswer(true);
  const onSaveToStorage = () => {
    answerStorage.saveValues();
    setIsEditMode(false);
  };
  const onStartEditing = () => {
    setIsEditMode(true);
  };
  const onShowModalAnswers = () => setShowModalAnswers(true);
  const onShowModalEvaluate = () => setShowModalEvaluate(true);
  const onCloseModalRealization = () => {
    setShowModalRealization(false);
    if (shouldEvaluate) {
      setShowModalEvaluate(true);
    }
  };

  return (
    <>
      <div className={style.wrapPDF} {...handlers} ref={refPassthrough}>
        <div onClick={handleClickOutside}>
          <Slider
            className={clsx(style.slider, style.modify)}
            onSwipe={swipeDirection => {
              if (swipeDirection === "up") {
                nextFunc();
              } else if (swipeDirection === "down") {
                prevFunc();
              }
            }}
            ref={slider}
            {...settings}
          >
            {props.lesson.images.map((imageUrl, idx) => (
              <ImagePageView
                key={`${props.lesson.id}_${idx}`}
                imageUrl={imageUrl.image_url}
                imageType={props.lesson.image_type}
                page={idx + 1}
                maxPage={props.lesson.images.length}
                currentPage={currentPage}
                student={true}
                myAnswer={props.myAnswers}
                answerStorageValues={storageValue}
                questions={currentPage === idx + 1 ? currentQuestions : []}
                issueId={
                  questions.find(question => question.page === idx + 1)
                    ?.issue_id
                }
                outerSize={wrapperSize}
                isEditMode={isEditMode}
                handleChangeAnswers={changeAnswersFunc}
              />
            ))}
          </Slider>
        </div>
        {!isEditMode && (
          <StepProcessBar
            totalPage={props.lesson.images.length}
            currentPage={currentPage}
            ref={stepRef}
          />
        )}
        {!isEditMode && (
          <ControlLessonButtons
            prevLessonId={props.prevLessonId}
            nextLessonId={props.nextLessonId}
            disablePrevPage={disablePrev}
            disableNextPage={disableNext}
            disableNextLesson={shouldEvaluate}
            nextPage={() => debounceClick(nextFunc)}
            prevPage={() => debounceClick(prevFunc)}
            nextLesson={() => {
              history.push({
                search: `?lesson=${props.nextLessonId}&page=1`,
              });
            }}
            prevLesson={() => {
              history.push({
                search: `?lesson=${props.prevLessonId}&${MOVE_TO_LAST_PAGE_PARAM}=1`,
              });
            }}
            ref={controlPaginationRef}
          />
        )}
        {shouldEvaluate && (
          <div className={style.evaludateWrapper}>
            <button className={style.evaluateBtn} onClick={onShowModalEvaluate}>
              <p>今日の授業はいかがでしたか？</p>
              <p>アンケートで感想を教えてください</p>
            </button>
          </div>
        )}
        {showRecommend && (
          <div
            className={style.recommend}
            onClick={() => setShowRecommend(false)}
          >
            <IconRecommend />
          </div>
        )}
        {showModalEvaluate && (
          <EvaluationLessonModal
            defaultRating={
              props.lesson.student_lesson_retrospective ?? {
                understand: null,
                sympathize: null,
                execute: null,
              }
            }
            onRating={(rating: ILessonRetrospectiveScore) => {
              props.sendEvaluateLesson(rating);
              setShowModalEvaluate(false);
            }}
            onClose={() => setShowModalEvaluate(false)}
          />
        )}
        {showModalAnswers && (
          <Modal
            onClose={() => setShowModalAnswers(false)}
            className={style.modal}
            transparentBackDrop={true}
          >
            <div className={style.modal__content}>
              <AnswerPage lessonId={props.lesson.id} issueId={issueId} />
            </div>
          </Modal>
        )}
        {showModalRealization && (
          <Modal
            onClose={onCloseModalRealization}
            className={style.modalRealization}
          >
            <div className={style.modalRealization__title}>
              <p className={style.message}>
                振り返りワークの回答を自動でGEARにストックしました。
              </p>
              <p className={style.note}>
                ※GEAR画面は左側メニューから開くことができます。
              </p>
              <div className={style.gearIcon}>
                <GearIcon />
              </div>
              <p className={style.message}>
                日常の学びや気づきをGEARに記入してみましょう！
              </p>
            </div>
            <div className={style.modalRealization__actions}>
              <button className={style.btnOK} onClick={onCloseModalRealization}>
                OK
              </button>
            </div>
          </Modal>
        )}
        {showModalResetAnswer && (
          <ModalConfirm
            onClose={() => setShowModalResetAnswer(false)}
            title={`送信していない変更内容は保存されません。\n削除しますか？`}
            buttons={[
              {
                title: "キャンセル",
                action: () => setShowModalResetAnswer(false),
                type: "None",
              },
              {
                title: "削除して終了",
                action: () => {
                  resetAnswer();
                  setShowModalResetAnswer(false);
                },
                type: "Danger",
              },
            ]}
          />
        )}
      </div>
      <MaterialSidebar
        hasIssue={hasIssue}
        isChangeAnswer={isChangeAnswer}
        isEditMode={isEditMode}
        lesson={props.lesson}
        currentPage={currentPage}
        onSubmit={onSubmit}
        onResetAnswer={onResetAnswer}
        onSaveToStorage={onSaveToStorage}
        onStartEditing={onStartEditing}
        onShowModalAnswers={onShowModalAnswers}
        onShowModalEvaluate={onShowModalEvaluate}
      />
    </>
  );
};

export default TextBookForImage;
