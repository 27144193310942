import React from "react";

import styles from "./TextAreaCell.module.scss";

interface ITextAreaCellProps {
  value?: string;
  placeholder?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextAreaCell: React.FC<ITextAreaCellProps> = ({
  value,
  placeholder,
  width = "100%",
  height = "100%",
  disabled,
  handleChange,
}) => {
  return (
    <textarea
      className={styles.textAreaCell}
      style={{ width, height }}
      value={value}
      placeholder={placeholder ? placeholder : "テキストを入力してください。"}
      disabled={disabled}
      onChange={e => handleChange && handleChange(e)}
    />
  );
};

export default TextAreaCell;
