import React from "react";
import clsx from "clsx";

import { IContent } from "../../state";

import styles from "./OneAnswerContents.module.scss";

type OneAnswerContentsProps = {
  contents: IContent[];
};

export const OneAnswerContents: React.FC<OneAnswerContentsProps> = ({
  contents,
}) => {
  return (
    <div className={styles.contents}>
      {contents.map(content => (
        <div
          className={clsx(
            styles.content_item,
            styles[`content_item_col${content.layout_cols}`],
            styles[`content_item_style${content.layout_style}`],
          )}
          style={{ order: content.layout_order }}
          key={content.question_id}
        >
          <p className={styles.statement}>{content.statement}</p>
          <p className={styles.value}>{content.content}</p>
        </div>
      ))}
    </div>
  );
};

export default OneAnswerContents;
