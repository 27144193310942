import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { IAnswer } from "../../../../../constants/active_learning";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import ThemeVideo from "../../../../ActiveLearning/ThemeVideo";
import styles from "../../ActiveLearningIssue.module.scss";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
  themeId: number;
}

export interface IAnswerToQ1 extends IAnswer {
  value: string;
}

export const Issue1 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 1;
  const question = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);

  const [answer, setAnswer] = useState<IAnswerToQ1>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question?.order_num || NaN,
    question_id: question?.id || NaN,
    value: "",
  });

  useEffect(() => {
    const targetAnswer = state.active_learning_answers.find(
      answer =>
        answer.issue_no === currentIssueNo &&
        answer.question_no === question?.order_num,
    );
    const answerFormatOfI1Q1: IAnswerToQ1 = {
      id: targetAnswer?.id || 0,
      issue_no: currentIssueNo,
      question_no: 1,
      question_id: targetAnswer?.question_id || 0,
      value: targetAnswer?.contents?.value || "",
    };
    setAnswer({ ...answerFormatOfI1Q1 });
  }, [state.active_learning_answers, question?.order_num]);

  useEffect(() => {
    const answers = props.answers.filter(
      answer =>
        !(
          answer.issue_no === currentIssueNo &&
          answer.question_no === question?.order_num
        ),
    );
    props.setAnswers([
      ...answers,
      {
        id: answer.id || null,
        issue_no: answer.issue_no,
        question_no: question?.order_num || NaN,
        question_id: question?.id || NaN,
        contents: {
          value: answer.value,
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, question?.order_num, question?.id]);

  const changeAnswer = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setAnswer({
      id: answer.id,
      question_id: question?.id || NaN,
      question_no: question?.order_num || NaN,
      issue_no: currentIssueNo,
      value,
    });
  };

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          実在する企業も、それぞれの「問い」を立て、「問い」に答えを出すための課題解決に取り組んでいる。
          <br />
          Lesson01
          WORK02で選んだテーマに関連する取り組みの事例の動画を視聴しよう。
          <br />
          <br />
          操作：「資料を見る」ボタンをクリックすると動画に関する説明資料を見ることができます。
        </p>
      </div>
      <ThemeVideo themeId={props.themeId} />
      {question && (
        <div className={styles.question}>
          <div className={styles.themEntry}>
            <p className={styles.title}>{question.title}</p>
            <p className={styles.read}>
              ヒントを参考にして、動画を見て気づいたことや、感じたことを入力しよう。
              <br />
              <br />
              感想を入力する際のヒント
              <br />
              『どんな取り組みをしていたか』『どんな社会課題に取り組んでいたか』『どんな想いを持って取り組んでいたか』
              <br />
              『面白いと思ったところ』『感動したところ』『「自分だったらこんなことができるかも」と思ったところ』
              <br />
              <br />
              例：動画では、〇〇という課題に、〇〇を使って取り組んでいた。/動画の中にあった〇〇という部分が自分にはなかった考え方なので面白かった。/自分だったら〇〇の問題に対して〇〇ができるかもしれないと思った。
            </p>
            <div className={styles.textAreaWrapper}>
              <TextArea value={answer.value} handleChange={changeAnswer} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Issue1;
