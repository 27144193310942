import React from "react";

import { IActiveLearningStoryBoard } from "../../../state";

import styles from "./ActiveLearningStoryboard.module.scss";

export interface Props {
  activeLearningStoryBoard: IActiveLearningStoryBoard | null;
}

const ActiveLearningStoryboard: React.FC<Props> = ({
  activeLearningStoryBoard,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.guidelineImages}>
        {activeLearningStoryBoard &&
          activeLearningStoryBoard.image_urls.map((url, i) => (
            <div className={styles.imageContainer} key={i}>
              <img
                className={styles.image}
                src={`https://admin.gear.enageed.net${url}`}
                alt={`guideline-${i}`}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ActiveLearningStoryboard;
