import React from "react";
import { useHistory, useParams } from "react-router-dom";

import Button from "../../../atoms/Button";
import { ISurveyAnswers, IStudent } from "../../../state";
import { generateFullName } from "../../../libs/Util";
import { surveyPath } from "../../../studentStore";
import RadarChart from "../../../atoms/RadarChart";

import styles from "./QuestionnaireStatistics.module.scss";

export type Props = {
  survey_answers: ISurveyAnswers[];
  survey_answers_per_teaching_material: ISurveyAnswers[];
  class_name: string;
  student?: IStudent;
  has_post_answers: boolean;
  has_pre_answers: boolean;
};

const QuestionnaireStatistics: React.FC<Props> = ({
  survey_answers,
  survey_answers_per_teaching_material,
  class_name,
  student,
  has_post_answers,
  has_pre_answers,
}) => {
  const { id } = useParams();
  const history = useHistory();

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <p className={styles.class}>
          {generateFullName([
            student?.last_name_kana,
            student?.first_name_kana,
          ])}
        </p>
        <p className={styles.name}>
          {generateFullName([student?.last_name, student?.first_name])}
        </p>
        <p className={styles.class}>クラス: {class_name}</p>
      </div>
      <div className={styles.wrapperExplainSurvey}>
        このアンケートは、エナジードの教材を使った授業の実施前と教材の完了ごとに行うものです。1~5までの数字は「点数」ではなく、高ければ良いというわけではありません。今の自分に当てはまるものを素直に記入してください。
      </div>
      <div className="d-flex align-items-center w-100 position-relative my-5">
        {has_pre_answers && (
          <Button
            className={styles.btn_fix_survey}
            name="事前アンケートを修正する"
            variant="outline"
            onClick={() =>
              history.push(`${surveyPath}/${id}/edit`, {
                isPreSurvey: true,
                isPostSurvey: false,
                isPreAnswer: true,
              })
            }
          />
        )}
        {!has_post_answers && (
          <Button
            className={styles.btn_post_survey}
            name={has_pre_answers ? "事後アンケートを記入" : "アンケートを記入"}
            variant="normal"
            onClick={() =>
              history.push(`${surveyPath}/${id}`, {
                isPreSurvey: false,
                isPostSurvey: false,
                isPreAnswer: has_pre_answers ? false : true,
              })
            }
          />
        )}
        {has_post_answers && (
          <Button
            className={styles.btn_fix_survey}
            name="事後アンケートを修正する"
            variant="outline"
            onClick={() =>
              history.push(`${surveyPath}/${id}/edit`, {
                isPreSurvey: false,
                isPostSurvey: true,
                isPreAnswer: false,
              })
            }
          />
        )}
      </div>

      <RadarChart
        survey_answers={survey_answers}
        survey_answers_per_teaching_material={
          survey_answers_per_teaching_material
        }
      />
      <div className={styles.wrapperExplainChart}>
        ※右側にある「授業前アンケート / 授業後アンケート」をそれぞれタップする
        <br />
        ことで各グラフの表示 / 非表示をコントロールすることができます。
      </div>
    </div>
  );
};

export default QuestionnaireStatistics;
