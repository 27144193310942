import React from "react";
import { useRouteMatch } from "react-router-dom";

import MyData from "../../../templates/student/MyData";
import { Context } from "../../../studentStore";

export default () => {
  const { url } = useRouteMatch();
  const { state } = React.useContext(Context);
  return (
    <MyData
      url={url}
      student={state.student!}
      isSurveyEnabled={state.survey_enabled}
    />
  );
};
