import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import clsx from "clsx";

import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { Context } from "../../../../../studentStore";
import MultipleTextArea from "../../../../../organisms/ActiveLearning/MultipleTextArea";
import { IAnswer, HintList } from "../../../../../constants/active_learning";
import HintCard from "../../../../../atoms/ActiveLearning/HintCard";
import styles from "../../ActiveLearningIssue.module.scss";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface ICurrentAnswer extends IAnswer {
  good: string;
  motto: string;
}

export const Issue1 = (props: IActiveLearningIssue1Props) => {
  const { state } = React.useContext(Context);
  const currentIssueNo = 1;
  const question1 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);

  const [answerToQ1, setAnswerToQ1] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question1?.order_num || NaN,
    question_id: question1?.id || NaN,
    good: "",
    motto: "",
  });

  useEffect(() => {
    const excludedAnswer = props.answers.filter(
      answer => answer.issue_no !== currentIssueNo,
    );
    const currentAnswers: IActiveLearningAnswers[] = [];
    currentAnswers.push({
      id: answerToQ1.id,
      issue_no: answerToQ1.issue_no,
      question_no: answerToQ1.question_no,
      question_id: answerToQ1.question_id,
      contents: {
        good: answerToQ1.good,
        motto: answerToQ1.motto,
      },
    });
    props.setAnswers([...excludedAnswer, ...currentAnswers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerToQ1, props.setAnswers]);

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          計画を元に行動した結果、どんな成果が得られたかをまとめよう。また、改善点があれば入力しよう。
        </p>
      </div>
      <div className={styles.hint}>
        <p className={styles.title}>行動の考察をするためのヒント</p>
        <div className={clsx(styles.hintList, styles.isPc)}>
          {HintList.lesson8.issue1.hint1.map((hint, index) => {
            return (
              <HintCard
                key={index}
                title={hint.title}
                width={"calc((100% - (16px * 2)) / 3)"}
              >
                {hint.text}
              </HintCard>
            );
          })}
        </div>
        <div className={clsx(styles.hintList, styles.isSp)}>
          {HintList.lesson8.issue1.hint1.map((hint, index) => {
            return (
              <HintCard key={index} title={hint.title}>
                {hint.text}
              </HintCard>
            );
          })}
        </div>
      </div>
      {question1 && (
        <div className={styles.themEntry}>
          <div className={styles.textAreaWrapper}>
            <MultipleTextArea
              activeLearningAnswers={state.active_learning_answers}
              currentIssueNo={currentIssueNo}
              question={question1}
              answer={answerToQ1}
              setAnswer={setAnswerToQ1}
              goodLabel={"わかったこと"}
              mottoLabel={"改善点"}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default Issue1;
