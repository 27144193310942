import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import DataSurveyPage from "../../../pages/student/DataSurveyPage";
import DataAnswersListPage from "../../../pages/student/DataAnswersListPage";
import Header from "../../../organisms/student/Header";
import Tabs from "../../../atoms/Tabs";
import { ReactComponent as IconTeachingMaterial } from "../../../assets/svg/book.svg";
import { ReactComponent as IconSurveyStudent } from "../../../assets/svg/survey-student.svg";
import { IStudent } from "../../../state";
import { notFoundPath } from "../../../studentStore";

import styles from "./MyData.module.scss";

export interface IMyDataProps {
  url: string;
  student: IStudent;
  isSurveyEnabled: boolean;
}

export const MyData = (props: IMyDataProps) => {
  const getListTab = () => {
    return [
      {
        title: "教材回答一覧",
        icon: <IconTeachingMaterial />,
        link: `${props.url}`,
      },
      {
        title: "事前/事後アンケート確認",
        icon: <IconSurveyStudent />,
        link: `${props.url}/survey`,
        hide: !props.isSurveyEnabled,
      },
    ];
  };

  const getRouteSurveyPage = () => {
    if (props.isSurveyEnabled)
      return (
        <Route exact path={`${props.url}/survey`}>
          <DataSurveyPage />
        </Route>
      );
    return (
      <Redirect
        to={{
          pathname: notFoundPath,
        }}
      />
    );
  };

  return (
    <>
      <Header />
      <Tabs className={styles.tabActive} listTabsTitle={getListTab()}>
        <Switch>
          <Route exact path={`${props.url}`}>
            <DataAnswersListPage />
          </Route>
          {getRouteSurveyPage()}
        </Switch>
      </Tabs>
    </>
  );
};

export default MyData;
