import React from "react";
import { useMemo, useCallback } from "react";

import { IActiveLearningAnswers } from "../../../../state";
import { Context, updateActiveLearningAnswers } from "../../../../studentStore";
import Header from "../../../../organisms/ActiveLearning/Header";
import EndLesson from "../../../../organisms/ActiveLearning/EndLesson";
import Issue1 from "../../../../organisms/student/ActiveLearningLessons/Lesson2/Issue1";
import Issue2 from "../../../../organisms/student/ActiveLearningLessons/Lesson2/Issue2";
import Issue3 from "../../../../organisms/student/ActiveLearningLessons/Lesson2/Issue3";
import styles from "../ActiveLearningLesson.module.scss";
import useWaitApiCall from "../../../../hooks/useWaitApiCall";
import SaveAnswerButton from "../../../../atoms/ActiveLearning/SaveAnswerButton";

export const Lesson = () => {
  const { state, dispatch } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const [answers, setAnswers] = React.useState<IActiveLearningAnswers[]>([]);

  React.useEffect(() => {
    setAnswers([...state.active_learning_answers]);
  }, [state.active_learning_answers]);

  const currentActiveLearning = useMemo(() => {
    return state.active_learning_data_by_lesson;
  }, [state.active_learning_data_by_lesson]);

  const findIssue = useCallback(
    (no: number) =>
      currentActiveLearning.issues?.find(issue => issue.no === no),
    [currentActiveLearning],
  );

  const saveAnswers = () => {
    const params = answers;
    const { material_code, no } = state.active_learning_data_by_lesson;
    if (calling) return;
    dispatch(
      peep(
        updateActiveLearningAnswers(material_code, no, JSON.stringify(params)),
      ),
    );
  };

  const getThemeId = useCallback(
    ({
      lessonNo,
      issueNo,
      questionNo,
    }: {
      lessonNo: number;
      issueNo: number;
      questionNo: number;
    }): number => {
      const themeId = state.active_learning_answers_by_material
        .find(answer => answer.lesson_no === lessonNo)
        ?.issues.find(i => i.issue_no === issueNo)
        ?.questions.find(q => q.question_no === questionNo)?.answers[0]
        ?.contents.theme_id;
      return themeId || 0;
    },
    [state.active_learning_answers_by_material],
  );

  return (
    <div className={styles.wrapper} id="accordion">
      <Header
        no={currentActiveLearning.no}
        label={"やりたいこと/もっと〇〇に気づく"}
        title={currentActiveLearning.title}
        read={
          "企業の課題解決ストーリーや周りの意見を参考にして、自分だけの「問い」を考えよう。"
        }
      />
      <div className={styles.container}>
        <div className={styles.section}>
          <Issue1
            issue={findIssue(1)}
            answers={answers}
            setAnswers={setAnswers}
            themeId={getThemeId({ lessonNo: 1, issueNo: 2, questionNo: 1 })}
          />
        </div>
        <div className={styles.saveAnswer}>
          <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
        </div>
        <div className={styles.section}>
          <Issue2
            issue={findIssue(2)}
            answers={answers}
            setAnswers={setAnswers}
          />
        </div>
        <div className={styles.saveAnswer}>
          <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
        </div>
        <div className={styles.section}>
          <Issue3
            issue={findIssue(3)}
            answers={answers}
            setAnswers={setAnswers}
          />
        </div>
        <div className={styles.saveAnswer}>
          <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
        </div>
        <div className={styles.section}>
          <EndLesson
            text="次のLessonでは、考えた「問い」をさらに具体的にし、これから取り組む「問い」を立てる。そして、その「問い」に答えを出すことを妨げる問題を予測する。次回までに、本日考えた「問い」をさらに具体的にするにはどうしたらいいかについてイメージを膨らませておこう。"
            saveAnswers={saveAnswers}
          />
        </div>
      </div>
    </div>
  );
};
export default Lesson;
