import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
  useState,
  useContext,
} from "react";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { IAnswer } from "../../../../../constants/active_learning";
import Selector from "../../../../../atoms/ActiveLearning/Selector";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import styles from "../../ActiveLearningIssue.module.scss";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
  commonAnswers: IActiveLearningAnswers[];
}

export interface ITextAnswer extends IAnswer {
  value: string;
}
export interface INoAnswer extends IAnswer {
  stepNo: number;
}

export const Issue2 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 2;
  const question1 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);
  const question2 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 2);
  }, [props.issue?.questions]);

  const [selectedStepNo, setSelectedStepNo] = useState(NaN);
  const [answer1, setAnswer1] = useState<INoAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question1?.order_num || NaN,
    question_id: question1?.id || NaN,
    stepNo: NaN,
  });
  const [answer2, setAnswer2] = useState<ITextAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question2?.order_num || NaN,
    question_id: question2?.id || NaN,
    value: "",
  });

  useEffect(() => {
    const targetAnswerToQ1 = state.active_learning_answers.find(
      answer =>
        answer.issue_no === currentIssueNo &&
        answer.question_no === question1?.order_num,
    );
    setAnswer1({
      id: targetAnswerToQ1?.id || null,
      issue_no: currentIssueNo,
      question_no: question1?.order_num || NaN,
      question_id: targetAnswerToQ1?.question_id || NaN,
      stepNo: targetAnswerToQ1?.contents?.stepNo || NaN,
    });
    setSelectedStepNo(targetAnswerToQ1?.contents?.stepNo);
    const targetAnswerToQ2 = state.active_learning_answers.find(
      answer =>
        answer.issue_no === currentIssueNo &&
        answer.question_no === question2?.order_num,
    );
    setAnswer2({
      id: targetAnswerToQ2?.id || null,
      issue_no: currentIssueNo,
      question_no: question1?.order_num || NaN,
      question_id: targetAnswerToQ2?.question_id || NaN,
      value: targetAnswerToQ2?.contents?.value || "",
    });
  }, [state.active_learning_answers, question1, question2]);

  useEffect(() => {
    const answers = props.answers.filter(
      answer =>
        !(
          answer.issue_no === currentIssueNo &&
          [question1?.order_num, question2?.order_num].includes(
            answer.question_no,
          )
        ),
    );
    props.setAnswers([
      ...answers,
      ...[
        {
          id: answer1.id,
          issue_no: currentIssueNo,
          question_no: 1,
          question_id: question1?.id || NaN,
          contents: {
            stepNo: answer1.stepNo,
          },
        },
        {
          id: answer2.id,
          issue_no: currentIssueNo,
          question_no: 2,
          question_id: question2?.id || NaN,
          contents: {
            value: answer2.value,
          },
        },
      ],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer1, answer2, question1, question2]);

  const commonAnswers = useMemo(() => {
    return props.commonAnswers
      .filter(
        answer => answer.contents.value !== "" && !answer.contents._destroy,
      )
      .map(answer => ({
        label: answer.contents.value,
        value: answer.contents.stepNo,
      }));
  }, [props.commonAnswers]);

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          立てた計画に基づいて行動しよう。どのステップまでなら行動できそうかを考えて、次のLessonまでにやるべきことを決めよう。
          <br />
          <br />
          操作：「選択してください」と表示されている回答枠をクリックすると、WORK01で考えたステップから行動を選択できます。
        </p>
      </div>
      {question1 && (
        <Selector
          selectedValue={selectedStepNo}
          options={commonAnswers}
          handleChange={e => {
            const { value } = e.target;
            setAnswer1({
              id: answer1.id,
              question_id: question1?.id || NaN,
              question_no: question1?.order_num || NaN,
              issue_no: currentIssueNo,
              stepNo: Number(value),
            });
            setSelectedStepNo(Number(e.target.value));
          }}
        />
      )}
      {question2 && (
        <div className={styles.themEntry}>
          <p className={styles.title}>{question2.title}</p>
          <p className={styles.read}>
            次のLessonまでに選んだステップまでの行動を実行した結果、どのような情報が得られていればいいかを予測して入力しよう。
          </p>
          <div className={styles.textAreaWrapper}>
            <TextArea
              value={answer2.value}
              handleChange={e => {
                const { value } = e.target;
                setAnswer2({
                  id: answer2.id,
                  question_id: question1?.id || NaN,
                  question_no: question1?.order_num || NaN,
                  issue_no: currentIssueNo,
                  value,
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default Issue2;
