import React from "react";

import AnswerBox from "../../../molecules/AnswerBox";
import { IAnswerR, IStudent, IIssueR } from "../../../state";

import styles from "./AnswerList.module.scss";

export type AnswerListProps = {
  issue: IIssueR;
  answers: IAnswerR[];
  students: IStudent[];
  currentUser: IStudent;
  calling: boolean;
  handleComment: (answerId: number, text: string, commentId?: number) => void;
  handleDeleteReply: (answerId: number, commentId: number) => void;
  handleLike: (answerId: number) => void;
};

enum ETab {
  all = "クラスの回答",
  byGroup = "グループの回答",
}

const AnswerList: React.FC<AnswerListProps> = ({
  issue,
  answers,
  handleComment,
  handleDeleteReply,
  students,
  currentUser,
  handleLike,
  calling,
}) => {
  const [tabActive, setTabActive] = React.useState<ETab>(ETab.all);

  const currentStudent = React.useMemo(() => {
    return students.find(student => student.id === currentUser.id);
  }, [students, currentUser]);

  const myAnswer = React.useMemo(() => {
    return answers.filter(answer => answer.student.id === currentUser.id);
  }, [answers, currentUser]);

  const renderAnswer = React.useCallback(
    (students: IStudent[], answers: IAnswerR[]) => {
      const { name_opened, answer_opened } = issue || {
        name_opened: false,
        answer_opened: false,
      };
      if (name_opened) {
        if (!answer_opened) {
          return (
            <AnswerBox
              student={answers.length ? answers[0].student : currentUser}
              name_opened={true}
              answers={answers}
              currentUser={currentUser}
              calling={calling}
              onSendComment={handleComment}
              onDeleteReply={handleDeleteReply}
              handleLike={handleLike}
            />
          );
        }

        return (
          <>
            <AnswerBox
              student={currentStudent}
              name_opened={true}
              answers={myAnswer}
              currentUser={currentUser}
              calling={calling}
              isMyAnswer
              onSendComment={handleComment}
              onDeleteReply={handleDeleteReply}
              handleLike={handleLike}
            />
            {students
              .filter(student => student.id !== currentUser.id)
              .map(student => (
                <AnswerBox
                  key={student.id}
                  student={student}
                  name_opened={true}
                  answers={answers.filter(
                    answer => answer.student.id === student.id,
                  )}
                  currentUser={currentUser}
                  calling={calling}
                  onSendComment={handleComment}
                  onDeleteReply={handleDeleteReply}
                  handleLike={handleLike}
                />
              ))}
          </>
        );
      }

      return (
        <AnswerBox
          name_opened={false}
          answer_opened={answer_opened}
          answers={answers}
          currentUser={currentUser}
          calling={calling}
          onSendComment={handleComment}
          onDeleteReply={handleDeleteReply}
          handleLike={handleLike}
        />
      );
    },
    [
      calling,
      currentUser,
      issue,
      currentStudent,
      myAnswer,
      handleComment,
      handleDeleteReply,
      handleLike,
    ],
  );

  const renderList = React.useCallback(() => {
    const isGroupTab = tabActive === ETab.byGroup;
    let listStudents: IStudent[] = [];
    let listAnswers: IAnswerR[] = [];
    if (isGroupTab) {
      listStudents = [
        ...students.filter(
          student =>
            student.group_id === currentUser.group_id &&
            currentUser.group_id !== 0,
        ),
      ];
      listAnswers = [
        ...answers.filter(
          answer =>
            !!answer.student.group?.id &&
            answer.student.group.id === currentUser.group_id,
        ),
      ];
    } else {
      listStudents = [...students];
      listAnswers = [...answers];
    }
    return renderAnswer(listStudents, listAnswers);
  }, [answers, currentUser.group_id, students, tabActive, renderAnswer]);

  return (
    <div className={styles.wrapperGroup}>
      <ul className={styles.tab}>
        {(Object.keys(ETab) as Array<keyof typeof ETab>).map(tab => (
          <li
            key={tab}
            onClick={() => setTabActive(ETab[tab])}
            className={tabActive === ETab[tab] ? styles.active : ""}
          >
            {ETab[tab]}
          </li>
        ))}
      </ul>
      {renderList()}
    </div>
  );
};

export default AnswerList;
