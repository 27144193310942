import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import Assessment from "../../../templates/student/Assessment";
import {
  Context,
  fetchAssessment,
  editExperienceReason,
  deleteExperienceReason,
  editNextAction,
  deleteNextAction,
  fetchAssessmentPast,
  updateAssessmentLastActive,
  submitExperience,
  InitialAppState,
} from "../../../studentStore";
import { CREATE_ASSESSMENT_SUCCESS_MSG } from "../../../studentStore/messages";
import useWaitApiCall from "../../../hooks/useWaitApiCall";
import useUpdateAssessmentLastActive from "../../../hooks/useUpdateAssessmentLastActive";
import useAssessmentStorage from "../../../hooks/useAssessmentStorage";

export default () => {
  const { state, dispatch } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const history = useHistory();
  const { search } = useLocation();
  const isNewAssessment = React.useMemo(() => search.includes("new"), [search]);
  const isPastAssessment = React.useMemo(() => search.includes("past"), [
    search,
  ]);

  const [
    assessmentStorage,
    { loadAssessment, setScore, setReason, setNextAction, clearAfterSubmit },
  ] = useAssessmentStorage();

  const studentName = React.useMemo(() => {
    if (state.student) {
      return `${state.student.last_name} ${state.student.first_name}`;
    }
    return "";
  }, [state.student]);

  const previousAssessment = React.useMemo(() => {
    return isNewAssessment
      ? state.assessment
      : state.assessments_past.find(
          a => a.term_no < state.assessment.term_no,
        ) ?? null;
  }, [isNewAssessment, state.assessment, state.assessments_past]);

  useUpdateAssessmentLastActive(
    state.student?.assessment_last_active_date ?? null,
    dispatch,
    updateAssessmentLastActive,
  );

  React.useEffect(() => {
    if (isNewAssessment || isPastAssessment) {
      clearAfterSubmit();
    }
    return () => {
      if (isNewAssessment || isPastAssessment) {
        clearAfterSubmit();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewAssessment, isPastAssessment]);

  React.useEffect(() => {
    if (isPastAssessment && !state.assessment.id) {
      history.replace({
        search: "",
      });
    }
  }, [history, isPastAssessment, state.assessment.id]);

  React.useEffect(() => {
    if (!isPastAssessment) {
      dispatch(peep(fetchAssessment()));
    }
    if (!isNewAssessment) {
      dispatch(peep(fetchAssessmentPast()));
    }
  }, [dispatch, peep, isNewAssessment, isPastAssessment]);

  React.useEffect(() => {
    if (
      state.success_message === CREATE_ASSESSMENT_SUCCESS_MSG &&
      !isPastAssessment
    ) {
      clearAfterSubmit();
      history.replace({
        search: "",
      });

      if (!state.assessment.id) {
        dispatch(peep(fetchAssessment()));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.success_message, isPastAssessment]);

  React.useEffect(() => {
    isNewAssessment
      ? loadAssessment(InitialAppState.assessment)
      : loadAssessment(state.assessment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.assessment, isNewAssessment]);

  const handleSubmitExperience = React.useCallback(() => {
    if (calling) return;
    return dispatch(
      peep(
        submitExperience({
          ...assessmentStorage.data,
          term_no: isNewAssessment
            ? (state.student?.max_term_no ?? 0) + 1
            : isPastAssessment
            ? state.assessment.term_no
            : state.student?.max_term_no ?? 1,
        }),
      ),
    );
  }, [
    calling,
    dispatch,
    peep,
    assessmentStorage.data,
    state.student,
    isNewAssessment,
    isPastAssessment,
    state.assessment,
  ]);

  const handleEditReason = React.useCallback(
    (id: number, reason: string) => {
      if (calling) return;
      return dispatch(peep(editExperienceReason(id, reason)));
    },
    [calling, dispatch, peep],
  );

  const handleDeleteReason = React.useCallback(
    (id: number) => {
      if (calling) return;
      return dispatch(peep(deleteExperienceReason(id)));
    },
    [calling, dispatch, peep],
  );

  const handleEditNextAction = React.useCallback(
    (id: number, next_action: string) => {
      if (calling) return;
      return dispatch(peep(editNextAction(id, next_action)));
    },
    [calling, dispatch, peep],
  );

  const handleDeleteNextAction = React.useCallback(
    (id: number) => {
      if (calling) return;
      return dispatch(peep(deleteNextAction(id)));
    },
    [calling, dispatch, peep],
  );

  return (
    <Assessment
      studentName={studentName}
      assessment={state.assessment}
      assessments_past={state.assessments_past}
      previousAssessment={previousAssessment}
      isNewAssessment={isNewAssessment}
      assessmentStorage={assessmentStorage}
      calling={calling}
      saveScore={setScore}
      saveReason={setReason}
      saveNextAction={setNextAction}
      onSubmitExperience={handleSubmitExperience}
      onEditReason={handleEditReason}
      onDeleteReason={handleDeleteReason}
      onEditNextAction={handleEditNextAction}
      onDeleteNextAction={handleDeleteNextAction}
    />
  );
};
