import clsx from "clsx";
import React from "react";

import RatingStar from "../../../molecules/RatingStar";
import { ILessonRetrospectiveScore } from "../../../state";

import style from "./EvaluationLessonModal.module.scss";

export type Props = {
  defaultRating: ILessonRetrospectiveScore;
  onRating: (rating: ILessonRetrospectiveScore) => void;
  onClose: () => void;
};

const EvaluationLessonModal: React.FC<Props> = props => {
  const [rating, setRating] = React.useState<ILessonRetrospectiveScore>(
    props.defaultRating,
  );

  const isDirtyData = React.useMemo(() => {
    return !rating.understand || !rating.sympathize || !rating.execute;
  }, [rating]);

  const update = (args: { [key: string]: any }) => {
    setRating(prevValues => ({ ...prevValues, ...args }));
  };

  return (
    <div className={style.evaluationLesson}>
      <div
        className={style.evaluationLesson__backDrop}
        onClick={() => props.onClose()}
      />
      <div className={style.evaluationLesson__content}>
        <p className={style.title}>お疲れさまでした。</p>
        <div className={clsx(style.description, "text-center")}>
          <strong>
            今の自分の状態に一番近い項目をチェックして授業を終了しましょう。
          </strong>
          <br />
          <span>
            ※アンケートはクラスには共有されません。※評価には関係ありません。
          </span>
        </div>
        <div className="row mt-3">
          <div className="col-5" />
          <div className="col-7">
            <div className={style.labels}>
              <span>そう思わない</span>
              <span>そう思う</span>
            </div>
          </div>
        </div>
        <RatingStar
          indexTitle="1"
          title={
            <>
              授業の内容が理解できた
              <br />
              &nbsp;
            </>
          }
          onHandleRating={index => update({ understand: index })}
          rating={rating.understand ?? 0}
        />
        <RatingStar
          indexTitle="2"
          title={
            <>
              授業内の考え方を聞いて
              <br />
              納得した/その通りだと思った
            </>
          }
          onHandleRating={index => update({ sympathize: index })}
          rating={rating.sympathize ?? 0}
        />
        <RatingStar
          indexTitle="3"
          title={
            <>
              「自分ならこんな事ができそう」
              <br />
              という具体的な行動が浮かんだ
            </>
          }
          onHandleRating={index => update({ execute: index })}
          rating={rating.execute ?? 0}
        />
        <div className="mt-4 d-flex justify-content-end">
          <button
            className={style.btnSubmit}
            type="button"
            onClick={() => props.onRating(rating)}
            disabled={isDirtyData}
          >
            完了
          </button>
        </div>
      </div>
    </div>
  );
};

export default EvaluationLessonModal;
