import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";
import clsx from "clsx";

// import VideoJS from "../../../../../atoms/VideoJS";
import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { IAnswer, HintList } from "../../../../../constants/active_learning";
import HintCard from "../../../../../atoms/ActiveLearning/HintCard";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import styles from "../../ActiveLearningIssue.module.scss";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface ICurrentAnswer extends IAnswer {
  value: string;
}

export const Issue3 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 3;
  const question = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);

  const [answer, setAnswer] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question?.order_num || NaN,
    question_id: question?.id || NaN,
    value: "",
  });

  useEffect(() => {
    const targetAnswer = state.active_learning_answers.find(
      answer =>
        answer.issue_no === currentIssueNo &&
        answer.question_no === question?.order_num,
    );

    setAnswer({
      id: targetAnswer?.id || null,
      issue_no: currentIssueNo,
      question_no: question?.order_num || NaN,
      question_id: question?.id || 0,
      value: targetAnswer?.contents?.value || "",
    });
  }, [state.active_learning_answers, question?.order_num, question?.id]);

  useEffect(() => {
    const answers = props.answers.filter(
      answer =>
        !(
          answer.issue_no === currentIssueNo &&
          question?.order_num === answer.question_no
        ),
    );
    props.setAnswers([
      ...answers,
      {
        id: answer.id,
        issue_no: currentIssueNo,
        question_no: 1,
        question_id: question?.id || NaN,
        contents: {
          value: answer.value,
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, question]);

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          意見交換した内容やヒントを参考に、決めた解決のアイデアをもっと具体的に考えてみよう。
        </p>
      </div>
      <div className={styles.hint}>
        <p className={styles.title}>アイデアを具体的にするヒント</p>
        <div className={clsx(styles.hintList, styles.isPc)}>
          {HintList.lesson5.issue3.hint1.map((hint, index) => {
            return (
              <HintCard
                key={index}
                title={hint.title}
                width={"calc((100% - 16px) / 2)"}
              >
                {hint.text}
              </HintCard>
            );
          })}
        </div>
        <div className={clsx(styles.hintList, styles.isSp)}>
          {HintList.lesson5.issue3.hint1.map((hint, index) => {
            return (
              <HintCard key={index} title={hint.title}>
                {hint.text}
              </HintCard>
            );
          })}
        </div>
      </div>
      {question && (
        <TextArea
          value={answer.value}
          handleChange={e =>
            setAnswer({
              id: answer.id,
              question_id: question?.id || NaN,
              question_no: question?.order_num || NaN,
              issue_no: currentIssueNo,
              value: e.target.value,
            })
          }
        />
      )}
    </div>
  );
};
export default Issue3;
