import React from "react";

import TextAreaCell from "../../../atoms/ActiveLearning/TextAreaCell";

import styles from "./FeedbackTextArea.module.scss";

type IFeedbackTextAreaProps = {
  goodValue?: string;
  mottoValue?: string;
  width?: string;
  handleChangeGood?: (e: any) => void;
  handleChangeMotto?: (e: any) => void;
};

export const FeedbackTextArea: React.FC<IFeedbackTextAreaProps> = ({
  goodValue,
  mottoValue,
  width = "100%",
  handleChangeGood,
  handleChangeMotto,
}) => {
  return (
    <div className={styles.feedbackTextArea} style={{ width }}>
      <div className={styles.block}>
        <p className={styles.title}>想定される行動</p>
        <TextAreaCell
          value={goodValue}
          height={"480px"}
          handleChange={handleChangeGood}
        />
      </div>
      <div className={styles.block}>
        <p className={styles.title}>もっとこうだったらいのに/こうしたい</p>
        <TextAreaCell
          value={mottoValue}
          height={"480px"}
          handleChange={handleChangeMotto}
        />
      </div>
    </div>
  );
};

export default FeedbackTextArea;
