import React from "react";
import { useEffect, useMemo, useCallback } from "react";
import clsx from "clsx";

import {
  IActiveLearningAnswers,
  IActiveLearningIssue,
} from "../../../../state";
import { Context, updateActiveLearningAnswers } from "../../../../studentStore";
import { getActiveLearningAnswers } from "../../../../libs/ActiveLearning";
import TreeCard from "../../../../atoms/ActiveLearning/TreeCard";
import Header from "../../../../organisms/ActiveLearning/Header";
import EndLesson from "../../../../organisms/ActiveLearning/EndLesson";
import Issue1 from "../../../../organisms/student/ActiveLearningLessons/Lesson14/Issue1";
import Issue2 from "../../../../organisms/student/ActiveLearningLessons/Lesson14/Issue2";
import styles from "../ActiveLearningLesson.module.scss";
import useWaitApiCall from "../../../../hooks/useWaitApiCall";
import SaveAnswerButton from "../../../../atoms/ActiveLearning/SaveAnswerButton";

export const Lesson = () => {
  const { state, dispatch } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const [answers, setAnswers] = React.useState<IActiveLearningAnswers[]>([]);

  useEffect(() => {
    setAnswers([...state.active_learning_answers]);
  }, [state.active_learning_answers]);

  const answersByMaterial = useMemo(() => {
    return state.active_learning_answers_by_material;
  }, [state.active_learning_answers_by_material]);

  const currentActiveLearning = useMemo(() => {
    return state.active_learning_data_by_lesson;
  }, [state.active_learning_data_by_lesson]);

  const findIssue = useCallback(
    (no: number): IActiveLearningIssue | undefined =>
      currentActiveLearning.issues?.find(issue => issue.no === no),
    [currentActiveLearning.issues],
  );

  const saveAnswers = () => {
    const params = answers;
    const { material_code, no } = state.active_learning_data_by_lesson;
    if (calling) return;
    return dispatch(
      peep(
        updateActiveLearningAnswers(material_code, no, JSON.stringify(params)),
      ),
    );
  };

  const lastAnswerQ2 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers = getActiveLearningAnswers(answersByMaterial, 3, 2, 1);

    return currentAnswers.map(result => result.value).join("");
  }, [state.active_learning_answers_by_material]);

  const lastAnswerI3Q1 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers = getActiveLearningAnswers(answersByMaterial, 3, 3, 1);

    return currentAnswers.map(result => result.value).join("");
  }, [state.active_learning_answers_by_material]);

  const lastAnswerI3Q2 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers = getActiveLearningAnswers(answersByMaterial, 3, 3, 2);

    return currentAnswers.map(result => result.value).join("");
  }, [state.active_learning_answers_by_material]);

  const lastAnswerQ4 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers = getActiveLearningAnswers(answersByMaterial, 3, 4, 1);

    return currentAnswers.map(result => result.value).join("");
  }, [state.active_learning_answers_by_material]);

  const lastAnswers = useMemo(() => {
    const results = answersByMaterial
      .find(data => data.lesson_no === 3)
      ?.issues.find(i => i.issue_no === 3)
      ?.questions.find(q => q.question_no === 1)
      ?.answers?.map(answer => answer.contents);

    if (!results || results?.length === 0) return [];

    return results.map(result => result.value);
  }, [answersByMaterial]);

  return (
    <>
      <div className={styles.wrapper} id="accordion">
        <Header
          no={currentActiveLearning.no}
          label={"やりたいこと/もっと〇〇に気づく"}
          title={currentActiveLearning.title}
          read={
            "ターゲットの立場に立って、その人の行動と「もっと〇〇」を想像してみよう。\n「問い」の内容を実現させるときに、立ちはだかる問題について調査しよう。"
          }
        />
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles.review}>
              <p className={styles.title}>前回の振り返り</p>
              <div className={styles.tree}>
                <TreeCard
                  title={"取り組む問い"}
                  text={lastAnswerQ2 || "回答データがありません。"}
                />
                <ul className={styles.branchList}>
                  <li className={styles.branch}>
                    <div className={styles.labelWrapper}>
                      <span className={styles.label}>誰に？</span>
                    </div>
                    <ul className={styles.answerList}>
                      <li
                        className={clsx(
                          styles.answer,
                          lastAnswers.length < 2 && styles.single,
                        )}
                      >
                        {lastAnswerI3Q1 || "回答データがありません。"}
                      </li>
                    </ul>
                  </li>
                  <li className={styles.branch}>
                    <div className={styles.labelWrapper}>
                      <span className={styles.label}>理由</span>
                    </div>
                    <ul className={styles.answerList}>
                      <li
                        className={clsx(
                          styles.answer,
                          lastAnswers.length < 2 && styles.single,
                        )}
                      >
                        {lastAnswerI3Q2 || "回答データがありません。"}
                      </li>
                    </ul>
                  </li>
                  <li className={styles.branch}>
                    <div className={styles.labelWrapper}>
                      <span className={styles.label}>場面</span>
                    </div>
                    <ul className={styles.answerList}>
                      <li
                        className={clsx(
                          styles.answer,
                          lastAnswers.length < 2 && styles.single,
                        )}
                      >
                        {lastAnswerQ4 || "回答データがありません。"}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <Issue1
              issue={findIssue(1)}
              answers={answers}
              setAnswers={setAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <Issue2
              lastAnswer={lastAnswerQ2}
              issue={findIssue(2)}
              answers={answers}
              setAnswers={setAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <EndLesson
              text={
                "次のLessonでは、考えた問題の中から重要な問題を選び、その原因を深堀って「課題」を設定する。次回までに、どの問題を取り除いたらいいかイメージを膨らませておこう。"
              }
              saveAnswers={saveAnswers}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Lesson;
