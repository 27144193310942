import React, { Dispatch, SetStateAction, useEffect } from "react";

import {
  IActiveLearningAnswers,
  IActiveLearningQuestion,
} from "../../../state";
import { IAnswer } from "../../../constants/active_learning";
import TextArea from "../../../atoms/ActiveLearning/TextArea";

import styles from "./MultipleTextArea.module.scss";

export interface ICurrentAnswer extends IAnswer {
  good: string;
  motto: string;
}

interface IActiveLearningIssue1Props {
  activeLearningAnswers?: IActiveLearningAnswers[];
  answer?: ICurrentAnswer;
  setAnswer?: Dispatch<SetStateAction<ICurrentAnswer>>;
  question?: IActiveLearningQuestion | undefined;
  currentIssueNo?: number;
  goodLabel: string;
  mottoLabel: string;
}

export const MultipleTextArea = (props: IActiveLearningIssue1Props) => {
  useEffect(() => {
    const targetAnswer = props.activeLearningAnswers?.find(
      answer =>
        answer.issue_no === props.currentIssueNo &&
        answer.question_no === props.question?.order_num,
    );
    const answer: ICurrentAnswer = {
      id: targetAnswer?.id || null,
      issue_no: props.currentIssueNo || NaN,
      question_no: props.question?.order_num || NaN,
      question_id: props.question?.id || 0,
      good: targetAnswer?.contents?.good || "",
      motto: targetAnswer?.contents?.motto || "",
    };
    props.setAnswer && props.setAnswer(answer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeLearningAnswers, props.question]);

  return (
    <div className={styles.multiple}>
      <div className={styles.block}>
        <p className={styles.label}>{props.goodLabel}</p>
        <TextArea
          width={"100%"}
          value={props.answer?.good}
          handleChange={e =>
            props.setAnswer &&
            props.setAnswer({
              id: props.answer?.id || null,
              question_id: props.question?.id || NaN,
              question_no: props.question?.order_num || NaN,
              issue_no: props.currentIssueNo || NaN,
              good: e.target.value,
              motto: props.answer?.motto || "",
            })
          }
        />
      </div>
      <div className={styles.block}>
        <p className={styles.label}>{props.mottoLabel}</p>
        <TextArea
          width={"100%"}
          value={props.answer?.motto}
          handleChange={e =>
            props.setAnswer &&
            props.setAnswer({
              id: props.answer?.id || null,
              question_id: props.question?.id || NaN,
              question_no: props.question?.order_num || NaN,
              issue_no: props.currentIssueNo || NaN,
              good: props.answer?.good || "",
              motto: e.target.value,
            })
          }
        />
      </div>
    </div>
  );
};
export default MultipleTextArea;
