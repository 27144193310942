import React from "react";
import { useHistory } from "react-router-dom";

import { parseQueryParams } from "../../../libs/Util";
import {
  Context,
  fetchActiveLearningMaterials,
  activeLearningLessonPath,
  notFoundPath,
} from "../../../studentStore";
import ActiveLearningMaterialList from "../../../templates/student/ActiveLearningMaterialList";

export default () => {
  const { state, dispatch } = React.useContext(Context);
  const material_code = parseQueryParams()["code"];
  const history = useHistory();
  if (
    state.available_item_codes.length === 0 ||
    !state.available_item_codes.includes(material_code)
  ) {
    history.push(notFoundPath);
    return null;
  }
  React.useEffect(() => {
    dispatch(fetchActiveLearningMaterials());
  }, [dispatch]);

  const directTo = React.useCallback(
    (active_learning_material_code: string, lesson_no: number) => {
      history.push(
        `${activeLearningLessonPath}/${lesson_no}?code=${active_learning_material_code}`,
      );
    },
    [history],
  );

  return (
    <ActiveLearningMaterialList
      availableItemCodes={state.available_item_codes}
      activeLearningMaterials={state.active_learning_materials}
      directTo={directTo}
    />
  );
};
