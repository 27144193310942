import React from "react";

import { formatDate } from "../../../libs/Util";
import Modal from "../../../atoms/Modal";
import { IAssessmentPast } from "../../../state";

import styles from "./ModalPastAssessment.module.scss";

export type Props = {
  pastAssessments: IAssessmentPast[];
  onClose: () => void;
  onDirectToPastAssessment: (assessment: IAssessmentPast) => void;
};

const ModalPastAssessment: React.FC<Props> = ({
  pastAssessments,
  onClose,
  onDirectToPastAssessment,
}) => {
  const [
    selectedPastAssessment,
    setSelectedPastAssessment,
  ] = React.useState<IAssessmentPast | null>(null);

  const pastAssessmentList = React.useMemo(() => {
    return pastAssessments.sort(
      (assessment1, assessment2) => assessment2.term_no - assessment1.term_no,
    );
  }, [pastAssessments]);

  const handleClose = () => {
    setSelectedPastAssessment(null);
    onClose();
  };

  return (
    <Modal onClose={handleClose} className={styles.modalEdiPassAssessment}>
      <div className={styles.modalEdiPassAssessment__title}>
        過去の記録を編集する
      </div>
      <div className={styles.passAssessmentList}>
        {pastAssessmentList.map((pastAssessment, index) => (
          <div key={index} className={styles.passAssessmentItem}>
            <input
              className={styles.passAssessmentItem__radio}
              type="radio"
              name="past"
              id={`item-${pastAssessment.id}`}
              onChange={() => setSelectedPastAssessment(pastAssessment)}
            />
            <label
              className={styles.passAssessmentItem__label}
              htmlFor={`item-${pastAssessment.id}`}
            >
              <div className={styles.itemTitle}>
                {pastAssessment.term_no}回目
              </div>
              <div className={styles.itemCreatedAt}>
                {formatDate(pastAssessment.created_at, "/")}
              </div>
            </label>
          </div>
        ))}
      </div>
      <div className={styles.modalEdiPassAssessment__actions}>
        <button className={styles.btnCancel} onClick={handleClose}>
          キャンセル
        </button>
        <button
          className={styles.btnSave}
          disabled={!selectedPastAssessment}
          onClick={() => {
            if (!selectedPastAssessment) return;
            onDirectToPastAssessment(selectedPastAssessment);
          }}
        >
          編集する
        </button>
      </div>
    </Modal>
  );
};

export default ModalPastAssessment;
