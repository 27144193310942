import clsx from "clsx";
import React, { ButtonHTMLAttributes } from "react";

import styles from "./Button.module.scss";

type Props = {
  name: string;
  color?: "red";
  variant?: "outline" | "normal";
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<Props> = ({
  name,
  color = "green",
  variant = "outline",
  className,
  children,
  ...rest
}) => {
  return (
    <button
      className={clsx(styles.button, styles[variant], styles[color], className)}
      {...rest}
    >
      {children}
      <span>{name}</span>
    </button>
  );
};

export default Button;
