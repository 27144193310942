import React from "react";
import clsx from "clsx";

import { ReactComponent as SPAssessmentDone } from "../../../assets/svg/sp_assessment_done.svg";
import { ReactComponent as SPAssessment1 } from "../../../assets/svg/sp_assessment_1.svg";
import { ReactComponent as SPAssessment2 } from "../../../assets/svg/sp_assessment_2.svg";
import { ReactComponent as SPAssessment3 } from "../../../assets/svg/sp_assessment_3.svg";
import { ReactComponent as SPAssessment4 } from "../../../assets/svg/sp_assessment_4.svg";
import { ReactComponent as SPAssessment5 } from "../../../assets/svg/sp_assessment_5.svg";
import { ReactComponent as SPAssessment6 } from "../../../assets/svg/sp_assessment_6.svg";
import { ReactComponent as SPAssessment7 } from "../../../assets/svg/sp_assessment_7.svg";
import { ReactComponent as SPAssessment8 } from "../../../assets/svg/sp_assessment_8.svg";
import AssessmentContent from "../../../organisms/student/AssessmentContent";
import {
  PAGE_1,
  PAGE_2,
  PAGE_3,
  PAGE_4,
  PAGE_5,
  PAGE_6,
  PAGE_7,
  NEXT_ACTIONS_PAGE,
  X_LABELS_JSX,
  X_LABELS_JSX_SP,
} from "../../../constants/assessment-content";
import BarChart, { IPastAssessmentScore } from "../../../atoms/BarChart";
import DropdownTerm, { ITerm } from "../../../atoms/DropdownTerm";
import {
  IAssessment,
  IAssessmentPast,
  IAssessmentBulkUpdate,
  IAssessmentSelect,
} from "../../../state";
import AssessmentContentForSP from "../../../organisms/student/AssessmentContentForSP";
import { formatDate } from "../../../libs/Util";
import { NextActionKind, NextActionKinds } from "../../../constants/enum";

import styles from "./Assessment.module.scss";

export type Props = {
  studentName: string;
  assessment: IAssessment;
  isNewAssessment: boolean;
  assessments_past: IAssessmentPast[];
  previousAssessment: IAssessment | IAssessmentPast | null;
  assessmentStorage: {
    data: IAssessmentBulkUpdate;
    isChange: boolean;
    scores: number[];
    isAllScore: boolean;
  };
  calling: boolean;
  saveScore: (experience_no: number, score: number) => void;
  saveReason: (experience_no: number, reason: string) => void;
  saveNextAction: (kind: NextActionKind, next_action: string) => void;
  onSubmitExperience: () => void;
  onEditReason: (id: number, reason: string) => void;
  onDeleteReason: (id: number) => void;
  onEditNextAction: (id: number, next_action: string) => void;
  onDeleteNextAction: (id: number) => void;
};

const getTermName = (assessment: { term_no: number; created_at: string }) =>
  `${assessment.term_no}回目 (${formatDate(assessment.created_at, "/")})`;

const Assessment: React.FC<Props> = ({
  studentName,
  assessment,
  assessments_past,
  previousAssessment,
  isNewAssessment,
  assessmentStorage,
  calling,
  saveScore,
  saveReason,
  saveNextAction,
  onSubmitExperience,
  onEditReason,
  onDeleteReason,
  onEditNextAction,
  onDeleteNextAction,
}) => {
  const contentSPref = React.useRef<HTMLDivElement>(null);
  const switchRef = React.useRef<HTMLDivElement>(null);
  const [tabActive, setTabActive] = React.useState(1);
  const [pastAssessmentScores, setPastAssessmentScores] = React.useState<
    IPastAssessmentScore[]
  >([]);
  const [previousTerms, setPreviousTerms] = React.useState<ITerm[]>([]);
  const [selectedTerms, setSelectedTerms] = React.useState<number[]>([]);

  const showAssessmentContent = () => {
    switch (tabActive) {
      case 1:
        return PAGE_1;
      case 2:
        return PAGE_2;
      case 3:
        return PAGE_3;
      case 4:
        return PAGE_4;
      case 5:
        return PAGE_5;
      case 6:
        return PAGE_6;
      case 7:
        return PAGE_7;
      default:
        return NEXT_ACTIONS_PAGE;
    }
  };

  const currentScores = assessmentStorage.scores;
  const isActiveNextAction = assessmentStorage.isAllScore;
  const currentReason = React.useMemo(
    () =>
      assessmentStorage.data.reasons.find(r => r.experience_no === tabActive)
        ?.reason ?? null,
    [assessmentStorage, tabActive],
  );
  const currentNextActions = React.useMemo(() => {
    const res: { [key: number]: string | null } = {};
    NextActionKinds.forEach(k => {
      res[k.kind] =
        assessmentStorage.data.next_actions.find(item => item.kind === k.kind)
          ?.next_action ?? null;
    });
    return res;
  }, [assessmentStorage]);

  React.useEffect(() => {
    const observerItem = document.createElement("div");
    observerItem.classList.add(styles.observerItem);
    if (switchRef.current && switchRef.current.parentNode) {
      switchRef.current.parentNode.insertBefore(
        observerItem,
        switchRef.current,
      );
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].intersectionRatio === 0) {
            switchRef.current &&
              switchRef.current.classList.add(styles.stickyElement);
            observerItem.style.height = "80px";
          } else if (entries[0].intersectionRatio === 1) {
            switchRef.current &&
              switchRef.current.classList.remove(styles.stickyElement);
            observerItem.style.height = "1px";
          }
        },
        { threshold: [0, 1] },
      );
      observer.observe(observerItem);
    }
  }, []);

  React.useEffect(() => {
    const selectedAssessmentPast: IAssessmentSelect[] = assessments_past.filter(
      assessment => selectedTerms.includes(assessment.term_no),
    );
    selectedTerms.forEach(term => {
      if (
        !selectedAssessmentPast.some(assessment => assessment.term_no === term)
      ) {
        const assessmentEmpty: IAssessmentSelect = {
          id: term,
          term_no: term,
          assessment_experiences: Array.from({ length: 7 }, (_, i) => ({
            experience_no: i + 1,
            score: 0,
          })),
        };
        selectedAssessmentPast.push(assessmentEmpty);
      }
    });
    const pastScores: IPastAssessmentScore[] = selectedAssessmentPast
      .sort(
        (assessment1, assessment2) => assessment1.term_no - assessment2.term_no,
      )
      .map(assessment => ({
        term_no: assessment.term_no,
        scores: assessment.assessment_experiences.map(item => item.score),
      }));
    setPastAssessmentScores(pastScores);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTerms]);

  React.useEffect(() => {
    if (assessments_past.length > 0 && !isNewAssessment) {
      const terms: ITerm[] = assessments_past
        .filter(a => a.term_no < assessment.term_no)
        .map(a => ({
          term_no: a.term_no,
          name: getTermName(a),
        }));
      setPreviousTerms(terms);
    }
  }, [assessments_past, assessment.term_no, isNewAssessment]);

  const handleOnSaveScore = (score: number) => {
    saveScore(tabActive, score);
  };

  const handleOnSaveReason = (reason: string) => {
    saveReason(tabActive, reason);
  };

  return (
    <div className={clsx(styles.wrapper)}>
      <div className={styles.wrapperTitle}>
        <p className={styles.title}>
          <span>{studentName}</span>ENAGEED GLIDE
        </p>
        {isActiveNextAction && !isNewAssessment && (
          <div className={styles.assessmentTermWrapper}>
            <p className={styles.assessmentCurrentTitle}>
              {getTermName(assessment)}
            </p>
            {!!previousTerms.length && (
              <DropdownTerm
                previousTerms={previousTerms}
                onChange={setSelectedTerms}
              />
            )}
          </div>
        )}
      </div>
      <div className={styles.wrapperContent}>
        <div
          className={clsx(
            styles.summary,
            !isActiveNextAction && styles.emptyData,
          )}
        >
          {isActiveNextAction && (
            <BarChart
              currentData={currentScores}
              pastData={pastAssessmentScores}
            />
          )}
        </div>
        <div
          ref={switchRef}
          className={clsx(
            styles.switch,
            !isActiveNextAction && styles.hasBorder,
          )}
        >
          <div
            className={clsx(
              styles.switch__item,
              tabActive === PAGE_1.id && [styles.active, styles.selected],
              currentScores[0] > 0 && styles.active,
            )}
            onClick={() => setTabActive(1)}
          >
            <div>
              <p>1</p>
              <span>{X_LABELS_JSX[0]}</span>
            </div>
          </div>
          <div
            className={clsx(
              styles.switch__item,
              tabActive === PAGE_2.id && [styles.active, styles.selected],
              currentScores[1] > 0 && styles.active,
            )}
            onClick={() => setTabActive(2)}
          >
            <div>
              <p>2</p>
              <span>{X_LABELS_JSX[1]}</span>
            </div>
          </div>
          <div
            className={clsx(
              styles.switch__item,
              tabActive === PAGE_3.id && [styles.active, styles.selected],
              currentScores[2] > 0 && styles.active,
            )}
            onClick={() => setTabActive(3)}
          >
            <div>
              <p>3</p>
              <span>{X_LABELS_JSX[2]}</span>
            </div>
          </div>
          <div
            className={clsx(
              styles.switch__item,
              tabActive === PAGE_4.id && [styles.active, styles.selected],
              currentScores[3] > 0 && styles.active,
            )}
            onClick={() => setTabActive(4)}
          >
            <div>
              <p>4</p>
              <span>{X_LABELS_JSX[3]}</span>
            </div>
          </div>
          <div
            className={clsx(
              styles.switch__item,
              tabActive === PAGE_5.id && [styles.active, styles.selected],
              currentScores[4] > 0 && styles.active,
            )}
            onClick={() => setTabActive(5)}
          >
            <div>
              <p>5</p>
              <span>{X_LABELS_JSX[4]}</span>
            </div>
          </div>
          <div
            className={clsx(
              styles.switch__item,
              tabActive === PAGE_6.id && [styles.active, styles.selected],
              currentScores[5] > 0 && styles.active,
            )}
            onClick={() => setTabActive(6)}
          >
            <div>
              <p>6</p>
              <span>{X_LABELS_JSX[5]}</span>
            </div>
          </div>
          <div
            className={clsx(
              styles.switch__item,
              tabActive === PAGE_7.id && [styles.active, styles.selected],
              currentScores[6] > 0 && styles.active,
            )}
            onClick={() => setTabActive(7)}
          >
            <div>
              <p>7</p>
              <span>{X_LABELS_JSX[6]}</span>
            </div>
          </div>
          <div
            className={clsx(
              styles.switch__item,
              tabActive === NEXT_ACTIONS_PAGE.id && [
                styles.active,
                styles.selected,
              ],
            )}
            onClick={() => {
              if (isActiveNextAction) setTabActive(8);
            }}
          >
            <span>まとめ</span>
          </div>
        </div>
        <AssessmentContent
          isActiveNextAction={isActiveNextAction}
          content={showAssessmentContent()}
          selectedScore={currentScores[tabActive - 1]}
          currentReason={currentReason}
          currentNextActions={currentNextActions}
          previousAssessment={previousAssessment}
          assessmentExperience={
            assessment.assessment_experiences[tabActive - 1] ?? null
          }
          assessmentNextActions={assessment.assessment_next_actions}
          calling={calling}
          onSaveScore={handleOnSaveScore}
          onSaveReason={handleOnSaveReason}
          onSaveNextAction={saveNextAction}
          onEditReason={onEditReason}
          onDeleteReason={onDeleteReason}
          onEditNextAction={onEditNextAction}
          onDeleteNextAction={onDeleteNextAction}
        />
      </div>
      <div
        className={clsx(
          "position-fixed bottom-0 align-items-center justify-content-end",
          styles.button_wrapper,
        )}
      >
        <button
          className={styles.saveBtn}
          disabled={calling || !assessmentStorage.isChange}
          onClick={onSubmitExperience}
        >
          保存
        </button>
      </div>
      <div className={styles.wrapper__headerSP}>
        <div className={styles.specer} />
        <div className={styles.titleSP}>ENAGEED GLIDE</div>
        <button
          className={styles.saveBtn}
          disabled={calling || !assessmentStorage.isChange}
          onClick={onSubmitExperience}
        >
          保存
        </button>
      </div>
      <div className={styles.wrapper__contentSP} ref={contentSPref}>
        {tabActive === NEXT_ACTIONS_PAGE.id && (
          <div className={styles.wrapper__contentSP__chart}>
            <p className={styles.wrapper__contentSP__studentName}>
              {studentName}
            </p>
            {!isNewAssessment && (
              <div className={styles.assessmentTermWrapper}>
                <p
                  className={clsx(
                    styles.assessmentCurrentTitle,
                    styles.assessmentTitleSP,
                  )}
                >
                  {getTermName(assessment)}
                </p>
                {!!previousTerms.length && (
                  <DropdownTerm
                    className={styles.wrapper__contentSP__selectTerms}
                    previousTerms={previousTerms}
                    onChange={setSelectedTerms}
                  />
                )}
              </div>
            )}
            <BarChart
              currentData={currentScores}
              pastData={pastAssessmentScores}
            />
            <div className={styles.wrapper__contentSP__xLabels}>
              {X_LABELS_JSX_SP.map((label, index) => (
                <div
                  className={styles.wrapper__contentSP__xLabels__item}
                  key={index}
                >
                  {label}
                </div>
              ))}
            </div>
          </div>
        )}
        <AssessmentContentForSP
          isActiveNextAction={isActiveNextAction}
          content={showAssessmentContent()}
          selectedScore={currentScores[tabActive - 1]}
          currentReason={currentReason}
          currentNextActions={currentNextActions}
          assessmentExperience={
            assessment.assessment_experiences[tabActive - 1] ?? null
          }
          assessmentNextActions={assessment.assessment_next_actions}
          previousAssessment={previousAssessment}
          calling={calling}
          onSaveScore={handleOnSaveScore}
          onSaveReason={handleOnSaveReason}
          onSaveNextAction={saveNextAction}
          onEditReason={onEditReason}
          onDeleteReason={onDeleteReason}
          onEditNextAction={onEditNextAction}
          onDeleteNextAction={onDeleteNextAction}
          onChangeTab={tab => {
            setTabActive(tab);
            contentSPref.current?.scrollTo({ top: 0 });
          }}
        />
      </div>
      <div className={styles.wrapper__footerSP}>
        <button
          className={clsx(
            styles.wrapper__footerSP__item,
            tabActive === PAGE_1.id && styles.active,
          )}
          onClick={() => setTabActive(1)}
        >
          <SPAssessment1 />
          <span>{X_LABELS_JSX_SP[0]}</span>
        </button>
        <button
          className={clsx(
            styles.wrapper__footerSP__item,
            tabActive === PAGE_2.id && styles.active,
          )}
          onClick={() => setTabActive(2)}
        >
          <SPAssessment2 />
          <span>{X_LABELS_JSX_SP[1]}</span>
        </button>
        <button
          className={clsx(
            styles.wrapper__footerSP__item,
            tabActive === PAGE_3.id && styles.active,
          )}
          onClick={() => setTabActive(3)}
        >
          <SPAssessment3 />
          <span>{X_LABELS_JSX_SP[2]}</span>
        </button>
        <button
          className={clsx(
            styles.wrapper__footerSP__item,
            tabActive === PAGE_4.id && styles.active,
          )}
          onClick={() => setTabActive(4)}
        >
          <SPAssessment4 />
          <span>{X_LABELS_JSX_SP[3]}</span>
        </button>
        <button
          className={clsx(
            styles.wrapper__footerSP__item,
            tabActive === PAGE_5.id && styles.active,
          )}
          onClick={() => setTabActive(5)}
        >
          <SPAssessment5 />
          <span>{X_LABELS_JSX_SP[4]}</span>
        </button>
        <button
          className={clsx(
            styles.wrapper__footerSP__item,
            tabActive === PAGE_6.id && styles.active,
          )}
          onClick={() => setTabActive(6)}
        >
          <SPAssessment6 />
          <span>{X_LABELS_JSX_SP[5]}</span>
        </button>
        <button
          className={clsx(
            styles.wrapper__footerSP__item,
            tabActive === PAGE_7.id && styles.active,
          )}
          onClick={() => setTabActive(7)}
        >
          <SPAssessment7 />
          <span>{X_LABELS_JSX_SP[6]}</span>
        </button>
        <button
          className={clsx(
            styles.wrapper__footerSP__item,
            tabActive === NEXT_ACTIONS_PAGE.id && styles.active,
          )}
          onClick={() => setTabActive(8)}
        >
          <SPAssessment8 />
          {tabActive !== NEXT_ACTIONS_PAGE.id && (
            <SPAssessmentDone className={styles.wrapper__footerSP__icon} />
          )}
          <span>まとめ</span>
        </button>
      </div>
    </div>
  );
};

export default Assessment;
