import React from "react";

import RadarChart from "../../../atoms/RadarChart";
import Dropdown from "../../../atoms/Dropdown";
import { IPackage, ISurveyAnswers, IDetailsSurvey } from "../../../state";
import { keyGenerator } from "../../../libs/Util";

import styles from "./DataSurvey.module.scss";

export type Props = {
  packages: IPackage[];
  details_survey: IDetailsSurvey[] | null;
  survey_answers: ISurveyAnswers[];
  survey_answers_per_teaching_material: ISurveyAnswers[];
  fetchSurveyFunc: (material_id: number) => void;
  fetchDetailsSurveyFunc: (package_id: number) => void;
};

const DataSurvey: React.FC<Props> = ({
  packages,
  survey_answers,
  survey_answers_per_teaching_material,
  fetchSurveyFunc,
  fetchDetailsSurveyFunc,
  details_survey,
}) => {
  const checkScore = (question: any, score: number) => {
    if (question.first_answer === score && question.last_answer === score)
      return styles.score__same;
    else if (question.first_answer === score) return styles.score__gray;
    else if (question.last_answer === score) return styles.score__blue;
  };
  return (
    <div className={styles.wrapper}>
      <Dropdown
        options={packages.map(pack => ({
          value: pack.id,
          label: `${pack.name}`,
          data: pack.teaching_materials[pack.teaching_materials.length - 1].id,
        }))}
        onChange={(package_id, material_id) => {
          fetchSurveyFunc(material_id);
          fetchDetailsSurveyFunc(package_id);
        }}
      />
      <RadarChart
        survey_answers={survey_answers}
        survey_answers_per_teaching_material={
          survey_answers_per_teaching_material
        }
        className={styles.chart}
      />
      <div className={styles.note}>
        <h5>事前事後アンケート回答結果</h5>
        <div>
          <p>授業前アンケート結果</p>
          <p>授業後アンケート結果</p>
        </div>
      </div>
      {details_survey &&
        details_survey.map(
          item =>
            item.survey_topics &&
            item.survey_topics.map(topic => (
              <div className={styles.result_survey} key={item.id + topic.name}>
                <div className={styles.topic}>
                  <div className={styles.name}>
                    <strong>- {topic.name} -</strong>
                  </div>
                  <div className={styles.score}>
                    <span>1</span>
                    <span>1.5</span>
                    <span>2</span>
                    <span>2.5</span>
                    <span>3</span>
                    <span>3.5</span>
                    <span>4</span>
                    <span>4.5</span>
                    <span>5</span>
                  </div>
                </div>
                {topic.questions.map(question => (
                  <div key={keyGenerator(question)} className={styles.question}>
                    <div className={styles.name}>{question.content}</div>
                    <div className={styles.score}>
                      <span className={checkScore(question, 1)} />
                      <span className={checkScore(question, 1.5)} />
                      <span className={checkScore(question, 2)} />
                      <span className={checkScore(question, 2.5)} />
                      <span className={checkScore(question, 3)} />
                      <span className={checkScore(question, 3.5)} />
                      <span className={checkScore(question, 4)} />
                      <span className={checkScore(question, 4.5)} />
                      <span className={checkScore(question, 5)} />
                    </div>
                  </div>
                ))}
              </div>
            )),
        )}
    </div>
  );
};

export default DataSurvey;
