import React from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import { ReactComponent as MenuIcon } from "../../../assets/svg/menu.svg";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

import styles from "./HamburgerMenu.module.scss";

interface IHamburgerMenuProps {
  className?: string;
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
}

const HamburgerMenu: React.FC<IHamburgerMenuProps> = ({
  className = "",
  isOpen,
  onToggle,
  children,
}) => {
  const location = useLocation();
  const toggleMenu = () => onToggle(!isOpen);
  const handleClose = () => onToggle(false);

  useDidMountEffect(() => {
    onToggle(false);
  }, [location.pathname]);

  return (
    <div className={clsx(styles.hamburgerMenuWrapper, className)}>
      <button className={styles.hamburgerMenuButton} onClick={toggleMenu}>
        <MenuIcon />
      </button>
      {isOpen && (
        <div className={styles.modalWrapper}>
          <div className={styles.backdrop} onClick={handleClose} />
          {children}
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
