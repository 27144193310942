import React from "react";

import Modal from "../../atoms/ActiveLearning/Modal";

import styles from "./ActiveLearningModalConfirm.module.scss";

export type Props = {
  title: string;
  buttons: {
    title: string;
    type: "Success" | "Warning" | "Danger" | "None";
    action: () => void;
  }[];
  onClose: () => void;
};

const ActiveLearningModalConfirm: React.FC<Props> = ({
  onClose,
  title,
  buttons,
}) => {
  return (
    <Modal handleClose={onClose} className={styles.modal}>
      <p className={styles.title}>{title}</p>
      <div className={styles.actions}>
        {buttons.map((btn, index) => (
          <button
            key={index}
            className={styles[btn.type.toLocaleLowerCase()]}
            onClick={btn.action}
          >
            {btn.title}
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default ActiveLearningModalConfirm;
