import React from "react";
import { useHistory } from "react-router-dom";

import AssessmentDesc from "../../../templates/student/AssessmentDesc";
import {
  Context,
  assessmentPath,
  fetchAssessment,
  fetchAssessmentPast,
  setCurrentAssessment,
} from "../../../studentStore";
import { IAssessmentPast } from "../../../state";

export default () => {
  const history = useHistory();

  const { state, dispatch } = React.useContext(Context);

  React.useEffect(() => {
    if (!!state.student?.max_term_no) {
      dispatch(fetchAssessment());
      dispatch(fetchAssessmentPast());
    }
  }, [dispatch, state.student]);

  const handleDirectToAssessment = React.useCallback(() => {
    history.push(assessmentPath);
  }, [history]);

  const handleDirectToCreateAssessment = React.useCallback(() => {
    history.push({ pathname: assessmentPath, search: "new" });
  }, [history]);

  const handleDirectToPastAssessment = React.useCallback(
    (assessment: IAssessmentPast) => {
      dispatch(setCurrentAssessment(assessment));
      history.push({ pathname: assessmentPath, search: "past" });
    },
    [history, dispatch],
  );

  return (
    <AssessmentDesc
      onDirectToAssessment={handleDirectToAssessment}
      onDirectToCreateAssessment={handleDirectToCreateAssessment}
      onDirectToPastAssessment={handleDirectToPastAssessment}
      hasAssessment={!!state.student?.max_term_no}
      currentAssessment={state.assessment}
      pastAssessments={state.assessments_past}
    />
  );
};
