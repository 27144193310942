import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
  useContext,
  useEffect,
} from "react";
import clsx from "clsx";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { IAnswer, HintList } from "../../../../../constants/active_learning";
import HintCard from "../../../../../atoms/ActiveLearning/HintCard";
import TextInput from "../../../../../atoms/ActiveLearning/TextInput";
import styles from "../../ActiveLearningIssue.module.scss";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface IAnswerToQ1 extends IAnswer {
  value: string;
}

export const Issue3 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 3;
  const question = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);

  const [answer, setAnswer] = useState<IAnswerToQ1>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question?.order_num || NaN,
    question_id: question?.id || NaN,
    value: "",
  });

  useEffect(() => {
    const targetAnswer = state.active_learning_answers.find(
      answer =>
        answer.issue_no === currentIssueNo &&
        answer.question_no === question?.order_num,
    );
    const answerFormatOfI1Q1: IAnswerToQ1 = {
      id: targetAnswer?.id || 0,
      issue_no: currentIssueNo,
      question_no: 1,
      question_id: targetAnswer?.question_id || 0,
      value: targetAnswer?.contents?.value || "",
    };
    setAnswer({ ...answerFormatOfI1Q1 });
  }, [state.active_learning_answers, question?.order_num]);

  useEffect(() => {
    const answers = props.answers.filter(
      answer =>
        !(
          answer.issue_no === currentIssueNo &&
          answer.question_no === question?.order_num
        ),
    );
    props.setAnswers([
      ...answers,
      {
        id: answer.id || null,
        issue_no: answer.issue_no,
        question_no: question?.order_num || NaN,
        question_id: question?.id || NaN,
        contents: {
          value: answer.value,
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, question?.order_num, question?.id]);

  const changeAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAnswer({
      id: answer.id,
      question_id: question?.id || NaN,
      question_no: question?.order_num || NaN,
      issue_no: currentIssueNo,
      value,
    });
  };

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          WORK02では、社会で価値を生み出す事例を知った。グループワークで得た周りの意見を参考にして、これから取り組んでいく「問い」を考えてみよう。
          <br />
          『「問い」を立てる』とは、やりたいこと/願い/希望/想いなどの「自分だったらこうしたい」を明確にし、「それを実現するためには？」という議題を投げかけることを指す。
          <br />
          与えられた情報を覚えるだけでなく、自分で考え、調査し、学びを深めていくことが重要なので、まずは自分自身に問いかけ、これから何について考えていくかを定めよう。
        </p>
      </div>
      <div className={styles.hint}>
        <p className={styles.title}>「問い」を考えるためのヒント</p>
        <div className={clsx(styles.hintList, styles.isPc)}>
          {HintList.lesson12.issue3.hint1.map((hint, index) => {
            return (
              <HintCard
                key={index}
                title={hint.title}
                width={"calc((100% - 16px) / 2)"}
              >
                {hint.text}
              </HintCard>
            );
          })}
        </div>
        <div className={clsx(styles.hintList, styles.isSp)}>
          {HintList.lesson12.issue3.hint1.map((hint, index) => {
            return (
              <HintCard key={index} title={hint.title}>
                {hint.text}
              </HintCard>
            );
          })}
        </div>
      </div>
      {question && (
        <TextInput value={answer.value} handleChange={changeAnswer} />
      )}
    </div>
  );
};
export default Issue3;
