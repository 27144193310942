import { useCallback, useMemo, useState } from "react";

export interface IAnswerStorageValue {
  [key: number]: string | null;
}

const useAnswerStorage = (): [
  {
    storageValue: IAnswerStorageValue;
    tempValue: IAnswerStorageValue;
  },
  {
    setQuestionIds: (questionIds: number[]) => IAnswerStorageValue;
    setTempValue: (questionId: number, value: string) => void;
    saveValues: () => void;
    removeValues: () => void;
  },
] => {
  const [storageState, setStorageState] = useState<IAnswerStorageValue>({});
  const [tempState, setTempState] = useState<IAnswerStorageValue>({});
  const [ids, setIds] = useState<number[]>([]);

  const setQuestionIds = useCallback((questionIds: number[]) => {
    const newState: IAnswerStorageValue = {};

    questionIds.forEach(id => {
      newState[id] = localStorage.getItem(`question_answer_${id}`);
    });
    setStorageState(newState);
    setTempState({ ...newState }); // copy object
    setIds(questionIds);

    return newState;
  }, []);

  const setTempValue = useCallback((questionId: number, value: string) => {
    setTempState(prevState => ({
      ...prevState,
      [questionId]: value,
    }));
  }, []);

  const saveValues = useCallback(() => {
    ids.forEach(id => {
      const value = tempState[id];
      if (value !== null) {
        localStorage.setItem(`question_answer_${id}`, value);
      }
    });
    // copy tempState to storageState
    setStorageState({ ...tempState });
  }, [ids, tempState]);

  const removeValues = useCallback(() => {
    const newState: IAnswerStorageValue = {};
    ids.forEach(id => {
      localStorage.removeItem(`question_answer_${id}`);
      newState[id] = null;
    });

    setStorageState(newState);
    setTempState({ ...newState }); // copy object
  }, [ids]);

  const answerStorage = useMemo(
    () => ({
      setQuestionIds,
      setTempValue,
      saveValues,
      removeValues,
    }),
    [setQuestionIds, setTempValue, saveValues, removeValues],
  );

  return [{ storageValue: storageState, tempValue: tempState }, answerStorage];
};

export default useAnswerStorage;
