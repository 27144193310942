import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";

import {
  Context,
  myDataPath,
  assessmentDescriptionPath,
} from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningQuestion,
  IActiveLearningAnswers,
} from "../../../../../state";
import {
  IAnswer,
  CONFIRM_PAGE_LEAVE_TEXT,
} from "../../../../../constants/active_learning";
import Button from "../../../../../atoms/ActiveLearning/Button";
import ActiveLearningModalConfirm from "../../../../../molecules/ActiveLearningModalConfirm";
import CheckModal from "../../../../ActiveLearning/CheckModal";
import InterestTable from "../../../../ActiveLearning/InterestTable";
import styles from "../../ActiveLearningIssue.module.scss";
import { ReactComponent as IconLive } from "../../../../../assets/images/ActiveLearning/icon_live.svg";
import { ReactComponent as IconOpen } from "../../../../../assets/images/ActiveLearning/icon_open.svg";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
  setFieldCounts: Dispatch<SetStateAction<{ [fieldId: number]: number }>>;
  setActionCounts: Dispatch<SetStateAction<{ [actionId: number]: number }>>;
  actionCategories: { id: number; name: string }[];
  fieldCategories: { id: number; name: string }[];
  saveAnswers: () => void;
}

export interface IAnswerToQ1 extends IAnswer<string> {
  interest: string;
  reason: string;
  field_ids: number[];
}

export interface IAnswerToQ2 extends IAnswer<string> {
  action_ids: number[];
}

export const Issue1 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 1;
  const history = useHistory();

  const question = useMemo(() => {
    return props.issue?.questions.find(question => question.order_num === 1);
  }, [props.issue?.questions]);
  const question2 = useMemo(() => {
    return props.issue?.questions.find(question => question.order_num === 2);
  }, [props.issue?.questions]);

  const [answersToQ1, setAnswersToQ1] = useState<IAnswerToQ1[]>([]);
  const [answerToQ2, setAnswerToQ2] = useState<IAnswerToQ2>({
    id: "",
    issue_no: currentIssueNo,
    question_no: question2?.order_num || NaN,
    question_id: question2?.id || NaN,
    action_ids: [],
  });
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);

  useEffect(() => {
    const answers: IActiveLearningAnswers[] = answersToQ1
      .filter((answer: IAnswerToQ1) => {
        // interest & reason共未入力の回答情報を除外
        const ai = answer.interest;
        const ar = answer.reason;
        return ai?.length > 0 || ar?.length > 0;
      })
      .map((answer: IAnswerToQ1) => {
        return {
          id: answer.id?.includes("#") ? null : Number(answer.id),
          issue_no: answer.issue_no,
          question_no: answer.question_no,
          question_id: answer.question_id,
          contents: {
            interest: answer.interest,
            reason: answer.reason,
            field_ids: answer.field_ids,
          },
        };
      });

    // この設問以外の回答情報
    const otherAnswers = props.answers.filter(
      answer =>
        !(answer.issue_no === currentIssueNo && answer.question_no === 1),
    );
    props.setAnswers([...otherAnswers, ...answers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersToQ1]);

  useEffect(() => {
    const answers: IActiveLearningAnswers = {
      id: answerToQ2.id?.includes("#") ? null : Number(answerToQ2.id),
      issue_no: answerToQ2.issue_no,
      question_no: answerToQ2.question_no,
      question_id: answerToQ2.question_id,
      contents: {
        action_ids: answerToQ2.action_ids,
      },
    };

    // この設問以外の回答情報
    const otherAnswers = props.answers.filter(
      answer =>
        !(answer.issue_no === currentIssueNo && answer.question_no === 2),
    );
    props.setAnswers([...otherAnswers, answers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerToQ2]);

  useEffect(() => {
    const answersQ1 = state.active_learning_answers.filter(
      answer => answer.issue_no === currentIssueNo && answer.question_no === 1,
    );

    const initAnswersQ1: IAnswerToQ1[] =
      answersQ1.length > 0
        ? answersQ1.map(answer => ({
            id: String(answer.id),
            issue_no: answer.issue_no,
            question_no: answer.question_no,
            question_id: answer.question_id,
            interest: answer.contents?.interest || "",
            reason: answer.contents?.reason || "",
            field_ids: answer.contents?.field_ids || [],
          }))
        : [...Array(3)].map(_ => {
            return {
              id: `#${Math.random().toString(36).slice(-8)}`,
              issue_no: currentIssueNo,
              question_id: question?.id || NaN,
              question_no: question?.order_num || NaN,
              interest: "",
              reason: "",
              field_ids: [],
            };
          });
    setAnswersToQ1([...initAnswersQ1]);

    const answerQ2 = state.active_learning_answers.find(
      answer => answer.issue_no === currentIssueNo && answer.question_no === 2,
    );
    setAnswerToQ2({
      id: String(answerQ2?.id),
      issue_no: currentIssueNo || NaN,
      question_no: question2?.order_num || NaN,
      question_id: question2?.id || NaN,
      action_ids: answerQ2?.contents?.action_ids || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.active_learning_answers]);

  const addAnswerField = (question: IActiveLearningQuestion) => {
    const oneTimeId = `#${Math.random().toString(36).slice(-8)}`;
    setAnswersToQ1([
      ...answersToQ1,
      {
        id: oneTimeId,
        issue_no: props.issue?.no || NaN,
        question_id: question.id,
        question_no: question.order_num,
        interest: "",
        reason: "",
        field_ids: [],
      },
    ]);
  };

  const [isShowCheckModal, setIsShowCheckModal] = useState<{
    attrName: "action_ids" | "field_ids" | null;
    answerId: string;
  }>({
    attrName: null,
    answerId: "",
  });

  const handleTextAnswerChange = (e: any, attrName: "interest" | "reason") => {
    const { name, value } = e.target;
    const results = answersToQ1.map(answer => {
      if (answer.id === name) {
        answer[attrName] = value;
      }
      return answer;
    });

    setAnswersToQ1([...results]);
  };

  const handleCheckFieldCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    const attrName = "field_ids";
    const { name, value } = e.target;

    const results = answersToQ1.map(answer => {
      if (answer.id === name) {
        const checkedActionIds = answer[attrName];
        const checkedId = Number(value);
        if (checkedActionIds.includes(checkedId)) {
          answer[attrName] = checkedActionIds.filter(id => id !== checkedId);
        } else {
          answer[attrName] = [...checkedActionIds, checkedId];
        }
      }
      return answer;
    });

    setAnswersToQ1([...results]);
  };

  const handleCheckActionCategory = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const checkedId = Number(value);
      const checkedActionIds = answerToQ2.action_ids;
      const results = checkedActionIds.includes(checkedId)
        ? checkedActionIds.filter(id => id !== checkedId)
        : [...checkedActionIds, checkedId];

      setAnswerToQ2({ ...answerToQ2, action_ids: results });
    },
    [answerToQ2],
  );

  const checkedAnswerIds = useMemo(() => {
    const result: {
      [key: string]: { field_ids: number[] };
    } = {};

    answersToQ1.forEach(answer => {
      result[answer.id] = {
        field_ids: answer.field_ids,
      };
    });
    return result;
  }, [answersToQ1]);

  useEffect(() => {
    const countFieldCategory = answersToQ1
      .flatMap(answer => answer.field_ids)
      .reduce<{ [fieldId: number]: number }>((acc, fieldId) => {
        acc[fieldId] = (acc[fieldId] || 0) + 1;
        return acc;
      }, {});

    const countActionCategory = answerToQ2.action_ids.reduce<{
      [actionId: number]: number;
    }>((acc, actionId) => {
      acc[actionId] = (acc[actionId] || 0) + 1;
      return acc;
    }, {});

    props.setFieldCounts(countFieldCategory);
    props.setActionCounts(countActionCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersToQ1, answerToQ2]);

  return (
    <>
      <div>
        {isShowCheckModal.attrName === "field_ids" && (
          <CheckModal
            checkCategories={props.fieldCategories}
            name={isShowCheckModal.answerId}
            checkedAnswerIds={
              checkedAnswerIds[isShowCheckModal.answerId][
                isShowCheckModal.attrName
              ]
            }
            changeCategory={e => handleCheckFieldCategory(e)}
            handleClose={() =>
              setIsShowCheckModal({
                ...isShowCheckModal,
                attrName: null,
              })
            }
          />
        )}
        {isShowCheckModal.attrName === "action_ids" && (
          <CheckModal
            checkCategories={props.actionCategories}
            name={isShowCheckModal.answerId}
            checkedAnswerIds={answerToQ2.action_ids}
            changeCategory={e => handleCheckActionCategory(e)}
            handleClose={() =>
              setIsShowCheckModal({
                ...isShowCheckModal,
                attrName: null,
              })
            }
          />
        )}
        {isShowModalConfirm && (
          <ActiveLearningModalConfirm
            onClose={() => setIsShowModalConfirm(false)}
            title={CONFIRM_PAGE_LEAVE_TEXT}
            buttons={[
              {
                title: "いいえ",
                action: () => {
                  setIsShowModalConfirm(false);
                },
                type: "None",
              },
              {
                title: "はい",
                action: () => {
                  props.saveAnswers();
                  setIsShowModalConfirm(false);
                  history.push(myDataPath);
                },
                type: "Success",
              },
            ]}
          />
        )}
        <div className={styles.issue}>
          <div className={styles.header}>
            <span className={styles.no}>
              WORK{currentIssueNo < 10 ? `0${currentIssueNo}` : currentIssueNo}
            </span>
            <p className={styles.title}>{props.issue?.title}</p>
            <p className={styles.read}>
              自分だけの課題解決ストーリーを描くことは、考えたい/知りたいという想いを起点に、課題を設定することから始まる。
              <br />
              まずはPOINTを参考にして、自分のやりたいことや興味があること、そしてその理由を考えよう。
              <br />
              次に、自分のやりたいことや興味があることに関連する分野のタグを選択しよう。
              <br />
              <br />
              例：電車の写真を撮ること。/人に勉強を教えること。/旅行に行くこと。/野球の練習をすること。/絵を描くこと。/デザイナーになること。/スポーツ選手になること。/宇宙飛行士になること。
              <br />
              <br />
              操作：「項目を追加する」ボタンをクリックすると項目を増やすことができます。［関連する分野］のタグは1項目に付き3つまで選択できます。
            </p>
          </div>
          {question && (
            <InterestTable
              answers={answersToQ1}
              fieldCategories={props.fieldCategories}
              handleTextAnswerChange={handleTextAnswerChange}
              setIsShowCheckModal={setIsShowCheckModal}
              question={question}
              addAnswerField={addAnswerField}
            />
          )}
          {question2 && (
            <div className={styles.themEntry}>
              <p className={styles.title}>{question2.title}</p>
              <p className={styles.read}>
                特性とは、自分が持つ個性や性質のこと。
                <br />
                自分の［やりたいこと/興味があること］［その理由］を参考に、自分にはどのような特性があるかを考えてタグを選択しよう。
                <br />
                <br />
                操作：回答枠をクリックするとタグ一覧が表示されます。［特性］のタグは3つまで選択できます。
                <br />
              </p>
              <div
                className={styles.checkField}
                onClick={() =>
                  setIsShowCheckModal({
                    answerId: answerToQ2.id,
                    attrName: "action_ids",
                  })
                }
              >
                <ul className={styles.actionCategoryList}>
                  {answerToQ2.action_ids.length > 0 ? (
                    props.actionCategories
                      .filter(category =>
                        answerToQ2.action_ids.includes(category.id),
                      )
                      .map(category => (
                        <li key={category.id} className={styles.actionCategory}>
                          {category.name}
                        </li>
                      ))
                  ) : (
                    <li className={styles.text}>
                      選択された特性はありません。
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
          <div className={styles.card}>
            <div className={styles.wrapper}>
              <div className={styles.block}>
                <p className={styles.label}>
                  <IconLive />
                  POINT
                </p>
                <p className={styles.title}>
                  いままでの自分を
                  <br className={styles.isPc} />
                  振り返る
                </p>
              </div>
              <div className={styles.block}>
                <p>
                  CORE、GEAR、GLIDEに入力したことを参考に興味・関心を見つけよう。
                </p>
                <div className={clsx(styles.buttonWrapper, styles.isPc)}>
                  <Button
                    width={"240px"}
                    height={"38px"}
                    fontSize={"14px"}
                    handleClick={() => setIsShowModalConfirm(true)}
                  >
                    COREのワークの回答を見る
                    <IconOpen />
                  </Button>
                  <a
                    href={process.env.REACT_APP_STUDENT_GEAR_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button width={"240px"} height={"38px"} fontSize={"14px"}>
                      GEARで行動を振り返る
                      <IconOpen />
                    </Button>
                  </a>
                  {!!state.student?.g4s_enabled || state.g4s_basic_enabled ? (
                    <a
                      href={`${process.env.REACT_APP_STUDENT_GEAR_URL}/student/assessment_description`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button width={"240px"} height={"38px"} fontSize={"14px"}>
                        GLIDEで自己評価を見る
                        <IconOpen />
                      </Button>
                    </a>
                  ) : (
                    <Link aria-current="page" to={assessmentDescriptionPath}>
                      <Button width={"240px"} height={"38px"} fontSize={"14px"}>
                        GLIDEで自己評価を見る
                        <IconOpen />
                      </Button>
                    </Link>
                  )}
                </div>
                <div className={clsx(styles.buttonWrapper, styles.isSp)}>
                  <Button
                    width={"100%"}
                    height={"58px"}
                    fontSize={"14px"}
                    handleClick={() => setIsShowModalConfirm(true)}
                  >
                    COREのワークの回答を見る
                    <IconOpen />
                  </Button>
                  <a
                    className={styles.link}
                    href={process.env.REACT_APP_STUDENT_GEAR_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button width={"100%"} height={"58px"} fontSize={"14px"}>
                      GEARで行動を振り返る
                      <IconOpen />
                    </Button>
                  </a>
                  {!!state.student?.g4s_enabled || state.g4s_basic_enabled ? (
                    <a
                      className={styles.link}
                      href={`${process.env.REACT_APP_STUDENT_GEAR_URL}/student/assessment_description`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button width={"100%"} height={"58px"} fontSize={"14px"}>
                        GLIDEで自己評価を見る
                        <IconOpen />
                      </Button>
                    </a>
                  ) : (
                    <Link aria-current="page" to={assessmentDescriptionPath}>
                      <Button width={"100%"} height={"58px"} fontSize={"14px"}>
                        GLIDEで自己評価を見る
                        <IconOpen />
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Issue1;
