import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";
import clsx from "clsx";

import { Context } from "../../../../../studentStore";
import { getActiveLearningAnswers } from "../../../../../libs/ActiveLearning";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { IAnswer, HintList } from "../../../../../constants/active_learning";
import HintCard from "../../../../../atoms/ActiveLearning/HintCard";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import styles from "../../ActiveLearningIssue.module.scss";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface ICurrentAnswer extends IAnswer {
  value: string;
}

export const Issue1 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 1;
  const question = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);

  const materialCode = useMemo(() => {
    return state.active_learning_data_by_lesson.material_code;
  }, [state.active_learning_data_by_lesson]);

  const [answer, setAnswer] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question?.order_num || NaN,
    question_id: question?.id || NaN,
    value: "",
  });

  useEffect(() => {
    const targetAnswer = state.active_learning_answers.find(
      answer => answer.issue_no === currentIssueNo && answer.question_no === 1,
    );
    const answer: ICurrentAnswer = {
      id: targetAnswer?.id || null,
      issue_no: currentIssueNo,
      question_no: 1,
      question_id: question?.id || 0,
      value: targetAnswer?.contents?.value || "",
    };
    setAnswer({ ...answer });
  }, [state.active_learning_answers, question]);

  useEffect(() => {
    const answers = props.answers.filter(
      answer =>
        !(
          answer.issue_no === currentIssueNo &&
          question?.order_num === answer.question_no
        ),
    );
    props.setAnswers([
      ...answers,
      ...[
        {
          id: answer.id,
          issue_no: currentIssueNo,
          question_no: question?.order_num || NaN,
          question_id: question?.id || NaN,
          contents: {
            value: answer.value,
          },
        },
      ],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, question]);

  const pastAnswersL3 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers =
      materialCode === "ECS001"
        ? getActiveLearningAnswers(answersByMaterial, 3, 2)
        : getActiveLearningAnswers(answersByMaterial, 3, 2);

    return currentAnswers.map(result => result.value);
  }, [state.active_learning_answers_by_material, materialCode]);
  const pastAnswersL4 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers =
      materialCode === "ECS001"
        ? getActiveLearningAnswers(answersByMaterial, 4, 3)
        : getActiveLearningAnswers(answersByMaterial, 5, 3);

    return currentAnswers.map(result => result.value);
  }, [state.active_learning_answers_by_material, materialCode]);

  const pastAnswerL5Q6 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers =
      materialCode === "ECS001"
        ? getActiveLearningAnswers(answersByMaterial, 5, 2, 6)
        : getActiveLearningAnswers(answersByMaterial, 6, 2, 6);

    return currentAnswers.map(result => result.value).join("");
  }, [state.active_learning_answers_by_material, materialCode]);

  const pastAnswerL5Q7 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers =
      materialCode === "ECS001"
        ? getActiveLearningAnswers(answersByMaterial, 5, 2, 7)
        : getActiveLearningAnswers(answersByMaterial, 6, 2, 7);

    return currentAnswers.map(result => result.value).join("");
  }, [state.active_learning_answers_by_material, materialCode]);

  const pastAnswerL5Q8 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers =
      materialCode === "ECS001"
        ? getActiveLearningAnswers(answersByMaterial, 5, 3, 1)
        : getActiveLearningAnswers(answersByMaterial, 6, 3, 1);

    return currentAnswers.map(result => result.value).join("");
  }, [state.active_learning_answers_by_material, materialCode]);

  const pastAnswersL8 = useMemo(() => {
    const answersByMaterial = state.active_learning_answers_by_material;
    const currentAnswers =
      materialCode === "ECS001"
        ? getActiveLearningAnswers(answersByMaterial, 8, 2, 1)
        : getActiveLearningAnswers(answersByMaterial, 9, 2, 1);

    return currentAnswers.map(result => ({
      stepNo: result.stepNo,
      value: result.value,
    }));
  }, [materialCode, state.active_learning_answers_by_material]);

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          このWORKには、今までのLessonで入力してきた、「問い」/「課題」/解決のアイデア/実現のステップとそれを考えた過程が表示されている。
          <br />
          入力内容を振り返って、スライドやポスターなど発表するための資料をつくろう。
        </p>
      </div>
      <div className={styles.hint}>
        <p className={styles.title}>発表するときのポイント</p>
        <div className={clsx(styles.hintList, styles.isPc)}>
          {HintList.lesson9.issue1.hint1.map((hint, index) => {
            return (
              <HintCard
                key={index}
                title={hint.title}
                width={"calc((100% - (16px * 2)) / 3)"}
              >
                {hint.text}
              </HintCard>
            );
          })}
        </div>
        <div className={clsx(styles.hintList, styles.isSp)}>
          {HintList.lesson9.issue1.hint1.map((hint, index) => {
            return (
              <HintCard key={index} title={hint.title}>
                {hint.text}
              </HintCard>
            );
          })}
        </div>
      </div>
      {question && (
        <div className={styles.question}>
          <div className={styles.themEntry}>
            <p className={styles.title}>「問い」/その「問い」を立てた理由</p>
            <p className={styles.read}>
              設定した「問い」とその「問い」を立てた理由が表示されます。
            </p>
            <ul className={styles.pastAnswerList}>
              {pastAnswersL3.length > 0 ? (
                pastAnswersL3.map((answer, index) => (
                  <li className={styles.pastAnswer} key={`${answer}${index}`}>
                    {answer}
                  </li>
                ))
              ) : (
                <li className={styles.pastAnswer}>回答データがありません。</li>
              )}
            </ul>
          </div>
          <div className={styles.themEntry}>
            <p className={styles.title}>「課題」/その「課題」を設定した理由</p>
            <p>
              「問い」に答えを出すための「課題」とその「課題」を設定した理由が表示されます。
            </p>
            <ul className={styles.pastAnswerList}>
              {pastAnswersL4.length > 0 ? (
                pastAnswersL4.map((answer, index) => (
                  <li className={styles.pastAnswer} key={`${answer}${index}`}>
                    {answer}
                  </li>
                ))
              ) : (
                <li className={styles.pastAnswer}>回答データがありません。</li>
              )}
            </ul>
          </div>
          <div className={styles.themEntry}>
            <p className={styles.title}>
              解決のアイデア/そのアイデアに決めた理由
            </p>
            <p className={styles.read}>
              解決のアイデアとそのアイデアに決めた理由が表示されます。
            </p>
            <ul className={styles.pastAnswerList}>
              <li className={styles.pastAnswer}>
                {pastAnswerL5Q6 || "回答データがありません。"}
              </li>
              <li className={styles.pastAnswer}>
                {pastAnswerL5Q7 || "回答データがありません。"}
              </li>
              <li className={styles.pastAnswer}>
                {pastAnswerL5Q8 || "回答データがありません。"}
              </li>
            </ul>
          </div>
          <div className={styles.themEntry}>
            <p className={styles.title}>
              解決のアイデアを実現するまでのステップ
            </p>
            <p className={styles.read}>
              解決のアイデアを実現するために考えたステップが表示されます。
            </p>
            <div className={clsx(styles.tree, styles.past)}>
              {pastAnswersL8.length > 0 ? (
                <ul className={styles.branchList}>
                  {pastAnswersL8
                    .sort((a, b) => b.stepNo - a.stepNo)
                    .map(lastAnswer => (
                      <li key={lastAnswer.stepNo} className={styles.branch}>
                        <div className={styles.labelWrapper}>
                          <span className={styles.label}>
                            ステップ{lastAnswer.stepNo}
                          </span>
                        </div>
                        <ul className={styles.answerList}>
                          <li className={clsx(styles.answer, styles.single)}>
                            {lastAnswer.value}
                          </li>
                        </ul>
                      </li>
                    ))}
                </ul>
              ) : (
                <p className={styles.answerList}>回答データがありません。</p>
              )}
            </div>
          </div>
          <div className={styles.themEntry}>
            <p className={styles.title}>{question.title}</p>
            <p className={styles.read}>
              ヒントを参考に、自分の回答に加えて補足したい内容があれば入力しよう。
            </p>
            <div className={styles.textAreaWrapper}>
              <TextArea
                value={answer.value}
                handleChange={e =>
                  setAnswer({
                    id: answer.id,
                    question_id: question?.id || NaN,
                    question_no: question?.order_num || NaN,
                    issue_no: currentIssueNo,
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Issue1;
