import React from "react";

import { ReactComponent as EditIcon } from "../../../assets/svg/assessment_edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/svg/assessment_delete.svg";
import { IReasonExperience } from "../../../state";
import { formatDate } from "../../../libs/Util";
import Modal from "../../../atoms/Modal";

import styles from "./AssessmentFormComment.module.scss";

type Props = {
  assessmentReason: IReasonExperience;
  calling: boolean;
  onEdit: (id: number, reason: string) => void;
  onDelete: (id: number) => void;
};

const AssessmentFormComment: React.FC<Props> = ({
  assessmentReason,
  onEdit,
  onDelete,
  calling,
}) => {
  const [editing, setEditing] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [content, setContent] = React.useState("");
  const [defaultContent, setDefaultContent] = React.useState("");

  React.useEffect(() => {
    setContent(assessmentReason.reason);
    setDefaultContent(assessmentReason.reason);
  }, [assessmentReason.reason]);

  const handleSaveContentEdited = () => {
    onEdit(assessmentReason.id, content);
    setEditing(false);
  };

  const handleConfirmDelete = () => {
    onDelete(assessmentReason.id);
    setShowDelete(false);
  };

  return (
    <div className={styles.commentDetail}>
      <div className={styles.content}>
        {editing && (
          <>
            <textarea
              value={content}
              onChange={e =>
                setContent(e.target.value.replace(/\r\n|\r|\n/g, "\n"))
              }
            />
            <div className="ms-auto d-flex">
              <button
                className={styles.buttonCancel}
                onClick={() => setEditing(false)}
              >
                キャンセル
              </button>
              <button
                className={styles.button}
                onClick={handleSaveContentEdited}
                disabled={
                  calling ||
                  !content.trim().length ||
                  defaultContent === content
                }
              >
                保存
              </button>
            </div>
          </>
        )}
        {!editing && <p>{assessmentReason.reason}</p>}
      </div>
      {editing ? (
        <button
          className={styles.buttonCancel}
          onClick={() => setEditing(false)}
        >
          キャンセル
        </button>
      ) : (
        <div className={styles.columnRight}>
          <div className={styles.actions}>
            <button onClick={() => setEditing(true)} disabled={calling}>
              <EditIcon />
            </button>
            <button onClick={() => setShowDelete(true)} disabled={calling}>
              <DeleteIcon />
            </button>
          </div>
          <span>{formatDate(assessmentReason.created_at, ".")}</span>
        </div>
      )}
      {showDelete && (
        <Modal
          onClose={() => setShowDelete(false)}
          className={styles.modalNotify}
        >
          <div className={styles.modalNotify__title}>本当に削除しますか？</div>
          <div className={styles.modalNotify__actions}>
            <button
              className={styles.btnCancel}
              onClick={() => setShowDelete(false)}
            >
              キャンセル
            </button>
            <button className={styles.btnDelete} onClick={handleConfirmDelete}>
              削除
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AssessmentFormComment;
