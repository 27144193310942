import React, { useContext } from "react";
import { useParams, useLocation } from "react-router-dom";

import { SurveyAnswersUpdate } from "../../../state";
import {
  fetchSurveyData,
  updateSurveyAnswers,
  Context,
  fetchSurveyAnswers,
  postSurveyAnswers,
} from "../../../studentStore";
import Survey from "../../../templates/student/Survey";
import useWaitApiCall from "../../../hooks/useWaitApiCall";

export default () => {
  const { state, dispatch } = useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const { id } = useParams();
  const location = useLocation<{
    isPostSurvey: boolean;
    isPreSurvey: boolean;
    isPreAnswer: boolean;
  }>();

  React.useEffect(() => {
    dispatch(fetchSurveyData(id));
    dispatch(fetchSurveyAnswers(Number(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleUpdateSurveyAnswers = (
    is_pre_answer: boolean,
    student_survey_answers: SurveyAnswersUpdate[],
  ) => {
    dispatch(peep(updateSurveyAnswers(is_pre_answer, student_survey_answers)));
  };

  const handlePostSurveyAnswers = (
    is_pre_answer: boolean,
    student_survey_answers: SurveyAnswersUpdate[],
  ) => {
    dispatch(peep(postSurveyAnswers(is_pre_answer, student_survey_answers)));
  };

  return (
    <Survey
      survey={state.surveys}
      handleUpdateSurveyAnswers={handleUpdateSurveyAnswers}
      handlePostSurveyAnswers={handlePostSurveyAnswers}
      postAnswers={location.state.isPostSurvey ? state.post_answers : []}
      preAnswers={location.state.isPreSurvey ? state.pre_answers : []}
      isPreAnswer={location.state.isPreAnswer}
      calling={calling}
    />
  );
};
