import React from "react";
import clsx from "clsx";
import { isSameDay } from "date-fns";

import Button from "../../../atoms/Button";
import { IAssessment, IAssessmentPast } from "../../../state";
import { formatDate } from "../../../libs/Util";
import ModalPastAssessment from "../../../organisms/student/ModalPastAssessment";

import styles from "./AssessmentDesc.module.scss";

export type Props = {
  hasAssessment: boolean;
  currentAssessment: IAssessment;
  pastAssessments: IAssessmentPast[];
  onDirectToAssessment: () => void;
  onDirectToCreateAssessment: () => void;
  onDirectToPastAssessment: (assessment: IAssessmentPast) => void;
};

const AssessmentDesc: React.FC<Props> = ({
  hasAssessment,
  currentAssessment,
  pastAssessments,
  onDirectToAssessment,
  onDirectToCreateAssessment,
  onDirectToPastAssessment,
}) => {
  const [showModalPastAssessment, setShowModalPastAssessment] = React.useState(
    false,
  );

  const hasSameDayAssessment = React.useMemo(() => {
    return isSameDay(new Date(currentAssessment.created_at), new Date());
  }, [currentAssessment]);

  return (
    <div className={styles.wrapper}>
      <h1>ENAGEED GLIDE</h1>
      <div className={styles.content}>
        <div className={styles.assessmentText}>ENAGEED GLIDE</div>
        <div className={styles.mainContent}>
          <p>
            これからの時代に
            <br />
            求められる力の
            <br />
            現在地を記録する。
          </p>
          <a
            href="https://www.enageed.jp/assessment-for-s"
            target="assessment-for-s"
          >
            ENAGEED GLIDEの内容と使い方
          </a>
        </div>
        <div className={styles.bottomContent}>
          {!hasSameDayAssessment && (
            <Button
              name="新しい記録"
              variant="normal"
              className={clsx(styles.button, styles.create)}
              onClick={onDirectToCreateAssessment}
            />
          )}
          {hasAssessment && (
            <Button
              name={`${currentAssessment.term_no}回目 (${formatDate(
                currentAssessment.created_at,
                "/",
              )})`}
              variant="outline"
              className={styles.button}
              onClick={onDirectToAssessment}
            />
          )}
          {pastAssessments.length > 0 && (
            <div
              className={styles.editPastAssessment}
              onClick={() => setShowModalPastAssessment(true)}
            >
              過去の記録を編集する
            </div>
          )}
          {showModalPastAssessment && (
            <ModalPastAssessment
              pastAssessments={pastAssessments}
              onDirectToPastAssessment={onDirectToPastAssessment}
              onClose={() => setShowModalPastAssessment(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentDesc;
