import React from "react";

import { ReactComponent as IconPerson } from "../assets/images/ActiveLearning/icon_person.svg";
import { ReactComponent as IconExcl } from "../assets/images/ActiveLearning/icon_excl.svg";
import { ReactComponent as IconClock } from "../assets/images/ActiveLearning/icon_clock.svg";
import { ReactComponent as IconLocation } from "../assets/images/ActiveLearning/icon_location.svg";

export interface IAnswer<TId = null | number> {
  id: TId;
  issue_no: number;
  question_no: number;
  question_id: number;
}

export interface IAnswerParam extends IAnswer {
  answer: any;
}

export interface IAnswerInterest extends IAnswer<string> {
  interest: string;
  reason: string;
  field_ids: number[];
}

export interface IAnswerCorporateInitiatives extends IAnswer<string> {
  id: string;
  interest: string;
  reason: string;
  activity: string;
}

export interface IAnswerToQ1 extends IAnswer<string> {
  id: string;
  interest: string;
  reason: string;
  action_ids: number[];
  field_ids: number[];
}

export interface IAnswerToI2Q1 extends IAnswer {
  theme_id: number;
}

export interface IAnswerToI2Q2 extends IAnswer {
  reason: string;
}

export type LessonContents = { label: string; read: string; end: string };

export const LESSON_CONTENTS: {
  [key: string]: LessonContents;
} = {
  lesson1: {
    label: "やりたいこと/もっと〇〇に気づく",
    read:
      "これから取り組んでいく活動について理解しよう。\n感情の動く「問い」を立てるために、自分がどんなものに興味・関心を持っているかを調査して、意志を持って取り組めるテーマを選択しよう。",
    end:
      "次のLessonでは、企業の動画を参考に「問い」について考える。次回までに取り組みたい「問い」についてイメージを膨らませておこう。",
  },
  lesson2: {
    label: "やりたいこと/もっと〇〇に気づく",
    read:
      "企業の課題解決ストーリーや周りの意見を参考にして、自分だけの「問い」を考えよう。",
    end:
      "次のLessonでは、考えた「問い」をさらに具体的にし、これから取り組む「問い」を立てる。そして、その「問い」に答えを出すことを妨げる問題を予測する。次回までに、本日考えた「問い」をさらに具体的にするにはどうしたらいいかについてイメージを膨らませておこう。",
  },
  lesson3: {
    label: "やりたいこと/もっと〇〇に気づく",
    read:
      "設定した「問い」を様々な観点から具体的にして、もっと取り組みたいと思える「問い」にしよう。\nその「問い」の内容を実現させるときに、立ちはだかる問題を考えてみよう。",
    end:
      "次のLessonでは、考えた問題の中から重要な問題を選び、その原因を深堀って「課題」を設定する。次回までに、どの問題を取り除いたらいいかイメージを膨らませておこう。",
  },
  lesson4: {
    label: "実現までの道筋を描く",
    read:
      "「問い」の内容を実現させるときに、立ちはだかる問題について調査しよう。\nその問題を解決したときの理想状況を想像して、取り組んでいく「課題」を決定しよう。",
    end:
      "次のLessonでは、設定した「課題」を解決するアイデアを考える。次回までに、どんなアイデアなら「課題」を解決できそうかのイメージを膨らませておこう。",
  },
  lesson5: {
    label: "実現までの道筋を描く",
    read:
      "意見交換を通じて、設定した「課題」に対する解決のアイデアをつくり上げよう。",
    end:
      "次のLessonでは、今まで考えた内容を発表し合う。次回までに、どのように話したら相手に内容が伝わりやすいかのイメージを膨らませておこう。",
  },
  lesson6: {
    label: "実現までの道筋を描く",
    read:
      "今まで考えてきた「問い」、「課題」、解決のアイデアを共有して様々な考えに触れよう。	\n発表して感じたことと発表に対してもらった意見を参考に、どんな部分が良かったのか、どうすればもっと良くなるのかを考えよう。",
    end:
      "次のLessonでは、解決のアイデアの実現のために計画をする。次回までに、アイデアを実現するためにはどのようなステップが必要なのか、イメージを膨らませておこう。",
  },
  lesson7: {
    label: "実現までの道筋を描く",
    read:
      "解決のアイデアを実現するためにはどんなステップが必要かを考えて、次の授業までにできる行動を決めよう。",
    end:
      "次のLessonでは、計画を実行した結果を振り返り、計画を調整する。次回までに、決めたステップまでの計画を実行してこよう。",
  },
  lesson8: {
    label: "描いたことを実現する",
    read:
      "行動してみた結果どんな成果が得られたのかを考察して、解決のアイデアをさらに実現できそうな計画を立ててみよう。",
    end:
      "次のLessonでは、今まで考えてきた内容をまとめ、発表の準備をする。次回までに、どのようにまとめれば相手に伝わりやすいかのイメージを膨らませておこう。",
  },
  lesson9: {
    label: "描いたことを実現する",
    read:
      "課題解決のストーリーをまとめて、スライドやポスターなど発表するための資料をつくろう。",
    end:
      "次のLessonでは、まとめた内容を発表する。次回までに、自分の伝えたいことを整理しておこう。",
  },
  lesson10: {
    label: "描いたことを実現する",
    read:
      "自分が何を伝えたいのか、相手にどのように伝えたら伝わりやすいかを考えながら、課題解決のストーリーを発表しよう。",
    end:
      "次のLessonでは、今まで学んできた内容を振り返る。次回までに、学んだことや成長した点を整理しておこう。",
  },
  lesson11: {
    label: "描いたことを実現する",
    read: "今までの学びを振り返って、学びが自分の将来にどう活きるか考えよう。",
    end: "",
  },
  lesson12: {
    label: "やりたいこと/もっと〇〇に気づく",
    read:
      "企業の課題解決ストーリーと他の企業の取り組み、周りの意見を参考にして、自分だけの「問い」を考えよう。",
    end:
      "次のLessonでは、考えた「問い」をさらに具体的にし、これから取り組む「問い」を立てる。そして、その「問い」に答えを出すまでにどんな人に価値を届けられるかを考える。次回までに、本日考えた「問い」をさらに具体的にするにはどうしたらいいかについてイメージを膨らませておこう。",
  },
  lesson13: {
    label: "やりたいこと/もっと〇〇に気づく",
    read:
      "「問い」を具体的にして、もっと取り組みたいと思える「問い」にしよう。\nその「問い」が生み出す価値とターゲットについて考えよう。",
    end:
      "次のLessonでは、設定したターゲットの行動をさらに具体的にし、「問い」に答えを出すまでに立ちはだかる問題を予測する。次回までに、設定したターゲットはどのような人なのかについてイメージを膨らませておこう。",
  },
  lesson14: {
    label: "やりたいこと/もっと〇〇に気づく",
    read:
      "ターゲットの立場に立って、その人の行動と「もっと〇〇」を想像してみよう。\n「問い」の内容を実現させるときに、立ちはだかる問題について調査しよう。",
    end:
      "次のLessonでは、考えた問題の中から重要な問題を選び、その原因を深堀って「課題」を設定する。次回までに、どの問題を取り除いたらいいかイメージを膨らませておこう。",
  },
  lesson15: {
    label: "描いたことを実現する",
    read:
      "今までの学びを振り返って、やってみたい仕事や今後への活かし方を考えよう。",
    end: "",
  },
};

export const HintList = {
  lesson2: {
    issue3: {
      hint1: [
        {
          title: "さらに良くできないかな？",
          text:
            "今の状態からさらに良くできないかという考え方。\n例：「学校でスポーツの活動をさらに盛んにするには、どんなイベントがあればいいだろう？」\n 「おじいちゃんの暮らしをさらに快適にするスマホの使い方ってなんだろう？」",
        },
        {
          title: "どうしたらこの状況を変えられるかな？",
          text:
            "マイナスな要素を改善するためにどうするかという考え方。\n例：「学校でのスマホの使用が学生の集中力を下げているから、改善できないかな？」\n「食べ物を無駄にしたくない…学校で廃棄される給食をなくすには？」",
        },
        {
          title: "こんな未来があったらいいな。",
          text:
            "理想の状況を実現するにはどうするかという考え方。\n例：「学校に行くのが楽しみになる授業があればいいのにな。どんな授業があることが理想的だろう？」\n「自然と共存するまちづくりができないかな？」",
        },
        {
          title: "もっとこうしたい。",
          text:
            "自分の欲求をどう叶えるかという考え方。\n例：「忙しくて自分の時間がない…何か工夫して趣味の時間をつくれないかな？」\n「芸術の良さをもっと広めるには？」",
        },
      ],
    },
  },
  lesson3: {
    issue1: {
      hint1: [
        {
          icon: <IconPerson />,
          label: "Who",
          title: "対象を具体的にしよう。",
          text:
            "年齢や性別、その人を取り巻く環境に着目しよう。\n例「地元の社会人になりたての若者の地元離れを解消するには？」",
        },
        {
          icon: <IconExcl />,
          label: "What",
          title: "原因を具体的にしよう。",
          text:
            "「問い」の中にあるマイナスの要素に着目しよう。\n例：「地元の娯楽施設がないことによる若者離れを解消するには？」",
        },
        {
          icon: <IconClock />,
          label: "When",
          title: "時間を具体的にしよう。",
          text:
            "時期や季節、期限などの制限に着目しよう。\n例：「大学を卒業したときに起こる地元の若者離れを解消するには？」",
        },
        {
          icon: <IconLocation />,
          label: "Where",
          title: "状況を具体的にしよう。",
          text:
            "地域や場所などの状況に着目しよう。\n例：「〇〇県〇〇市の若者離れを解消するには？」",
        },
      ],
    },
    issue3: {
      hint1: [
        {
          title: "対象を取り巻く環境や状況の問題？",
          text:
            "制度やルールの制限によって問題が生まれていないか考えよう。\n例：「〇〇市は就職先の種類が少ない。」「子育ての支援が少ないのかも。」",
        },
        {
          title: "必要な資源や手段の問題？",
          text:
            "必要なものが足りない、方法が確立されていないなどの問題が生まれていないか考えよう。\n例：「アピールポイントが少ないのでは？」「魅力を伝えるための手段がないのでは？」",
        },
        {
          title: "人の感情や文化、習慣の問題？",
          text:
            "人との関係性や習慣、意識によって問題が生まれていないか考えよう。\n例：「都会に出ないと好きな仕事ができない、という意識が若者にあるのでは？」",
        },
      ],
    },
  },
  lesson4: {
    issue1: {
      hint1: [
        {
          title: "影響の大きさ",
          text:
            "問題を取り除くことで起こる、変化の大きさに注目して考えてみよう。",
        },
        {
          title: "問題同士の関連性",
          text:
            "一つの問題を取り除くことで、他に取り除ける問題はないか考えてみよう。",
        },
        {
          title: "実現性",
          text:
            "具体的な原因や解決のアイデアがイメージでき、問題を取り除けそうかを考えてみよう。",
        },
      ],
      hint2: [
        {
          title: "関係する人やモノの視点",
          text: "関係する人やモノが原因となって問題が起きていないか。",
        },
        {
          title: "関わり方や関係性の視点",
          text: "関わり方や関係性が原因となって問題が起きていないか。",
        },
        {
          title: "不足や間違いの視点",
          text: "不足や間違いが原因となって問題が起きていないか。",
        },
        {
          title: "制度や組織の視点",
          text: "制度や組織が原因となって問題が起きていないか。",
        },
        {
          title: "特定の時期や場所の視点",
          text: "特定の時期や場所が原因となって問題が起きていないか。",
        },
        {
          title: "文化や価値観の視点",
          text: "文化や価値観が原因となって問題が起きていないか。",
        },
      ],
      hint3: [
        {
          title: "どんな情報が欲しい？",
          text:
            "どんな情報が欲しいかをはじめにイメージすることで、調べることが明確になる。予測して考えよう。\n例：「まずはこの問題が本当に起きているか調べたいから、キーワードを使って検索してみよう。」",
        },
        {
          title: "比較できる情報はない？",
          text:
            "今の状況と、違った状況とを比べて考えよう。\n例：現在と過去の違い/日本と海外の違い/放課後と休日の違い/習い事がある日とない日の違い",
        },
        {
          title: "似た事例はない？",
          text:
            "自分が考えていることに関して、既に答えを出している人がいるかも知れない。似た事例を調べてみよう。\n例：「自分の暮らす地域以外にも、若者の減少に悩んでいる人がいる地域があるかも。」",
        },
        {
          title: "その情報は信頼できる？",
          text:
            "情報が古くて今とは状況が変わっていたり、根拠のない情報が発信されていることがある。調べるときには、出典や根拠が明示されてるか確認しよう。\n例：「このページの数字には、引用元が書かれていない。この情報について検索して本当に事実なのかを確認しよう。」",
        },
      ],
    },
  },
  lesson5: {
    issue1: {
      hint1: [
        {
          title: "興味・関心があることと掛け合わせたら？",
          text:
            "自分の興味・関心があることをアイデアに反映できないか考えよう。\n例：料理やイベントに興味「特産品を使った料理のイベントができないかな。」/教育に興味「小学校でまちづくりに関する授業ができないかな。」",
        },
        {
          title: "巻き込める人やモノ・サービスは？",
          text:
            "協力者や使えそうなモノ、サービスをアイデアに反映できないか考えよう。\n例：「市役所の人に協力してもらえないかな」「〇〇市の観光案内サービスをうまく使えないかな。」",
        },
      ],
    },
    issue2: {
      hint1: [
        {
          title: "どんな情報が欲しい？",
          text:
            "どんな情報が欲しいかをはじめにイメージすることで、調べることが明確になる。予測して考えよう。\n例：「まずはこの問題が本当に起きているか調べたいから、キーワードを使って検索してみよう。」",
        },
        {
          title: "比較できる情報はない？",
          text:
            "今の状況と、違った状況とを比べて考えよう。\n例：現在と過去の違い/日本と海外の違い/放課後と休日の違い/習い事がある日とない日の違い",
        },
        {
          title: "似た事例はない？",
          text:
            "自分が考えていることに関して、既に答えを出している人がいるかも知れない。似た事例を調べてみよう。\n例：「自分の暮らす地域以外にも、若者の減少に悩んでいる人がいる地域があるかも。」",
        },
        {
          title: "その情報は信頼できる？",
          text:
            "情報が古くて今とは状況が変わっていたり、根拠のない情報が発信されていることがある。調べるときには、出典や根拠が明示されてるか確認しよう。\n例：「このページの数字には、引用元が書かれていない。この情報について検索して本当に事実なのかを確認しよう。」",
        },
      ],
    },
    issue3: {
      hint1: [
        {
          title: "要素を組み合わせる",
          text:
            "組み合わせたり統合できたりしないか、ほかのアイデアと合わせたらどうなるかを考えよう。\n例：環境問題×アート「環境問題をアートで伝える方法」/スポーツ×勉強「スポーツと勉強を両立させるプログラム」",
        },
        {
          title: "既にあるものを応用する",
          text:
            "今あるものや過去にあったものからアイデアを持ってきたり、お手本にできるものはないかを考えよう。\n例：「プロジェクターの技術を応用して机に教科書を投影できるようにする」「昭和レトロの文化を現代のカフェに応用する」",
        },
        {
          title: "代わりになる組み合わせを探す",
          text:
            "本来のものと何かを入れ替えて考えよう。\n例：「プラごみを利用して洋服をつくる」「米の研ぎ汁で床をピカピカにする」",
        },
        {
          title: "他の使い道を探す",
          text:
            "一つの物事に対して、他に新しい使い道はないか、部分的に修正したらどうなるかを考えよう。\n例：「廃校を宿泊施設にする」「形の悪い食品を訳ありグルメとして販売する」",
        },
      ],
    },
  },
  lesson7: {
    issue1: {
      hint1: [
        {
          title: "やることを洗い出そう。",
          text:
            "定めた解決のアイデアを達成するために、まずは「やること」の洗い出しをしよう。「やること」はできる限り具体的でわかりやすいものにしよう。",
        },
        {
          title: "やることの優先順位を決めよう。",
          text:
            "優先順位を決めるために、やること同士がお互いに影響しあっていないか確認しよう。\n例：「Aのやることが終わらないとBのやることを進めることができないから、Aを先にやろう。」",
        },
      ],
      hint2: [
        {
          title: "人を巻き込む",
          text:
            "身近な人と話し合ってアドバイスをもらったり、関係者や専門家にインタビューする。",
        },
        {
          title: "モノ・サービスを利用する",
          text: "今あるモノやサービスを活用する。",
        },
        {
          title: "情報収集をする",
          text:
            "情報収集をして優先順位を決めたり、ステップを進めたときに出てくる疑問について調べる。",
        },
        {
          title: "データを整理・分析する",
          text: "データを分析し、データの傾向などを整理する。",
        },
        {
          title: "プロトタイプをつくる",
          text: "実現したいアイデアの企画書や模型などをつくる。",
        },
        {
          title: "アイデアを広める",
          text: "考えたアイデアを届けるための方法を考える。",
        },
      ],
    },
  },
  lesson8: {
    issue1: {
      hint1: [
        {
          title: "計画の達成度を振り返ろう",
          text:
            "計画の中で、行動すると決めた内容をどの程度進められたか。予定していた行動の他にできたことはあるか。",
        },
        {
          title: "予想と結果を比較しよう",
          text:
            "行動する前に予測した結果と実際に行動した結果の違いはあったか。",
        },
        {
          title: "必要な情報を見つけよう",
          text:
            "行動した結果、今回の調査では足りなかった情報があるかもしれない。もう一度行動できるとしたらどんな情報が必要か。",
        },
      ],
    },
  },
  lesson9: {
    issue1: {
      hint1: [
        {
          title: "伝えたいことを明確にする",
          text:
            "発表の目的に合わせて情報を取捨選択し、伝えたいことを整理しよう。自分の伝えたいことや結論は簡潔にしよう。",
        },
        {
          title: "具体的に伝える",
          text:
            "伝えたいことに説得力を持たせるために、具体的な例やデータを盛り込んでみよう。調べてきた自分にしかわからない情報も、相手に伝わるように整理しよう。",
        },
        {
          title: "体験談を盛り込む",
          text:
            "難しい話や理解しづらい話をわかりやすく伝えたり、聞き手を惹きつけるために、自分自身の体験談を盛り込んでみよう。",
        },
      ],
    },
  },
  lesson12: {
    issue3: {
      hint1: [
        {
          title: "さらに良くできないかな？",
          text:
            "今の状態からさらに良くできないかという考え方。\n例：「学校でスポーツの活動をさらに盛んにするには、どんなイベントがあればいいだろう？」\n「おじいちゃんの暮らしをさらに快適にするスマホの使い方ってなんだろう？」",
        },
        {
          title: "どうしたらこの状況を変えられるかな？",
          text:
            "マイナスな要素を改善するためにどうするかという考え方。\n例：「学校でのスマホの使用が学生の集中力を下げているから、改善できないかな？」\n「食べ物を無駄にしたくない…学校で廃棄される給食をなくすには？」",
        },
        {
          title: "こんな未来があったらいいな。",
          text:
            "理想の状況を実現するにはどうするかという考え方。\n例：「学校に行くのが楽しみになる授業があればいいのにな。どんな授業があることが理想的だろう？」\n「自然と共存するまちづくりができないかな？」",
        },
        {
          title: "もっとこうしたい。",
          text:
            "自分の欲求をどう叶えるかという考え方。\n例：「忙しくて自分の時間がない…何か工夫して趣味の時間をつくれないかな？」\n「芸術の良さをもっと広めるには？」",
        },
      ],
    },
  },
  lesson13: {
    issue1: {
      hint1: [
        {
          icon: <IconPerson />,
          label: "Who",
          title: "対象を具体的にしよう。",
          text:
            "年齢や性別、その人を取り巻く環境に着目しよう。\n例：「地元の社会人になりたての若者の地元離れを解消するには？」",
        },
        {
          icon: <IconExcl />,
          label: "What",
          title: "原因を具体的にしよう。",
          text:
            "「問い」の中にあるマイナスの要素に着目しよう。\n例：「地元の娯楽施設がないことによる若者離れを解消するには？」",
        },
        {
          icon: <IconClock />,
          label: "When",
          title: "時間を具体的にしよう。",
          text:
            "時期や季節、期限などの制限に着目しよう。\n例：「大学を卒業したときに起こる地元の若者離れを解消するには？」",
        },
        {
          icon: <IconLocation />,
          label: "Where",
          title: "状況を具体的にしよう。",
          text:
            "地域や場所などの状況に着目しよう。\n例：「〇〇県〇〇市の若者離れを解消するには？」",
        },
      ],
    },
  },
  lesson14: {
    issue2: {
      hint1: [
        {
          title: "対象を取り巻く環境や状況の問題？",
          text:
            "制度やルールの制限によって問題が生まれていないか考えよう。\n例：「〇〇市は就職先の種類が少ない。」「子育ての支援が少ないのかも。」",
        },
        {
          title: "必要な資源や手段の問題？",
          text:
            "必要なものが足りない、方法が確立されていないなどの問題が生まれていないか考えよう。\n例：「アピールポイントが少ないのでは？」「魅力を伝えるための手段がないのでは？」",
        },
        {
          title: "人の感情や文化、習慣の問題？",
          text:
            "人との関係性や習慣、意識によって問題が生まれていないか考えよう。\n例：「都会に出ないと好きな仕事ができない、という意識が若者にあるのでは？」",
        },
      ],
    },
  },
};

export const LESSON5_ASSESSMENT_AXIS: { [key: number]: string } = {
  1: "有効性：問題の大部分が解決されるアイデアか。",
  2: "新規性：既に世の中にあるものと比較して自分ならではの要素が含まれたアイデアか。",
  3: "実現性：実現までの道のりをイメージできるアイデアか。",
  4: "意志：自分がやってみたいと思えるアイデアか。",
};

export const CONFIRM_PAGE_LEAVE_TEXT =
  "別ページに移動すると、保存していない回答が自動的に保存されます。別ページに移動しますか？";
