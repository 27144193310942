import React from "react";
import { useHistory } from "react-router-dom";

import { IStudentCountAnswers } from "../../../state";
import Button from "../../../atoms/Button";
import { oecdSurveyQuestionPath } from "../../../studentStore";
import Header from "../../../organisms/student/Header";

import Icons from "./icons";
import styles from "./Home.module.scss";

export type Props = {
  student?: IStudentCountAnswers;
  oecdSurveyEnabled: boolean;
  isDlpEnabled: boolean;
};

const Home: React.FC<Props> = ({
  student,
  oecdSurveyEnabled,
  isDlpEnabled,
}) => {
  const history = useHistory();
  const handleDirectToOecdSurvey = React.useCallback(() => {
    history.push({ pathname: oecdSurveyQuestionPath });
  }, [history]);

  const checkSkill = (skill_no: number) => {
    return student?.skill_list.find(skill => skill.skill_no === skill_no)
      ?.acquired
      ? "#FFFFFF"
      : "#4A4B4B";
  };

  return (
    <div className={styles.wrapper}>
      <Header />
      <p className={styles.title}>ENAGEED</p>
      <div className={styles.footer}>
        <div className={styles.details}>
          {isDlpEnabled && (
            <>
              <p>日本中で生まれたアイデアの数</p>
              <div className={styles.total}>
                <p className={styles.circle} />
                <p className={styles.count}>{student?.total_answers ?? 0}</p>
              </div>
            </>
          )}
        </div>
        <div className={styles.oecdSurveyBtnWrap}>
          {oecdSurveyEnabled && (
            <Button
              name="日常および学校生活に関するアンケート"
              variant="normal"
              className={styles.button}
              onClick={handleDirectToOecdSurvey}
            />
          )}
        </div>
      </div>
      <div className={styles.icons}>
        {Icons.map((Icon, index) =>
          !!student?.skill_list.find(skill => skill.skill_no === index) ? (
            <Icon color={checkSkill(index)} key={index} />
          ) : null,
        )}
      </div>
    </div>
  );
};

export default Home;
