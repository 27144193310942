import React from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

import {
  homePath,
  questionnairePath,
  myDataPath,
  listMaterialPath,
  selectMaterialsPath,
  assessmentDescriptionPath,
  assessmentPath,
} from "../../../studentStore";
import { isAvailablePartItemCodes } from "../../../libs/ActiveLearning";
import LogoImg from "../../../assets/images/logo.png";
import { ReactComponent as HomeIcon } from "../../../assets/svg/home-menu.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/svg/category.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/svg/logout.svg";
import { ReactComponent as QuestionnaireIcon } from "../../../assets/svg/questionnaire.svg";
import { ReactComponent as MyDataIcon } from "../../../assets/svg/class-data.svg";
import { ReactComponent as AssessmentIcon } from "../../../assets/svg/assessment.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/caret_down.svg";
import { ReactComponent as GearIcon } from "../../../assets/svg/gear.svg";
import Modal from "../../../atoms/Modal";

import styles from "./SPSidebar.module.scss";

export interface SPSidebarProps {
  className?: string;
  isDlpEnabled: boolean;
  isSurveyEnabled: boolean;
  isOnlyAssessment: boolean;
  isG4sEnabled: boolean;
  isDummy: boolean;
  availableItemCodes: string[];
  logout: () => void;
  onClose?: () => void;
}

const SPSidebar: React.FC<SPSidebarProps> = ({
  className = "",
  isOnlyAssessment,
  isDlpEnabled,
  isSurveyEnabled,
  isG4sEnabled,
  isDummy,
  availableItemCodes,
  logout,
  onClose,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const location = useLocation();

  const path = location.pathname;
  const checkActive = (...args: string[]) => {
    return clsx(args.some(compare => compare === path) && styles.active);
  };

  return (
    <>
      <nav className={clsx(styles.SPSidebar, className)}>
        <div className={styles.SPSidebarHeader}>
          <Link to={homePath}>
            <img src={LogoImg} alt="Logo" />
          </Link>
          {onClose && <button onClick={onClose}>✕</button>}
        </div>
        <ul className={styles.menuList}>
          {!isOnlyAssessment && (
            <>
              <li className={checkActive(homePath)}>
                <Link aria-current="page" to={homePath}>
                  <div>
                    <HomeIcon />
                    HOME
                  </div>
                  <ArrowIcon />
                </Link>
              </li>
              <li
                className={clsx(
                  checkActive(listMaterialPath),
                  !isDlpEnabled && styles.disable,
                )}
              >
                <Link
                  aria-current="page"
                  to={
                    isAvailablePartItemCodes(availableItemCodes)
                      ? selectMaterialsPath
                      : listMaterialPath
                  }
                >
                  <div>
                    <CategoryIcon />
                    教材一覧
                  </div>
                  <ArrowIcon />
                </Link>
              </li>
              {!isDummy && (
                <li
                  className={clsx(
                    `${checkActive(myDataPath)} ${
                      path.includes(myDataPath) && styles.active
                    }`,
                    !isDlpEnabled && styles.disable,
                  )}
                >
                  <Link aria-current="page" to={myDataPath}>
                    <div>
                      <MyDataIcon />
                      マイデータ
                    </div>
                    <ArrowIcon />
                  </Link>
                </li>
              )}
            </>
          )}
          <li
            className={checkActive(assessmentDescriptionPath, assessmentPath)}
          >
            {isG4sEnabled ? (
              <a
                href={`${process.env.REACT_APP_STUDENT_GEAR_URL}/student/assessment_description`}
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <AssessmentIcon />
                  自己評価ツール
                </div>
                <ArrowIcon />
              </a>
            ) : (
              <Link aria-current="page" to={assessmentDescriptionPath}>
                <div>
                  <AssessmentIcon />
                  自己評価ツール
                </div>
                <ArrowIcon />
              </Link>
            )}
          </li>
          {isSurveyEnabled && !isOnlyAssessment && (
            <li
              className={clsx(
                checkActive(questionnairePath),
                !isDlpEnabled && styles.disable,
              )}
            >
              <Link aria-current="page" to={questionnairePath}>
                <div>
                  <QuestionnaireIcon />
                  アンケート
                </div>
                <ArrowIcon />
              </Link>
            </li>
          )}
          <div className={styles.split} />
          {isG4sEnabled && (
            <li>
              <a
                className={styles.gearLink}
                href={process.env.REACT_APP_STUDENT_GEAR_URL}
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <div>
                    <GearIcon />
                  </div>
                  GEAR
                </div>
                <ArrowIcon />
              </a>
            </li>
          )}
          <li>
            <button onClick={() => setShowModal(true)}>
              <div>
                <LogoutIcon />
                ログアウト
              </div>
              <ArrowIcon />
            </button>
          </li>
        </ul>
      </nav>
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          className={styles.modalLogout}
        >
          <div className={styles.modalLogout__title}>ログアウトしますか？</div>
          <div className={styles.modalLogout__actions}>
            <button
              className={styles.btnCancel}
              onClick={() => setShowModal(false)}
            >
              キャンセル
            </button>
            <button className={styles.btnOk} onClick={logout}>
              ログアウト
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SPSidebar;
