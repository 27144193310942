import React, { SVGProps } from "react";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";

import styles from "./Tabs.module.scss";

export type Props = {
  className?: string;
  listTabsTitle: {
    title: string;
    icon: SVGProps<SVGSVGElement>;
    id?: string;
    link?: string;
    state?: { [key: string]: any };
    hide?: boolean;
  }[];
};

export const Tabs: React.FC<Props> = ({
  children,
  listTabsTitle,
  className,
}) => {
  const history = useHistory();

  return (
    <div className={clsx(styles.tabs, className)}>
      <div className={clsx(styles.tabs__header, "nav nav-tabs")}>
        {listTabsTitle.map((tab, index) =>
          tab.link ? (
            <Link
              key={index}
              style={{
                width:
                  100 /
                    (listTabsTitle.length === 1 ? 2 : listTabsTitle.length) +
                  "%",
              }}
              to={{ pathname: tab.link, state: tab.state }}
              className={clsx(
                "nav-link",
                tab.hide && styles.isHideTab,
                history.location.pathname === tab.link && "active",
                listTabsTitle.length === 2 && index === 1 && styles.tabRight,
              )}
            >
              {tab.icon}
              <p>{tab.title}</p>
            </Link>
          ) : (
            <a
              style={{
                width:
                  100 /
                    (listTabsTitle.length === 1 ? 2 : listTabsTitle.length) +
                  "%",
              }}
              data-bs-toggle="tab"
              role="tab"
              href={`#${tab.id}`}
              key={tab.id}
              className={clsx(
                "nav-link",
                index === 0 && "active",
                listTabsTitle.length === 2 && index === 1 && styles.tabRight,
              )}
            >
              {tab.icon}
              <p>{tab.title}</p>
            </a>
          ),
        )}
      </div>
      <div className={clsx(styles.tabs__content, "tab-content")}>
        {children}
      </div>
    </div>
  );
};
export default Tabs;
