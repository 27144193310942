import React from "react";
import clsx from "clsx";

import Portal from "../Portal";

import styles from "./Modal.module.scss";

export type Props = {
  className?: string;
  onClose: () => void;
  transparentBackDrop?: boolean;
  children: React.ReactNode;
};

const Modal = React.forwardRef<HTMLDivElement, Props>(
  ({ className, children, onClose, transparentBackDrop }, ref) => {
    return (
      <Portal>
        <div className={styles.modal}>
          <div
            className={clsx(
              styles.modal__backDrop,
              transparentBackDrop && styles.modal__transparentBackground,
            )}
            onClick={onClose}
          />
          <div ref={ref} className={clsx(styles.modal__content, className)}>
            {children}
          </div>
        </div>
      </Portal>
    );
  },
);

export default Modal;
