import * as React from "react";
import clsx from "clsx";

import { IMAGE_TYPE } from "../../Definition";
import { keyGenerator } from "../../libs/Util";
import { IAnswerStorageValue } from "../../hooks/useAnswerStorage";
import { INewAnswer, IContent, IQuestionData } from "../../state";

import styles from "./ImagePageView.module.scss";

const HORIZONTAL_CORE_RATE = 0.78;

export interface ImagePageViewProps {
  imageUrl: string;
  imageType: number;
  page: number;
  maxPage: number;
  currentPage: number;
  student?: boolean;
  myAnswer: INewAnswer[] | [];
  answerStorageValues: IAnswerStorageValue;
  questions: IQuestionData[];
  issueId?: number;
  outerSize: { width: number; height: number };
  isEditMode: boolean;
  handleChangeAnswers: (questionId: number, value: string) => void;
}

export const ImagePageView: React.FC<ImagePageViewProps> = props => {
  const [answerContents, setAnswerContents] = React.useState<IContent[]>([]);
  const contentsRef = React.useRef<HTMLDivElement>(null);
  const imageRef = React.useRef<HTMLImageElement>(null);
  const [imageSize, setImageSize] = React.useState({ width: 0, height: 0 });
  const [inputFieldsScale, setInputFieldsScale] = React.useState(1);
  const [loadImageUrl, setLoadImageUrl] = React.useState<string | null>(null);

  const isHorizontal = props.imageType === IMAGE_TYPE.horizontal;
  const INPUT_FIELDS_BASE_WIDTH = isHorizontal ? 1080 : 728;

  React.useEffect(() => {
    if (loadImageUrl === props.imageUrl) {
      return;
    }
    if (props.page === props.currentPage) {
      setLoadImageUrl(props.imageUrl);
    } else {
      setLoadImageUrl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.imageUrl, props.page, props.currentPage]);

  const setContentPosition = () => {
    if (!loadImageUrl || imageRef.current!.naturalWidth < 1) {
      return;
    }
    setImageSize({
      width: imageRef.current!.naturalWidth,
      height: imageRef.current!.naturalHeight,
    });
  };

  React.useEffect(() => {
    if (imageSize.width < 1) {
      return;
    }
    const coreHeight = isHorizontal
      ? imageSize.height * HORIZONTAL_CORE_RATE
      : imageSize.height;

    const wrapperWidth = props.outerSize.width - (isHorizontal ? 0 : 16);
    const wrapperHeight = props.outerSize.height - (isHorizontal ? 0 : 16);
    let contentWidth = wrapperWidth;
    let contentHeight = wrapperHeight;

    if (wrapperWidth / imageSize.width > wrapperHeight / coreHeight) {
      // Horizontal Screen
      const s = wrapperHeight / coreHeight;
      contentWidth = Math.floor(imageSize.width * s);
      if (isHorizontal) {
        contentHeight = Math.floor(wrapperHeight / HORIZONTAL_CORE_RATE);
      }
    } else {
      // Vertical Screen
      const s = wrapperWidth / imageSize.width;
      contentHeight = Math.floor(imageSize.height * s);
    }

    contentsRef.current!.style.width = `${contentWidth}px`;
    contentsRef.current!.style.height = `${contentHeight}px`;

    const iScale = contentWidth / INPUT_FIELDS_BASE_WIDTH;
    setInputFieldsScale(iScale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSize, props.outerSize]);

  React.useEffect(() => {
    if (props.page !== props.currentPage) {
      return;
    }
    const propsAnswer: INewAnswer[] = props.myAnswer;

    const newAnswerContents = propsAnswer
      .map(a => a.contents)
      .flat()
      .map(c => {
        return { ...c };
      });

    props.questions.forEach(question => {
      const localValue = props.answerStorageValues[question.id];
      if (!localValue) return;

      const findContent = newAnswerContents.find(
        c => c.question_id === question.id,
      );
      if (findContent) {
        if (findContent.content !== localValue) {
          // edited local value
          findContent.content = localValue;
        }
      } else {
        // new local value, no saved data
        newAnswerContents.push({
          question_id: question.id,
          statement: question.statement,
          content: localValue,
        });
      }
    });

    setAnswerContents(newAnswerContents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.myAnswer, props.issueId, props.answerStorageValues]);

  const handleChange = (content: string, question: IQuestionData) => {
    const newContent = {
      question_id: question.id,
      statement: question.statement,
      content,
    };
    const target = answerContents.find(c => c.question_id === question.id);
    const newAnswerContents = target
      ? answerContents.map(c =>
          c.question_id === question.id ? newContent : c,
        )
      : [...answerContents, newContent];
    setAnswerContents(newAnswerContents);
    props.handleChangeAnswers(question.id, content);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.contents} ref={contentsRef}>
        {loadImageUrl && (
          <img
            className={styles.image}
            ref={imageRef}
            src={props.imageUrl}
            alt={"image" + props.page}
            onLoad={setContentPosition}
          />
        )}
        {props.isEditMode && <div className={styles.editModeBackground} />}
        {props.questions.map(question => (
          <div
            key={keyGenerator(question)}
            className={clsx(
              styles.answerWrapper,
              props.isEditMode && styles.edit,
            )}
            style={{
              left: question.x * inputFieldsScale,
              top: question.y * inputFieldsScale,
              width: question.width * inputFieldsScale,
              height: question.height * inputFieldsScale,
            }}
          >
            <textarea
              className={styles.answer}
              style={{
                width: question.width * inputFieldsScale,
                height: question.height * inputFieldsScale,
              }}
              maxLength={500}
              onKeyDown={e => {
                if (e.key === "Tab") e.preventDefault();
              }}
              value={(() => {
                const answerContent = answerContents.find(
                  c => c.question_id === question.id,
                );
                return answerContent?.content ?? "";
              })()}
              onClick={e => e.stopPropagation()}
              onChange={e => handleChange(e.target.value, question)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default ImagePageView;
