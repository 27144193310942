import React from "react";
import clsx from "clsx";

import { NEXT_ACTIONS_PAGE } from "../../../constants/assessment-content";
import { SCORE_LABELS } from "../../../constants/assessment-content";
import { IAssessmentContent } from "../../../constants/assessment-content";
import AssessmentComment from "../AssessmentComment";
import {
  IAssessmentExperience,
  IReasonExperience,
  INextAction,
  IAssessment,
  IAssessmentPast,
} from "../../../state";
import { ReactComponent as CaretIcon } from "../../../assets/svg/caret_down.svg";
import { ReactComponent as TriangleIcon } from "../../../assets/svg/glide_triangle.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/glide_arrow.svg";
import { ReactComponent as PreviousScoreIcon } from "../../../assets/svg/icon_student_previous.svg";
import { NextActionKind, NextActionKinds } from "../../../constants/enum";
import { REASON_LABEL } from "../../../constants/assessment-content";

import styles from "./AssessmentContentForSP.module.scss";

export type Props = {
  isActiveNextAction: boolean;
  content: IAssessmentContent;
  selectedScore: number | null;
  currentReason: string | null;
  currentNextActions: { [key: number]: string | null };
  assessmentExperience: IAssessmentExperience | null;
  assessmentNextActions: INextAction[];
  previousAssessment: IAssessment | IAssessmentPast | null;
  calling: boolean;
  onSaveScore: (score: number) => void;
  onSaveReason: (reason: string) => void;
  onSaveNextAction: (kind: NextActionKind, next_action: string) => void;
  onEditReason: (id: number, reason: string) => void;
  onDeleteReason: (id: number) => void;
  onEditNextAction: (id: number, reason: string) => void;
  onDeleteNextAction: (id: number) => void;
  onChangeTab: (tab: number) => void;
};

const AssessmentContentForSP: React.FC<Props> = ({
  isActiveNextAction,
  content,
  selectedScore,
  currentReason,
  currentNextActions,
  assessmentExperience,
  assessmentNextActions,
  previousAssessment,
  calling,
  onSaveScore,
  onSaveReason,
  onSaveNextAction,
  onEditReason,
  onDeleteReason,
  onEditNextAction,
  onDeleteNextAction,
  onChangeTab,
}) => {
  const score: number = React.useMemo(() => selectedScore ?? 0, [
    selectedScore,
  ]);
  const previousScore: number = React.useMemo(
    () =>
      previousAssessment?.assessment_experiences[content.id - 1]?.score ?? 0,
    [previousAssessment, content],
  );

  const reasons: IReasonExperience[] = React.useMemo(() => {
    return assessmentExperience?.reasons || [];
  }, [assessmentExperience]);

  const filteredNextActions = React.useMemo(() => {
    const res: { [key: number]: IReasonExperience[] } = {};
    NextActionKinds.forEach(k => {
      res[k.kind] = assessmentNextActions
        .filter(item => item.kind === k.kind)
        .map(item => ({
          id: item.id,
          created_at: item.created_at,
          reason: item.next_action,
        }));
    });
    return res;
  }, [assessmentNextActions]);

  const enableCommentSubmit = React.useMemo(
    () => score > 0 || isActiveNextAction,
    [score, isActiveNextAction],
  );

  const reasonLabel = React.useMemo(() => {
    if (previousScore === 0 || score === 0) {
      return REASON_LABEL.defaultText;
    } else if (score < previousScore) {
      return REASON_LABEL.downText;
    } else {
      return REASON_LABEL.upTexts[score];
    }
  }, [previousScore, score]);

  return (
    <div className={styles.assessmentSP}>
      {content.id !== NEXT_ACTIONS_PAGE.id && (
        <div
          className={clsx(
            styles.assessmentSP__title,
            styles[`assessmentSP__title--${content.id}`],
          )}
        >
          <span>{content.titleForStudentGlide}</span>
          <strong>{content.questionTitle}</strong>
          <span>{content.subQuestionTitle}</span>
        </div>
      )}
      {content.id !== NEXT_ACTIONS_PAGE.id && (
        <div className={styles.assessmentSP__content}>
          <div
            className={clsx(
              styles.assessmentSP__content__question,
              styles[`assessmentSP__content__question--${content.id}`],
            )}
          >
            <p>{content.questionContentForStudent}</p>
            <div className={styles.assessmentSP__conversations}>
              <div className={styles.assessmentSP__conversations__person}>
                <img src={content.iconGlide1} alt="question icon" />
                <div
                  className={clsx(
                    styles.assessmentSP__conversations__chat,
                    styles["assessmentSP__conversations__chat--A"],
                  )}
                >
                  <span>この素養がないと…</span>
                  <p>{content.personASP}</p>
                </div>
              </div>
              <div className={styles.assessmentSP__conversations__arrow}>
                <ArrowIcon />
              </div>
              <div className={styles.assessmentSP__conversations__person}>
                <div
                  className={clsx(
                    styles.assessmentSP__conversations__chat,
                    styles["assessmentSP__conversations__chat--B"],
                    styles[
                      `assessmentSP__conversations__chat--B-${content.id}`
                    ],
                  )}
                >
                  <span>この素養があると…</span>
                  <p>{content.personBSP}</p>
                  <TriangleIcon />
                </div>
                <img src={content.iconGlide2} alt="question icon" />
              </div>
            </div>
          </div>
          <p className={styles.assessmentSP__content__remind}>
            この素養について、今自分はどの段階にいるのか、
            <br />
            以下８つの中から当てはまるものを選びましょう。
          </p>
          <div
            className={clsx(
              styles.assessmentSP__content__answers,
              styles[`assessmentSP__content__answers--${content.id}`],
            )}
          >
            {Array.from(Array(8).keys()).map(idx => (
              <div
                className={clsx(
                  styles.assessmentSP__content__answer,
                  score === idx + 1 && styles.active,
                )}
                key={idx}
                onClick={() => onSaveScore(idx + 1)}
              >
                <label>{(SCORE_LABELS as any)[`label${idx + 1}`]}</label>
                <p>{(content as { [key: string]: any })[`score${idx + 1}`]}</p>
                {!isActiveNextAction && previousScore === idx + 1 && (
                  <PreviousScoreIcon />
                )}
              </div>
            ))}
          </div>
          <p className={styles.assessmentSP__content__titleComment}>
            {reasonLabel}
          </p>
          <AssessmentComment
            calling={calling}
            currentReason={currentReason}
            reasons={reasons}
            contentId={content.id}
            onSaveReason={reason => onSaveReason(reason)}
            onEdit={(id, reason) => onEditReason(id, reason)}
            onDelete={id => onDeleteReason(id)}
            enableSubmit={enableCommentSubmit}
            placeholder={content.placeholder}
          />
          <div className="d-flex justify-content-center mt-4">
            <button
              className={clsx(
                styles.assessmentSP__actions,
                styles[`assessmentSP__actions--next`],
              )}
              onClick={() => onChangeTab(content.id + 1)}
            >
              次へ
              <CaretIcon />
            </button>
          </div>
        </div>
      )}
      {content.id === NEXT_ACTIONS_PAGE.id && (
        <div className={styles.assessmentSP__content}>
          <div className={styles.assessmentSP__content__titleNextActions}>
            現状を把握し、未来について考えてみましょう。
            <p>
              自分の経験が増えるたびに、いつでも
              <br />
              何回でも記録しましょう。
            </p>
          </div>
          {NextActionKinds.map(k => (
            <div key={k.kind} className="mb-3">
              <p className={styles.assessmentSP__content__titleNextActions}>
                {k.title}
              </p>
              <AssessmentComment
                calling={calling}
                currentReason={currentNextActions[k.kind]}
                reasons={filteredNextActions[k.kind]}
                contentId={content.id}
                onSaveReason={reason => onSaveNextAction(k.kind, reason)}
                onEdit={(id, reason) => onEditNextAction(id, reason)}
                onDelete={id => onDeleteNextAction(id)}
                enableSubmit={enableCommentSubmit}
                placeholder={k.placeholder}
              />
            </div>
          ))}
          <div className="d-flex justify-content-center mt-4" />
        </div>
      )}
    </div>
  );
};

export default AssessmentContentForSP;
