import React from "react";

import Modal from "../../atoms/Modal";

import styles from "./ModalConfirm.module.scss";

export type Props = {
  title: string;
  buttons: {
    title: string;
    type: "Success" | "Warning" | "Danger" | "None";
    action: () => void;
  }[];
  onClose: () => void;
};

const ModalConfirm: React.FC<Props> = ({ onClose, title, buttons }) => {
  return (
    <Modal onClose={onClose} className={styles.customModal}>
      <p className={styles.customModal__title}>{title}</p>
      <div className={styles.customModal__actions}>
        {buttons.map((btn, index) => (
          <button
            key={index}
            className={styles[btn.type.toLocaleLowerCase()]}
            onClick={btn.action}
          >
            {btn.title}
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default ModalConfirm;
