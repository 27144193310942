import React from "react";
import clsx from "clsx";

import { ReactComponent as ArrowNext } from "../../assets/svg/arrow_next.svg";
import { ReactComponent as ArrowPrev } from "../../assets/svg/arrow_prev.svg";
import { ReactComponent as IconArrow } from "../../assets/svg/caret_down.svg";

import style from "./ControlLessonButtons.module.scss";

export type Props = {
  prevLessonId: number | null;
  nextLessonId: number | null;
  disablePrevPage: boolean;
  disableNextPage: boolean;
  disableNextLesson: boolean;
  nextPage: () => void;
  prevPage: () => void;
  nextLesson: () => void;
  prevLesson: () => void;
};

const ControlLessonButtons = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      prevLessonId,
      nextLessonId,
      disablePrevPage,
      disableNextPage,
      disableNextLesson,
      nextPage,
      prevPage,
      nextLesson,
      prevLesson,
    },
    ref,
  ) => {
    return (
      <div ref={ref}>
        <button
          className={style.paginate}
          disabled={disablePrevPage}
          onClick={prevPage}
        >
          <IconArrow />
        </button>
        <button
          onClick={prevLesson}
          disabled={!disablePrevPage || !prevLessonId}
          className={clsx(style.btnControlLesson, style.prevLesson)}
        >
          <ArrowPrev />
          <p>
            前の
            <br />
            Lessonへ
          </p>
        </button>
        <button
          onClick={nextLesson}
          disabled={disableNextLesson || !disableNextPage || !nextLessonId}
          className={clsx(style.btnControlLesson, style.nextLesson)}
        >
          <p>
            次の
            <br />
            Lessonへ
          </p>
          <ArrowNext />
        </button>
        <button
          className={style.paginate}
          disabled={disableNextPage}
          onClick={nextPage}
        >
          <IconArrow />
        </button>
      </div>
    );
  },
);

export default ControlLessonButtons;
