import * as React from "react";
import { datadogRum } from "@datadog/browser-rum";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";

import cookie, { cookieNames } from "./libs/cookie";
import { diff, deleteAllCookies, isMobile } from "./libs/Util";
import { useReducerWithEnhancedDispatch } from "./libs/enhancedDispatch";
import {
  reducer,
  Context,
  fetchInitialData,
  InitialAppState,
  AppState,
  Actions,
  homePath,
  loginPath,
  teachingMaterialPath,
  activeLearningStoryBookPath,
  logout,
  groupPath,
  questionnairePath,
  clearSuccessMessage,
  clearErrorMessage,
  updateStudentPasswordPath,
  questionnaireStatisticsPath,
  surveyPath,
  oecdSurveyQuestionPath,
  myDataPath,
  selectMaterialsPath,
  listMaterialPath,
  listActiveLearningPath,
  activeLearningLessonPath,
  assessmentDescriptionPath,
  assessmentPath,
  notFoundPath,
  defaultPath,
  LOGOUT_SUCCESS_MESSAGE,
} from "./studentStore";
import ModalProvider from "./libs/ModalProvider";
import ScrollToTop from "./libs/ScrollToTop";
import Toast from "./molecules/Toast";
import CustomSidebar from "./organisms/student/CustomSidebar";
import Error404Page from "./templates/Error404Page";
import Error503Page from "./templates/Error503Page";
import Login from "./pages/student/Login";
import SelectMaterialsPage from "./pages/student/SelectMaterialsPage";
import TeachingMaterialListPage from "./pages/student/TeachingMaterialListPage";
import TeachingMaterialPage from "./pages/student/TeachingMaterialPage";
import ActiveLearningMaterialListPage from "./pages/student/ActiveLearningMaterialListPage";
import ActiveLearningLessonPage from "./pages/student/ActiveLearningLessonPage";
import ActiveLearningStoryboardPage from "./pages/student/ActiveLearningStoryboardPage";
import MyDataPage from "./pages/student/MyDataPage";
import GroupPage from "./pages/student/GroupPage";
import QuestionnaireListPage from "./pages/student/QuestionnaireListPage";
import AssessmentDescPage from "./pages/student/AssessmentDescPage";
import AssessmentPage from "./pages/student/AssessmentPage";
import Home from "./pages/student/Home";
import QuestionnaireStatisticsPage from "./pages/student/QuestionnaireStatisticsPage";
import UpdateStudentPassword from "./pages/student/UpdateStudentPassword";
import OecdSurveyQuestionPage from "./pages/student/OecdSurveyQuestionPage";
import SurveyPage from "./pages/student/SurveyPage";
import styles from "./App.module.scss";

interface IConditionalProps extends RouteProps {
  readonly component: React.ComponentClass<any> | React.StatelessComponent<any>;
  readonly isDisabled?: boolean;
}
const ConditionalRoute = ({
  component,
  isDisabled = false,
  ...rest
}: IConditionalProps) => {
  const Component = component;

  const render = (renderProps: RouteComponentProps<any>) => {
    if (isDisabled) {
      return (
        <Redirect
          to={{
            pathname: notFoundPath,
            state: { from: renderProps.location },
          }}
        />
      );
    }

    return <Component {...renderProps} />;
  };
  return <Route {...rest} render={render} />;
};

function updateVh() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function handleOrientationChange(media: any) {
  updateVh();
}

const reducerForApp =
  process.env.NODE_ENV === "production"
    ? reducer
    : (state: AppState, action: Actions) => {
        console.groupCollapsed(action.types);
        console.log("action", action);
        const result = reducer(state, action);
        console.log("diff", diff(state, result));
        console.log("prevState", state);
        console.log("currentState", result);
        console.groupEnd();
        return result;
      };

const SESSION_KEY = "student_session_id";

export const StudentApp = (props: any) => {
  if (window.location.href.match(/session_id=/)) {
    const result = window.location.href.split("session_id=").pop() as string;
    const sessionID = result.replace(/&.*$/, "");
    sessionStorage.setItem(SESSION_KEY, sessionID);
  }
  const savedSessionId =
    sessionStorage.getItem(SESSION_KEY) ||
    cookie.getCookie(cookieNames.student_session_id) ||
    "";
  const [state, dispatch] = useReducerWithEnhancedDispatch({
    reducer: reducerForApp,
    initialAppState: {
      ...InitialAppState,
      session_id: savedSessionId,
    },
  });
  const [showMsg, setShowMsg] = React.useState<{
    text: string;
    type: string;
  }>({
    text: "",
    type: "",
  });

  React.useEffect(() => {
    if (state.student) {
      datadogRum.setUser({
        id: state.student.id,
        type: "student",
        class_id: state.student.class_id,
        school_id: state.student.school_id,
      });
    }
  }, [state.student]);

  React.useEffect(() => {
    if (isMobile()) {
      const mediaQueryList = window.matchMedia("(orientation: portrait)");
      handleOrientationChange(mediaQueryList);
      mediaQueryList.addListener(handleOrientationChange);
      return () => {
        mediaQueryList.removeListener(handleOrientationChange);
      };
    }
  }, []);

  React.useEffect(() => {
    if (state.session_id) {
      if (window.location.href.match(/session_id=/)) {
        window.history.pushState(null, "", window.location.pathname);
      }
      dispatch(fetchInitialData());
    }
  }, [state.session_id, dispatch]);

  React.useEffect(() => {
    if (
      state.success_message &&
      state.success_message !== LOGOUT_SUCCESS_MESSAGE
    ) {
      setShowMsg({
        text: state.success_message,
        type: "success",
      });
      dispatch(clearSuccessMessage());
    }

    if (state.error) {
      setShowMsg({
        text: state.error,
        type: "error",
      });
      dispatch(clearErrorMessage());
    }
  }, [dispatch, state.error, state.success_message]);

  React.useEffect(() => {
    if (
      savedSessionId &&
      !sessionStorage.getItem(SESSION_KEY) &&
      !cookie.getCookie(cookieNames.student_session_id) &&
      !state.session_id
    ) {
      window.location.reload();
    } else if (!savedSessionId && state.session_id) {
      cookie.set(cookieNames.student_session_id, state.session_id, 365);
    } else if (savedSessionId && !state.session_id) {
      sessionStorage.removeItem(SESSION_KEY);
      cookie.deleteAll();
    }
  }, [savedSessionId, state.session_id]);

  const logoutFunc = React.useCallback(() => {
    deleteAllCookies();
    sessionStorage.clear();
    localStorage.clear();
    dispatch(logout());
  }, [dispatch]);

  React.useEffect(() => {
    if (
      state.success_message === LOGOUT_SUCCESS_MESSAGE ||
      state.expires < new Date()
    ) {
      window.location.reload();
    }
  }, [state]);

  return state.isServiceUnavailable ? (
    <Error503Page />
  ) : (
    <Context.Provider value={{ state, dispatch }}>
      <ModalProvider>
        <ScrollToTop {...props}>
          <Router basename={"/st"}>
            <div className={styles.appContainer}>
              <div className={styles.wrapper}>
                {state.session_id && (
                  <CustomSidebar
                    isLoaded={state.student}
                    isDlpEnabled={
                      state.student ? state.student.dlp_enabled : true
                    }
                    isSurveyEnabled={state.survey_enabled}
                    isOnlyAssessment={state.only_assessment}
                    isG4sEnabled={
                      !!state.student?.g4s_enabled || state.g4s_basic_enabled
                    }
                    isDummy={state.is_dummy}
                    availableItemCodes={state.available_item_codes}
                    logout={logoutFunc}
                  />
                )}
                {state.session_id && state.student && (
                  <div className={styles.content}>
                    <Switch>
                      <ConditionalRoute
                        path={defaultPath}
                        exact
                        component={
                          state.only_assessment ? AssessmentDescPage : Home
                        }
                      />
                      <ConditionalRoute
                        path={homePath}
                        exact
                        component={Home}
                      />
                      <ConditionalRoute
                        path={selectMaterialsPath}
                        exact
                        component={SelectMaterialsPage}
                        isDisabled={
                          (state.student && !state.student.dlp_enabled) ||
                          state.only_assessment
                        }
                      />
                      <ConditionalRoute
                        path={listMaterialPath}
                        exact
                        component={TeachingMaterialListPage}
                        isDisabled={
                          (state.student && !state.student.dlp_enabled) ||
                          state.only_assessment
                        }
                      />
                      <ConditionalRoute
                        path={listMaterialPath}
                        component={TeachingMaterialListPage}
                        isDisabled={state.student && !state.student.dlp_enabled}
                      />
                      <ConditionalRoute
                        path={`${teachingMaterialPath}/:id`}
                        component={TeachingMaterialPage}
                        isDisabled={
                          (state.student && !state.student.dlp_enabled) ||
                          state.only_assessment
                        }
                      />
                      <ConditionalRoute
                        path={listActiveLearningPath}
                        exact
                        component={ActiveLearningMaterialListPage}
                        isDisabled={
                          (state.student && !state.student.dlp_enabled) ||
                          state.only_assessment
                        }
                      />
                      <ConditionalRoute
                        path={`${activeLearningLessonPath}/:no`}
                        component={ActiveLearningLessonPage}
                        isDisabled={false}
                      />
                      <ConditionalRoute
                        path={`${activeLearningStoryBookPath}/:id`}
                        component={ActiveLearningStoryboardPage}
                        isDisabled={state.only_assessment}
                      />
                      <ConditionalRoute
                        path={myDataPath}
                        component={MyDataPage}
                        isDisabled={
                          (state.student && !state.student.dlp_enabled) ||
                          state.only_assessment ||
                          state.is_dummy
                        }
                      />
                      <ConditionalRoute
                        path={groupPath}
                        component={GroupPage}
                        isDisabled={
                          (state.student && !state.student.dlp_enabled) ||
                          state.only_assessment
                        }
                      />
                      <ConditionalRoute
                        path={questionnairePath}
                        component={QuestionnaireListPage}
                        isDisabled={
                          (state.student && !state.student.dlp_enabled) ||
                          state.only_assessment ||
                          !state.survey_enabled
                        }
                      />
                      <ConditionalRoute
                        path={assessmentDescriptionPath}
                        component={AssessmentDescPage}
                      />
                      <ConditionalRoute
                        path={assessmentPath}
                        component={AssessmentPage}
                      />
                      <ConditionalRoute
                        path={`${questionnaireStatisticsPath}/:id`}
                        component={QuestionnaireStatisticsPage}
                        isDisabled={
                          (state.student && !state.student.dlp_enabled) ||
                          state.only_assessment
                        }
                      />
                      <ConditionalRoute
                        path={`${surveyPath}/:id`}
                        component={SurveyPage}
                        isDisabled={
                          (state.student && !state.student.dlp_enabled) ||
                          state.only_assessment
                        }
                      />
                      <ConditionalRoute
                        path={`${surveyPath}/:id/edit`}
                        component={SurveyPage}
                        isDisabled={
                          (state.student && !state.student.dlp_enabled) ||
                          state.only_assessment
                        }
                      />
                      <ConditionalRoute
                        path={`${oecdSurveyQuestionPath}`}
                        component={OecdSurveyQuestionPage}
                      />
                      <Redirect
                        from={loginPath}
                        to={{
                          pathname: state.only_assessment
                            ? assessmentDescriptionPath
                            : homePath,
                        }}
                      />
                      <Route component={Error404Page} />
                    </Switch>
                  </div>
                )}
                {!state.session_id && (
                  <div className={styles.content}>
                    <Switch>
                      <ConditionalRoute path={loginPath} component={Login} />
                      <ConditionalRoute
                        path={`${updateStudentPasswordPath}/:uuid`}
                        component={UpdateStudentPassword}
                      />
                      <Redirect
                        to={{
                          pathname: loginPath,
                        }}
                      />
                    </Switch>
                  </div>
                )}
              </div>
            </div>
            {showMsg.text && (
              <Toast
                showBottomRight={window.location.pathname.includes(
                  teachingMaterialPath,
                )}
                onClose={() =>
                  setShowMsg({
                    text: "",
                    type: "",
                  })
                }
                message={showMsg}
              />
            )}
          </Router>
        </ScrollToTop>
      </ModalProvider>
    </Context.Provider>
  );
};

export default StudentApp;
