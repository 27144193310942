import React from "react";
import clsx from "clsx";

import LessonNo from "../../../atoms/ActiveLearning/LessonNo";

import styles from "./Header.module.scss";

type IHeaderProps = {
  no: number;
  label: string;
  title: string;
  read: string;
  isTeacher?: boolean;
};

export const Header: React.FC<IHeaderProps> = ({
  no,
  label,
  title,
  read,
  isTeacher,
}) => {
  return (
    <div className={clsx(styles.header, isTeacher && styles.isTeacher)}>
      <div className={styles.headerWrapper}>
        <LessonNo no={no} />
        <div className={styles.titleWrapper}>
          <span className={styles.label}>{label}</span>
          <p className={styles.title}>{title}</p>
          <p className={styles.read}>{read}</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
