import React from "react";

import { ReactComponent as IconLive } from "../../../assets/images/ActiveLearning/icon_live.svg";

import styles from "./TreeCard.module.scss";

interface ITreeCardProps {
  title: string;
  text: string;
}

export const TreeCard: React.FC<ITreeCardProps> = ({ title, text }) => {
  return (
    <div className={styles.treeCard}>
      <p className={styles.title}>
        <IconLive />
        {title}
      </p>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default TreeCard;
