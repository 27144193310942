import React from "react";

import { ReactComponent as ArrowIcon } from "../../../assets/svg/caret_down.svg";
import { IPackage } from "../../../state";

import styles from "./Questionnaire.module.scss";

export interface TeachingMaterialProps {
  packageQuestion: IPackage;
  directTo: (teaching_material_id: number) => void;
}

const Questionnaire = ({
  packageQuestion,
  directTo,
}: TeachingMaterialProps) => {
  return (
    <>
      <div
        className={styles.cover}
        key={packageQuestion.id}
        data-bs-toggle="collapse"
        data-bs-target={`#teaching${packageQuestion.id.toString()}`}
        role="button"
        aria-expanded="false"
        aria-controls={`#teaching${packageQuestion.id.toString()}`}
      >
        <img
          className={styles.coverImage}
          src={packageQuestion.image_url}
          alt="cover"
        />
        <div className={styles.header}>
          <div className={styles.name}>{packageQuestion.name}</div>
        </div>
      </div>
      {packageQuestion.teaching_materials && (
        <div
          className={`collapse ${styles.collapseContent}`}
          id={`teaching${packageQuestion.id.toString()}`}
          data-bs-parent="#accordion"
        >
          {packageQuestion.teaching_materials.map(material => {
            return (
              <div key={material.id} className={styles.index}>
                <div className={styles.lessonName}>{material.name}</div>
                <div
                  className={styles.lessonTitle}
                  onClick={() => directTo(material.id)}
                >
                  {material.title}
                </div>
                <ArrowIcon />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Questionnaire;
