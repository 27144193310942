import React from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

import Sidebar from "../Sidebar";
import HamburgerMenu from "../HamburgerMenu";
import SPSidebar from "../SPSidebar";
import {
  teachingMaterialPath,
  activeLearningLessonPath,
} from "../../../studentStore";
import { parseQueryParams } from "../../../libs/Util";

import styles from "./CustomSidebar.module.scss";

export interface CustomSidebarProps {
  isLoaded: boolean;
  isDlpEnabled: boolean;
  isSurveyEnabled: boolean;
  isOnlyAssessment: boolean;
  isG4sEnabled: boolean;
  isDummy: boolean;
  availableItemCodes: string[];
  logout: () => void;
}

const excludedPaths = [teachingMaterialPath, activeLearningLessonPath];

const CustomSidebar: React.FC<CustomSidebarProps> = ({
  isLoaded,
  isDlpEnabled,
  isSurveyEnabled,
  isOnlyAssessment,
  isG4sEnabled,
  isDummy,
  availableItemCodes,
  logout,
}) => {
  const location = useLocation();
  const showDefaultSidebar = !new RegExp(excludedPaths.join("|")).test(
    location.pathname,
  );
  const hideSidebar = parseQueryParams()["sidebar"] === "no";
  const [state, setState] = React.useState({
    isShowSidebar: false,
  });
  const handleCloseSidebar = () =>
    setState(prevState => ({ ...prevState, isShowSidebar: false }));

  const handleToggleMenu = (isOpen: boolean) =>
    setState(prevState => ({ ...prevState, isShowSidebar: isOpen }));

  return (
    <>
      {showDefaultSidebar && !hideSidebar && (
        <Sidebar
          className={styles.sidebar}
          isLoaded={isLoaded}
          isDlpEnabled={isDlpEnabled}
          isSurveyEnabled={isSurveyEnabled}
          isOnlyAssessment={isOnlyAssessment}
          isG4sEnabled={isG4sEnabled}
          isDummy={isDummy}
          availableItemCodes={availableItemCodes}
          logout={logout}
        />
      )}
      <HamburgerMenu
        className={clsx(
          styles.hamburgerMenuWrapper,
          !showDefaultSidebar &&
            !hideSidebar &&
            styles["hamburgerMenuWrapper--alwaysShow"],
        )}
        isOpen={state.isShowSidebar}
        onToggle={handleToggleMenu}
      >
        {!showDefaultSidebar && (
          <Sidebar
            hasFadeInLeftAnimation
            className={styles.sidebar}
            isLoaded={isLoaded}
            isDlpEnabled={isDlpEnabled}
            isSurveyEnabled={isSurveyEnabled}
            isOnlyAssessment={isOnlyAssessment}
            isG4sEnabled={isG4sEnabled}
            isDummy={isDummy}
            availableItemCodes={availableItemCodes}
            logout={logout}
          />
        )}
        <SPSidebar
          className={styles.SPSidebar}
          isDlpEnabled={isDlpEnabled}
          isSurveyEnabled={isSurveyEnabled}
          isOnlyAssessment={isOnlyAssessment}
          isG4sEnabled={isG4sEnabled}
          isDummy={isDummy}
          availableItemCodes={availableItemCodes}
          onClose={handleCloseSidebar}
          logout={logout}
        />
      </HamburgerMenu>
    </>
  );
};

export default CustomSidebar;
