import React from "react";

import LogoImg from "../../assets/images/logo.png";

import styles from "./ResetPasswordForm.module.scss";

export interface ResetPasswordFormProps {
  calling: boolean;
  updatePassword: (password: string) => void;
}

const validates: { [key: string]: (value: any) => string } = {};

validates["password"] = val => {
  return val.password.length < 6 || val.password.length > 32
    ? "パスワードは6文字以上32文字以内で入力してください。"
    : "";
};
validates["passwordConfirm"] = val => {
  return val.password !== val.passwordConfirm
    ? "パスワードが一致しません。"
    : "";
};

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const [state, setState] = React.useState({
    password: "",
    passwordConfirm: "",
    isSubmitted: false,
    errorMessages: {} as { [key: string]: string },
  });

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const errorMessages = {} as { [key: string]: string };
      Object.keys(validates).forEach(key => {
        const ret = validates[key](state);
        if (ret) {
          errorMessages[key] = ret;
        }
      });
      if (Object.keys(errorMessages).length > 0) {
        setState(s => ({ ...s, errorMessages }));
        return;
      }
      props.updatePassword(state.password);
    },
    [state, props],
  );

  const onChange = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      e.preventDefault();
      const val = e.currentTarget.value;
      const key = e.currentTarget.name;
      setState(prevState => {
        const errorMessages = {} as { [key: string]: string };
        const arg = { ...prevState, [key]: val };
        Object.keys(validates).forEach(k => {
          errorMessages[k] = validates[k](arg);
        });
        return {
          ...arg,
          errorMessages,
        };
      });
    },
    [],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>
          <div>
            <img src={LogoImg} alt="" />
          </div>
        </h1>
      </div>
      <main className={styles.main}>
        <h2>パスワード登録</h2>
        <div>
          <form onSubmit={onSubmit}>
            <div className="mb-3">
              <label htmlFor="password">パスワード</label>
              <div className={styles.wrapInput}>
                <input
                  type="password"
                  name="password"
                  className={
                    state.errorMessages["password"]
                      ? "is-invalid"
                      : state.password
                      ? "is-valid"
                      : ""
                  }
                  id="password"
                  value={state.password}
                  onChange={onChange}
                />
                <div className={styles.error}>
                  {state.errorMessages["password"]}
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="passwordConfirm">確認用パスワード</label>
              <div className={styles.wrapInput}>
                <div className={styles.wrapInput__inputPass}>
                  <input
                    type="password"
                    id="passwordConfirm"
                    name="passwordConfirm"
                    className={
                      state.errorMessages["passwordConfirm"]
                        ? "is-invalid"
                        : state.passwordConfirm
                        ? "is-valid"
                        : ""
                    }
                    value={state.passwordConfirm}
                    onChange={onChange}
                  />
                </div>
                <div className={styles.error}>
                  {state.errorMessages["passwordConfirm"]}
                </div>
              </div>
            </div>
            <button
              className={styles.btnSubmit}
              onClick={onSubmit}
              disabled={
                Object.keys(state.errorMessages).filter(
                  k => state.errorMessages[k],
                ).length > 0 || props.calling
              }
            >
              登録
            </button>
          </form>
        </div>
      </main>
    </div>
  );
};

export default ResetPasswordForm;
