import React from "react";

import Portal from "../../atoms/Portal";

import styles from "./Toast.module.scss";

export type Props = {
  onClose: () => void;
  showBottomRight?: boolean;
  message: {
    text: string;
    type: string;
  };
};
const Toast: React.FC<Props> = ({ onClose, message, showBottomRight }) => {
  const [isClose, setIsClose] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setIsClose(true), 2000);

    return () => clearTimeout(timer);
  }, []);

  const animationEnd = () => {
    if (isClose) onClose();
  };

  return (
    <Portal>
      <div
        className={`${styles.toast} ${styles[message.type]} ${
          showBottomRight ? styles.bottomRight : styles.topRight
        } ${isClose ? styles.close : styles.open} `}
        onAnimationEnd={animationEnd}
      >
        {message.text}
      </div>
    </Portal>
  );
};

export default Toast;
