import React from "react";

import Home from "../../../templates/student/Home";
import { Context, fetchCountAnswers } from "../../../studentStore";

export default () => {
  const { state, dispatch } = React.useContext(Context);

  React.useEffect(() => {
    if (state.student && state.student.dlp_enabled) {
      dispatch(fetchCountAnswers());
    }
  }, [dispatch, state.student]);

  return (
    <Home
      student={state.studentAnswer}
      oecdSurveyEnabled={state.oecd_survey_enabled}
      isDlpEnabled={!!state.student?.dlp_enabled}
    />
  );
};
