import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useMemo,
} from "react";
import clsx from "clsx";

import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { Context } from "../../../../../studentStore";
import { IAnswer, HintList } from "../../../../../constants/active_learning";
import HintCard from "../../../../../atoms/ActiveLearning/HintCard";
import Card from "../../../../../atoms/ActiveLearning/Card";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import TextInput from "../../../../../atoms/ActiveLearning/TextInput";
import AssessmentTable from "../../../../ActiveLearning/AssessmentTable";
import styles from "../../ActiveLearningIssue.module.scss";
import { ReactComponent as IconLive } from "../../../../../assets/images/ActiveLearning/icon_live.svg";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
  commonAnswers: IActiveLearningAnswers[];
}

interface IAuthenticityAnswer extends IAnswer {
  parentUid: string;
  parentLabel: string;
  authenticity: number;
}
interface IResearchedTextAnswer extends IAnswer {
  parentUid: string;
  value: string;
}
interface ITextAnswer extends IAnswer {
  value: string;
}

export const Issue2 = (props: IActiveLearningIssue1Props) => {
  const { state } = React.useContext(Context);
  const currentIssueNo = 2;
  const question1 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);
  const question2 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 2);
  }, [props.issue?.questions]);
  const question3 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 3);
  }, [props.issue?.questions]);
  const question4 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 4);
  }, [props.issue?.questions]);
  const question5 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 5);
  }, [props.issue?.questions]);
  const question6 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 6);
  }, [props.issue?.questions]);
  const question7 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 7);
  }, [props.issue?.questions]);

  const [selectedUid, setSelectedUid] = useState("");
  const [answersToQ1, setAnswersToQ1] = useState<IAuthenticityAnswer[]>([]);
  const [answersToQ2, setAnswersToQ2] = useState<IAuthenticityAnswer[]>([]);
  const [answersToQ3, setAnswersToQ3] = useState<IAuthenticityAnswer[]>([]);
  const [answersToQ4, setAnswersToQ4] = useState<IAuthenticityAnswer[]>([]);
  const [answersToQ5, setAnswersToQ5] = useState<IResearchedTextAnswer[]>([]);
  const [answerToQ6, setAnswerToQ6] = useState<ITextAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question6?.order_num || NaN,
    question_id: question6?.id || NaN,
    value: "",
  });
  const [answerToQ7, setAnswerToQ7] = useState<ITextAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question7?.order_num || NaN,
    question_id: question7?.id || NaN,
    value: "",
  });

  useEffect(() => {
    setSelectedUid(props.commonAnswers[0]?.contents?.uid);
  }, [props.commonAnswers]);

  useEffect(() => {
    // Store側のデータを各ステートに同期
    const answerQ6 = state.active_learning_answers.find(
      answer =>
        answer.issue_no === currentIssueNo &&
        answer.question_no === question6?.order_num,
    );

    setAnswerToQ6({
      id: answerQ6?.id || null,
      issue_no: currentIssueNo,
      question_no: question6?.order_num || NaN,
      question_id: question6?.id || NaN,
      value: answerQ6?.contents?.value,
    });

    const answerQ7 = state.active_learning_answers.find(
      answer =>
        answer.issue_no === currentIssueNo &&
        answer.question_no === question7?.order_num,
    );

    setAnswerToQ7({
      id: answerQ7?.id || null,
      issue_no: currentIssueNo,
      question_no: question7?.order_num || NaN,
      question_id: question7?.id || NaN,
      value: answerQ7?.contents?.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.active_learning_answers]);

  useEffect(() => {
    const excludedAnswer = props.answers.filter(
      answer => answer.issue_no !== currentIssueNo,
    );

    const currentAnswers: IActiveLearningAnswers[] = [];
    [answersToQ1, answersToQ2, answersToQ3, answersToQ4].forEach(answers => {
      answers.forEach(answer => {
        currentAnswers.push({
          id: answer.id,
          issue_no: answer.issue_no,
          question_no: answer.question_no,
          question_id: answer.question_id,
          contents: {
            parentUid: answer?.parentUid,
            parentLabel: answer?.parentLabel,
            authenticity: answer?.authenticity | NaN,
          },
        });
      });
    });

    answersToQ5.forEach(answer => {
      currentAnswers.push({
        id: answer.id,
        issue_no: answer.issue_no,
        question_no: answer.question_no,
        question_id: answer.question_id,
        contents: {
          parentUid: answer?.parentUid,
          value: answer?.value,
        },
      });
    });
    currentAnswers.push({
      id: answerToQ6.id,
      issue_no: answerToQ6.issue_no,
      question_no: answerToQ6.question_no,
      question_id: answerToQ6.question_id,
      contents: {
        value: answerToQ6?.value || "",
      },
    });
    currentAnswers.push({
      id: answerToQ7.id,
      issue_no: answerToQ7.issue_no,
      question_no: answerToQ7.question_no,
      question_id: answerToQ7.question_id,
      contents: {
        value: answerToQ7?.value || "",
      },
    });
    props.setAnswers([...excludedAnswer, ...currentAnswers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    answersToQ1,
    answersToQ2,
    answersToQ3,
    answersToQ4,
    answersToQ5,
    answerToQ6,
    answerToQ7,
    props.setAnswers,
  ]);

  const commonAnswers = useMemo(() => {
    return props.commonAnswers.map(answer => {
      return { label: answer.contents.value, value: answer.contents.uid };
    });
  }, [props.commonAnswers]);

  const questions = {
    question1,
    question2,
    question3,
    question4,
    question5,
  };

  const answersTo = {
    answersToQ1,
    answersToQ2,
    answersToQ3,
    answersToQ4,
    answersToQ5,
  };

  const setAnswersTo = {
    setAnswersToQ1,
    setAnswersToQ2,
    setAnswersToQ3,
    setAnswersToQ4,
    setAnswersToQ5,
  };

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          考えたアイデアについて評価ポイントを参考に調査し、アイデアをそれぞれ評価しよう。
          <br />
          評価が終わったら、アイデアを比較して、自分が実現したいと思うアイデアを1つ決めよう。
          <br />
          <br />
          操作：「選択してください。」と表示されている回答枠をクリックすると、WORK01で考えたアイデアの中からアイデアを1つ選択できます。評価項目は3つの中から1つクリックできます。評価が終わったら「選択してください。」と表示されている回答枠から別のアイデアを選択し、次の調べ学習をしながら評価をします。
        </p>
      </div>
      <div className={styles.hint}>
        <p className={styles.title}>調べる視点のヒント</p>
        <div className={clsx(styles.hintList, styles.isPc)}>
          {HintList.lesson5.issue2.hint1.map((hint, index) => {
            return (
              <HintCard
                key={index}
                title={hint.title}
                width={"calc((100% - 16px) / 2)"}
              >
                {hint.text}
              </HintCard>
            );
          })}
        </div>
        <div className={clsx(styles.hintList, styles.isSp)}>
          {HintList.lesson5.issue2.hint1.map((hint, index) => {
            return (
              <HintCard key={index} title={hint.title}>
                {hint.text}
              </HintCard>
            );
          })}
        </div>
      </div>
      {question1 && question2 && question3 && question4 && question5 && (
        <AssessmentTable
          activeLearningAnswers={state.active_learning_answers}
          currentIssueNo={currentIssueNo}
          selectedUid={selectedUid}
          commonAnswers={commonAnswers}
          setSelectedUid={setSelectedUid}
          questions={questions}
          answersTo={answersTo}
          setAnswersTo={setAnswersTo}
        />
      )}
      {question6 && (
        <div className={styles.themEntry}>
          <p className={styles.title}>{question6.title}</p>
          <p className={styles.read}>
            考えた解決のアイデアの中から、これから実現していく解決のアイデアを選ぼう。WORK01で考えたアイデアから内容を調整したい場合は調整して入力しよう。
          </p>
          <div className={styles.textAreaWrapper}>
            <TextInput
              key={answerToQ6?.id}
              value={answerToQ6.value}
              handleChange={e => {
                setAnswerToQ6({
                  ...answerToQ6,
                  value: e.target.value,
                });
              }}
            />
          </div>
        </div>
      )}
      {question7 && (
        <div className={styles.themEntry}>
          <p className={styles.title}>{question7.title}</p>
          <p className={styles.read}>
            Lesson03〜04で、設定した「課題」を解決するアイデアを考えた。調べた内容を参考に、解決のアイデアを考える過程で気づいたことや考えたことを入力しよう。
            <br />
            <br />
            例：〇〇のアイデアにするか△△のアイデアにするかで迷っていたけれど、〇〇と△△を組み合わせたらより良いアイデアになると気づいて──
          </p>
          <div className={styles.textAreaWrapper}>
            <TextArea
              key={answerToQ7?.id}
              value={answerToQ7.value}
              handleChange={e => {
                setAnswerToQ7({
                  ...answerToQ7,
                  value: e.target.value,
                });
              }}
            />
          </div>
        </div>
      )}
      <Card label={"TRY"} icon={<IconLive />} title={"グループワーク"}>
        ①同じテーマを選んだメンバーでグループを組もう。
        <br />
        ②自分の考えた解決のアイデアとその理由を発表し合おう。
        <br />
        ③アイデアを具体的にするための話し合いをしよう。
      </Card>
    </div>
  );
};
export default Issue2;
