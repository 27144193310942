import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import GroupIcon from "../../../assets/images/nav-group.png";
import Header from "../../../organisms/student/Header";
import { IGroup, IStudent } from "../../../state";
import { teachingMaterialPath } from "../../../studentStore";

import styles from "./GroupList.module.scss";

export interface GroupListProps {
  groups: IGroup[];
  currentUser: IStudent;
  chooseGroup: (id: number) => void;
}

const GroupList: React.FC<GroupListProps> = ({
  groups,
  chooseGroup,
  currentUser,
}) => {
  const history = useHistory();
  const location = useLocation<{
    materialId: number;
    lessonId: number;
    page: number;
  }>();

  return (
    <div className="w-100">
      <Header />
      <div className={styles.wrapperGroup}>
        <h2>
          <img src={GroupIcon} alt="" />
          所属するグループを選ぼう
        </h2>

        <div className={styles.listGroup}>
          {groups.map(group => {
            return (
              <div key={group.id} className={styles.singleGroup}>
                <button
                  className={
                    currentUser?.group_id === group.id ? styles.active : ""
                  }
                  onClick={() => chooseGroup(group.id)}
                >
                  {group.name}
                </button>
              </div>
            );
          })}
        </div>

        <div
          className={styles.btnBack}
          onClick={() =>
            history.push(
              `${teachingMaterialPath}/${location.state.materialId}?lesson=${location.state?.lessonId}&page=${location.state?.page}`,
            )
          }
        >
          教材に戻る
        </div>
      </div>
    </div>
  );
};

export default GroupList;
