import React from "react";

import { IPackage } from "../../../state";
import Header from "../../../organisms/student/Header";
import Questionnaire from "../../../organisms/student/Questionnaire";

import styles from "./QuestionnaireList.module.scss";

export interface IMaterialsListProps {
  packageQuestion: IPackage[];
  directTo: (teaching_material_id: number) => void;
}

export const QuestionnaireList = (props: IMaterialsListProps) => {
  return (
    <>
      <Header />
      <div className={styles.wrapper} id="accordion">
        {props.packageQuestion.length > 0 &&
          props.packageQuestion.map(question => (
            <React.Fragment key={question.id}>
              <img
                src={question.image_url}
                alt=""
                style={{ display: "none" }}
              />
              <Questionnaire
                key={question.id}
                packageQuestion={question}
                directTo={props.directTo}
              />
            </React.Fragment>
          ))}
      </div>
    </>
  );
};

export default QuestionnaireList;
