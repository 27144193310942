import React, { useContext } from "react";

import { postOecdSurveyAnswers, Context } from "../../../studentStore";
import OecdSurvey from "../../../templates/student/OecdSurvey";
import useWaitApiCall from "../../../hooks/useWaitApiCall";

export default () => {
  const { state, dispatch } = useContext(Context);
  const [_, peep] = useWaitApiCall(state);

  const handlePostOecdSurveyAnswers = (student_survey_answers: any) => {
    dispatch(peep(postOecdSurveyAnswers(student_survey_answers)));
  };

  return (
    <>
      {state.oecd_survey_enabled ? (
        <OecdSurvey
          already_answered_oecd_survey={
            state.student?.already_answered_oecd_survey
          }
          handlePostOecdSurveyAnswers={handlePostOecdSurveyAnswers}
        />
      ) : (
        <p>学校はアンケートを許可していません。</p>
      )}
    </>
  );
};
