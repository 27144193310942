import React from "react";

import OecdQuestionSection from "../../../organisms/student/OecdQuestionSection";
import Button from "../../../atoms/Button";
import { surveyData, surveyColumnNames } from "../../../constants/oedc_survey";

import styles from "./OecdSurvey.module.scss";

export type OecdSurveyProps = {
  already_answered_oecd_survey: boolean | undefined;
  handlePostOecdSurveyAnswers: (student_survey_answers: any) => void;
};

const OecdSurvey: React.FC<OecdSurveyProps> = ({
  handlePostOecdSurveyAnswers,
  already_answered_oecd_survey,
}) => {
  const [formValue, setFormValue] = React.useState({});
  const [isValid, setIsValid] = React.useState(false);

  React.useEffect(() => {
    setIsValid(
      !surveyColumnNames
        .map(name => Reflect.has(formValue, name))
        .includes(false),
    );
  }, [formValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    const answer = {
      [name]: Number(value),
    };

    setFormValue({ ...answer, ...formValue });
  };

  return (
    <>
      {already_answered_oecd_survey ? (
        <div className={styles.completePage}>
          <p>ご回答ありがとうございました。</p>
        </div>
      ) : (
        <form
          className={styles.form}
          onSubmit={e => {
            e.preventDefault();
            if (!isValid) return;
            handlePostOecdSurveyAnswers(formValue);
          }}
        >
          <div className={styles.oecdSurveyQuestions}>
            <div className={styles.oecdSurveyDesc}>
              <p>
                あなたの日常や学校生活について、以下の質問にご回答ください。
              </p>
              <p>
                回答頂いた内容は集計され、合計値や平均値の算出のみに用いられますので、
                あなたの回答が特定されることはありません。
              </p>
              <p>
                「正しい答え」「間違った答え」はありませんので、
                あなたの考えにもっとも近いものを選択してください。
              </p>
            </div>
            {surveyData.map((section, section_no) => {
              return (
                <div
                  key={`question_group${section_no}`}
                  className={styles.section}
                >
                  <h2 className={styles.headLine}>{section.title}</h2>
                  {section.block.map(block => {
                    return (
                      <OecdQuestionSection
                        block={block}
                        label={section.label}
                        sectionNo={section_no}
                        handleInputChange={handleInputChange}
                        key={`section_${block.section}`}
                      />
                    );
                  })}
                </div>
              );
            })}
            <div className={styles.buttonWrap}>
              <Button
                name={`回答を送信する`}
                className={styles.buttonSubmit}
                disabled={!isValid}
              />
            </div>
            {isValid ? (
              <p className={styles.thanksMsg}>
                質問は以上です。ご回答ありがとうございました。
              </p>
            ) : (
              <p className={styles.error}>未入力項目があります</p>
            )}
          </div>
        </form>
      )}
    </>
  );
};

export default OecdSurvey;
