import * as React from "react";
import { Link } from "react-router-dom";

import NotFoundImg from "../../assets/images/not-found.png";

import styles from "./Error404Page.module.scss";

export default function Error404Page() {
  return (
    <div>
      <main className={styles.main}>
        <img src={NotFoundImg} alt="oops" />
        <p>お探しのページが見つかりませんでした</p>
        <Link to={"/"}>ホーム画面に戻る</Link>
      </main>
    </div>
  );
}
