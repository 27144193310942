export const PARTICIPATE_GROUP_SUCCESS_MSG = "グループの参加に成功しました";

// TODO : fix message
export const REGISTER_ANSWERS_SUCCESS_MSG = "回答を送信しました";
export const REGISTER_ANSWERS_FAILURE_MSG = "回答を送信できませんでした";
export const CREATE_COMMENT_SUCCESS_MSG = "コメントを送信しました";
export const UPDATE_COMMENT_SUCCESS_MSG = "コメントを更新しました";
export const DELETE_COMMENT_SUCCESS_MSG = "コメントを削除しました";
export const UPDATE_STUDENT_PASSWORD_SUCCESS_MESSAGE =
  "パスワードの更新に成功しました";
export const UPDATE_SURVEY_SUCCESS_MSG = "回答の送信に成功しました";
export const UPDATE_SURVEY_FAILURE_MSG = "回答の送信に失敗しました";
export const CREATE_ASSESSMENT_SUCCESS_MSG = "保存しました";
export const UPDATE_REASON_SUCCESS_MSG = "更新しました";
export const DELETE_REASON_SUCCESS_MSG = "削除しました";
