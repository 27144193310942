import React, { useCallback, useMemo } from "react";

import { IActiveLearningTheme } from "../../../state";
import VideoJS from "../../../atoms/VideoJS";
import Modal from "../../../atoms/ActiveLearning/Modal";
import Button from "../../../atoms/ActiveLearning/Button";
import { ReactComponent as IconClose } from "../../../assets/images/ActiveLearning/icon_close.svg";
import { prefixPath } from "../../../teacherStore";

import styles from "./ThemeModal.module.scss";

export type Props = {
  isTeacherSide?: boolean;
  videoDomain: string | undefined;
  activeLearningThemes: IActiveLearningTheme[];
  activeLearningStoryBookPath: string;
  hideActionButton?: boolean;
  handleAction?: () => void;
  handleClose: () => void;
  themeId: number;
};
const ThemeModal: React.FC<Props> = ({
  isTeacherSide = false,
  videoDomain,
  activeLearningThemes,
  activeLearningStoryBookPath,
  hideActionButton,
  handleAction,
  handleClose,
  themeId,
}) => {
  const theme = useMemo(() => {
    return activeLearningThemes.find(theme => theme.id === themeId);
  }, [activeLearningThemes, themeId]);

  const videoOptions = useMemo(() => {
    return {
      url: theme
        ? `${
            videoDomain ? videoDomain : process.env.REACT_APP_STUDENT_DLP_URL
          }${theme.video_url}`
        : "",
      playing: false,
    };
  }, [theme, videoDomain]);

  const openStoryBoard = useCallback(() => {
    window.open(
      `${
        isTeacherSide ? prefixPath : ""
      }${activeLearningStoryBookPath}/${themeId}/?sidebar=no`,
      "_blank",
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeId, isTeacherSide]);

  return (
    <Modal className={styles.modal} handleClose={handleClose}>
      <IconClose className={styles.close} onClick={handleClose} />
      <div className={styles.wrapper}>
        <p className={styles.heading}>{theme?.title}</p>
        <ul className={styles.categoryList}>
          {theme?.action_categories.map(category => (
            <li key={category.id} className={styles.category}>
              #{category.name}
            </li>
          ))}
          {theme?.field_categories.map(category => (
            <li key={category.id} className={styles.category}>
              #{category.name}
            </li>
          ))}
        </ul>
        <ul className={styles.detailList}>
          <li className={styles.detail}>
            <p className={styles.label}>テーマに関する事例</p>
            <p className={styles.text}>{theme?.case_study}</p>
          </li>
          <li className={styles.detail}>
            <p className={styles.label}>動画に出てくる企業</p>
            <p className={styles.text}>{theme?.company_profile}</p>
          </li>
          <li className={styles.detail}>
            <p className={styles.label}>{theme?.desc}</p>
            <VideoJS sourceUrl={videoOptions.url} showVideo={true} />
            <style>{`.video-js .vjs-tech { position: inherit; }`}</style>
          </li>
        </ul>
        <div className={styles.buttonWrapper}>
          <Button
            variant={"secondary"}
            width={"240px"}
            height={"38px"}
            fontSize={"14px"}
            handleClick={openStoryBoard}
          >
            資料を見る
          </Button>
        </div>
        <div className={styles.buttonWrapper}>
          {!hideActionButton && (
            <Button
              handleClick={() => {
                handleAction && handleAction();
                handleClose();
              }}
            >
              取り組む
            </Button>
          )}
          <Button variant={"tertiary"} handleClick={handleClose}>
            閉じる
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ThemeModal;
