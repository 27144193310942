import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";
import clsx from "clsx";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
  IActiveLearningQuestion,
} from "../../../../../state";
import { IAnswer, HintList } from "../../../../../constants/active_learning";
import HintCard from "../../../../../atoms/ActiveLearning/HintCard";
import Button from "../../../../../atoms/ActiveLearning/Button";
import TextInput from "../../../../../atoms/ActiveLearning/TextInput";
import TreeCard from "../../../../../atoms/ActiveLearning/TreeCard";
import styles from "../../ActiveLearningIssue.module.scss";
import { ReactComponent as IconAdd } from "../../../../../assets/images/ActiveLearning/icon_add.svg";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface ICurrentAnswer extends IAnswer<string> {
  value: string;
}

export const Issue3 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = props.issue?.no || NaN;
  const question = useMemo(() => {
    const q = props.issue?.questions.find(q => q.order_num === 1);
    if (q) return q;
  }, [props.issue]);

  const [answersToQ1, setAnswersToQ1] = useState<ICurrentAnswer[]>([]);

  useEffect(() => {
    const answers: IActiveLearningAnswers[] = answersToQ1
      .filter((answer: ICurrentAnswer) => answer.value.length > 0)
      .map((answer: ICurrentAnswer) => {
        return {
          id: answer.id?.includes("#") ? null : Number(answer.id),
          issue_no: answer.issue_no,
          question_no: answer.question_no,
          question_id: answer.question_id,
          contents: {
            value: answer.value,
          },
        };
      });

    // この設問以外の回答情報
    const otherAnswers = props.answers.filter(
      answer => !(answer.issue_no === currentIssueNo),
    );
    props.setAnswers([...otherAnswers, ...answers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersToQ1]);

  useEffect(() => {
    const answersQ1 = state.active_learning_answers.filter(
      answer => answer.issue_no === 3 && answer.question_no === 1,
    );

    const initAnswersQ1: ICurrentAnswer[] =
      answersQ1.length > 0
        ? answersQ1.map(answer => ({
            id: String(answer.id),
            issue_no: answer.issue_no,
            question_no: answer.question_no,
            question_id: answer.question_id,
            value: answer.contents.value,
          }))
        : [...Array(3)].map(_ => {
            return {
              id: `#${Math.random().toString(36).slice(-8)}`,
              issue_no: currentIssueNo,
              question_id: question?.id || NaN,
              question_no: question?.order_num || NaN,
              value: "",
            };
          });
    setAnswersToQ1([...initAnswersQ1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.active_learning_answers]);

  const changeAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    const targetAnswer = answersToQ1.find(answer => answer.id === id);
    if (!targetAnswer) throw new Error(`Not found answer id: #{id}`);

    const newAnswer = {
      id: targetAnswer.id,
      issue_no: currentIssueNo,
      question_id: question?.id || NaN,
      question_no: question?.order_num || NaN,
      value,
    };

    const updatedAnswers = answersToQ1.map(existingAnswer =>
      existingAnswer.id === targetAnswer.id ? newAnswer : existingAnswer,
    );

    !answersToQ1.some(
      existingAnswer => existingAnswer.id === targetAnswer.id,
    ) && updatedAnswers.push(newAnswer);

    setAnswersToQ1(updatedAnswers);
  };

  const addAnswerField = (question: IActiveLearningQuestion) => {
    const oneTimeId = `#${Math.random().toString(36).slice(-8)}`;
    setAnswersToQ1([
      ...answersToQ1,
      {
        id: oneTimeId,
        issue_no: props.issue?.no || NaN,
        question_id: question.id,
        question_no: question.order_num,
        value: "",
      },
    ]);
  };

  const prevLessonAnswerValue = useMemo(
    () =>
      props.answers.find(
        answer => answer.issue_no === 2 && answer.question_no === 1,
      )?.contents.value,
    [props.answers],
  );

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          「問い」に答えを出すことを妨げている問題はなんだろう。その問題を取り除くことが「問い」に答えを出すことにつながる。
          <br />
          まずはヒントを参考にして、立ちはだかる問題を3つ以上入力してみよう。
          <br />
          <br />
          操作：「項目を追加する」ボタンをクリックすると項目を増やすことができます。
        </p>
      </div>
      <div className={styles.hint}>
        <p className={styles.title}>問題を予測するためのヒント</p>
        <p className={styles.text}>
          例：「〇〇県〇〇市の若者が大学を卒業したときに地元を離れないようにするためには？」
        </p>
        <div className={clsx(styles.hintList, styles.isPc)}>
          {HintList.lesson3.issue3.hint1.map((hint, index) => {
            return (
              <HintCard
                key={index}
                title={hint.title}
                width={"calc((100% - (16px * 2)) / 3)"}
              >
                {hint.text}
              </HintCard>
            );
          })}
        </div>
        <div className={clsx(styles.hintList, styles.isSp)}>
          {HintList.lesson3.issue3.hint1.map((hint, index) => {
            return (
              <HintCard key={index} title={hint.title}>
                {hint.text}
              </HintCard>
            );
          })}
        </div>
      </div>
      {question && (
        <div className={styles.question}>
          <div className={styles.tree}>
            <TreeCard
              title={"取り組む問い"}
              text={prevLessonAnswerValue || "問いが未入力です。"}
            />
            <ul className={styles.branchList}>
              {answersToQ1.map(answer => (
                <li key={answer.id} className={styles.branch}>
                  <div className={styles.labelWrapper}>
                    <span className={styles.label}>問題</span>
                  </div>
                  <div className={styles.inputField}>
                    <TextInput
                      id={answer.id}
                      value={answer.value}
                      width={"100%"}
                      handleChange={e => changeAnswer(e)}
                    />
                  </div>
                </li>
              ))}
            </ul>
            <div className={styles.buttonWrapper}>
              <Button
                width={"150px"}
                height={"37px"}
                fontSize={"14px"}
                handleClick={() => addAnswerField(question)}
              >
                <IconAdd />
                項目を追加する
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Issue3;
