import React from "react";
import { useMemo, useCallback, useEffect } from "react";
import clsx from "clsx";

import {
  IActiveLearningAnswers,
  IActiveLearningIssue,
} from "../../../../state";
import { Context, updateActiveLearningAnswers } from "../../../../studentStore";
import { getActiveLearningAnswers } from "../../../../libs/ActiveLearning";
import TreeCard from "../../../../atoms/ActiveLearning/TreeCard";
import Header from "../../../../organisms/ActiveLearning/Header";
import EndLesson from "../../../../organisms/ActiveLearning/EndLesson";
import Issue1 from "../../../../organisms/student/ActiveLearningLessons/Lesson7/Issue1";
import Issue2 from "../../../../organisms/student/ActiveLearningLessons/Lesson7/Issue2";
import styles from "../ActiveLearningLesson.module.scss";
import useWaitApiCall from "../../../../hooks/useWaitApiCall";
import SaveAnswerButton from "../../../../atoms/ActiveLearning/SaveAnswerButton";

export const Lesson7 = () => {
  const { state, dispatch } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const [answers, setAnswers] = React.useState<IActiveLearningAnswers[]>([]);
  const [commonAnswers, setCommonAnswers] = React.useState<
    IActiveLearningAnswers[]
  >([]);

  const materialCode = state.active_learning_data_by_lesson.material_code;

  useEffect(() => {
    setAnswers([...state.active_learning_answers]);
  }, [state.active_learning_answers]);

  const currentActiveLearning = useMemo(() => {
    return state.active_learning_data_by_lesson;
  }, [state.active_learning_data_by_lesson]);

  const findIssue = useCallback(
    (no: number): IActiveLearningIssue | undefined =>
      currentActiveLearning.issues?.find(issue => issue.no === no),
    [currentActiveLearning.issues],
  );

  const saveAnswers = () => {
    const params = answers;
    const { material_code, no } = state.active_learning_data_by_lesson;
    if (calling) return;
    return dispatch(
      peep(
        updateActiveLearningAnswers(material_code, no, JSON.stringify(params)),
      ),
    );
  };

  const LessonNo = materialCode === "ECS001" ? 5 : 6;

  const pastAnswerL5I2Q2 = useMemo(() => {
    const allAnswers = state.active_learning_answers_by_material;
    return (
      getActiveLearningAnswers(allAnswers, LessonNo, 2, 6)
        .map(result => result.value)
        .join("") || "回答データがありません。"
    );
  }, [state.active_learning_answers_by_material, LessonNo]);

  const pastAnswersL5 = useMemo(() => {
    const allAnswers = state.active_learning_answers_by_material;
    return [
      {
        label: "理由",
        data: getActiveLearningAnswers(allAnswers, LessonNo, 2, 7).map(result =>
          result.value ? result.value : "回答データがありません。",
        ),
      },
      {
        label: "解決案",
        data: getActiveLearningAnswers(allAnswers, LessonNo, 1).map(result =>
          result.uid
            ? `
              ${result.value}/
              ${
                getActiveLearningAnswers(allAnswers, LessonNo, 2, 5).find(
                  answer => answer.parentUid === result.uid,
                )?.value || "回答データがありません。"
              }
            `
            : "回答データがありません。",
        ),
      },
      {
        label: "詳細",
        data: getActiveLearningAnswers(allAnswers, LessonNo, 3, 1).map(
          result => result.value,
        ),
      },
    ];
  }, [state.active_learning_answers_by_material, LessonNo]);

  return (
    <>
      <div className={styles.wrapper} id="accordion">
        <Header
          no={currentActiveLearning.no}
          label={"実現までの道筋を描く"}
          title={currentActiveLearning.title}
          read={
            "解決のアイデアを実現するためにはどんなステップが必要かを考えて、次の授業までにできる行動を決めよう。"
          }
        />
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles.review}>
              <p className={styles.title}>前回の振り返り</p>
              <div className={styles.tree}>
                <TreeCard title={"解決のアイデア"} text={pastAnswerL5I2Q2} />
                <ul className={styles.branchList}>
                  {pastAnswersL5.map(lastAnswer => (
                    <li key={lastAnswer.label} className={styles.branch}>
                      <div className={styles.labelWrapper}>
                        <span className={styles.label}>{lastAnswer.label}</span>
                      </div>
                      <ul className={styles.answerList}>
                        {lastAnswer.data.length > 0 ? (
                          lastAnswer.data.map((answer, index) => (
                            <li
                              key={`${answer}${index}`}
                              className={clsx(
                                styles.answer,
                                lastAnswer.data.length < 2 && styles.single,
                              )}
                            >
                              {answer}
                            </li>
                          ))
                        ) : (
                          <li className={clsx(styles.answer, styles.single)}>
                            回答データがありません。
                          </li>
                        )}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <Issue1
              issue={findIssue(1)}
              answers={answers}
              setAnswers={setAnswers}
              setCommonAnswers={setCommonAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <Issue2
              issue={findIssue(2)}
              answers={answers}
              setAnswers={setAnswers}
              commonAnswers={commonAnswers}
            />
          </div>
          <div className={styles.saveAnswer}>
            <SaveAnswerButton handleClick={saveAnswers} disabled={calling} />
          </div>
          <div className={styles.section}>
            <EndLesson
              text={
                "次のLessonでは、計画を実行した結果を振り返り、計画を調整する。次回までに、決めたステップまでの計画を実行してこよう。"
              }
              saveAnswers={saveAnswers}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Lesson7;
