import { ReactComponent as Skill0 } from "../../../assets/svg/skills/skill0.svg";
import { ReactComponent as Skill1 } from "../../../assets/svg/skills/skill1.svg";
import { ReactComponent as Skill2 } from "../../../assets/svg/skills/skill2.svg";
import { ReactComponent as Skill3 } from "../../../assets/svg/skills/skill3.svg";
import { ReactComponent as Skill4 } from "../../../assets/svg/skills/skill4.svg";
import { ReactComponent as Skill5 } from "../../../assets/svg/skills/skill5.svg";
import { ReactComponent as Skill6 } from "../../../assets/svg/skills/skill6.svg";
import { ReactComponent as Skill7 } from "../../../assets/svg/skills/skill7.svg";
import { ReactComponent as Skill8 } from "../../../assets/svg/skills/skill8.svg";
import { ReactComponent as Skill9 } from "../../../assets/svg/skills/skill9.svg";
import { ReactComponent as Skill10 } from "../../../assets/svg/skills/skill10.svg";
import { ReactComponent as Skill11 } from "../../../assets/svg/skills/skill11.svg";
import { ReactComponent as Skill12 } from "../../../assets/svg/skills/skill12.svg";
import { ReactComponent as Skill13 } from "../../../assets/svg/skills/skill13.svg";
import { ReactComponent as Skill14 } from "../../../assets/svg/skills/skill14.svg";
import { ReactComponent as Skill15 } from "../../../assets/svg/skills/skill15.svg";
import { ReactComponent as Skill16 } from "../../../assets/svg/skills/skill16.svg";
import { ReactComponent as Skill17 } from "../../../assets/svg/skills/skill17.svg";
import { ReactComponent as Skill18 } from "../../../assets/svg/skills/skill18.svg";
import { ReactComponent as Skill19 } from "../../../assets/svg/skills/skill19.svg";
import { ReactComponent as Skill20 } from "../../../assets/svg/skills/skill20.svg";
import { ReactComponent as Skill21 } from "../../../assets/svg/skills/skill21.svg";

export default [
  Skill0,
  Skill1,
  Skill2,
  Skill3,
  Skill4,
  Skill5,
  Skill6,
  Skill7,
  Skill8,
  Skill9,
  Skill10,
  Skill11,
  Skill12,
  Skill13,
  Skill14,
  Skill15,
  Skill16,
  Skill17,
  Skill18,
  Skill19,
  Skill20,
  Skill21,
];
