import {
  IActiveLearningAnswersByMaterial,
  IActiveLearningStudentAnswers,
  IActiveLearningKlassStudentAnswers,
} from "../state";

export const ITEM_CODES = {
  round1: "ECS001",
  round2: "ECS002",
};

export function getActiveLearningAnswers(
  activeLearningAnswers: IActiveLearningAnswersByMaterial[],
  lessonNo: number,
  issueNo: number,
  questionNo?: number | undefined,
) {
  const answers = questionNo
    ? activeLearningAnswers
        .find(answer => answer.lesson_no === lessonNo)
        ?.issues.find(i => i.issue_no === issueNo)
        ?.questions.find(q => q.question_no === questionNo)?.answers
    : activeLearningAnswers
        .find(answer => answer.lesson_no === lessonNo)
        ?.issues.find(i => i.issue_no === issueNo)
        ?.questions.flatMap(q => q.answers);

  if (!answers || answers?.length === 0) {
    return [];
  } else {
    const contents = answers.map(answer => answer.contents);
    return contents;
  }
}

export function getStudentAnswersByQuestion(
  materialCode: string,
  activeLearningStudentAnswers:
    | IActiveLearningStudentAnswers
    | IActiveLearningKlassStudentAnswers
    | null,
  lessonNo: number,
  issueNo: number,
  questionNo?: number | undefined,
) {
  return questionNo
    ? activeLearningStudentAnswers?.answers.filter(
        answer =>
          answer.material_code === materialCode &&
          answer.lesson_no === lessonNo &&
          answer.issue_no === issueNo &&
          answer.question_no === questionNo,
      ) || []
    : activeLearningStudentAnswers?.answers.filter(
        answer =>
          answer.material_code === materialCode &&
          answer.lesson_no === lessonNo &&
          answer.issue_no === issueNo,
      ) || [];
}

export function isAvailableItemCode(itemCode: string) {
  return [ITEM_CODES["round1"], ITEM_CODES["round2"]].includes(itemCode);
}

export function isAvailableAllItemCodes(itemCodes: string[]) {
  const results = itemCodes.map(itemCode =>
    [ITEM_CODES["round1"], ITEM_CODES["round2"]].includes(itemCode),
  );
  return !results.includes(false);
}

export function isAvailablePartItemCodes(itemCodes: string[]) {
  const results = itemCodes.map(itemCode =>
    [ITEM_CODES["round1"], ITEM_CODES["round2"]].includes(itemCode),
  );
  return results.includes(true);
}
