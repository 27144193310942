import React, { useState, useEffect, ReactElement, useRef } from "react";
import clsx from "clsx";

import { ReactComponent as IconCaret } from "../../assets/svg/caret_up.svg";
import { ReactComponent as IconCheck } from "../../assets/svg/check.svg";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import styles from "./Dropdown.module.scss";
export type Option = {
  value: number;
  label: string | ReactElement;
  data?: any;
};

export enum Variant {
  normal = "normal",
  blackTransparent = "blackTransparent",
}

export type Props = {
  options: Option[];
  placeholder?: string;
  defaultValue?: Option;
  variant?: Variant;
  className?: string;
  classNameOptions?: string;
  onChange: (value: number, data?: any) => void;
};

const TEXT_NO_DATA = "データがありません";

export const Dropdown: React.FC<Props> = ({
  options,
  placeholder,
  defaultValue,
  variant = Variant.normal,
  className,
  classNameOptions,
  onChange,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [optionSelected, setOptionSelected] = useState<Option>({
    value: -1,
    label: TEXT_NO_DATA,
    data: null,
  });

  useEffect(() => {
    if (
      defaultValue === undefined &&
      optionSelected.value === -1 &&
      options.length !== 0
    ) {
      placeholder
        ? setOptionSelected({ value: -1, label: placeholder })
        : setOptionSelected(options[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, defaultValue]);

  useEffect(() => {
    if (optionSelected.value !== -1) {
      onChange(optionSelected.value, optionSelected.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionSelected.value]);

  useEffect(() => {
    if (defaultValue) {
      setOptionSelected({
        value: defaultValue.value,
        label: defaultValue.label,
        data: defaultValue.data,
      });
    }
  }, [defaultValue]);

  const labelChange = (option: Option) => {
    if (options.length !== 0)
      if (
        options.filter(o => o.value === option.value).length === 0 &&
        !!options[0]
      ) {
        return placeholder;
      } else return option.label;
    else return TEXT_NO_DATA;
  };

  const handleClickOutside = () => setOpen(false);

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div
      ref={ref}
      className={clsx(styles.wrapSelect, styles[variant], className)}
      onClick={() => setOpen(!open)}
    >
      <div className={styles.option}>
        <div className={styles.label}>{labelChange(optionSelected)}</div>
        <IconCaret />
      </div>
      {open && options.length !== 0 && (
        <div className={clsx(styles.options, classNameOptions)}>
          {options.map((item, index) => (
            <div
              className={clsx(
                styles.option,
                item.value === optionSelected.value && styles.active,
              )}
              onClick={() => {
                setOptionSelected(item);
              }}
              key={index}
            >
              <div className={styles.label}>{item.label}</div>
              <IconCheck />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Dropdown;
