import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useContext,
  useState,
} from "react";
import clsx from "clsx";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
  IActiveLearningQuestion,
} from "../../../../../state";
import { IAnswer } from "../../../../../constants/active_learning";
import TextInput from "../../../../../atoms/ActiveLearning/TextInput";
import styles from "../../ActiveLearningIssue.module.scss";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  commonAnswers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface ICurrentAnswer extends IAnswer {
  subject: string;
  reason: string;
  value: string;
}

export const Issue2 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const parentIssueNo = 1;
  const currentIssueNo = 2;
  const question1 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);
  const question2 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 2);
  }, [props.issue?.questions]);
  const question3 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 3);
  }, [props.issue?.questions]);

  const findAnswerFrom = (
    targetAnswers: IActiveLearningAnswers[],
    questionNo: number,
  ) => {
    return targetAnswers.find(
      answer =>
        answer.question_no === questionNo && answer.issue_no === parentIssueNo,
    )?.contents;
  };

  const [answerToI2Q1, setAnswerToI2Q1] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: 1,
    question_id: question1?.id || NaN,
    subject: findAnswerFrom(props.answers, 1)?.subject || "",
    reason: findAnswerFrom(props.answers, 1)?.value || "",
    value: "",
  });
  const [answerToI2Q2, setAnswerToI2Q2] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: 2,
    question_id: question2?.id || NaN,
    subject: findAnswerFrom(props.answers, 2)?.subject || "",
    reason: findAnswerFrom(props.answers, 2)?.value || "",
    value: "",
  });
  const [answerToI2Q3, setAnswerToI2Q3] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: 3,
    question_id: question3?.id || NaN,
    subject: findAnswerFrom(props.answers, 3)?.subject || "",
    reason: findAnswerFrom(props.answers, 3)?.value || "",
    value: "",
  });

  const syncStoreAnswerToState = useCallback(
    (
      question: IActiveLearningQuestion | undefined,
      setter: Dispatch<React.SetStateAction<ICurrentAnswer>>,
    ) => {
      if (!question) return;
      const targetAnswer = state.active_learning_answers.find(
        answer =>
          answer.issue_no === currentIssueNo &&
          answer.question_no === question.order_num,
      );
      const parentAnswer = state.active_learning_answers.find(
        answer =>
          answer.issue_no === parentIssueNo &&
          answer.question_no === question.order_num,
      );

      if (!targetAnswer) return;
      const answer: ICurrentAnswer = {
        id: targetAnswer?.id || null,
        issue_no: currentIssueNo,
        question_no: question.order_num,
        question_id: question.id,
        value: targetAnswer?.contents?.value || "",
        subject: parentAnswer?.contents?.subject || "",
        reason: parentAnswer?.contents?.value || "",
      };
      setter({ ...answer });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.active_learning_answers, props.answers],
  );

  useEffect(() => {
    // Store側のデータを各ステートに同期
    syncStoreAnswerToState(question1, setAnswerToI2Q1);
    syncStoreAnswerToState(question2, setAnswerToI2Q2);
    syncStoreAnswerToState(question3, setAnswerToI2Q3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.active_learning_answers]);

  useEffect(() => {
    const answers = props.answers.filter(
      answer => answer.issue_no !== currentIssueNo,
    );
    const currentAnswers = [answerToI2Q1, answerToI2Q2, answerToI2Q3].map(
      data => {
        return {
          id: data.id,
          issue_no: currentIssueNo,
          question_no: data.question_no,
          question_id: data.question_id,
          contents: {
            value: data.value,
            subject: data.subject,
            reason: data.reason,
          },
        };
      },
    );
    props.setAnswers([...answers, ...currentAnswers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerToI2Q1, answerToI2Q2, answerToI2Q3]);

  // TODO: 要修正
  // answersを依存配列してしまうと無限ループが発生(setAnswerToXを実行しているため)。
  // 共通情報は別途props(commonAnswers)で渡す
  useEffect(() => {
    const answerQ1 = findAnswerFrom(props.commonAnswers, 1);
    const answerQ2 = findAnswerFrom(props.commonAnswers, 2);
    const answerQ3 = findAnswerFrom(props.commonAnswers, 3);

    setAnswerToI2Q1({
      ...answerToI2Q1,
      question_id: question1?.id || NaN,
      subject: answerQ1?.subject,
      reason: answerQ1?.value,
    });
    setAnswerToI2Q2({
      ...answerToI2Q2,
      question_id: question2?.id || NaN,
      subject: answerQ2?.subject,
      reason: answerQ2?.value,
    });
    setAnswerToI2Q3({
      ...answerToI2Q3,
      question_id: question3?.id || NaN,
      subject: answerQ3?.subject,
      reason: answerQ3?.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.commonAnswers]);

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          原因を取り除いた理想状況が実現できれば、問題も一緒に取り除くことができる。
          <br />
          原因が取り除かれた理想状況を入力しよう。
          <br />
          <br />
          例：原因「〇〇市の子育て支援が充実していない」→理想状況「〇〇市の子育て支援が充実している」
        </p>
      </div>
      {question1 && (
        <div className={styles.question}>
          <div className={styles.tree}>
            <ul className={clsx(styles.answerList, styles.wide)}>
              <li className={clsx(styles.answer, styles.single)}>
                {answerToI2Q1.subject || "回答データがありません。"}
              </li>
            </ul>
            <ul className={styles.branchList}>
              <li className={styles.branch}>
                <div className={styles.labelWrapper}>
                  <span className={styles.label}>原因</span>
                </div>
                <ul className={styles.answerList}>
                  <li className={clsx(styles.answer, styles.single)}>
                    {answerToI2Q1.reason || "回答データがありません。"}
                  </li>
                </ul>
              </li>
              <li className={styles.branch}>
                <div className={styles.labelWrapper}>
                  <span className={styles.label}>理想状況</span>
                </div>
                <div className={styles.inputField}>
                  <TextInput
                    value={answerToI2Q1.value}
                    handleChange={e => {
                      setAnswerToI2Q1({
                        ...answerToI2Q1,
                        question_id: question1.id,
                        question_no: question1.order_num,
                        value: e.target.value,
                      });
                    }}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
      {question2 && (
        <div className={styles.question}>
          <div className={styles.tree}>
            <ul className={clsx(styles.answerList, styles.wide)}>
              <li className={clsx(styles.answer, styles.single)}>
                {answerToI2Q2.subject || "回答データがありません。"}
              </li>
            </ul>
            <ul className={styles.branchList}>
              <li className={styles.branch}>
                <div className={styles.labelWrapper}>
                  <span className={styles.label}>原因</span>
                </div>
                <ul className={styles.answerList}>
                  <li className={clsx(styles.answer, styles.single)}>
                    {answerToI2Q2.reason || "回答データがありません。"}
                  </li>
                </ul>
              </li>
              <li className={styles.branch}>
                <div className={styles.labelWrapper}>
                  <span className={styles.label}>理想状況</span>
                </div>
                <div className={styles.inputField}>
                  <TextInput
                    value={answerToI2Q2.value}
                    handleChange={e => {
                      setAnswerToI2Q2({
                        ...answerToI2Q2,
                        question_id: question2.id,
                        question_no: question2.order_num,
                        value: e.target.value,
                      });
                    }}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
      {question3 && (
        <div className={styles.question}>
          <div className={styles.tree}>
            <ul className={clsx(styles.answerList, styles.wide)}>
              <li className={clsx(styles.answer, styles.single)}>
                {answerToI2Q3.subject || "回答データがありません。"}
              </li>
            </ul>
            <ul className={styles.branchList}>
              <li className={styles.branch}>
                <div className={styles.labelWrapper}>
                  <span className={styles.label}>原因</span>
                </div>
                <ul className={styles.answerList}>
                  <li className={clsx(styles.answer, styles.single)}>
                    {answerToI2Q3.reason || "回答データがありません。"}
                  </li>
                </ul>
              </li>
              <li className={styles.branch}>
                <div className={styles.labelWrapper}>
                  <span className={styles.label}>理想状況</span>
                </div>
                <div className={styles.inputField}>
                  <TextInput
                    value={answerToI2Q3.value}
                    handleChange={e => {
                      setAnswerToI2Q3({
                        ...answerToI2Q3,
                        question_id: question3.id,
                        question_no: question3.order_num,
                        value: e.target.value,
                      });
                    }}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
export default Issue2;
