import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";
import clsx from "clsx";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import { IAnswer } from "../../../../../constants/active_learning";
import TreeCard from "../../../../../atoms/ActiveLearning/TreeCard";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import styles from "../../ActiveLearningIssue.module.scss";
import { getActiveLearningAnswers } from "../../../../../libs/ActiveLearning";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface ICurrentAnswer extends IAnswer {
  value: string;
}

export const Issue1 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 1;
  const question = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);

  const materialCode = state.active_learning_data_by_lesson.material_code;

  const [answer, setAnswer] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question?.order_num || NaN,
    question_id: question?.id || NaN,
    value: "",
  });

  const setAnswerWraper = useCallback(
    (questionNo: number, setter: Dispatch<React.SetStateAction<any>>) => {
      const targetAnswer = state.active_learning_answers.find(
        answer =>
          answer.issue_no === currentIssueNo &&
          answer.question_no === questionNo,
      );
      const answer: ICurrentAnswer = {
        id: targetAnswer?.id || null,
        issue_no: currentIssueNo,
        question_no: questionNo,
        question_id: question?.id || 0,
        value: targetAnswer?.contents?.value || "",
      };
      setter({ ...answer });
    },
    [state.active_learning_answers, question],
  );

  useEffect(() => {
    setAnswerWraper(1, setAnswer);
  }, [setAnswerWraper]);

  useEffect(() => {
    const answers = props.answers.filter(
      answer =>
        !(
          answer.issue_no === currentIssueNo &&
          question?.order_num === answer.question_no
        ),
    );
    props.setAnswers([
      ...answers,
      ...[
        {
          id: answer.id,
          issue_no: currentIssueNo,
          question_no: question?.order_num || NaN,
          question_id: question?.id || NaN,
          contents: {
            value: answer.value,
          },
        },
      ],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, question]);

  const handleChangeAnswer = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    answer: ICurrentAnswer,
    setter: Dispatch<React.SetStateAction<any>>,
  ) => {
    const { value } = e.target;
    setter({
      id: answer.id,
      question_id: question?.id || NaN,
      question_no: question?.order_num || NaN,
      issue_no: currentIssueNo,
      value,
    });
  };

  const pastAnswerL3I2Q1 = useMemo(() => {
    const allAnswers = state.active_learning_answers_by_material;
    return (
      getActiveLearningAnswers(allAnswers, 3, 2, 1)
        .map(result => result.value)
        .join("") || "回答データがありません。"
    );
  }, [state.active_learning_answers_by_material]);

  const lastLessonNo = useMemo(() => {
    return materialCode === "ECS001" ? 4 : 5;
  }, [materialCode]);

  const pastAnswersL3 = useMemo(() => {
    const allAnswers = state.active_learning_answers_by_material;
    const targetAnswers = getActiveLearningAnswers(allAnswers, 3, 2, 2);

    return targetAnswers.map(result => result.value);
  }, [state.active_learning_answers_by_material]);

  const pastAnswerL4I3Q1 = useMemo(() => {
    const allAnswers = state.active_learning_answers_by_material;
    const targetAnswers = getActiveLearningAnswers(
      allAnswers,
      lastLessonNo,
      3,
      1,
    );

    return (
      targetAnswers.map(result => result.value).join("") ||
      "回答データがありません。"
    );
  }, [state.active_learning_answers_by_material, lastLessonNo]);

  const pastAnswersL4 = useMemo(() => {
    const allAnswers = state.active_learning_answers_by_material;
    return [
      {
        label: "理由",
        data: getActiveLearningAnswers(
          allAnswers,
          lastLessonNo,
          3,
          2,
        ).map(result =>
          result.value ? result.value : "回答データがありません。",
        ),
      },
      {
        label: "問題",
        data: getActiveLearningAnswers(
          allAnswers,
          lastLessonNo,
          1,
        ).map(result =>
          result.subject ? result.subject : "回答データがありません。",
        ),
      },
      {
        label: "原因",
        data: getActiveLearningAnswers(
          allAnswers,
          lastLessonNo,
          1,
        ).map(result =>
          result.value ? result.value : "回答データがありません。",
        ),
      },
      {
        label: "理想状況",
        data: getActiveLearningAnswers(
          allAnswers,
          lastLessonNo,
          2,
        ).map(result =>
          result.value ? result.value : "回答データがありません。",
        ),
      },
    ];
  }, [state.active_learning_answers_by_material, lastLessonNo]);

  const pastAnswerL5I2Q2 = useMemo(() => {
    const allAnswers = state.active_learning_answers_by_material;
    return (
      (materialCode === "ECS001"
        ? getActiveLearningAnswers(allAnswers, 5, 2, 6)
        : getActiveLearningAnswers(allAnswers, 6, 2, 6)
      )
        .map(result => result.value)
        .join("") || "回答データがありません。"
    );
  }, [state.active_learning_answers_by_material, materialCode]);

  const pastAnswersL5 = useMemo(() => {
    const allAnswers = state.active_learning_answers_by_material;
    return [
      {
        label: "理由",
        data: (materialCode === "ECS001"
          ? getActiveLearningAnswers(allAnswers, 5, 2, 7)
          : getActiveLearningAnswers(allAnswers, 6, 2, 7)
        ).map(result =>
          result.value ? result.value : "回答データがありません。",
        ),
      },
      {
        label: "解決案",
        data:
          materialCode === "ECS001"
            ? getActiveLearningAnswers(allAnswers, 5, 1).map(result =>
                result.uid
                  ? `
                    ${result.value}/
                    ${
                      getActiveLearningAnswers(allAnswers, 5, 2, 5).find(
                        answer => answer.parentUid === result.uid,
                      )?.value || "回答データがありません。"
                    }
                  `
                  : "回答データがありません。",
              )
            : getActiveLearningAnswers(allAnswers, 6, 1).map(result =>
                result.uid
                  ? `
                    ${result.value}/
                    ${
                      getActiveLearningAnswers(allAnswers, 6, 2, 5).find(
                        answer => answer.parentUid === result.uid,
                      )?.value || "回答データがありません。"
                    }
                  `
                  : "回答データがありません。",
              ),
      },
      {
        label: "詳細",
        data: (materialCode === "ECS001"
          ? getActiveLearningAnswers(allAnswers, 5, 3, 1)
          : getActiveLearningAnswers(allAnswers, 6, 3, 1)
        ).map(result => result.value),
      },
    ];
  }, [state.active_learning_answers_by_material, materialCode]);

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          このWORKには、今までのLessonで入力してきた、「問い」/「課題」/解決のアイデアとそれを考えた過程が表示されている。
          <br />
          入力内容を振り返り、補足したい情報があれば記載して発表の準備をしよう。
        </p>
      </div>
      <div className={styles.tree}>
        <TreeCard title={"問い"} text={pastAnswerL3I2Q1} />
        <ul className={styles.branchList}>
          <li className={styles.branch}>
            <div className={styles.labelWrapper}>
              <span className={styles.label}>理由</span>
            </div>
            <ul className={styles.answerList}>
              {pastAnswersL3.length > 0 ? (
                pastAnswersL3.map((answer, index) => (
                  <li
                    key={`${answer}${index}`}
                    className={clsx(
                      styles.answer,
                      pastAnswersL3.length < 2 && styles.single,
                    )}
                  >
                    {answer}
                  </li>
                ))
              ) : (
                <li className={clsx(styles.answer, styles.single)}>
                  回答データがありません。
                </li>
              )}
            </ul>
          </li>
        </ul>
      </div>
      <div className={styles.tree}>
        <TreeCard title={"課題"} text={pastAnswerL4I3Q1} />
        <ul className={styles.branchList}>
          {pastAnswersL4.map(lastAnswer => (
            <li key={lastAnswer.label} className={styles.branch}>
              <div className={styles.labelWrapper}>
                <span className={styles.label}>{lastAnswer.label}</span>
              </div>
              <ul className={styles.answerList}>
                {lastAnswer.data.length > 0 ? (
                  lastAnswer.data.map((answer, index) => (
                    <li
                      key={`${answer}${index}`}
                      className={clsx(
                        styles.answer,
                        lastAnswer.data.length < 2 && styles.single,
                      )}
                    >
                      {answer}
                    </li>
                  ))
                ) : (
                  <li
                    className={clsx(
                      styles.answer,
                      lastAnswer.data.length < 2 && styles.single,
                    )}
                  >
                    回答データがありません。
                  </li>
                )}
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.tree}>
        <TreeCard title={"解決のアイデア"} text={pastAnswerL5I2Q2} />
        <ul className={styles.branchList}>
          {pastAnswersL5.map(lastAnswer => (
            <li key={lastAnswer.label} className={styles.branch}>
              <div className={styles.labelWrapper}>
                <span className={styles.label}>{lastAnswer.label}</span>
              </div>
              <ul className={styles.answerList}>
                {lastAnswer.data.length > 0 ? (
                  lastAnswer.data.map((answer, index) => (
                    <li
                      key={`${answer}${index}`}
                      className={clsx(
                        styles.answer,
                        lastAnswer.data.length < 2 && styles.single,
                      )}
                    >
                      {answer}
                    </li>
                  ))
                ) : (
                  <li className={clsx(styles.answer, styles.single)}>
                    回答データがありません。
                  </li>
                )}
              </ul>
            </li>
          ))}
        </ul>
      </div>
      {question && (
        <div className={styles.themEntry}>
          <p className={styles.title}>{question.title}</p>
          <p className={styles.read}>
            考えをまとめたり、伝わりやすくするためのメモを入力しよう。
          </p>
          <div className={styles.textAreaWrapper}>
            <TextArea
              value={answer.value}
              handleChange={e => handleChangeAnswer(e, answer, setAnswer)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default Issue1;
