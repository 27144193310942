import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningQuestion,
  IActiveLearningAnswers,
} from "../../../../../state";
import { IAnswer } from "../../../../../constants/active_learning";
import Card from "../../../../../atoms/ActiveLearning/Card";
import TreeCard from "../../../../../atoms/ActiveLearning/TreeCard";
import CorporateInitiativesTable from "../../../../ActiveLearning/CorporateInitiativesTable";
import styles from "../../ActiveLearningIssue.module.scss";
import { ReactComponent as IconLive } from "../../../../../assets/images/ActiveLearning/icon_live.svg";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
  themeId: number;
}

export interface IAnswerToQ1 extends IAnswer<string> {
  id: string;
  interest: string;
  reason: string;
  activity: string;
}

export const Issue1 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 2;
  const [answersToQ1, setAnswersToQ1] = useState<IAnswerToQ1[]>([]);

  useEffect(() => {
    const answers: IActiveLearningAnswers[] = answersToQ1
      .filter((answer: IAnswerToQ1) => {
        const { interest, reason, activity } = answer;
        // interest & reason & activityの全てが未入力の回答情報を除外
        return (
          interest?.length > 0 || reason?.length > 0 || activity?.length > 0
        );
      })
      .map((answer: IAnswerToQ1) => {
        return {
          id: answer.id?.includes("#") ? null : Number(answer.id),
          issue_no: answer.issue_no,
          question_no: answer.question_no,
          question_id: answer.question_id,
          contents: {
            interest: answer.interest,
            reason: answer.reason,
            activity: answer.activity,
          },
        };
      });

    // この設問以外の回答情報
    const otherAnswers = props.answers.filter(
      answer => !(answer.issue_no === currentIssueNo),
    );
    props.setAnswers([...otherAnswers, ...answers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersToQ1]);

  useEffect(() => {
    const answersQ1 = state.active_learning_answers.filter(
      answer => answer.issue_no === currentIssueNo && answer.question_no === 1,
    );
    const initAnswersQ1: IAnswerToQ1[] =
      answersQ1.length > 0
        ? answersQ1.map(answer => ({
            id: String(answer.id),
            issue_no: answer.issue_no,
            question_no: answer.question_no,
            question_id: answer.question_id,
            interest: answer.contents?.interest || "",
            reason: answer.contents?.reason || "",
            activity: answer.contents?.activity || "",
          }))
        : [...Array(3)].map(_ => {
            return {
              id: `#${Math.random().toString(36).slice(-8)}`,
              issue_no: currentIssueNo,
              question_id: question?.id || NaN,
              question_no: question?.order_num || NaN,
              interest: "",
              reason: "",
              activity: "",
            };
          });
    setAnswersToQ1([...initAnswersQ1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.active_learning_answers]);

  const addAnswerField = (question: IActiveLearningQuestion) => {
    const oneTimeId = `#${Math.random().toString(36).slice(-8)}`;
    setAnswersToQ1([
      ...answersToQ1,
      {
        id: oneTimeId,
        issue_no: props.issue?.no || NaN,
        question_id: question.id,
        question_no: question.order_num,
        interest: "",
        reason: "",
        activity: "",
      },
    ]);
  };

  const handleTextAnswerChange = (
    e: any,
    attrName: "interest" | "reason" | "activity",
  ) => {
    const { name, value } = e.target;
    const results = answersToQ1.map(answer => {
      if (answer.id === name) {
        answer[attrName] = value;
      }
      return answer;
    });

    setAnswersToQ1([...results]);
  };

  const question = useMemo(() => {
    return props.issue?.questions[0];
  }, [props.issue?.questions]);

  const theme = useMemo(() => {
    return state.active_learning_themes.find(
      theme => theme.id === props.themeId,
    );
  }, [state.active_learning_themes, props.themeId]);

  return (
    <>
      {question && (
        <div>
          <div className={styles.issue}>
            <div className={styles.header}>
              <span className={styles.no}>
                WORK
                {props.issue?.no && props.issue?.no < 10
                  ? `0${props.issue?.no}`
                  : props.issue?.no}
              </span>
              <p className={styles.title}>{props.issue?.title}</p>
              <p className={styles.read}>
                社会には、課題に取り組んでいる企業がたくさんある。
                <br />
                動画に出てきた企業以外に、自分が選んだテーマに関連する課題設定や取り組みを行っている企業がないか探してみよう。
                <br />
                ［企業名］［取り組み/届けている価値］［取り上げた理由］を調べて、1つ以上入力しよう。
                <br />
                <br />
                操作：「項目を追加する」ボタンをクリックすると項目を増やすことができます。
              </p>
            </div>
            <TreeCard
              title={"テーマ"}
              text={theme?.title || "テーマが選択されていません。"}
            />
            <CorporateInitiativesTable
              answers={answersToQ1}
              handleTextAnswerChange={handleTextAnswerChange}
              question={question}
              addAnswerField={addAnswerField}
            />
            <Card label={"TRY"} icon={<IconLive />} title={"グループワーク"}>
              ①同じテーマ同士でグループをつくろう。
              <br />
              ②様々な価値を届ける事例を発表し合おう。
            </Card>
          </div>
        </div>
      )}
    </>
  );
};
export default Issue1;
