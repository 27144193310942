import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";

import { Context, activeLearningStoryBookPath } from "../../../studentStore";
import Button from "../../../atoms/ActiveLearning/Button";
import VideoJS from "../../../atoms/VideoJS";
import notFoundImg from "../../../assets/images/ActiveLearning/not_found_theme.jpg";

import styles from "./ThemeVideo.module.scss";

interface IThemeVideoProps {
  themeId?: number;
}

export const ThemeVideo: React.FC<IThemeVideoProps> = ({ themeId }) => {
  const { state } = useContext(Context);
  const [videoOptions, setVideoOptions] = useState({
    url: "",
    playing: false,
  });

  const theme = useMemo(() => {
    return state.active_learning_themes.find(theme => theme.id === themeId);
  }, [state.active_learning_themes, themeId]);

  useEffect(() => {
    setVideoOptions({
      url: theme
        ? `${process.env.REACT_APP_STUDENT_DLP_URL}${theme.video_url}`
        : "",
      playing: false,
    });
  }, [themeId, theme]);

  const openStoryBoard = useCallback(() => {
    window.open(
      `${activeLearningStoryBookPath}/${theme?.id}/?sidebar=no`,
      "_blank",
    );
  }, [theme]);

  return (
    <div className={styles.themeVideo}>
      <div className={styles.titleWrapper}>
        <img
          className={styles.image}
          src={
            theme?.enageed_theme_name
              ? `https://admin.gear.enageed.net${theme.image_url}`
              : notFoundImg
          }
          alt="企業テーマ"
        />
        <p className={styles.title}>{theme?.title}</p>
        <ul className={styles.tagList}>
          {theme?.action_categories.map(category => (
            <li className={styles.tag} key={category.id}>
              #{category.name}
            </li>
          ))}
          {theme?.field_categories.map(category => (
            <li className={styles.tag} key={category.id}>
              #{category.name}
            </li>
          ))}
        </ul>
      </div>
      {videoOptions.url ? (
        <div className={styles.videoWrapper}>
          <VideoJS sourceUrl={videoOptions.url} showVideo={true} />
          <style>{`.video-js .vjs-tech { position: inherit; }`}</style>
          {theme?.id && (
            <div className={styles.buttonWrapper}>
              <Button
                variant={"secondary"}
                width={"240px"}
                height={"38px"}
                handleClick={openStoryBoard}
              >
                資料を見る
              </Button>
            </div>
          )}
        </div>
      ) : (
        <p className={styles.text}>
          Lesson1の回答データがないため
          <br />
          動画を表示できません。
        </p>
      )}
    </div>
  );
};

export default ThemeVideo;
