import React from "react";
import clsx from "clsx";

import Button from "../../../atoms/ActiveLearning/Button";
import { ReactComponent as IconCheck } from "../../../assets/images/ActiveLearning/icon_check.svg";

import styles from "./ThemeCard.module.scss";

interface IThemeCardProps {
  no: string;
  title: string;
  imageUrl: string | undefined;
  desc: string;
  selected?: boolean;
  width: string;
  backgroundColor?: string;
  hideActionButton?: boolean;
  handleDetail?: () => void;
  handleAction?: () => void;
}

export const ThemeCard: React.FC<IThemeCardProps> = ({
  no,
  title,
  imageUrl,
  desc,
  selected,
  width,
  backgroundColor,
  hideActionButton,
  handleDetail,
  handleAction,
}) => {
  return (
    <div
      className={clsx(styles.wrapper, selected && styles.isSelected)}
      style={{ width, backgroundColor }}
    >
      <IconCheck className={styles.check} />
      <img className={styles.image} src={imageUrl} alt="企業テーマ" />
      <div className={styles.titleWrapper}>
        <span className={styles.no}>{no}</span>
        <p className={styles.title}>{title}</p>
        <p className={styles.desc}>{desc}</p>
      </div>
      <div className={clsx(styles.buttonWrapper, styles.isPc)}>
        <Button
          variant={hideActionButton ? "primary" : "secondary"}
          width={"100%"}
          height={"38px"}
          fontSize={"14px"}
          handleClick={handleDetail}
          isEnabled={!!handleDetail}
        >
          詳細を見る
        </Button>
        {!hideActionButton && (
          <Button
            width={"100%"}
            height={"38px"}
            fontSize={"14px"}
            handleClick={handleAction}
          >
            取り組む
          </Button>
        )}
      </div>
      <div className={clsx(styles.buttonWrapper, styles.isSp)}>
        <Button
          variant={handleAction ? "secondary" : "primary"}
          width={"240px"}
          height={"38px"}
          fontSize={"14px"}
          handleClick={handleDetail}
          isEnabled={!!handleDetail}
        >
          詳細を見る
        </Button>
        {!hideActionButton && (
          <Button
            width={"240px"}
            height={"38px"}
            fontSize={"14px"}
            handleClick={handleAction}
          >
            取り組む
          </Button>
        )}
      </div>
    </div>
  );
};

export default ThemeCard;
