import React from "react";

import styles from "./TextInput.module.scss";

interface ITextInputProps {
  id?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextInput: React.FC<ITextInputProps> = ({
  name,
  id,
  value,
  placeholder,
  width = "100%",
  height,
  disabled,
  handleChange,
}) => {
  return (
    <input
      className={styles.textInput}
      style={{ width, height }}
      id={id}
      name={name}
      type="text"
      value={value || ""}
      placeholder={placeholder ? placeholder : "テキストを入力してください。"}
      disabled={disabled}
      onChange={e => handleChange && handleChange(e)}
    />
  );
};

export default TextInput;
