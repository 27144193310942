import { useEffect } from "react";

import { formatDate } from "../libs/Util";

const useUpdateAssessmentLastActive = (
  assessment_last_active_date: Date | null,
  dispatch: (action: any) => void,
  updateAssessmentLastActive: () => any,
) => {
  useEffect(() => {
    const shouldUpdateAssessmentLastActive =
      (assessment_last_active_date
        ? formatDate(assessment_last_active_date)
        : null) !== formatDate(new Date());
    if (shouldUpdateAssessmentLastActive)
      dispatch(updateAssessmentLastActive());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useUpdateAssessmentLastActive;
