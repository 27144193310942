import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useContext,
  useState,
} from "react";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
} from "../../../../../state";
import TextArea from "../../../../../atoms/ActiveLearning/TextArea";
import FeedbackTextArea from "../../../../../molecules/ActiveLearning/FeedbackTextArea";
import { IAnswer } from "../../../../../constants/active_learning";
import styles from "../../ActiveLearningIssue.module.scss";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface ITextAnswer extends IAnswer {
  value: string;
}

export interface ICurrentAnswer extends IAnswer {
  good: string;
  motto: string;
}

export const Issue2 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 1;
  const question1 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 1);
  }, [props.issue?.questions]);

  const question2 = useMemo(() => {
    return props.issue?.questions.find(q => q.order_num === 2);
  }, [props.issue?.questions]);

  const [answerToQ1, setAnswerToQ1] = useState<ITextAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question1?.order_num || NaN,
    question_id: question1?.id || NaN,
    value: "",
  });

  const [answerToQ2, setAnswerToQ2] = useState<ICurrentAnswer>({
    id: null,
    issue_no: currentIssueNo,
    question_no: question2?.order_num || NaN,
    question_id: question2?.id || NaN,
    good: "",
    motto: "",
  });

  useEffect(() => {
    const excludedAnswer = props.answers.filter(
      answer => answer.issue_no !== currentIssueNo,
    );
    const currentAnswers: IActiveLearningAnswers[] = [];
    currentAnswers.push({
      id: answerToQ1.id,
      issue_no: answerToQ1.issue_no,
      question_no: answerToQ1.question_no,
      question_id: answerToQ1.question_id,
      contents: {
        value: answerToQ1.value,
      },
    });
    currentAnswers.push({
      id: answerToQ2.id,
      issue_no: answerToQ2.issue_no,
      question_no: answerToQ2.question_no,
      question_id: answerToQ2.question_id,
      contents: {
        good: answerToQ2.good,
        motto: answerToQ2.motto,
      },
    });
    props.setAnswers([...excludedAnswer, ...currentAnswers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerToQ1, answerToQ2, props.setAnswers]);

  useEffect(() => {
    const targetAnswerToQ1 = state.active_learning_answers.find(
      answer =>
        answer.issue_no === currentIssueNo &&
        answer.question_no === question1?.order_num,
    );
    const answerQ1: ITextAnswer = {
      id: targetAnswerToQ1?.id || null,
      issue_no: currentIssueNo,
      question_no: question1?.order_num || NaN,
      question_id: question1?.id || 0,
      value: targetAnswerToQ1?.contents?.value || "",
    };
    setAnswerToQ1(answerQ1);
    const targetAnswerToQ2 = state.active_learning_answers.find(
      answer =>
        answer.issue_no === currentIssueNo &&
        answer.question_no === question2?.order_num,
    );
    const answerQ2: ICurrentAnswer = {
      id: targetAnswerToQ2?.id || null,
      issue_no: currentIssueNo,
      question_no: question2?.order_num || NaN,
      question_id: question2?.id || 0,
      good: targetAnswerToQ2?.contents?.good || "",
      motto: targetAnswerToQ2?.contents?.motto || "",
    };
    setAnswerToQ2(answerQ2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.active_learning_answers, question1, question2]);

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          ターゲットについて深く考えるほど、その人が抱えている問題に近づくことができる。
          <br />
          まずは、ターゲットがどんな人かを考えて、その行動を洗い出そう。
          <br />
          洗い出した行動を参考に、ターゲットが感じる「もっとこうだったらいのに」や「こうしたい」を考えよう。
        </p>
      </div>
      {question1 && (
        <div className={styles.themEntry}>
          <p className={styles.title}>{question1.title}</p>
          <div className={styles.textAreaWrapper}>
            <TextArea
              value={answerToQ1.value}
              handleChange={e => {
                const { value } = e.target;
                setAnswerToQ1({
                  id: answerToQ1.id,
                  question_id: question1?.id || NaN,
                  question_no: question1?.order_num || NaN,
                  issue_no: currentIssueNo,
                  value,
                });
              }}
            />
          </div>
        </div>
      )}
      {question2 && (
        <FeedbackTextArea
          goodValue={answerToQ2.good}
          mottoValue={answerToQ2.motto}
          handleChangeGood={e =>
            setAnswerToQ2({
              id: answerToQ2.id,
              question_id: question2?.id || NaN,
              question_no: question2?.order_num || NaN,
              issue_no: currentIssueNo,
              good: e.target.value,
              motto: answerToQ2.motto,
            })
          }
          handleChangeMotto={e =>
            setAnswerToQ2({
              id: answerToQ2.id,
              question_id: question2?.id || NaN,
              question_no: question2?.order_num || NaN,
              issue_no: currentIssueNo,
              good: answerToQ2.good,
              motto: e.target.value,
            })
          }
        />
      )}
    </div>
  );
};

export default Issue2;
