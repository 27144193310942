import React, { useCallback } from "react";

import useWaitApiCall from "../../../hooks/useWaitApiCall";
import {
  cancelLike,
  createComment,
  deleteComment,
  fetchStudents,
  sendLike,
  updateComment,
} from "../../../studentStore";
import { Context, fetchAnswers } from "../../../studentStore";
import AnswerList from "../../../templates/student/AnswerList";

type Props = {
  lessonId?: number;
  issueId?: number;
};

const AnswerListPage: React.FC<Props> = props => {
  const { state, dispatch } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(state);

  const refreshAnswers = useCallback(() => {
    props.issueId && dispatch(fetchAnswers(props.issueId));
  }, [dispatch, props.issueId]);

  const answersWithIndex = React.useMemo(() => {
    let index = state.answers.length + 1;
    return state.answers.map(answer => {
      index--;
      return { ...answer, index };
    });
  }, [state.answers]);

  React.useEffect(() => {
    dispatch(fetchStudents());
  }, [dispatch]);

  React.useEffect(() => {
    refreshAnswers();
    const refetchInterval = setInterval(() => refreshAnswers(), 10000);
    return () => clearInterval(refetchInterval);
  }, [refreshAnswers]);

  const handleComment = React.useCallback(
    (answerId: number, text: string, commentId?: number) => {
      if (commentId) {
        dispatch(peep(updateComment(answerId, text, commentId)));
      } else {
        dispatch(peep(createComment(answerId, text)));
      }
    },
    [dispatch, peep],
  );

  const handleDeleteReply = React.useCallback(
    (answerId: number, commentId: number) => {
      if (calling) return;
      return dispatch(peep(deleteComment(answerId, commentId)));
    },
    [dispatch, peep, calling],
  );

  const handleLike = React.useCallback(
    (answerId: number) => {
      const answer = state.answers.filter(answer => answer.id === answerId)[0];
      answer.is_like
        ? dispatch(peep(cancelLike(answerId)))
        : dispatch(peep(sendLike(answerId)));
    },
    [dispatch, peep, state.answers],
  );

  return (
    <div className="d-flex h-100">
      <div className={`w-100 overflow-auto`}>
        {state.lesson && (
          <AnswerList
            calling={calling}
            handleComment={handleComment}
            handleDeleteReply={handleDeleteReply}
            issue={state.issue}
            answers={answersWithIndex}
            students={state.students}
            currentUser={state.student!}
            handleLike={handleLike}
          />
        )}
      </div>
    </div>
  );
};

export default AnswerListPage;
