import * as React from "react";
import { useHistory } from "react-router-dom";

import {
  Context,
  clearSuccessMessage,
  updateStudentPassword,
  loginPath,
} from "../../../studentStore";
import { UPDATE_STUDENT_PASSWORD_SUCCESS_MESSAGE } from "../../../studentStore/messages";
import ResetPasswordForm from "../../../templates/ResetPasswordForm";
import useWaitApiCall from "../../../hooks/useWaitApiCall";

export default (props: { match: { params: { uuid: string } } }) => {
  const { state, dispatch } = React.useContext(Context);
  const [calling, peep] = useWaitApiCall(state);
  const history = useHistory();
  const uuid = props.match.params.uuid;

  React.useEffect(() => {
    if (state.success_message === UPDATE_STUDENT_PASSWORD_SUCCESS_MESSAGE) {
      dispatch(clearSuccessMessage());
      history.push(loginPath);
    }
  }, [history, dispatch, state.success_message]);

  const updateStudentPasswordFunc = React.useCallback(
    (password: string) => {
      dispatch(peep(updateStudentPassword(uuid, password)));
    },
    [dispatch, uuid, peep],
  );

  return (
    <ResetPasswordForm
      calling={calling}
      updatePassword={updateStudentPasswordFunc}
    />
  );
};
