import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";

import { Context } from "../../../../../studentStore";
import {
  IActiveLearningIssue,
  IActiveLearningAnswers,
  IActiveLearningQuestion,
} from "../../../../../state";
import { IAnswer } from "../../../../../constants/active_learning";
import { getActiveLearningAnswers } from "../../../../../libs/ActiveLearning";
import HintCard from "../../../../../atoms/ActiveLearning/HintCard";
import Button from "../../../../../atoms/ActiveLearning/Button";
import TextInput from "../../../../../atoms/ActiveLearning/TextInput";
import TreeCard from "../../../../../atoms/ActiveLearning/TreeCard";
import ExchangeStepAnswerButton from "../../../../../molecules/ActiveLearning/ExchangeStepAnswerButton";
import styles from "../../ActiveLearningIssue.module.scss";
import { ReactComponent as IconAdd } from "../../../../../assets/images/ActiveLearning/icon_add.svg";
import { ReactComponent as IconDelete } from "../../../../../assets/images/ActiveLearning/icon_delete.svg";

interface IActiveLearningIssue1Props {
  issue?: IActiveLearningIssue;
  answers: IActiveLearningAnswers[];
  setAnswers: Dispatch<SetStateAction<IActiveLearningAnswers[]>>;
}

export interface ICurrentAnswer extends IAnswer<string> {
  value: string;
  stepNo: number;
  _destroy?: boolean;
}

export const Issue2 = (props: IActiveLearningIssue1Props) => {
  const { state } = useContext(Context);
  const currentIssueNo = 2;
  const question = useMemo(() => {
    const q = props.issue?.questions.find(q => q.order_num === 1);
    if (q) return q;
  }, [props.issue]);

  const [answersToQ1, setAnswersToQ1] = useState<ICurrentAnswer[]>([]);
  const materialCode = useMemo(() => {
    return state.active_learning_data_by_lesson.material_code;
  }, [state.active_learning_data_by_lesson]);

  const initAnswers = useCallback((): ICurrentAnswer[] => {
    const lessonNo = materialCode === "ECS001" ? 7 : 8;
    const allAnswers = state.active_learning_answers_by_material;
    const pastAnswers = getActiveLearningAnswers(
      allAnswers,
      lessonNo,
      1,
      1,
    ).map(result => result);
    return pastAnswers.map(answer => {
      return {
        id: `#${Math.random().toString(36).slice(-8)}`,
        issue_no: currentIssueNo,
        question_no: question?.order_num || NaN,
        question_id: question?.id || NaN,
        value: answer.value || "",
        stepNo: Number(answer.stepNo),
      };
    });
  }, [
    question,
    currentIssueNo,
    materialCode,
    state.active_learning_answers_by_material,
  ]);

  useEffect(() => {
    const answers: IActiveLearningAnswers[] = answersToQ1.map(
      (answer: ICurrentAnswer) => {
        return {
          id: answer.id?.includes("#") ? null : Number(answer.id),
          issue_no: answer.issue_no,
          question_no: answer.question_no,
          question_id: answer.question_id,
          contents: {
            value: answer.value,
            stepNo: answer.stepNo,
            _destroy: answer._destroy,
          },
        };
      },
    );

    // この設問以外の回答情報
    const otherAnswers = props.answers.filter(
      answer => !(answer.issue_no === currentIssueNo),
    );
    props.setAnswers([...otherAnswers, ...answers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersToQ1]);

  useEffect(() => {
    const answersQ1 = state.active_learning_answers.filter(
      answer => answer.issue_no === currentIssueNo && answer.question_no === 1,
    );
    const answersFormatOfQ1: ICurrentAnswer[] =
      answersQ1.length > 0
        ? answersQ1.map(answer => ({
            id: String(answer.id),
            issue_no: answer.issue_no,
            question_no: answer.question_no,
            question_id: answer.question_id,
            value: answer.contents.value,
            stepNo: Number(answer.contents.stepNo),
          }))
        : initAnswers();
    setAnswersToQ1([...answersFormatOfQ1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.active_learning_answers,
    state.active_learning_answers_by_material,
  ]);

  const changeAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    const targetAnswer = answersToQ1.find(answer => answer.id === id);
    if (!targetAnswer) throw new Error(`Not found answer id: #{id}`);

    const newAnswer = {
      id: targetAnswer.id,
      issue_no: currentIssueNo,
      question_id: question?.id || NaN,
      question_no: question?.order_num || NaN,
      value,
      stepNo: targetAnswer.stepNo,
    };

    const updatedAnswers = answersToQ1.map(existingAnswer =>
      existingAnswer.id === targetAnswer.id ? newAnswer : existingAnswer,
    );

    !answersToQ1.some(
      existingAnswer => existingAnswer.id === targetAnswer.id,
    ) && updatedAnswers.push(newAnswer);

    setAnswersToQ1(updatedAnswers);
  };

  const addAnswerField = (question: IActiveLearningQuestion) => {
    const oneTimeId = `#${Math.random().toString(36).slice(-8)}`;
    const unRemovedAnswers = answersToQ1.filter(answer => !answer._destroy);
    setAnswersToQ1([
      ...answersToQ1,
      {
        id: oneTimeId,
        issue_no: props.issue?.no || NaN,
        question_id: question.id,
        question_no: question.order_num,
        value: "",
        stepNo: unRemovedAnswers.length + 1,
      },
    ]);
  };

  const allAnswers = state.active_learning_answers_by_material;
  const lastAnswer = getActiveLearningAnswers(
    allAnswers,
    materialCode === "ECS001" ? 5 : 6,
    2,
    6,
  )
    .map(result => result.value)
    .join("");

  const removeAnswer = (id: string) => {
    const targetAnswer = answersToQ1.find(answer => answer.id === id);
    if (!targetAnswer) return;
    const removedAnswers = answersToQ1.filter(
      answer => answer.id !== id && answer._destroy,
    );
    const unRemovedAnswers = answersToQ1
      .filter(answer => answer.id !== id && !answer._destroy)
      .sort((a, b) => a.stepNo - b.stepNo)
      .map((answer, index) => ({ ...answer, stepNo: index + 1 }));

    setAnswersToQ1([
      {
        ...targetAnswer,
        _destroy: true,
      },
      ...removedAnswers,
      ...unRemovedAnswers,
    ]);
  };

  const exchangeStepAnswers = (currentStepNo: number, targetStepNo: number) => {
    const findAnswerValue = (stepNo: number) =>
      answersToQ1.find(answer => !answer._destroy && answer.stepNo === stepNo)
        ?.value;

    const exchangeAnswerValue = (
      answer: ICurrentAnswer,
      exchangeValue: string,
    ) => ({
      ...answer,
      value: exchangeValue,
    });

    const currentValue = findAnswerValue(currentStepNo);
    const targetValue = findAnswerValue(targetStepNo);

    const exchangedAnswers = answersToQ1.map(answer => {
      switch (answer.stepNo) {
        case currentStepNo:
          return exchangeAnswerValue(answer, targetValue ?? answer.value);
        case targetStepNo:
          return exchangeAnswerValue(answer, currentValue ?? answer.value);
        default:
          return answer;
      }
    });

    setAnswersToQ1(exchangedAnswers);
  };

  const incrementStepAnswer = (currentStepNo: number) =>
    exchangeStepAnswers(currentStepNo, currentStepNo + 1);

  const decrementStepAnswer = (currentStepNo: number) =>
    exchangeStepAnswers(currentStepNo, currentStepNo - 1);

  return (
    <div className={styles.issue}>
      <div className={styles.header}>
        <span className={styles.no}>
          WORK
          {props.issue?.no && props.issue?.no < 10
            ? `0${props.issue?.no}`
            : props.issue?.no}
        </span>
        <p className={styles.title}>{props.issue?.title}</p>
        <p className={styles.read}>
          行動した結果を元に、ステップを書き換えたり、書き加えたりしてみよう。
          <br />
          <br />
          操作：「項目を追加する」ボタンをクリックすると項目を増やすことができます。「削除」ボタンをクリックすると、項目を削除することができます。
        </p>
      </div>
      <div className={styles.hint}>
        <p className={styles.title}>ステップを修正する際のヒント</p>
        <div className={styles.hintList}>
          <HintCard title={"イレギュラーを想定する"} width={"100%"}>
            ステップを進めていくときに、予期せぬ事態によってうまくゴールまで進められなくなることがあるかもしれない。
            <br />
            イレギュラーを想定して、それを乗り越えるための方法を考えながらステップに追記しよう。
          </HintCard>
        </div>
      </div>
      {question && (
        <div className={styles.question}>
          <div className={styles.tree}>
            <TreeCard
              title={"実現したいと思う解決のアイデア"}
              text={lastAnswer || "回答データがありません。"}
            />
            <ul className={styles.branchList}>
              {answersToQ1
                .filter(answer => !answer._destroy)
                .sort((a, b) => b.stepNo - a.stepNo)
                .map((answer, index, array) => (
                  <li
                    key={`${answer.id}${answer.stepNo}`}
                    className={styles.branch}
                  >
                    <div className={styles.labelWrapper}>
                      <span className={styles.label}>
                        ステップ{answer.stepNo}
                      </span>
                    </div>
                    <div className={styles.inputField}>
                      <TextInput
                        id={answer.id}
                        value={answer.value}
                        width={"100%"}
                        handleChange={e => changeAnswer(e)}
                      />
                    </div>
                    <ExchangeStepAnswerButton
                      index={index}
                      length={array.length - 1}
                      incrementStepAnswer={() =>
                        incrementStepAnswer(answer.stepNo)
                      }
                      decrementStepAnswer={() =>
                        decrementStepAnswer(answer.stepNo)
                      }
                    />
                    <div className={styles.deleteButton}>
                      <Button
                        variant={"secondary"}
                        width={"80px"}
                        height={"37px"}
                        fontSize={"14px"}
                        handleClick={() => removeAnswer(answer.id)}
                      >
                        <IconDelete />
                        削除
                      </Button>
                    </div>
                  </li>
                ))}
            </ul>
            <div className={styles.buttonWrapper}>
              <Button
                width={"150px"}
                height={"37px"}
                fontSize={"14px"}
                handleClick={() => addAnswerField(question)}
              >
                <IconAdd />
                項目を追加する
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Issue2;
