/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";

type ContextState = {
  scrollTo(scrollID?: string): void;
};

export const ScrollContext = React.createContext<ContextState>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  scrollTo: (scrollID?: string) => {},
});

function scrollToId(id: string, time: number) {
  setTimeout(() => {
    let element;
    if (id) {
      element = document.getElementById(id);
    }
    if (element) {
      window.scrollTo(0, element.offsetTop - 80);
    } else {
      window.scrollTo(0, 0);
    }
  }, time);
}

function scrollToNow(scrollID?: string) {
  scrollToId(scrollID ? scrollID : "", 0);
}

export default (props: any) => {
  const children = props.children as React.ReactElement;
  React.useEffect(() => {
    window.addEventListener("popstate", () => {
      const id = window.location.hash;
      if (id) {
        scrollToId(id.replace("#", ""), 200);
      }
      setTimeout(() => window.scrollTo(0, 0), 0);
    });
  }, []);
  return (
    <ScrollContext.Provider value={{ scrollTo: scrollToNow }}>
      {children}
    </ScrollContext.Provider>
  );
};
