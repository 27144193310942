import React from "react";
import clsx from "clsx";

import { IActiveLearningQuestion } from "../../../state";
import { IAnswerCorporateInitiatives } from "../../../constants/active_learning";
import Button from "../../../atoms/ActiveLearning/Button";
import TextInputCell from "../../../atoms/ActiveLearning/TextInputCell";
import { ReactComponent as IconAdd } from "../../../assets/images/ActiveLearning/icon_add.svg";

import styles from "./CorporateInitiativesTable.module.scss";

type CorporateInitiativesTableProps = {
  isTeacher?: boolean;
  answers: IAnswerCorporateInitiatives[];
  handleTextAnswerChange?: (
    e: any,
    attrName: "interest" | "reason" | "activity",
  ) => void;
  question?: IActiveLearningQuestion;
  addAnswerField?: (question: IActiveLearningQuestion) => void;
};

export const CorporateInitiativesTable: React.FC<CorporateInitiativesTableProps> = ({
  isTeacher,
  answers,
  handleTextAnswerChange,
  question,
  addAnswerField,
}) => {
  return (
    <div
      className={clsx(
        styles.corporateInitiativesTable,
        isTeacher && styles.isTeacher,
      )}
    >
      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              <th className={styles.interest}>企業名</th>
              <th className={styles.reason}>取り組み/届けている価値</th>
              <th className={styles.activity}>取り上げた理由</th>
            </tr>
          </thead>
          <tbody>
            {answers.map(answer => (
              <tr key={answer.id}>
                <td className={clsx(styles.textInput, styles.interest)}>
                  <TextInputCell
                    name={answer.id}
                    value={answer.interest}
                    handleChange={e =>
                      handleTextAnswerChange &&
                      handleTextAnswerChange(e, "interest")
                    }
                  />
                </td>
                <td className={clsx(styles.textInput, styles.reason)}>
                  <TextInputCell
                    name={answer.id}
                    value={answer.reason}
                    handleChange={e =>
                      handleTextAnswerChange &&
                      handleTextAnswerChange(e, "reason")
                    }
                  />
                </td>
                <td className={clsx(styles.textInput, styles.activity)}>
                  <TextInputCell
                    name={answer.id}
                    value={answer.activity}
                    handleChange={e =>
                      handleTextAnswerChange &&
                      handleTextAnswerChange(e, "activity")
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          width={"152px"}
          height={"38px"}
          fontSize={"14px"}
          handleClick={() =>
            addAnswerField && question && addAnswerField(question)
          }
        >
          <IconAdd />
          項目を追加する
        </Button>
      </div>
    </div>
  );
};
export default CorporateInitiativesTable;
